<div class="toolbar-mobile" style="position: fixed; bottom: 0px; width: 103%;">
    <div (click)="navigateTo('home')">
      <img [src]="'../../../../assets/' + homeSelectedIcon" alt="" class="img-mob">
      <br>
      <span [class.selected]="selectedItem === 'home'">Home</span>
    </div>
    <div (click)="navigateTo('discover')">
      <img [src]="'../../../../assets/' + discoverSelectedIcon" alt="" class="img-mob-long">
      <br>
      <span [class.selected]="selectedItem === 'discover'">Discover</span>
    </div>
    <div (click)="navigateTo('dashboard')">
      <img [src]="'../../../../assets/'+ dahboardSelectedIcon" alt="" class="img-mob-long">
      <br>
      <span [class.selected]="selectedItem === 'dashboard'">My Portfolio</span>
    </div>
    <div (click)="navigateTo('watchlist')">
      <img [src]="'../../../../assets/' + watchListSelectedIcon" alt="" class="img-mob-long">
      <br>
      <span [class.selected]="selectedItem === 'watchlist'">Watchlist</span>
    </div>
  </div>
  