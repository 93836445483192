import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTypeService } from '../../services/screen-type.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RiskProfileResultComponent } from '../risk-profile-result/risk-profile-result.component';
import { Router } from '@angular/router';
import { ApiServiceService } from '../../services/api-service.service';
import { LoginComponent } from '../auth/login/login.component';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { OnboardingService } from '../../services/onboarding.service';
import { MoengageService } from '../../services/moengage.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-risk-profile-questions',
  templateUrl: './risk-profile-questions.component.html',
  styleUrls: ['./risk-profile-questions.component.scss'],
})
export class RiskProfileQuestionsComponent implements OnInit {
  questionJSON: any[] = [];
  loading: boolean = false;
  usernameToolBar: boolean = false;
  userName: string = '';
  questionHeader: string = '';
  selectedOption: any;
  step_idValue: number = 6;
  pageNumber: number = 2;
  riskScore: number = 0;
  riskType: string = '';
  selectedQuestion: any;
  selectedQuestionObj: any;
  allRiskProfileList:any;
  suitabilityData: any;

  constructor(
    public screenType: ScreenTypeService,
    public dialog: MatDialog,
    private route: Router,
    private apiService: ApiServiceService,
    private _toastrService: ToastrService,
    private user_service: UserService,
    private onboardingService: OnboardingService,
    private cdr: ChangeDetectorRef,
    private moengageService: MoengageService,
  ) {}

  ngOnInit() {
    this.onboardingData();
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if (token && data.customer_meta_info.is_registered) {
      console.log('ngOnInit token data', data);
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
    }
  }

  setDisplay(){
    let found=true;
    this.allRiskProfileList.map((item:any)=>{
      if(item.completed && found){
        item['show']=false;
      }else{
        item['show']=found ? true:false ;
        found=false;
      }
    })
    console.log("this.allRiskProfileList....",this.allRiskProfileList)
  }

  onboardingData() {
    this.onboardingService.getOnboardingData(this.user_service.getHashKey()).subscribe((res: any) => {
      console.log('got risk profile array',res.data.riskProfile);
      this.allRiskProfileList= res.data.riskProfile;
      this.setDisplay();
      const allCompleted = res.data.riskProfile.every((step:any) => step.completed);
      console.log('sslslslslsl',this.allRiskProfileList);
      if (allCompleted) {
        this.riskScore = res.data.suitability_assesment[0].selected_product.riskScore;
        this.riskType = this.riskScore >= 15 && this.riskScore <= 24 ? 'Low' :
               this.riskScore >= 25 && this.riskScore <= 34 ? 'Medium' : 
               this.riskScore >= 35 ? 'High' : '';
        this.suitabilityData = res.data.suitability_assesment[0];
        this.openRiskProfileResultDialog();
    } else {
        const nextStepIndex = res.data.riskProfile.findIndex((step:any) => !step.completed && step.step_id !== 6);
        this.step_idValue = 6 + nextStepIndex;
        this.pageNumber = 1 + nextStepIndex;
        if (nextStepIndex !== -1) {
            this.questionHeader = res.data.riskProfile[nextStepIndex].parameters[0].label;
            this.questionJSON = res.data.riskProfile[nextStepIndex].parameters[0].options;
        }
        
    }
    if(res.data.riskProfile[0].completed == false) {
      this.questionHeader = res.data.riskProfile[0].parameters[0].label;
      this.questionJSON = res.data.riskProfile[0].parameters[0].options;
    }
    });
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      this.route.navigate(['login']);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: false,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

  onListSelectionChange(data: any) {
    console.log('selected data ---->>', data);
    this.selectedOption = data;
    this.allRiskProfileList.map((item:any)=>{
      if(item.step_id==data.step_id){
        item.parameters[0].selected=data.selectedItem;
        this.step_idValue = item.step_id;
      }
    });
    console.log('allRiskProfileList ---->>', this.allRiskProfileList);
  }

  goToPreviousStep(completed:boolean,stepId:number, pageNumber: any){
    let currentId:number=0;
    this.pageNumber = pageNumber - 1;
    this.allRiskProfileList.map((item:any)=>{
      if(item.show) {currentId=item.step_id;}
    })
    if((currentId-1)==5){
      this.pageNumber = 1;
      console.log("Go to PAN")
      this.route.navigate([`pan/${this.user_service.getHashKey()}`]);
    }else{
      this.allRiskProfileList.map((item:any)=>{
        if(item.step_id==(currentId -1)) {
          item['show']=true
        }else{
          item['show']=false
        }
      })
    }
    console.log("this.allRiskProfileList...",this.allRiskProfileList)
    
  }

  onContinueRiskProfile(completed:boolean,stepId:number, questionLabel: any){
    if(this?.selectedOption?.selectedItem){
      let reqData = {
        parameters: { selected: this.selectedOption.selectedItem },
        step_id: this.step_idValue,
        onboarding_detail_id: this.user_service.getOboardingDetailID(),
        onboarding_id: this.user_service.getHashKey(),
      };
  
      this.apiService.continueRiskProfile(reqData).subscribe((res: any) => {
        if(res && res.message == 'success') {
        // call moe event
          let eventData = this.onboardingService.getMoeEventDetails();
          let eventMoeData = {
          'onboardingId': eventData?.onboardingId,
          'plan': eventData?.plan,
          'basketPrice': eventData?.basketPrice,
          'basketRisk': eventData?.basketRisk,
          'status': eventData?.status,
          'subStatus': questionLabel,
          'basketName': eventData?.basketName,
        }
        this.moengageService.onboardingEvent(eventMoeData);
        //
          if(res?.data?.riskProfile){
            this.pageNumber = this.pageNumber + 1;
            this.allRiskProfileList = res.data.riskProfile;
            this.selectedOption=undefined;
            this.setDisplay();
          }
          // if(stepId+1==18){
          // this.suitabilityData = res.data.suitability_assesment[0];
          // this.riskScore = res.data.suitability_assesment[0].selected_product.riskScore;
          // this.riskType = this.riskScore <= 60 ? 'Medium' : 'High';
          // this.openRiskProfileResultDialog();
          // }
          if (this.allRiskProfileList[this.allRiskProfileList.length - 1].step_id === stepId) {
            this.onboardingService.setValueReviewAnswer(false);
          }
          this.onboardingService.sharedValue$.subscribe((val) => {
              const allCompleted = res.data.riskProfile.every((step:any) => step.completed);
              console.log('sslslslslsl',this.allRiskProfileList);
              if (allCompleted && !val) {
              this.riskScore = res.data.suitability_assesment[0].selected_product.riskScore;
              this.riskType = this.riskScore >= 15 && this.riskScore <= 24 ? 'Low' :
                     this.riskScore >= 25 && this.riskScore <= 34 ? 'Medium' : 
                     this.riskScore >= 35 ? 'High' : '';
      
              this.suitabilityData = res.data.suitability_assesment[0];
              // window.location.reload();
              this.onboardingData();
              }
              else {
                this.allRiskProfileList.map((item:any)=>{
                  if(item.step_id==(stepId+1)) {
                    item['show']=true
                  }else{
                    item['show']=false
                  }
                })
              }
          });
        }
      });

    }else{
      if(stepId==16){
        const allCompleted = this.allRiskProfileList.every((step:any) => step.completed);
        if (allCompleted) {
          this.riskScore = this.suitabilityData.selected_product.riskScore;
          this.riskType = this.riskScore >= 15 && this.riskScore <= 24 ? 'Low' :
               this.riskScore >= 25 && this.riskScore <= 34 ? 'Medium' : 
               this.riskScore >= 35 ? 'High' : '';

          this.suitabilityData = this.suitabilityData;
          this.openRiskProfileResultDialog();
          }
      }
      else if(completed){
        this.allRiskProfileList.map((item:any)=>{
          if(item.step_id==(stepId+1)) {
            item['show']=true
          }else{
            item['show']=false
          }
        });
        if (this.allRiskProfileList[this.allRiskProfileList.length - 1].step_id === stepId) {
          // window.location.reload()
          this.onboardingData();
        }
      }
      else{
        this._toastrService.error('Please select an option');
      }
    }
  }

  openRiskProfileResultDialog() {
    const dialogRef = this.dialog.open(RiskProfileResultComponent, {
      height: '95%',
      disableClose: true,
      autoFocus: false,
      width: '100%',
      data: {
        riskTypeData: this.riskType,
        suitabilityAssessment: this.suitabilityData,
      },
    });
    dialogRef.componentInstance.reviewClicked.subscribe(() => {
      this.onReviewAnswers();
    });
  }

  onReviewAnswers() {
    console.log('all risk ...', this.allRiskProfileList);
    this.allRiskProfileList.map((item:any)=>{
      if(item.step_id==6) {
        item['show']=true;
      }else{
        item['show']=false;
      }
    })
  }
}
