import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenBreakpointService {
  breakpointState: 'mob' | 'tab' | 'lap' | 'desk' | '' = '';
  observeBreakpointState: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private _breakPointObserver: BreakpointObserver) {
    this.observerBreakpoints();
  }

  private observerBreakpoints() {
    this._breakPointObserver
      .observe([Breakpoints.Handset])
      .subscribe((data) => {
        if (data.matches) {
          this.breakpointState = 'mob';
          this.observeBreakpointState.next(this.breakpointState);
        }
      });
    this._breakPointObserver
      .observe([
        Breakpoints.Tablet,
        Breakpoints.HandsetLandscape,
        Breakpoints.WebPortrait,
      ])
      .subscribe((data) => {
        if (data.matches) {
          this.breakpointState = 'tab';
          this.observeBreakpointState.next(this.breakpointState);
        }
      });
    this._breakPointObserver
      .observe([Breakpoints.WebLandscape, Breakpoints.Large])
      .subscribe((data) => {
        if (data.matches) {
          this.breakpointState = 'lap';
          this.observeBreakpointState.next(this.breakpointState);
        }
      });
    this._breakPointObserver.observe([Breakpoints.XLarge]).subscribe((data) => {
      if (data.matches) {
        this.breakpointState = 'desk';
        this.observeBreakpointState.next(this.breakpointState);
      }
    });
  }
}
