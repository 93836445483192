import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiServiceService } from '../../services/api-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-basket-more-details',
  templateUrl: './basket-more-details.component.html',
  styleUrls: ['./basket-more-details.component.scss'],
})
export class BasketMoreDetailsComponent implements OnInit {
moreDetails: any;
videoURL: any;
thumbnailUrl: any;
  constructor(
    private apiService: ApiServiceService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<BasketMoreDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  closeDialog(): void {
    this.dialogRef.close();
  }

  getVideo(videoUrl: any, thumbnailUrl: any) {
    this.videoURL = `${environment.apiUrlFiles}/file/${videoUrl}`;
    this.thumbnailUrl = `${environment.apiUrlFiles}/file/${thumbnailUrl}`;
  }

  ngOnInit() {
    this.moreDetails = this.data.moreDetails;
    console.log('ksjhskshjshj', this.moreDetails);
    this.getVideo(this.moreDetails.video_url, this.moreDetails.image_url);
  }

}