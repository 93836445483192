import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTypeService } from '../../services/screen-type.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../auth/login/login.component';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DomSanitizer, Meta, SafeHtml, SafeUrl, Title } from '@angular/platform-browser';
import {
  trigger,
  transition,
  animate,
  style,
  state,
} from '@angular/animations';
import { ApiServiceService } from '../../services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MoengageService } from '../../services/moengage.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';
import { WebSocketService } from '../../services/web-socket.service';
import { UserDeleteComponent } from '../user-delete/user-delete.component';
declare let Moengage: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  testimonials: any[] = [];
  activeIndex = 0;

  setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  selectedFilter: string = '3 Year';
  isSignup: boolean = true;
  isKYC: boolean = false;
  isInvest: boolean = false;
  savingsText: string = 'Grow Your Savings Now!';
  wealthCreationText: string = 'Start Wealth Creation';
  homePageJSONResponse: any;
  startIndex = 0;
  visibleItems: any;
  visibleItemsMobile: any;
  usernameToolBar: boolean = false;
  userName: string = '';
  isHoveredSignUp: boolean = true;
  isHoveredKYC: boolean = false;
  isHoveredSubscribe: boolean = false;
  isHoveredInvest: boolean = false;
  enlargedIndex: number | null = null;
  centerCardIndex: number = 2;
  chart: any;
  isFAQRoute: string = '';
  categoriesList: any[] = [];
  isLoggedIn: boolean = false;
  iconHeight = '100px';
  hoveredIndex = -1;
  thumbnailUrl: string = '';
  portfolio_url: string = '';
  benchmarkChartData: any;
  loadedIndexes: boolean[] = [];
  mobileLoadedIndexes: boolean[] = [];
  blogsData: any[] = [];
  getAuthToken: any;
  mobileNumber: any;
  mediaSectionData: any[] = [];
  isAtStart: boolean = true;
  isAtEnd: boolean = false;
  isAtStartTestimonial: boolean = true;
  isAtEndTestimonial: boolean = false;
  isAtStartLeaders: boolean = true;
  isAtEndLeaders: boolean = false;
  leaders: any[] = [];

  visibleItemCount = 5;
  currentTime: number = 0;
  private eventTriggered: boolean = false;

  mobile_number_newScreen: any;
  customer_Id: any;
  isActiveSubscription: boolean = false;

  get visibleData() {
    return this.homePageJSONResponse[9].content.slice(0, this.visibleItemCount);
  }

  get hasMoreData() {
    return this.homePageJSONResponse[9].content.length > this.visibleItemCount;
  }

  isExpanded = false;

  toggleVisibility() {
    if (this.isExpanded) {
      this.visibleItemCount = 5;
    } else {
      this.visibleItemCount = this.homePageJSONResponse[9].content.length;
    }
    this.isExpanded = !this.isExpanded;
  }

  onMouseEnter(index: number) {
    this.hoveredIndex = index;
  }

  onMouseLeave() {
    this.hoveredIndex = -1;
  }


  redirectToLinkedIn(url: any) {
    window.open(url, '_blank');
  }

  get buttonLabel() {
    return this.isExpanded ? 'View Less' : 'View More';
  }

  constructor(
    public screenType: ScreenTypeService,
    public dialog: MatDialog,
    private route: Router,
    private apiService: ApiServiceService,
    private _toastSerivce: ToastrService,
    private sanitizer: DomSanitizer,
    private router: ActivatedRoute,
    private moengageService: MoengageService,
    private elementRef: ElementRef,
    private websocket: WebSocketService,
    private meta: Meta,
    private title: Title
  ) {
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  handleLinkClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      const href = target.getAttribute('href');
      if (href && href.startsWith('app://')) {
        event.preventDefault();
        this.handleCustomLink(href);
      }
    }
  }

  handleCustomLink(href: string): void {
    if (href === 'app://delete-account') {
      if (!this.getAuthToken) {
        this._toastSerivce.error('Please login to delete your account');
      }
      else {
        const dialogRef = this.dialog.open(UserDeleteComponent, {
          height: 'auto',
          disableClose: false,
          autoFocus: false,
          width: 'auto'
        });
      }
      // this.deleteAccount();
    }
  }

  selectFilter(filter: string): void {
    this.selectedFilter = filter;
  }

  replaceBreakLine(data: any) {
    return data.replace('<br>', '');
  }

  scrollTop() {
    scroll(0, 0);
  }

  addMetaTags() {
    this.title.setTitle('Invest in Stock Baskets - DIY Platform for Stock Investments | Equentis - MultiplyRR');
    this.meta.addTags([
      { name: 'description', content: 'Multiply your savings by investing in stock market with MultiplyRR. Invest in stock baskets with multiple high-growth stocks in just 3 steps. Visit now!' }
    ]);
  }

  ngOnInit() {
    this.addMetaTags();
    // this.getBenchmarkData();
    localStorage.removeItem('categoryNameMoe');
    localStorage.removeItem('categoryClickCalled');
    this.getHomePageData();
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if (token) {
      const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
      this.apiService.getTokenData().subscribe((res: any) => {
        if(res && res.message == 'success') {
            if(res.result?.is_other_product_active) {
              const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
                height: 'auto',
                disableClose: true,
                autoFocus: false,
                backdropClass: 'backdropBackground',
                width: 'auto',
              });
            }
            else if (res.result?.customer_meta_info?.is_registered) {
              const name = res.result?.first_name || res.result?.full_name || '';
              this.customer_Id = res.result?.customer_id;
              this.usernameToolBar = true;
              this.userName = name;
              this.isLoggedIn = true;
              // check active subscription
              this.checkActiveSubscription();
              //
            }
            else {
              this.isLoggedIn = false;
            }
          }
      })
    }
    // this.router.paramMap.subscribe((params: any) => {
    //   let customData = history.state.customData;
    //   this.isFAQRoute = customData.customData;
    //   console.log('got prevoius route', customData.customData);
    // });
    // if (this.isFAQRoute) {
    //   this.scrollToFAQSection(0);
    // }
    this.getBlogsData();
  }

  onPanelToggle(data: any, isExpanded: boolean) {
    console.log(data.name, data.detail);
    if(isExpanded) {
      let eventData = {
        'faqQuestion': data.name,
        'isExpanded': isExpanded
      };
      this.moengageService.viewedFAQs(eventData);
    }
  }
  

  requestCallBack() {
    if (!this.getAuthToken) {
      this.openLogin('')
    }
    else {
      this.apiService.requestCallBack().subscribe((res: any) => {
        if (res && res.success) {
          this._toastSerivce.success('You will get a call within 24 hours');
        }
        else {
          this._toastSerivce.error('Request not sent');
        }
      });
    }
  }

  getBlogsData() {
    this.apiService.getBlogsData().subscribe((res: any) => {
      if (res && res.message == "success") {
        this.blogsData = res.result;
      }
    })
  }

  getFormattedDate(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  goToBlogs(url: any, data: any) {
    console.log(data)
    let eventData = {
      blogTitle: data.title,
      blogImage: data.image.src,
      isSubscribed : this.isActiveSubscription,
      blogURL: data.link
    }
    this.moengageService.blogClick(eventData);
    window.open(url, '_blank');
  }

  getBenchmarkData() {
    this.apiService.benchmarkData().subscribe((res: any) => {
      console.log(res, 'benchmark data...');
      if (res && res.success && res.data && res.data.data) {
        this.benchmarkChartData = res.data.data;
        this.initializeChartOptions(res.data.data);
      }
    });
  }

  initializeChartOptions(data: any) {
    console.log('chart data', data);
    if (!data || !data.benchmark_comparison) {
      console.error('Invalid data format');
      return;
    }

    // Safely assign to chartOptions using a new object
    this.chartOptions = {
      chart: {
        type: 'areaspline'
      },
      title: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories: data.benchmark_comparison.axis_date || [],
        title: {
          text: ''
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      tooltip: {
        shared: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5
        }
      },
      series: [
        {
          name: data.benchmark_comparison.jar_name || 'Jar',
          data: data.benchmark_comparison.axis_jar || [],
          type: 'areaspline',
          color: '#E49944'
        },
        {
          name: data.benchmark_comparison.benchmark_name || 'Benchmark',
          data: data.benchmark_comparison.axis_benchmark || [],
          type: 'areaspline',
          color: '#7E8B99'
        }
      ],
      legend: {
        enabled: false
      },
      accessibility: {
        enabled: false
      },
    } as Highcharts.Options; // Type assertion to ensure compatibility
  }




  getPortfolioVideo(id: string, thumbnailUrl: string) {
    this.portfolio_url = `${environment.apiUrlFiles}/file/${id}`;
    this.thumbnailUrl = `${environment.apiUrlFiles}/file/${thumbnailUrl}`;
    // this.apiService.getImagefromId(id).pipe(
    //   retry(3),
    //   catchError((error) => {
    //     console.error('API call failed after 3 retries', error);
    //     return of(new Blob([])); // Handle error by returning an empty Blob
    //   })
    // ).subscribe((res: Blob) => {
    //   const videoUrl = URL.createObjectURL(res);
    //   this.portfolio_url = this.sanitizer.bypassSecurityTrustUrl(videoUrl);
    //   this.thumbnailUrl = thumbnailUrl; // Set the thumbnail URL
    //   this.initializeVideoPlayer();
    // });

    // this.portfolio_url = this.apiService.getImagefromId(id)
  }


  scrollToFAQSection(index: number) {
    const faqSection = document.getElementById('faqSectionID' + index);
    console.log('faqSection:', faqSection);
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getMediaSectionData() {
    this.apiService.getMediaService().subscribe((res: any) => {
      if (res && res.message == "success") {
        this.mediaSectionData = res.result;
        console.log('this.mediaSectionData', this.mediaSectionData);
      }
    })
  }

  getLeadersData() {
    this.apiService.getLeadersService().subscribe((res: any) => {
      if (res && res.message == "success") {
        this.leaders = res.result;
        console.log('this.leaders', this.mediaSectionData);
      }
    })
  }

  getTestimonialData() {
    this.apiService.getTestimonialService().subscribe((res: any) => {
      if (res && res.message == "success") {
        this.testimonials = res.result;
        console.log('this.testimonials', this.testimonials);
      }
    })
  }

  checkActiveSubscription() {
      this.apiService.getPurchasedBasketData(this.customer_Id).subscribe((res: any) => {
        if(res && res.success && res.data.data.length>0) {
         this.isActiveSubscription = true;
         localStorage.setItem('ActiveSubscription', 'true');
        }
        else {
         this.isActiveSubscription = false;
         localStorage.setItem('ActiveSubscription', 'false');
        }
      });
  }

  getHomePageData() {
    this.apiService.homePageService().subscribe((res: any) => {
      if (res.message == 'success') {
        // get mediaSection data
        this.getMediaSectionData();
        //
        // get leaders data
        this.getLeadersData();
        //
        // get testimonial data
        this.getTestimonialData();
        //

        this.homePageJSONResponse = res.result;
        //get categories list
        this.apiService.getCategoriesList().subscribe((res: any) => {
          if (res && res.message == 'success') {
            this.categoriesList = res.result;
            this.visibleItems = res.result;
            console.log('hshshshsh', this.visibleItems);
            this.visibleItems.forEach((_: any, index: any) => {
              this.loadedIndexes[index] = false;
            });
            for (let data of res.result) {
              //calling api to get real image
              this.apiService.getImagefromId(data.image_url).subscribe((res: any) => {
                data.image_url = URL.createObjectURL(res);
              })
              //
            }
            this.visibleItemsMobile = res.result.slice(
              0,
              1
            );
            this.visibleItemsMobile.forEach((_: any, index: any) => {
              this.mobileLoadedIndexes[index] = false;
            });
          }
        });
        this.getPortfolioVideo(this.homePageJSONResponse[3].content[0].Url, this.homePageJSONResponse[3].content[0].thumbnail);
        //

        // this.visibleItems = this.homePageJSONResponse[0].content.slice(0, 5);
        // this.visibleItemsMobile = this.homePageJSONResponse[0].content.slice(
        //   0,
        //   1
        // );
      }
    });
  }

  onImageLoad(index: number) {
    this.loadedIndexes[index] = true;
  }

  onMobileImageLoad(index: number) {
    this.mobileLoadedIndexes[index] = true;
  }

  onMobileImageError(index: number) {
    // Handle error case if needed
    console.error(`Mobile image at index ${index} failed to load.`);
  }

  mouseHover(evt: any) {
    if (evt == 'KYC') {
      this.isHoveredKYC = true;
      this.isHoveredSignUp = false;
      this.isHoveredSubscribe = false;
      this.isHoveredInvest = false;
      this.isKYC = true;
      this.isSignup = false;
      this.isInvest = false;
    } else if (evt == 'signup') {
      this.isHoveredSignUp = true;
      this.isHoveredKYC = false;
      this.isHoveredSubscribe = false;
      this.isHoveredInvest = false;
      this.isKYC = false;
      this.isSignup = true;
      this.isInvest = false;
    } else if (evt == 'Subscribe') {
      this.isHoveredSubscribe = true;
      this.isHoveredSignUp = false;
      this.isHoveredKYC = false;
      this.isHoveredInvest = false;
      this.isKYC = true;
      this.isSignup = false;
      this.isInvest = false;
    } else {
      this.isInvest = true;
      this.isHoveredInvest = true;
      this.isHoveredSignUp = false;
      this.isHoveredKYC = false;
      this.isHoveredSubscribe = false;
      this.isSignup = false;
      this.isKYC = false;
    }
  }

  enlargeCard(index: number): void {
    this.enlargedIndex = index;
    this.centerCardIndex = -1;
  }

  resetCard(): void {
    this.enlargedIndex = null;
    this.centerCardIndex = 2;
  }

  hoverCard(index: number) {
    this.hoveredIndex = index;
  }

  unhoverCard(index: number) {
    if (this.hoveredIndex === index) {
      this.hoveredIndex = -1;
    }
  }

  scrollLeadership(direction: number) {
    const container = document.querySelector('.leadership-container') as HTMLElement | null;
    if (container) {
      const itemWidth = (container.querySelector('.leadership-item') as HTMLElement | null)?.offsetWidth || 0;
      const gap = 20;
      const scrollAmount = itemWidth + gap;
      this.isAtStartLeaders = container.scrollLeft === 0;
      this.isAtEndLeaders = container.scrollLeft + container.clientWidth >= container.scrollWidth;
      container.scrollBy({
        left: direction * scrollAmount * 3,
        behavior: 'smooth'
      });
    }
  }
  
  


  scroll(direction: number) {
    const track = document.querySelector('.carousel-track') as HTMLElement | null;
    if (track) {
      const card = track.querySelector('.carousel-card') as HTMLElement | null;
      if (card) {
        const cardWidth = card.clientWidth + 18; // Card width + gap
        track.scrollLeft += direction * cardWidth * 3; // Scroll by 3 cards at a time

        // Update flags to check if at start or end
        this.isAtStart = track.scrollLeft === 0;
        this.isAtEnd = track.scrollLeft + track.clientWidth >= track.scrollWidth;
      }
    }
  }

  scrollTestimonial(direction: number) {
    const track = document.querySelector('.testimonial-track') as HTMLElement | null;
    if (track) {
      const cardWidth = (track.querySelector('mat-card') as HTMLElement | null)?.offsetWidth || 0;
      const gap = parseInt(getComputedStyle(track).gap || '0', 10);
      const scrollAmount = cardWidth + gap;
      // Update flags to check if at start or end
      this.isAtStartTestimonial = track.scrollLeft === 0;
      this.isAtEndTestimonial = track.scrollLeft + track.clientWidth >= track.scrollWidth;
      track.scrollBy({
        left: direction * scrollAmount * 3,
        behavior: 'smooth'
      });
    }
  }


  scrollMobile(direction: number): void {
    if (direction === 1 && this.startIndex + 1 < this.categoriesList.length) {
      // Move forward and show the next set of items
      this.startIndex++;
    } else if (direction === -1 && this.startIndex > 0) {
      // Move backward and show the previous set of items
      this.startIndex--;
    }

    this.visibleItemsMobile = this.categoriesList.slice(
      this.startIndex,
      this.startIndex + 1
    );
}


  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      this.route.navigate(['login']);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: true,
        backdropClass: 'backdropBackground',
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

  openPlayStore() {
    let eventDetails = {
      Active_Subscription: this.isActiveSubscription,
      Page_URL: 'Ready to multiply your savings?',
      downloadAppButton: 'Android'
    }
    this.moengageService.appDownload(eventDetails);
    window.open('https://play.google.com/store/apps/details?id=com.equentis.multiplyrr&pli=1');
  }

  openAppStore() {
    let eventDetails = {
      Active_Subscription: this.isActiveSubscription,
      Page_URL: 'Ready to multiply your savings?',
      downloadAppButton: 'iOS'
    }
    this.moengageService.appDownload(eventDetails);
    window.open('https://apps.apple.com/us/app/equentis-multiplyrr/id6503437792');
  }

  openLoginNewScreen(data: string) {
    if (this.getAuthToken) {
      let eventData = {
        Active_Subscription: this.isActiveSubscription,
        Page_URL: 'Ready to multiply your savings?'
      }
      this.moengageService.beiginNowClick(eventData);
      this.route.navigate(['discover']);
    }
    else {
      const isMobileView = this.screenType.breakpointState === 'mob';
      if (isMobileView) {
        this.route.navigate(['login']);
      } else {
        const dialogRef = this.dialog.open(LoginComponent, {
          height: 'auto',
          disableClose: true,
          autoFocus: true,
          backdropClass: 'backdropBackground',
          width: isMobileView ? '100%' : '100%',
          data: {
            mobileNumber: this.mobile_number_newScreen
          },
        });
      }
    }
  }

  setCurrentTime(event: any) {
    this.currentTime = event.target.currentTime;
    if(!this.eventTriggered && this.currentTime >= 10) {
      let eventData = {
        'videoUrl' : this.portfolio_url,
        'watchedDuration': Math.round(this.currentTime),
        'videoThumbnailUrl' : this.thumbnailUrl,
        'isSubscribed' : this.isActiveSubscription
        }
        this.moengageService.videoWatchedHomePage(eventData);
        this.eventTriggered = true;
    }
  }

  videoPaused(event: any) {
    const watchedDuration = Math.round(event.target.currentTime);
    if(watchedDuration > 10) {
      let eventData = {
        'videoUrl': this.portfolio_url,
        'watchedDuration': watchedDuration,
        'videoThumbnailUrl': this.thumbnailUrl,
        'isSubscribed': this.isActiveSubscription
      };
      this.moengageService.videoWatchedHome(eventData);
    }
  }

  videoClick(event: any) {
    const time = Math.round(event.target.currentTime);
    if(time == 0) {
      let eventData = {
        'videoUrl' : this.portfolio_url,
        'videoThumbnailUrl' : this.thumbnailUrl,
        'isSubscribed' : this.isActiveSubscription
        }
      this.moengageService.basketVideoPlayHomePage(eventData);
    }
  }

  goToBaskets(data: any) {
    localStorage.setItem('categoryNameMoe', data.title);
    this.route.navigate(['baskets'], { queryParams: { category_id: data.category_id } });
  }

  stripHtmlTags(input: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = input;
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  goToDiscover(data: any, eventType: any) {
    if(eventType == 'beginNow') {
      let eventData = {
        Active_Subscription: this.isActiveSubscription,
        Page_URL: this.stripHtmlTags(data)
      }
      this.moengageService.beiginNowClick(eventData);
    }
    this.route.navigate(['discover']);
  }

  mediaRoute(link: any) {
    window.open(
      link,
      '_blank'
    );
  }
}
