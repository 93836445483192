<div class="risk-profile-wrapper">
    <div class="header">
        <span class="headerText" style="margin: 0 auto;">Your Risk Profile</span>
        <!-- <span (click)="closeDialog()"><img mat-dialog-close src="../../../../assets/crossIconDark.svg" alt=""></span> -->
    </div>
    <div>
    <img *ngIf="this.screenType.breakpointState !== 'mob'" src="../../../../assets/hrDarkTagIcon.svg" alt="">
    <img *ngIf="this.screenType.breakpointState == 'mob'" src="../../../../assets/hrDarkMobIcon.svg" alt="">
    </div>
    <br>
    <div class="center">
        <div *ngIf="riskType === 'Low'">
            <img src="../../../../assets/riskoMeter/img_Risklevel_Low.svg" alt="Risk Level Low">
            <br><br>
            <span class="center risk-description">
              You prefer steady investment <br> growth with minimal losses&nbsp;
              <!-- <img src="../../../../assets/information.svg" alt="Info Icon"
                   (mouseenter)="showLowRiskTooltip = true" 
                   (mouseleave)="showLowRiskTooltip = false" 
                   style="cursor: pointer;"> -->
            </span>

            <ng-container *ngIf="showLowRiskTooltip">
              <div class="tooltip-content" style="position: absolute; background-color: #f5f5f5; padding: 10px; border-radius: 5px;">
                <div style="display: flex; align-items: flex-start; flex-direction: column; margin-top: 20px;">
                  <span class="header">What is Low Risk?</span>
                  <span class="header">Our Low Growth is characterized by:</span>
                  <ul>
                    <li>The chosen businesses may have predictable earnings growth <br> and associated risks.</li>
                    <li>Low churning strategy with a possible downside of 10%.</li>
                    <li>Medium churning strategy with targeted 10-15% downside.</li>
                    <li>We choose and oversee portfolios by emphasizing the fundamental <br> framework of our investment strategy.</li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
          
          <div *ngIf="riskType === 'Medium'">
            <img src="../../../../assets/riskoMeter/img_Risklevel_Medium.svg" alt="Risk Level Medium">
            <br><br>
            <span class="center risk-description">
              You prefer balanced investment <br> growth with slight losses&nbsp;
              <!-- <img src="../../../../assets/information.svg" alt="Info Icon"
                   (mouseenter)="showMediumRiskTooltip = true" 
                   (mouseleave)="showMediumRiskTooltip = false" 
                   style="cursor: pointer;"> -->
            </span>

            <ng-container *ngIf="showMediumRiskTooltip">
              <div class="tooltip-content" style="position: absolute; background-color: #f5f5f5; padding: 10px; border-radius: 5px;">
                <div style="display: flex; align-items: flex-start; flex-direction: column; margin-top: 20px;">
                  <span class="header">What is Medium Risk?</span>
                  <span class="header">Our Medium Growth is characterized by:</span>
                  <ul>
                    <li>Businesses selected have a high degree of predictability of <br> earnings growth and risks.</li>
                    <li>Medium churning strategy with targeted 10-15% downside.</li>
                    <li>Portfolio selection & monitoring with focus on delivering <br> strong yearly returns within the overall strategy.</li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
          
        <div *ngIf="riskType === 'High'">
            <img src="../../../../assets/riskoMeter/img_Risklevel_High.svg" alt="Risk Level High">
            <br><br>
            <span class="center risk-description">
              You prefer high investment growth <br> with higher losses&nbsp;
              <!-- <img src="../../../../assets/information.svg" alt="Info Icon"
                   (mouseenter)="showTooltip = true" 
                   (mouseleave)="showTooltip = false" 
                   style="cursor: pointer;"> -->
            </span>
            <ng-container *ngIf="showTooltip">
              <div class="tooltip-content" style="position: absolute; background-color: #f5f5f5; padding: 10px; border-radius: 5px;">
                <div style="display: flex; align-items: flex-start; flex-direction: column; margin-top: 20px;">
                  <span class="header">What is High Risk?</span>
                  <span class="header">Our High Growth is characterized by:</span>
                  <ul>
                    <li>A few businesses selected may be in "Early Stage Growth" Cycle.<br>
                        These would be characterized by greater unpredictability in their <br> earnings growth potential; however, will have a very high probability <br> of positive earnings surprise and multiple re-rating.</li>
                    <li>High churning with targeted 15-20% downside.</li>
                    <li>Portfolio construction is based on a longer investment horizon of 3+ <br> years rather than yearly returns.</li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
    </div>
    <!-- uncomment for mismatch  -->
    <span class="riskMismatch-message center" *ngIf="!suitabilityData.selected_product.product_match">The {{suitabilityData?.selected_product?.name}} you’ve selected is {{suitabilityData?.selected_product?.product_risk}} risk. It does not match your risk profile.</span>
    <br>
    <div *ngIf="!suitabilityData.selected_product.product_match && this.screenType.breakpointState !== 'mob'">
        <div class="container-text">
            <span class="head-text">Recommended  Baskets</span>
            <hr>
        </div>
    <div class="recommended-wrapper">
        
        <div class="recommeded-content">
          <mat-card *ngFor="let card of productSuggestions; let i = index" class="mat-card-content">
            <mat-card-content>
              <div class="user-card" style="flex-direction: column;">
                <div class="header-tab">
                  <span class="headerText">{{ card.name }}</span>
                  <span *ngIf="card?.pb_details?.risk_level">

                    <span class="risk-wrapper" [ngStyle]="{
                        'background-color': card?.pb_details?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                        card?.pb_details?.risk_level === 'LOW' ? '#E4F3E3' : 
                        card?.pb_details?.risk_level === 'HIGH' ? '#FFECED' : ''
                      }">
                    <span class="risk-text" [ngStyle]="{
                        'color': card?.pb_details?.risk_level === 'MEDIUM' ? '#9B6F49' : 
                        card?.pb_details?.risk_level === 'LOW' ? '#0AC01C' : 
                        card?.pb_details?.risk_level === 'HIGH' ? '#FF4347' : ''
                      }">
                      {{card?.pb_details?.risk_level | camelCase}} Risk
                    </span>
                    </span>
                  </span>
                </div>
                <div>
                  <span class="sub-headerText">{{ card.description }}</span>
                </div>
              </div>
              <div class="matcard-content" style="display: flex; justify-content: space-between; margin-top: 30px;">
                <div>
                  <div class="headText-recommended">Minimum Investment</div>
                  <div class="headData">
                    <!-- hardcoding for now -->
                    <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{ card?.pb_details?.min_investment_amount | readableNumber }}
                  </div>
                </div>
                <div>
                  <div class="headText-recommended">Investment period</div>
                  <div class="headData">
                    <!-- hardcoding for now -->
                    {{ card?.investment_horizon }}
                  </div>
                </div>
                <div>
                  <div class="headText-recommended">3Y CAGR</div>
                  <div class="headData" style="color: #0D7816;">
                    <!-- hardcoding for now -->
                    <!-- [src]="card?.pb_details.cagr < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" -->
                    <img 
                         alt="">&nbsp;{{ card.pb_details?.cagr ?? 0 }} %
                  </div>
                </div>
              </div>
              <div>
                <img src="../../../../assets/dashboardHRIcon.svg" alt="" width="100%">
              </div>
              <div class="matcard-content-recommended">
                <div>
                  <span class="headText-recommended">Basket Price: <span class="price-recommended"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{ card?.fees_details[0].details.advisory_fees | readableNumber }}</span></span>
                </div>
                <div>
                    <app-button [savingsText]="'Subscribe Now'" (click)="subscribeProduct(card, card.name, card.isHighlighted, card.code)"></app-button>
                </div>
              </div>
              <div class="more-details">
                <span class="text" (click)="basketMoreDetails(card)">More Details</span>
            </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    <!-- <div class="recommendation-wrapper">

        <mat-card class="mat-cardWrapper" *ngFor="let item of productSuggestions; let i = index" (click)="onProductSelection(item, i)">
            <mat-card-content>
                <div class="content-wrapper">
                    <div class="headerText">{{item.name}}&nbsp;&nbsp;&nbsp;</div>
                    <span class="riskType">{{item.product_risk}} Risk</span>&nbsp;<span><img src="../../../../assets/informationIcon.svg" 
                        matTooltip="This portfolio carries a medium level of risk, balancing potential returns with a moderate exposure to market fluctuations. Suitable for investors seeking a balanced approach between risk and reward"
                        alt=""></span>
                </div>
                <span style="font-size: 10px; color: #7E8B99; font-weight: 500;">{{ item.description ? item.description : '&nbsp;' }}</span>
                <span><img src="../../../../assets/hrTagsmallRiskProfile.svg" alt=""></span>
                <div class="industryContainer">
                    <div>
                    <div>5Y CAGR <img src="../../../../assets/tooltipTagIcon.svg" alt=""></div>
                    <div style="color: #5BCD65; font-weight: 600;"><img src="../../../../assets/upwardArrowIcon.svg" alt=""> 10.23%</div>
                    </div>
        
                    <div>
                        <div>Min Investment <img src="../../../../assets/tooltipTagIcon.svg" alt=""></div> 
                        <div><i class="fa fa-rupee"></i> <span style="font-weight: 800; color: #001E5A;">10,000</span></div>
                    </div>
                     
                    </div>
                    <div class="button-wrapper">
                        <div>
                            <app-button [savingsText]="'Add To Watchlist'" [isTransparentBTN]="true" [showWatchListIcon]="true" (click)="addToWatchList(item)"></app-button>
                        </div>
                        &nbsp;
                        <div>
                            <app-button [savingsText]="'Subscribe Now'" (click)="subscribeProduct(item.name, item.isHighlighted, item.code)"></app-button>
                        </div>
                    </div>
            </mat-card-content>
        </mat-card>
    </div> -->
    </div>

    <div *ngIf="!suitabilityData.selected_product.product_match && this.screenType.breakpointState == 'mob'">
    <span style="font-size: 15px; font-weight: 600; font-family: 'manrope';">Recommended Baskets</span>
    <div class="recommendation-wrapper" style="display: flex; flex-direction: row; gap: 15px;">
        <!-- [ngClass]="{'special-item': item.isHighlighted}" -->
        <mat-card class="mat-cardWrapper" style="width: 100%;" *ngFor="let item of productSuggestions; let i = index" (click)="onProductSelection(item, i)">
            <mat-card-content>
                <div class="content-wrapper">
                    <div class="headerText">{{item.name}}&nbsp;&nbsp;&nbsp;</div>
                    <!-- <span class="riskType">{{item.product_risk}} Risk</span>&nbsp;<span><img src="../../../../assets/informationIcon.svg" 
                        matTooltip="This portfolio carries a medium level of risk, balancing potential returns with a moderate exposure to market fluctuations. Suitable for investors seeking a balanced approach between risk and reward"
                        alt=""></span> -->
                </div>
                <span style="font-size: 13px; color: #7E8B99; font-weight: 500;">{{ item.description ? item.description : '&nbsp;' }}</span>
                <span style="display: flex; justify-content: center; padding: 10px;"><img src="../../../../assets/hrDarkMobIcon.svg" alt=""></span>
                <div class="industryContainer">
                    <div>
                    <div>3Y CAGR</div>
                    <div style="color: #5BCD65; font-weight: 600;"><img src="../../../../assets/upwardArrowIcon.svg" alt=""> {{ item.pb_details?.cagr }} %</div>
                    </div>
        
                    <div>
                        <div>Min Investment</div>
                        <div><i class="fa fa-rupee"></i> <span style="font-weight: 800; color: #001E5A;">{{ item?.pb_details?.min_investment_amount | readableNumber }}</span></div>
                    </div>
                     
                    </div>
                    <div class="button-wrapper" style="display: flex; flex-direction: column; gap: 15px;">
                        <div>
                            <app-button [savingsText]="'Add To Watchlist'" [isTransparentBTN]="true" [showWatchListIcon]="true"></app-button>
                        </div>
                        <div>
                            <app-button [savingsText]="'Subscribe Now'" (click)="subscribeProduct(item ,item.name, item.isHighlighted, item.code)"></app-button>
                        </div>
                    </div>
            </mat-card-content>
        </mat-card>
    </div>
    </div>
    <br>
    <div class="center" *ngIf="this.screenType.breakpointState !== 'mob'">
        <app-button [savingsText]="'Review Answers'" [isTransparentBTN]="true" (click)="reviewAnswers()" [showWatchListIcon]="false"></app-button>
        &nbsp;&nbsp;<app-button [savingsText]="'Continue'" (click)="onSubmit()" [showWatchListIcon]="false" *ngIf="suitabilityData.selected_product.product_match"></app-button>
    </div>
    <div class="center" *ngIf="this.screenType.breakpointState == 'mob'">
        <app-button [savingsText]="'Review Answers'" [isTransparentBTN]="true" (click)="reviewAnswers()" [showWatchListIcon]="false"></app-button>
    </div>
    <br>
    <div class="center" *ngIf="this.screenType.breakpointState == 'mob'">
        <app-button [savingsText]="'Continue'" (click)="onSubmit()" *ngIf="suitabilityData.selected_product.product_match" [showWatchListIcon]="false"></app-button>
    </div>

</div>