import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from '@angular/material/form-field';
import { AppearanceType, SelectData } from 'src/app/core/models/types';
import { ApiServiceService } from 'src/app/core/services/api-service.service';

@Component({
  selector: 'app-search-ifsc',
  templateUrl: './search-ifsc.component.html',
  styleUrls: ['./search-ifsc.component.scss'],
})
export class SearchIFSCComponent {
  onboardingId: any;
  bankList: Array<SelectData> = [];
  cityList: Array<SelectData> = [];
  branchList: Array<SelectData> = [];
  selectedBank!: SelectData | null;
  selectedCity!: SelectData | null;
  selectedBranch!: SelectData | null;
  ifscCode: string | undefined;
  appearance: AppearanceType = AppearanceType.outline;
  @Input() floatLabel: FloatLabelType = 'always';

  constructor(
    public dialogRef: MatDialogRef<SearchIFSCComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private apiService: ApiServiceService
  ) {}

   getSelectFieldData(
    data: any[],
    key: string,
    value: string,
    group: string = '',
    prefix: string = ''
  ) {
    let mapped_list: Array<SelectData> = [];
    data.map((item: any) => {
      let obj = new SelectData();
      obj.key = item[key];
      obj.value = prefix + item[value];
      obj.group = item[group];
      obj.detailsInfo = item;
      mapped_list.push(obj);
    });
    return mapped_list;
  }

  ngOnInit(): void {
    this.onboardingId = this.dialogData.onboarding_id;
    this.getBankDetailsAPI();
  }

  getBankDetailsAPI() {
    this.apiService.getBranchDetails({}, this.onboardingId).subscribe(
      (res: any) => {
        if (res.message == 'success' && res.data) {
          const bankdData = res.data.map((bank: any) => ({
            bankName: bank,
          }));
          this.bankList = this.getSelectFieldData(
            bankdData,
            'bankName',
            'bankName'
          );
        }
      },
      (err: any) => {
        console.log('Error in getting branch details : ', err);
      }
    );
  }

  onSelectBank() {
    if (!this.selectedBank?.key) return;
    console.log('Selected bank : ', this.selectedBank?.key);

    this.apiService
      .getBranchDetails({ bank: this.selectedBank?.key }, this.onboardingId)
      .subscribe(
        (res: any) => {
          if (res.message == 'success' && res.data) {
            const transformedData = res.data.map((city: any) => ({
              cityName: city,
            }));

            this.cityList = this.getSelectFieldData(
              transformedData,
              'cityName',
              'cityName'
            );
          }
        },
        (err: any) => {
          console.log('Error in getting branch details : ', err);
        }
      );
  }

  onSelectCity() {
    if (!(this.selectedBank?.key && this.selectedCity?.key)) return;
    this.apiService
      .getBranchDetails(
        {
          bank: this.selectedBank?.key,
          city: this.selectedCity?.key,
        },
        this.onboardingId
      )
      .subscribe(
        (res: any) => {
          if (res.message == 'success' && res.data) {
            this.branchList = this.getSelectFieldData(
              res.data,
              'branch',
              'branch',
              'ifsc'
            );
          }
        },
        (err: any) => {
          console.log('Error in getting branch details : ', err);
        }
      );
  }

  onSubmitIFSC() {
    this.dialogRef.close(this.ifscCode);
  }

  onSelectionChange(e: any, type: string) {
    console.log('onSelectionChange...', e, '...type...', type);
    if (type == 'bank') {
      this.selectedCity = null;
      this.selectedBranch = null;
      this.cityList = [];
      this.branchList = [];
      if (this.selectedBank) {
        this.onSelectBank();
      }
    }
    if (type == 'city') {
      this.selectedBranch = null;
      this.branchList = [];
      if (this.selectedCity) {
        this.onSelectCity();
      }
    }
    if (type == 'branch') {
      this.ifscCode = this.selectedBranch?.group;
    }
  }
}
