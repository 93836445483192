import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {

  private sharedValueSource = new BehaviorSubject<boolean>(false);
  sharedValue$ = this.sharedValueSource.asObservable();
  private agreementState: boolean = false;
  private eventData: any;

  constructor(private http: HttpClient) {}

  public hashKey = localStorage.getItem('hashKey');

  setValueReviewAnswer(value: boolean) {
    this.sharedValueSource.next(value);
  }

  getValueReviewAnswer() {
    return this.sharedValueSource.value;
  }

  panVerify(payload: any) {
    return this.http.post(
      `${environment.onboarding_api_url}api/kyc/3`,
      payload
    );
  }

  getOnboardingData(id: any) {
    return this.http.get(`${environment.onboarding_api_url}api/validate/${id}`);
  }

  downloadDocument(id: any) {
    return this.http.post(
      `${environment.onboarding_api_url}api/kyc/${id}/download-documents`,
      {}
    );
  }

  saveBankDetails(payload: any) {
    const headers = new HttpHeaders().set('authorization', payload.token);
    return this.http.post(
      `${environment.onboarding_api_url_cans}bank/bank-detail/${payload.ifscCode}/${payload.bankAccNo}`,
      {},
      { headers: headers }
    );
  }

  saveFatcaDetails(payload: any, token: any) {
    const headers = new HttpHeaders().set('authorization', token);
    return this.http.post(
      `${environment.onboarding_api_url_cans}fatca`,
      payload,
      { headers: headers }
    );
  }

  getKYCStatus(id: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/check-kyc/${id}`
    );
  }

  getPDFService(id: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/generate-agreement/${id}`
    );
  }

  setAgreementState(isSuccess: boolean) {
    this.agreementState = isSuccess;
  }

  getAgreementState() {
    return this.agreementState;
  }

  checkAgreementStatus(id: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/digio/agreement-status-check/${id}`
    );
  }

  uploadAgreementDigio(reqData: any, id: any) {
    return this.http.post(
      `${environment.onboarding_api_url}api/digio/upload-agreement/${id}`,
      reqData
    );
  }

  downloadAgreementDigio(reqData: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/digio/agreement-download/${reqData}`
    );
  }

  getPaymentStatus(reqData: any) {
    return this.http.post(
      `${environment.onboarding_api_url}api/agreement/30`,
      reqData
    );
  }

  createOnboarding(reqData: any, authToken: any) {
    const headers = new HttpHeaders().set(
      'authorization',
      `Bearer ${authToken}`
    );
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/onboard/lead`,
      reqData,
      { headers: headers }
    );
  }

  renewOnboarding(reqData: any, authToken: any) {
    const headers = new HttpHeaders().set(
      'authorization',
      `Bearer ${authToken}`
    );
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/renewal`,
      reqData,
      { headers: headers }
    );
  }

  updateOnboarding(reqData: any, onboardingId: any, authToken: any) {
    const headers = new HttpHeaders().set(
      'authorization',
      `Bearer ${authToken}`
    );
    return this.http.patch(
      `${environment.apiUrl}/v1/desktop/onboard/lead/${onboardingId}`,
      reqData,
      { headers: headers }
    );
  }

  getBankDetails(bankId: any, onboardingId: any) {
    const headers = new HttpHeaders().set('authorization', `${onboardingId}`);
    return this.http.get(
      `${environment.onboarding_api_url_cans}bank-detail/${bankId}`,
      { headers: headers }
    );
  }

  getMasterCountries() {
    return this.http.get(
      `${environment.onboarding_api_url}api/master/countries`
    );
  }

  getFatcaData(id: any, token: any) {
    const headers = new HttpHeaders().set('authorization', `${token}`);
    return this.http.get(`${environment.onboarding_api_url_cans}fatca/${id}`, {
      headers: headers,
    });
  }

  checkPayment(hashKey: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/check-payment/${hashKey}`
    );
  }

  getStepsData(hashKey: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/get-onboarding-steps/${hashKey}`
    );
  }

  updateSuitability(hashKey: any) {
    return this.http.get(
      `${environment.onboarding_api_url}api/risk-assesment/${hashKey}`
    )
  }

  setMoeEventDetails(data: any) {
    this.eventData = data;
    localStorage.setItem('moeEventData', JSON.stringify(data));
  }

  getMoeEventDetails() {
    if (!this.eventData) {
      const storedData = localStorage.getItem('moeEventData');
      this.eventData = storedData ? JSON.parse(storedData) : null;
    }
    return this.eventData;
  }

  confirmRiskProfile() {
    return this.http.post(
      `${environment.onboarding_api_url}api/accept-risk-profile`,
      {
        onboarding_id: localStorage.getItem('hashKey'),
      }
    );
  }
}
