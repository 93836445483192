<div class="container-fluid login-page">
    <div class="row login-wrapper">
        <div class="container-fluid" style="position: fixed; top: 20px;">
            <div class="row">
                <div class="col-1"><img src="../../../../../assets/backIcon.svg" alt="" height="25px" style="cursor: pointer; float: left;" (click)="goToPreviousPage()"></div>
                <div class="col-6"></div>
                <div class="col-4">
                    <div class="row">
                        <!-- <div class="col-6" style="float: right; cursor: pointer; float: right;"><img src="../../../../../assets/notificationIcon.svg" alt=""></div>
                        <div class="col-6"><img src="../../../../assets/userMobIcon.svg" style="float: right; cursor: pointer; float: right;"></div> -->
                    </div>
                </div>
            </div>
        </div>
        <div>
        <span class="texHeader center">{{mobileHeader}}</span>
        <img src="../../../../../assets/hrMobIcon.svg" alt="" width="100%">
        <div class="fields-wrapper" style="margin-top: 30px;" *ngIf="mobileDiv">
            <p class="font-color center">Please Enter your 10 digits mobile number</p>
            <app-input [placeholder]="'Mobile No.'" [hint]="'You will receive an OTP on this number'" [hintRequired]="true" [errorMessage]="error_Message_MobileNo" (mouseleave)="onMobileChange()" [(ngModel)]="mobileNumber" [maxLength]="10" [type]="'text'" [isSuffix]="isMobileSuffix"></app-input>
            <mat-checkbox class="example-margin" [checked]="false" [(ngModel)]="termsData" (change)="onTermsChange($event)"><span style="color: white; font-size: 13px;">I have read and agreed to the &nbsp;<span style="cursor: pointer; color: #5589FF; font-size: 13px;"><a (click)="goToPrivacyPolicy()">privacy policy,</a><a (click)="goToTermsOfService()">terms & conditions.</a></span></span></mat-checkbox>
            <br>
            <span style="color: #FF8884; font-size: 14px; cursor: pointer;" *ngIf="termsCondition">Please accept terms of service</span>
            <div class="center" style="margin-top: 30px;">
            <app-button [savingsText]="'GET OTP'" (click)="onGetOTP()"></app-button>
            </div>
        </div>
    
        <div class="fields-wrapper" style="margin-top: 30px;" *ngIf="SignUpDiv">
            <app-input [placeholder]="'Full Name'" [hintRequired]="false" [errorMessage]="error_Message_Name" [(ngModel)]="fullName" (mouseleave)="onNameChange()" [type]="'text'" [isSuffix]="isNameSuffix"></app-input>
            <app-input [placeholder]="'Email Id'" [hintRequired]="false" [errorMessage]="error_Message_Email" [(ngModel)]="emailID" (mouseleave)="onEmailChange()" [type]="'text'" [isSuffix]="isEmailSuffix"></app-input>
          <div class="center" style="margin-top: 30px;">
          <app-button class="center" [savingsText]="'Next'" (click)="onSignup()"></app-button>
          </div>
      </div>
    
        <div class="fields-wrapper" style="margin-top: 30px;" *ngIf="otpDiv">
            <span class="font-color" style="display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;">We’ve sent you an {{ mobileNumber.includes('.com') ? 'Email' : 'SMS' }} with a 4-digit verification code on</span>
            <span class="center">
                <span class="mobileNumberStyle"><span *ngIf="!mobileNumber.includes('.com')">+91</span> {{ mobileNumber }}</span>
                &nbsp;&nbsp;
                <div style="cursor: pointer; font-size: 12px;" *ngIf="isEdit" (click)="editMobile()"><fa-icon style="color: #5589FF;" [icon]="['fas', 'edit']"></fa-icon>
                    <span style="color: #5589FF; text-decoration: underline; font-size: 15px;">Edit</span>
                </div>
              </span>
            <br>
            <app-pin-box (otpChange)="onMobileOtpChanges($event)" [loading]="loading" [errorMessage]="error_message"></app-pin-box>
            <span class="center" style="color: #FF8884;">{{OTPError}}</span>
            <span class="font-color center" *ngIf="!countdownCompleted">Resend OTP in &nbsp;<span style="color: #C3966E;">{{formatCountDownPipe()}}</span></span>
            <span class="font-color center" *ngIf="countdownCompleted">Resend OTP in &nbsp;<span class="mobileNumberStyle" style="color: #5589FF;" (click)="resendOTP()">Resend OTP</span></span>
        </div>
    </div>
    </div>
</div>