<div class="main-wrapper">
<div class="cross-icon-wrapper" (click)="closeDialog()">
    <img src="../../../../assets/crossIconDark.svg" style="cursor: pointer;" alt="">
</div>
<div class="main-header">
    Oops! You are enrolled in a higher-tier product and may be unable to subscribe to this product
</div>
<div class="main-header">
    Thank you for understanding
</div>

<div class="footer-items">
    <div>
       <span class="contact-us"><img src="../../../../../assets/supportCallIcon.svg" alt="">&nbsp;&nbsp;&nbsp;Contact us: 
        <a href="mailto: support.multiplyrr@equentis.com" class="support-link">
            support.multiplyrr&#64;equentis.com
        </a></span> 
    </div>
</div>
</div>