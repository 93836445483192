import { Component } from '@angular/core';
import { ScreenTypeService } from 'src/app/core/services/screen-type.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-already-exists',
  templateUrl: './user-already-exists.component.html',
  styleUrls: ['./user-already-exists.component.scss'],
})
export class UserAlreadyExistsComponent {

  constructor(public screenType: ScreenTypeService,
    private route: Router,
    public dialogRef: MatDialogRef<UserAlreadyExistsComponent>,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

}
