import { Injectable, OnInit } from '@angular/core';
import { OnboardingService } from './onboarding.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepsManagerService {
  
  stepsList: any[] = [];
  paymentURL: any;
  private stepsMap: { [key: string]: string } = {
    'pan': `pan/${this.user_service.getHashKey()}`,
    'bank_account': `bank_account/${this.user_service.getHashKey()}`,
    'fatca_detail': `fatca_detail/${this.user_service.getHashKey()}`,
    'riskProfile': `riskProfile/${this.user_service.getHashKey()}`,
    'suitability_assesment': `riskProfile/${this.user_service.getHashKey()}`,
    'agreement': `agreement/${this.user_service.getHashKey()}`,
    'payment': `payment/${localStorage.getItem('paymentId')}`,
  };

  constructor(
    private onboardingService: OnboardingService,
    private user_service: UserService,
    private router: Router
  ) {}

  getStepsList(): Observable<any[]> {
    return this.onboardingService.getStepsData(this.user_service.getHashKey()).pipe(
      map((res: any) => {
        if (res && res.message === 'success') {
          this.stepsList = res.data.stepOrder;
        }
        return this.stepsList;
      })
    );
  }

  

checkRouteAndNavigate(): void {
const urlSegments = this.router.url.split('/');
const currentRoute = urlSegments[urlSegments.length - 2];
console.log(currentRoute);

const currentIndex = this.stepsList.indexOf(currentRoute);
if (currentIndex !== -1 && currentIndex < this.stepsList.length - 1) {
  this.onboardingService.getOnboardingData(this.user_service.getHashKey()).subscribe({
    next: (res: any) => {
      if (res && res.message === 'success') {
        console.log('got onboarding data.....', res);
        let paymentURL = res.data.payment_url.split('payment/')[1];

        // Check if Risk step is completed
        const riskCompleted = res.data.suitability_assesment && res.data.suitability_assesment.length > 0 && res.data.suitability_assesment[0].completed;

        // Check if FATCA step is completed
        const fatcaCompleted = res.data.fatca && res.data.fatca.length > 0 && res.data.fatca[0].completed;

        // Check if Bank Account step is completed
        const bankAccountCompleted = res.data.bank_account && res.data.bank_account.length > 0 && res.data.bank_account[0].completed;

        // Check if Agreement step is completed
        const agreementCompleted = res.data.agreement && res.data.agreement.length > 0 && res.data.agreement[0].completed;

        // Check if Payment step is completed
        const paymentCompleted = res.data.payment && res.data.payment.length > 0 && res.data.payment[0].completed;

        // Determine next step based on completion status
        let nextStep = '';

        // if a step is enableDebugTools, then add that as if condition here then only it will route to that step

        // if (!bankAccountCompleted) {
        //   nextStep = 'bank_account';
        // } else if (!fatcaCompleted) {
        //   nextStep = 'fatca_detail';
        // } else 

        //
        if(!riskCompleted) {
          nextStep = 'riskProfile';
        }
        // else if (!bankAccountCompleted) {
        //   nextStep = 'bank_account';
        // }
        //  else if (!fatcaCompleted) {
        //   nextStep = 'fatca_detail';
        // }
         else if (!agreementCompleted) {
          nextStep = 'agreement';
        } 
        else if (!paymentCompleted) {
          nextStep = 'payment';
        } else {
          // All steps completed, navigate to dashboard
          this.router.navigate(['/dashboard']);
          return;
        }
        // Navigate to the next step in the array
        const nextStepPath = this.stepsMap[nextStep];
        // adding extra if condition for payment route to get updated payment_url fron JSON
        if(nextStepPath.includes('payment')) {
          this.router.navigate([`payment/${paymentURL}`]);
        }
        else if (nextStepPath) {
          this.router.navigate([nextStepPath]);
        } else {
          console.error('Next step path not found in stepsMap');
        }
      }
    },
    error: (err) => {
      console.error(err);
    },
  });
} else {
  console.error('Current route is not recognized or there is no next step');
}

  }

  initializeAndNavigate(): void {
    this.getStepsList().subscribe(
      steps => {
        console.log('Steps fetched:', steps);
        this.checkRouteAndNavigate();
      },
      error => {
        console.error('Failed to fetch steps:', error);
      }
    );
  }
}
