<div class="input-form-field" style="position: relative; display: flex; align-items: center; width: 100%;">
  <!-- Conditionally render the "+91 |" prefix -->
  <span *ngIf="placeholder === 'Mobile No.'" class="countryCode">
    +91 |
  </span>

  <mat-form-field appearance="outline" [style.width]="'100%'" style="flex-grow: 1;">
    <input matInput
      *ngIf="placeholder === 'Email Id'"
      type="{{type}}"
      placeholder="{{placeholder}}"
      [autofocus]="autofocus" 
      [(ngModel)]="inputValue"
      [required]="required"
      [maxlength]="maxLength"
      [readonly]="readonly"
      (ngModelChange)="onChange($event)"
      [style.padding-left]="paddingLeft"/>

      <input matInput
      *ngIf="placeholder !== 'Email Id'"
      appOnlyCharacter
      [placeholder]="placeholder"
      type="{{type}}"
      [autofocus]="autofocus" 
      [(ngModel)]="inputValue"
      [required]="required"
      [maxlength]="maxLength"
      [readonly]="readonly"
      [disabled]="isDisabled"
      (ngModelChange)="onChange($event)"
      [style.padding-left]="paddingLeft"/>

    <mat-hint style="color: #989898;" *ngIf="hintRequired">{{hint}}</mat-hint>
    <img src="../../../../../assets/tick.svg" alt="" matSuffix style="padding: 0 20px;" *ngIf="isSuffix">
    <img src="{{rmSuffixPath}}" alt="" matSuffix style="padding: 0 20px; cursor: pointer;" *ngIf="isRMSuffix"
    (click)="onImageClick()">
  </mat-form-field>
</div>
<span style="color: #FF8884; font-size: 14px; display: block; margin-top: 5px;">{{errorMessage}}</span>
