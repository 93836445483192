import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { SocketType } from '../models/types';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket: any;
  token!: string;
  onboarding_ID: string = '';

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private route: Router
  ) {}

  reset() {
    this.disconnect();
    this.socket = undefined;
  }

  initNotificationService() {
    console.log('init of notify call...');


    if (localStorage.getItem('authToken')) {
      let config = {
        auth: {
          token: localStorage.getItem('authToken'),
        },
        secure: true,
        transports: ['websocket'],
      } as any;
      // if(environment.production) {
      //   config.path = '/socket';
      // }
      this.socket = io(environment.socket_url_notification, config);
      this.socket.on('connect_error', (err: any) => {
        console.log(err.message);
        console.log(err.description);
      });
      // this.listenNotification(SocketType.portfolio).subscribe((res) => {
      //   console.log(res);
      // });

      this.listenNotification(SocketType.notification).subscribe((res: any) => {
       const response = JSON.parse(res);
       let parsedData = JSON.parse(response.body);
       console.log('Parsed Data:', parsedData);
      });
    }
  }

  initOnboradingService(onboarding_ID: any, all_url_routes: any) {
    this.onboarding_ID = onboarding_ID;
    console.log('init of websocket called');
    //this.token = this._userService.getOnBoardingId();
    if (onboarding_ID) {
      this.socket = io(environment.socket_url_onboarding, {
        secure: true,
        auth: {
          token: onboarding_ID,
        },
        transports: ['websocket'],
      });
      this.socket.on('connect_error', (err: any) => {
        console.log(err.message);

        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);

        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
        if (all_url_routes && all_url_routes?.length > 0) {
          all_url_routes.map((item: any) => {
            if (item.toString().length > 22) {
              this.init(item, []);
            }
          });
        }
      });

      this.listen(SocketType.serverResponse).subscribe((res) => {
        console.log('response from servre received from socket...', res);
      });

      // setInterval(() => {
      //   this.emit(SocketType.notification, 'LOL').subscribe((response) => {
      //     console.log('Received response from server:', response);
      //   });
      // }, 2000);
    }
  }

  

  init(onboarding_ID: any, all_url_routes: any) {
    this.onboarding_ID = onboarding_ID;
    console.log('init of websocket called', onboarding_ID, all_url_routes);
    //this.token = this._userService.getOnBoardingId();
    this.token = onboarding_ID;
    if (!this.socket && this.token) {
      this.socket = io(environment.socket_url, {
        secure: true,
        auth: {
          token: this.token,
        },
        transports: ['websocket'],
      });
      this.socket.on('connect_error', (err: any) => {
        console.log(err.message);

        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);

        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
        if (all_url_routes && all_url_routes?.length > 0) {
          all_url_routes.map((item: any) => {
            if (item.toString().length > 22) {
              this.init(item, []);
            }
          });
        }
      });
      this.listen(SocketType.ping).subscribe((res) => {
        console.log(res);
      });

      this.listen(SocketType.serverResponse).subscribe((res) => {
        console.log('response from servre received from socket...', res);
      });

      // setInterval(() => {
      //   // Call the emit method periodically
      //   this.emit(SocketType.notification, 'LOL').subscribe((response) => {
      //     console.log('Received response from server:', response);
      //   });
      // }, 2000);
    }
  }

  emit(event: SocketType, data?: any) {
    if (!this.socket && this.token) this.init(this.onboarding_ID, []);
    return new Observable((observer: Observer<object>) => {
      this.socket.emit(event, data, (message: any) => {
        observer.next(message);
      });
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  listen(eventname: SocketType): Observable<object> {
    console.log('listen...', eventname);
    if (!this.socket && this.token) this.init(this.onboarding_ID, []);
    return new Observable((observer: Observer<object>) => {
      this.socket.on(eventname, (message: any) => {
        observer.next(message);
      });
    });
  }

  listenNotification(eventname: SocketType): Observable<object> {
    console.log('listen...hjddjhdhj', eventname);
    return new Observable((observer: Observer<object>) => {
      this.socket.on(eventname, (message: any) => {
        console.log('received socket response'+message);
        observer.next(message);
      });
    });
  }

  listenOnboarding(eventname: SocketType): Observable<object> {
    console.log('listen...', eventname);
    if (localStorage.getItem('hashKey')) this.initOnboradingService(localStorage.getItem('hashKey'), []);
    return new Observable((observer: Observer<object>) => {
      this.socket.on(eventname, (message: any) => {
        observer.next(message);
      });
    });
  }
}
