import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-viewer',
 template: `
    <pdf-viewer
      [src]="pdfSrc"
      [page]="page"
      [zoom]="zoom"
      [original-size]="false"
      [render-text]="true"
      style="width: 100%; height: 100%;"
    ></pdf-viewer>
  `,
})
export class PdfViewerComponent {
  pdfSrc: string;
  page: number;
  zoom: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.pdfSrc = data.pdfSrc;
    this.page = data.page;
    this.zoom = data.zoom;
  }

}
