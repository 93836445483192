<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>

<div>
    <div class="fatca-details" [ngStyle]="{ 'padding': (screenType.breakpointState === 'mob' ? '40px 20px' : '40px 70px') }">
        <div class="overlay" *ngIf="spinner">
            <div class="spinner">
              <img src="../../../../assets/loader.svg" alt="" class="loader">
            </div>
        </div>
        <div class="fatca-wrapper" [ngStyle]="{ 'margin-top': (screenType.breakpointState === 'mob' ? '0' : '60px') }">
            <mat-card style="padding: 60px;">
                <mat-card-content>
                    <div class="plan">
                        <div class="bankIcon">
                            <img src="../../../../assets/bankDetails.svg" alt="">
                        </div>
                        <div class="plan-title">FATCA Details</div>
                        <div class="plan-title" style="font-size: 15px;">(Foreign Account Tax Compliance Act)</div>
                        <div class="plan-desc">Residency in a country other than India?
                            <img style="margin-top: 20px;" src="../../../../assets/hrDarkMobIcon.svg" alt="">
                        </div>
                        
                    </div>

                    <div style="margin-top: 30px; display: flex; flex-direction: column; align-items: center;">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOption" (change)="onSelectionChange($event)">
                            <mat-radio-button value="Yes" color="primary">Yes</mat-radio-button>
                            <mat-radio-button value="No" color="primary" style="margin-left: 40px;">No</mat-radio-button>
                          </mat-radio-group>
                          <br>
                          <span *ngIf="isOptionSelected && !selectedOption" style="color: #FF8884;font-size: 14px;">
                            Please select an option
                          </span>
                    </div>

                    <div *ngIf="selectedOption == 'Yes'" style="margin-top: 30px; display: flex; flex-direction: column; align-items: center;">
                    <div class="fields-wrapper" [ngClass]="{ 'mobile': screenType.breakpointState === 'mob' }" [ngStyle]="{ 'gap': (screenType.breakpointState === 'mob' ? '' : '100px') }">
                    <div>
                        <div class="label">
                            Place of Birth
                        </div>
                        <app-input
                        [placeholder]="'eg.Mumbai'"
                        [width]="'250px'"
                        [autofocus]="true"
                        [type]="'text'"
                        [name]="'bankNumber'"
                        [required]="true"
                        [(ngModel)]="placeOfBirth"
                        [errorMessage]="error_message_place"
                        ></app-input>
                    </div>
                    <div>
                        <div class="label">
                            Country of Birth
                        </div>
                        <app-dropdown
                        [label]="'Select an option'"
                        [width]="'250px'"
                        [options]="dropdownOptions"
                        [required]="true"
                        [(ngModel)]="countryOfBirth"
                        [errorMessage]="error_message_country_birth"
                        (selectedValueChange)="onSelectedValueChange($event, 'CountryofBirth')"
                      ></app-dropdown>
                    </div>
                    </div>

                    <div class="fields-wrapper" [ngClass]="{ 'mobile': screenType.breakpointState === 'mob' }" [ngStyle]="{ 'gap': (screenType.breakpointState === 'mob' ? '' : '100px') }">
                        <div>
                            <div class="label">
                                Country of Citizenship
                            </div>
                            <app-dropdown
                            [label]="'Select an option'"
                            [width]="'250px'"
                            [options]="dropdownOptions"
                            [required]="true"
                            [(ngModel)]="countryOfCitizenship"
                            [errorMessage]="error_message_country_citizenship"
                            (selectedValueChange)="onSelectedValueChange($event, 'CountryofCitizenship')"
                          ></app-dropdown>
                        </div>
                        <div>
                            <div class="label">
                                Country of Nationality
                            </div>
                            <app-dropdown
                            [label]="'Select an option'"
                            [width]="'250px'"
                            [options]="dropdownOptions"
                            [required]="true"
                            [(ngModel)]="countryOfNationality"
                            [errorMessage]="error_message_country_nationality"
                            (selectedValueChange)="onSelectedValueChange($event, 'CountryOfNationality')"
                          ></app-dropdown>
                        </div>
                    </div>

                    <div class="fields-wrapper" [ngClass]="{ 'mobile': screenType.breakpointState === 'mob' }" [ngStyle]="{ 'gap': (screenType.breakpointState === 'mob' ? '' : '100px') }">
                        <div>
                            <div class="label">
                                Country of Tax Residency
                            </div>
                            <app-dropdown
                            [label]="'Select an option'"
                            [width]="'250px'"
                            [options]="dropdownOptions"
                            [required]="true"
                            [(ngModel)]="countryOfTaxResidency"
                            [errorMessage]="error_message_country_taxResidency"
                            (selectedValueChange)="onSelectedValueChange($event, 'CountryOfTaxResidency')"
                          ></app-dropdown>
                        </div>
                        <div>
                            <div class="label">
                                Tax Indentification Number
                            </div>
                            <app-input
                            [placeholder]="'eg.123456'"
                            [width]="'250px'"
                            [autofocus]="true"
                            [type]="'text'"
                            [name]="'bankNumber'"
                            [required]="true"
                            [(ngModel)]="taxIdentificationNumber"
                            [errorMessage]="error_message_taxIdentificationNumber"
                            ></app-input>
                        </div>
                    </div>

                    <div class="fields-wrapper" [ngClass]="{ 'mobile': screenType.breakpointState === 'mob' }" [ngStyle]="{ 'gap': (screenType.breakpointState === 'mob' ? '' : '100px') }">
                        <div>
                            <div class="label">
                                Tax Identification Type
                            </div>
                            <app-input
                            [placeholder]="'eg.123456'"
                            [width]="'250px'"
                            [autofocus]="true"
                            [type]="'text'"
                            [name]="'bankNumber'"
                            [required]="true"
                            [(ngModel)]="taxIdentificationType"
                            [errorMessage]="error_message_taxIdentificationType"
                            ></app-input>
                        </div>
                    </div>
                    </div>
    
                    <div class="submit-btn">
                        <app-button [savingsText]="'Continue'" (click)="onSubmit()"></app-button>
                    </div>
                </mat-card-content>
            </mat-card>
            <img src="../../../../assets/bull_logo.svg" class="bull-logo" alt="">
        </div>
    </div>
</div>