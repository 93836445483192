import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-amo-confirmation',
  templateUrl: './amo-confirmation.component.html',
  styleUrls: ['./amo-confirmation.component.scss'],
})
export class AmoConfirmationComponent {
  constructor(public dialogRef: MatDialogRef<AmoConfirmationComponent>) {}

  onNoClick(): void {
    this.dialogRef.close('no'); // Close the dialog and send 'no' as the result
  }

  onYesClick(): void {
    this.dialogRef.close('yes'); // Close the dialog and send 'yes' as the result
  }
}
