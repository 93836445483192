import { Pipe, PipeTransform } from '@angular/core';
import { SelectData } from '../../models/types';

@Pipe({
  name: 'formatSelectData',
})
export class FormatSelectDataPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value != undefined && args.length == 1) {
      if (args[0] == true) {
        const tempData = value.map((item: SelectData) => {
          return item.key;
        });
        return tempData;
      } else {
        return value.key;
      }
    }
  }
}

@Pipe({
  name: 'formatSelectDataString',
})
export class FormatSelectDataStringPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value != undefined && args.length == 1) {
      if (args[0] == true) {
        const tempData = value.map((item: SelectData) => {
          return item.value;
        });
        return tempData;
      } else {
        return value.value;
      }
    }
  }
}
