<div class="main-wrapper">
<div class="header">Market is closed. Place after-market order?</div>
<div class="hrTag">
<img src="../../../../../assets/hrDarkTagIcon.svg" alt="">
</div>
<div class="sub-text">
    You are about to place an After-Market Order (AMO). AMOs are placed outside regular market
    hours and are executed as market orders when the market opens on the next trading day. 
</div>
<div class="sub-text">
<div style="font-weight: 700; color: #0F0F0F;">Please note:</div>
Execution prices may differ from the current stock price due to market fluctuations at the time of opening.
</div>
<div class="sub-text">
    Are you sure you’d like to continue?  
</div>
<div class="button-wrapper">
<app-button [savingsText]="'No'" [isTransparentBTN]="true" [showWatchListIcon]="false" (click)="onNoClick()"></app-button>
<app-button [savingsText]="'Yes'" (click)="onYesClick()"></app-button>
</div>
</div>