<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>
<div class="main-wrapper">
<div>
    <img src="../../../../assets/pageNotFound.svg" alt="">
</div>
<div>
    <img
    *ngIf="screenType.breakpointState != 'mob'"
    src="../../../../assets/hrDarkTagIcon.svg" alt="">
    <img
    *ngIf="screenType.breakpointState == 'mob'"
    src="../../../../assets/hrDarkMobIcon.svg" alt="">
</div>
<div>
    <span class="head-text">Page not found</span>
</div>
<div>
    <span class="sub-head-text">The page you requested could not be found.</span>
</div>
<app-button [savingsText]="'Back to home'" (click)="backToHome()"></app-button>
</div>