<app-toolbar [showUserName] = "true" (loginClicked)="openLogin($event)"></app-toolbar>


<!-- laptop view -->
<div class="insights-wrapper" *ngIf="this.screenType.breakpointState !== 'mob'">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
            <span class="headerText">Purchase History</span>
        </div>
        <div class="col-1"></div>
    </div>

    <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
            <table class="table">
                <tbody class="tbody-style">
                  <tr>
                    <td>Fast Growth Portfolio</td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                        <p>25 Sep 2023</p>
                    </td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                        <p>&#8377; 3000</p>
                    </td>
                    <td><img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt=""></td>
                  </tr>
                  <tr>
                    <td>Discounted Gem Portfolio</td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                        <p>25 Sep 2023</p>
                    </td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                        <p>&#8377; 3000</p>
                    </td>
                    <td ><img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt=""></td>
                  </tr>
                  <tr>
                    <td>Secure Growth Portfolio</td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                        <p>25 Sep 2023</p>
                    </td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                        <p>&#8377; 3000</p>
                    </td>
                    <td ><img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt=""></td>
                  </tr>
                  <tr>
                    <td>Future Gainers Portfolio</td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                        <p>25 Sep 2023</p>
                    </td>
                    <td>
                        <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                        <p>&#8377; 3000</p>
                    </td>
                    <td ><img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt=""></td>
                  </tr>
                </tbody>
            </table>
        </div>
        <div class="col-1"></div>
        </div>
</div>
<!--  -->

<!-- mobile view -->
<div class="insights-wrapper" style="padding-top: 40px;" *ngIf="this.screenType.breakpointState == 'mob'">
    <div class="row">
            <span class="headerText">Purchase History</span>
            <app-toolbar-mobile></app-toolbar-mobile>
    </div>
    <div class="row">
            <table class="table">
                <tbody class="tbody-style">
                  <tr>
                    <div class="row">
                        <p>Fast Growth Portfolio</p>
                      </div>
                      <div class="row d-flex">
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">25 Sep 2023</p>
                        </div>
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">&#8377; 3000</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5"></div>
                        <div class="col-7">
                            <img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt="">
                        </div>
                      </div>
                  </tr>

                  <tr>
                    <div class="row">
                        <hr>
                        <p>Fast Growth Portfolio</p>
                      </div>
                      <div class="row d-flex">
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">25 Sep 2023</p>
                        </div>
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">&#8377; 3000</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5"></div>
                        <div class="col-7">
                            <img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt="">
                        </div>
                      </div>
                  </tr>

                  <tr>
                    <div class="row">
                        <hr>
                        <p>Fast Growth Portfolio</p>
                      </div>
                      <div class="row d-flex">
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">25 Sep 2023</p>
                        </div>
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">&#8377; 3000</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5"></div>
                        <div class="col-7">
                            <img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt="">
                        </div>
                      </div>
                  </tr>

                  <tr>
                    <div class="row">
                        <hr>
                        <p>Fast Growth Portfolio</p>
                      </div>
                      <div class="row d-flex">
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Subscription Date</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">25 Sep 2023</p>
                        </div>
                        <div class="col-6 d-flex flex-column">
                          <p style="font-size: 14px; color: #2C3A47;">Paid Amount</p>
                          <p style="color: #0D7816; font-size: 18px; font-weight: 800;">&#8377; 3000</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-5"></div>
                        <div class="col-7">
                            <img style="cursor: pointer;" (click)="downloadInvoice()" src="../../../../assets/btn_Download Invoice.svg" alt="">
                        </div>
                      </div>
                  </tr>
                </tbody>
            </table>
        </div>
</div>
<!--  -->


