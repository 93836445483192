<div class="date-form-field">
    <mat-form-field
    appearance="outline">
      <!-- <mat-label>{{label}}</mat-label> -->
      <input
        matInput
        [matDatepicker]="picker"
        [min]="minDate"
        [max]="maxDate"
        [name]="name"
        [(ngModel)]="dateValue"
        (blur)="ngBlur = true"
        [required]="required"
        readonly="true"
        (click)="picker.open()"
        (ngModelChange)="onDateChange()"
        [disabled]="isDisabled"
        placeholder="{{placeholder}}" />
      <mat-icon *ngIf="showError" matSuffix class="error-icon">error</mat-icon>
      <mat-datepicker-toggle matSuffix [for]="$any(picker)">
      </mat-datepicker-toggle>
      <!-- <mat-icon
        *ngIf="dateValue && clearable"
        matDatepickerToggleIcon
        matSuffix
        (click)="clearDate($event)"
        >clear
      </mat-icon> -->

      <mat-datepicker #picker (closed)="ngBlur = true" [startAt]="startDate"></mat-datepicker>

      <img src="../../../../../assets/tick.svg" alt="" matSuffix style="padding: 12px; margin-top: -15px;" *ngIf="isSuffix">
    </mat-form-field>

    <span style="font-size: 14px; color: #FF8884;">{{errorMessage}}</span>
  </div>
  