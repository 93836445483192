import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { Router } from '@angular/router';
import { ScreenTypeService } from '../../services/screen-type.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OnboardingService } from '../../services/onboarding.service';
import { UserService } from '../../services/user.service';
import { SearchIFSCComponent } from '../../shared/dialogs/search-ifsc/search-ifsc.component';
import { debounceTime } from 'rxjs';
import { ApiServiceService } from '../../services/api-service.service';
import { StepsManagerService } from '../../services/steps-manager.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss'],
})
export class BankDetailsComponent implements OnInit {
  getAuthToken: string | undefined;
  usernameToolBar: any;
  userName: any;
  spinner: boolean = false;
  error_Message_bank: string = '';
  error_Message_ifsc: string = '';
  bankAccNo: any;
  ifscCode: any;
  isBankSuffix: boolean = false;
  isIFSCSuffix: boolean = false;
  ifscbankData: any = {};
  showIfscData: boolean = false;
  bankAccNoResponse: any;
  ifscCodeResponse: any;

  constructor(
    public screenType: ScreenTypeService,
    private route: Router,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    private router: ActivatedRoute,
    private onboardingService: OnboardingService,
    private user_service: UserService,
    private apiService: ApiServiceService,
    private steps_service: StepsManagerService,
  ) {

  }

  verifyIFSC(event: any) {
    console.log('IFSC code : ', event);
    let ifsc = event.target.value;
    let onboarding_id = this.user_service.getHashKey();
    const reqObj = {
      ifsc: ifsc,
      onboarding_id: onboarding_id,
    };

    if (ifsc.length < 11) {
      this.showIfscData = false;
      return;
    }

    this.apiService
      .verifyIFSCCode(reqObj, onboarding_id)
      .pipe(debounceTime(400))
      .subscribe(
        (res: any) => {
          console.log('IFSC code response : ', res);
          if (res.message == 'success' && res.data) {
            this.ifscbankData = res.data;
            this.showIfscData = true;
          }
        },
        (err: any) => {
          this.showIfscData = false;
          // this._toastSerivce.error('Invalid IFSC code');
        }
      );
  }

  onClickSearchIFSC() {
    const ifscDialog = this.dialog.open(SearchIFSCComponent, {
      height: 'auto',
      disableClose: false,
      data: {
        onboarding_id: this.user_service.getHashKey(),
      },
      panelClass: 'ifsc-dialog',
      width: '60%',
    });

    ifscDialog.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      if (result) {
        this.ifscCode = result;

        const reqObj = {
          ifsc: this.ifscCode,
          onboarding_id: this.user_service.getHashKey(),
        };

        this.apiService
          .verifyIFSCCode(reqObj, this.user_service.getHashKey())
          .pipe(debounceTime(400))
          .subscribe(
            (res: any) => {
              console.log('IFSC code response : ', res);
              if (res.message == 'success' && res.data) {
                this.ifscbankData = res.data;
                this.showIfscData = true;
              }
            },
            (err: any) => {
              this.showIfscData = false;
              // this._toastSerivce.error('Invalid IFSC code');
            }
          );
      }
    });
  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if(token && data.is_registered) {
      console.log('ngOnInit token data', data);
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
    } else {
      this.openLogin('');
    }

    this.getBankDetails();
  }

  getBankDetails() {
    this.onboardingService.getOnboardingData(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.message == 'success') {
       console.log('reeived data>>>>', res);
       if (res.data.bank_account[0].bank_detail_id) {
        this.onboardingService.getBankDetails(res.data.bank_account[0].bank_detail_id,
        this.user_service.getHashKey()).subscribe((res: any) => {
        if (res.message == 'success' && res.data) {
        console.log('get bank details.....',res);
        this.bankAccNo = res.data.account_number;
        this.ifscCode = res.data.ifsc;
        this.bankAccNoResponse = res.data.account_number;
        this.ifscCodeResponse = res.data.ifsc;
        }
        })
       }
      }
    })
  }

  onMouseLeaveBank() {
  if(this.bankAccNo) {
  this.error_Message_bank = '';
  this.isBankSuffix = true;
  }
  else {
  this.error_Message_bank = 'Please enter valid Bank No';
  this.isBankSuffix = false;
  }
  }

  onMouseLeaveIFSC() {
    if(this.ifscCode) {
      this.error_Message_ifsc = '';
      this.isIFSCSuffix = true;
      }
      else {
      this.error_Message_ifsc = 'Please enter valid IFSC code';
      this.isIFSCSuffix = false;
      }
  }

  onSubmit() {
    if(this.bankAccNoResponse == this.bankAccNo
      && this.ifscCodeResponse == this.ifscCode) {
      this.steps_service.initializeAndNavigate();
    }
    else {
      if(this.bankAccNo && this.ifscCode) {
        this.spinner = true;
        let reqData = {
        bankAccNo: this.bankAccNo,
        ifscCode: this.ifscCode,
        token: this.user_service.getHashKey(),
        }
        this.onboardingService.saveBankDetails(reqData).subscribe((res: any) => {
        if(res.message == 'success' &&
        res.data.message == 'Bank Account details verified successfully.') {
        this.spinner = false;
        this._toastrService.success(res.data.message);
        let id = this.user_service.getHashKey();
        // this.route.navigate(['fatca_detail', id]);
        this.steps_service.initializeAndNavigate();
        }
        else {
        this._toastrService.error(res.data.message);
        this.spinner = false;
        }
        },
      (err) => {
        this.spinner = false;
      }
      )
        }
    }

  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'bank_account',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

}
