import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _toastService: ToastrService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          console.log('Error Status Code: ', error.status);
          console.log('Error Message: ', error.url);

          if (error.status === 400) {
            this._toastService.error(`Oops! There seems to be an issue with your request. Please try again.`);
          } else if (error.status === 401) {
            this._toastService.error(`You need to log in to access this page. Please sign in and try again.`);
          } else if (error.status === 403) {
            this._toastService.error(`You don’t have permission to view this page. Contact support if this is an error.`);
          } else if (error.status === 404) {
            this._toastService.error(`We couldn’t find the page you’re looking for. Check the URL or go back to the homepage.`);
          }else if (error.status === 408) {
            this._toastService.error(`It’s taking too long to load. Please try again in a moment.`);
          }else if (error.status === 429) {
            this._toastService.error(`You’ve made too many requests in a short period. Please slow down and try again later.`);
          }else if (error.status === 500) {
            this._toastService.error(`Something went wrong on our end. Please refresh the page or try again later.`);
          }else if (error.status === 502) {
            this._toastService.error(`The server is having trouble. Please come back later.`);
          }else if (error.status === 503) {
            this._toastService.error(`We’re currently experiencing heavy traffic. Please try again soon.`);
          }else if (error.status === 504) {
            this._toastService.error(`Our server took too long to respond. Please refresh or check back later.`);
          }else if(error.url?.includes('files/api/file')) {
            console.error('video/image failed');
          }else {
            this._toastService.error(`Something went wrong. Please try again later.`);
          }
        }

        return throwError(() => error);
      })
    );
  }
}
