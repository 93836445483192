<div class="main-wrapper">
<div class="head-text">
Delete Account?
</div>
<div *ngIf="mainDiv">
<div *ngIf="isActiveSubscription" class="sub-head">
    You are already subscribed to a basket, if account deleted you'll loose the access. Are you sure you want to proceed for delete?
</div>
<div *ngIf="!isActiveSubscription" class="sub-head">
    You will loose the access to the account permanently. Are you sure you want to proceed for delete?
</div>
<div class="button-wrapper">
    <app-button [savingsText]="'Delete'" (click)="deleteAccount()"></app-button>
</div>
</div>

<div class="success-wrapper" *ngIf="successDiv">
    <span>Thanks, our executive will reach out to you.</span>
</div>
</div>