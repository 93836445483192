import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTypeService } from '../../services/screen-type.service';
import { NavigationExtras, Router } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { InvestementModalDashboardComponent } from '../investement-modal-dashboard/investement-modal-dashboard.component';
import { ToastrService } from 'ngx-toastr';
import { SmallCaseIntegrationService } from '../../services/small-case-integration.service';
import { ApiServiceService } from '../../services/api-service.service';
import { UserService } from '../../services/user.service';
import { WebSocketService } from '../../services/web-socket.service';
import { SocketType } from '../../models/types';
import { OnboardingService } from '../../services/onboarding.service';
import { BasketMoreDetailsComponent } from '../basket-more-details/basket-more-details.component';
import * as moment from 'moment';
import { MoengageService } from '../../services/moengage.service';
import { TransactionDetailsComponent } from '../transaction-details/transaction-details.component';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';
import { OrderInProgressComponent } from '../../shared/dialogs/order-in-progress/order-in-progress.component';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  usernameToolBar: boolean = false;
  userName: string = '';
  panelOpenState = false;
  getAuthToken: string = '';
  customer_Id: any;
  transaction_id: any;
  smallCaseInitToken: any;
  smallCaseAuthToken: any;
  product_code: any;
  sub_product_id: any
  stocksList: any[] = [];
  exitedStocksList: any[] = [];
  securitiesList: any[] = [];
  jarDetails: any;
  minimum_Investement_Amount: number = 0;
  investementDetails: any;
  onboardingId: any;
  isUserPortfolio: boolean = false;
  userListData: any[] = [];
  recommendedBasketList: any[] = [];
  paymentStatus: any;
  invest_now_text: string = 'Invest Now';
  currentUser: boolean = false;
  existinguser: boolean = false;
  gatewayInstance: any;
  goBackRoute: boolean = false;
  mainBasketData: any;
  btnTextExistingUser: any = 'Invest Now';
  btnColorExistingUser: string = '#C3966E';
  investNowText: any = 'Invest Now';
  fixInvestmentData: any[] = [];
  isData: boolean = true;
  spinner: boolean = false;
  tokenData: any;
  isBasketRebalanced: boolean = false;
  isRenewNow: boolean = false;
  activeStocks: boolean = true;
  exitedStocks: boolean = false;
  matBadgeCount: number = 0;
  selectedStockType: string = 'active';
  isDataLoaded: boolean = false;
  @Output() updateBadgeCount: EventEmitter<number> = new EventEmitter<number>();
  dashboardEventData: any;
  basketNames: any[] = [];
  basketNameMoe: any;
  isAfterMarketOrder: boolean = false;
  rebalancingInProgress: boolean = false;

  selectStockType(type: string): void {
    this.selectedStockType = type;
    if(type == 'active') {
      this.exitedStocks = false;
      this.activeStocks = true;
    }
    else if(type == 'exited') {
      this.activeStocks = false;
      this.exitedStocks = true;
    }
  }

  constructor(
    public screenType: ScreenTypeService,
    private route: Router,
    public dialog: MatDialog,
    private toast_message: ToastrService,
    private smallCase_service: SmallCaseIntegrationService,
    private apiService: ApiServiceService,
    private user_service: UserService,
    private socket_service: WebSocketService,
    private onboardingService: OnboardingService,
    private moengageService: MoengageService,
    private meta: Meta,
    private title: Title
  ) {}

  addMetaTags() {
    let ogTagImage = localStorage.getItem('ogTagImage') || '';
    this.meta.addTags([
      { property: 'og:title', content: 'Invest in Stock Baskets - DIY Platform for Stock Investments | Equentis - MultiplyRR' },
      { property: 'og:description', content: 'Multiply your savings by investing in stock market with MultiplyRR. Invest in stock baskets with multiple high-growth stocks in just 3 steps. Visit now!' },
      { property: 'og:image', content: ogTagImage },
      { property: 'og:image:type', content: 'image/svg' },
      { property: 'og:image:width', content: '200' },
      { property: 'og:image:height', content: '150' }
    ]);
  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
      this.tokenData = data;
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if (token && data.customer_meta_info.is_registered) {
      localStorage.setItem('categoryNameMoe', 'dashboard');
      console.log('ngOnInit token data', data);
      this.customer_Id = data.customer_id;
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
    } else {
      this.openLogin('');
    }
    // this.checkPaymentStatus();
    this.getDataFromRedis();
    // connecting socket for getting live notifications
    // this.socket_service
    //   .listenNotification(SocketType.notification)
    //   .subscribe((res: any) => {
    //     let response_data = JSON.parse(res);
    //     response_data = response_data.body;
    //     response_data = JSON.parse(response_data);
    //     console.log('got notification response..', response_data.title);
    //     this.getNotificationList(this.customer_Id);
    //     // this.toast_message.success(response_data.title);
    //     const toast = this.toast_message.success(response_data.title);

    //     toast.onTap.subscribe(() => {
    //       this.route.navigate(['notification']);
    //     });
    //   });
      //
  }

  getNotificationList(id: any) {
    this.apiService.getNotificationList(id, 1).subscribe((res: any) => {
      if (res && res.success) {
        this.matBadgeCount = res.data.undeliveredMsgCount;
        this.updateBadgeCount.emit(this.matBadgeCount);
      }
    })
  }

  addToWatchList(data: any, isWatchlisted: any) {
    console.log(data);
    if(isWatchlisted) {
      this.apiService.removeWatchList(data.watch_id).subscribe((res: any) => {
        if(res && res.success == true) {
        this.toast_message.success('Removed from Watchlist');
        this.recommendedBasketData();
        }
        else {
          this.toast_message.error('Failed to remove from Watchlist');
        }
      })
    }
    else {
      let payload = {
        sub_product_id: data.sub_product_id
      }
      this.apiService.addToWatchList(payload).subscribe((res: any) => {
        console.log(res);
        if(res && res.success == true) {
          this.toast_message.success('Added to Watchlist');
          this.recommendedBasketData();
        }
        else {
          this.toast_message.error('Failed to add Watchlist!');
        }
      });
    }
  }

  openTransactionDetails(data: any) {
    const dialogRef = this.dialog.open(TransactionDetailsComponent, {
      height: '100%',
      width: 'auto',
      disableClose: false,
      position: { right: '0' },
      data: {
        moreDetails: data,
        stockType: this.selectedStockType
      },
    });
  }

  basketMoreDetails(card: any) {
    const dialogRef = this.dialog.open(BasketMoreDetailsComponent, {
      height: 'auto',
      disableClose: false,
      autoFocus: false,
      backdropClass: 'backdropBackground',
      width: '100%',
      data: {
        moreDetails: card,
      },
    });
    
  }

  scrollCards(direction: string): void {
    const container = document.querySelector('.cards-container > div');
    
    if (container) {  // Null check
      const scrollAmount = 450; // This should match the card's min-width
  
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else if (direction === 'right') {
        container.scrollLeft += scrollAmount;
      }
    }
  }

formatDate(date: any) {
 return moment(date).format('Do MMMM YYYY');
}
  

buyRecommededBasket(data: any) {
//call create onboarding API
this.spinner = true;
//call moengage subscribeNow event
let eventDetails = {
  'basketName': data?.sub_product_name,
  'categoryName': 'Dashboard',
  'basketId': data?.category_id,
  'minInvestmentAmount': data?.pb_details?.min_investment_amount,
  'returns': data?.pb_details?.cagr || 0,
  'returnsDuration': data?.investment_horizon,
  'basketrisk': data?.pb_details?.risk_level,
  'basketprice': data?.fees_details[0]?.details?.advisory_fees
}
this.moengageService.subscribeNowEvent(eventDetails);
//
let payload = {
  "lead_id": this.tokenData.lead_id,
  "sub_product_id": data.sub_product_id,
  "country_id": this.tokenData.country_id === null ? 0 : this.tokenData.country_id,
  "state_id": this.tokenData.state_id === null ? 0 : this.tokenData.state_id,
  "complimentary_period": 0,
  "fees_details": data.fees_details[0],
  "first_year_amount": data.fees_details[0].details.max_yearly_amount,
  "advisory_discount_amount": 0,
  "immediate_advisory_payment_amount": data.fees_details[0].details.advisory_fees,
  "advisory_installment_option": data.advisory_installment_option[0],
  "advisory_number_of_installments": data.advisory_installment_option.length,
  "advisory_is_emandate": false,
  "advisory_emandate_date": moment().format('YYYY-MM-DD'),
  "advisory_emandate_discount": 0,
  "email_contact_detail_id": this.tokenData.email_contact_detail_id,
  "whatsapp_contact_detail_id": this.tokenData.mobile_contact_detail_id,
  "sms_contact_detail_id": this.tokenData.mobile_contact_detail_id
}
let authToken = localStorage.getItem('authToken');
this.onboardingService.createOnboarding(payload, authToken).subscribe((res: any) => {
  this.spinner = false;
  console.log('lead creation api res..', res);
  if(res && res.success && Object.keys(res.data).length != 0) {
      const parts = res.data.url.split('/');
      const id = parts[parts.length - 1];
      this.user_service.pushHashKey(id);

      // call redis api to save data
      let payload = {
        id: this.user_service.getHashKey(),
        data: {
          categoryId: data.category_id,
          subProductId: data.sub_product_id,
          onboardingId: res.data.onboarding_id,
          productCode: data.sub_product_code,
        }
      }
      this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
        console.log('got redis es..',res);
      })
      //

      this.route.navigate(['pan', id]);
  }
  else {
    this.toast_message.error('Unable to fetch onboarding details, please try again!');
  }
},
(err) => {
  this.spinner = false;
}
);
//
}

  recommendedBasketData() {
    this.apiService.getRecommendedBasketData().subscribe((res: any) => {
    if(res && res.success) {
    this.recommendedBasketList = res.data;
    }
    else {
      // this.toast_message.error('unable to get recommended baskets');
    }
    })
  }

  getPurchasedBasketData() {
  this.spinner = true;
  this.apiService.getPurchasedBasketData(this.customer_Id).subscribe((res: any) => {
  // calling recommended baskets api
  this.recommendedBasketData();
  //
  console.log('purchased basket list...', res);
  this.spinner = false;
  if(res.success) {
    for(let data of res?.data?.data) {
      this.basketNames.push(data?.jar_name)
    }
  }

  if(res && res.success && res.data.data.length>0) {
    this.isData = true;
    this.isDataLoaded = true;
    this.existinguser = true;
    this.userListData = res.data.data;
    this.goBackRoute = true;
    console.log('this.userListData',this.userListData);
  }
  else {
    this.isData = false;
    this.existinguser = false;
    this.goBackRoute = false;
  }
  });
  }

  getBackgroundColor(text: string): string {
  if(text == 'Fix Investment' || text == 'Rebalance Now') {
    return '#E17466';
  }
  else {
    return '#C3966E';
  }
  }

  getBtnText(firstTrueKey: any | null): any {
    if (firstTrueKey.is_getting_renewed) {
      this.btnColorExistingUser = '#E17466';
      return 'Renew Now';
    } else {
      switch (firstTrueKey.portfolio_state) {
        case 'rebalance_now':
          this.btnColorExistingUser = '#E17466';
          return 'Rebalance Now';
        case 'invest_more':
          this.btnColorExistingUser = '#C3966E';
          return 'Invest More';
        case 'invest_now':
          this.btnColorExistingUser = '#C3966E';
          return 'Invest Now';
        case 'fix_investment':
          this.btnColorExistingUser = '#E17466';
          return 'Fix Investment';
        case 'order_in_progress':
          this.btnColorExistingUser = '#C3966E';
          return 'Order Pending';
        case 'is_renewal':
          return 'Renew Portfolio';
        case 'rebalance_in_progress':
          this.btnColorExistingUser = '#C3966E';
          return 'Invest Now';
        default:
          return 'No Action Available';
      }
    }
    
  }

  getFirstTrueKey(portfolioStatus: any): string | null {
    const priorityKeys = ['isFixInvestment', 'isRebalanced', 'isInvestMore', 'isInvestNow', 'isRenewal'];
    
    for (let key of priorityKeys) {
      if (portfolioStatus[key]) {
        return key;
      }
    }
    return null;
  }

  goToRebalancingPage() {
    this.route.navigate(['rebalance-history'], { queryParams: { product_code: this.product_code } });
  }

  getDataFromRedis() {
    this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.success) {
        console.log('got dashboard redis',res);
        this.product_code = res.data.productCode;
        this.onboardingId = res.data.onboardingId;
        // this.getPortfolioData('');
        this.getPurchasedBasketData();
        this.getAllDashboardData();
      }
     })
  }

  getAllDashboardData() {
    this.apiService.getAllDashboardData(this.customer_Id).subscribe((res: any) => {
      if(res && res.success) {
      this.mainBasketData = res.data.data.investment_details;
      this.dashboardEventData = {
        'CategoryName': localStorage.getItem('categoryNameMoe') || '',
        'BasketName': this.basketNames,
        'InvestedAmount': this.mainBasketData?.invested_amount,
        'CurrentValue': this.mainBasketData?.current_value,
        'TotalReturns': this.mainBasketData?.total_returns,
        'DaysPL': this.mainBasketData?.daily_profit_loss,
      }
      this.dashboardEventData['eventType'] = 'DashboardViewed';
      this.moengageService.dashboardEvents(this.dashboardEventData);
      }
    })
  }

  checkPaymentStatus() {
  this.onboardingService.checkPayment(this.user_service.getHashKey()).subscribe((res: any) => {
    console.log('payment status', res);
    this.paymentStatus = res.data.payment;
    if(this.paymentStatus == 'done') {
    // this.initPaymentCompleted();
    }
    })
  }

  initPaymentCompleted() {
    let payload = {
      parameters:{},
      onboarding_id: this.user_service.getHashKey(),
      onboarding_detail_id: localStorage.getItem('onboarding_detail_id')
    }
    this.apiService.markPaymentCompletion(payload).subscribe((res: any) => {
      console.log('payment marked as completed', res);
      this.assignPortfolio();
    })
  }

  assignPortfolio() {
    let payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.apiService.assignPortfolio(payload).subscribe((res: any) => {
      console.log('assigned portfolio successfully');
    })
  }

  getExitedStocks() {
    let payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.apiService.getPortfolioExitedStocks(payload).subscribe((res: any) => {
     if(res && res.success && res.data.status) {
        this.exitedStocksList = res.data.data.portfolio_composition;
     }
     else {
      this.toast_message.error(res.data.data.message);
     }
    })
  }

  investMoreExistingUser(type: any, data: any, btnType: any) {
    this.investNowText = type;
    this.basketNameMoe = data.jar_name;
    if(type == 'Basket Details') {
      console.log('slkslkskls', type, data, btnType)
     if(type == 'Invest Now') {
      this.isUserPortfolio = true;
      }
      else {
      this.isUserPortfolio = false;
      }
      this.investNowText = btnType;
      if(btnType == 'Invest Now' && data.portfolio_state == "rebalance_in_progress") {
      this.rebalancingInProgress = true;
      }
      else {
      this.rebalancingInProgress = false;
      }
      this.currentUser = true;
      this.existinguser = false;
      this.product_code = data.code_id;
      this.sub_product_id = data.sub_product_id;
      // this.getPortfolioData('multipleBasket');
      // this.getExitedStocks();
      this.route.navigate(['basketDetails'], {
        queryParams: { code: data.code_id, product_id: data.sub_product_id }
      })
    }
    else if(type == 'Invest Now' && data.portfolio_state != 'rebalance_in_progress') {
      this.product_code = data.code_id;
      this.sub_product_id = data.sub_product_id;
      this.getPortfolioData('multipleBasket');
      this.openInvestementModal();
    }
    else if(type == 'Invest More') {
      this.product_code = data.code_id;
      this.sub_product_id = data.sub_product_id;
      this.getPortfolioData('multipleBasket');
      this.openInvestementModal();
    }
    else if(type == 'Fix Investment' || type == 'Rebalance Now') {
      this.product_code = data.code_id;
      this.sub_product_id = data.sub_product_id;
      this.getFixInvestmentData();
      this.getPortfolioData('multipleBasket');
      this.openInvestementModal();
    }
    else if(type == 'Renew Now') {
      this.renewNow(data);
    }
    else if(type == 'Order Pending') {
      this.openRebalancingInProgressModal('orderPending');
    // this.toast_message.warning('Your order is in progress, we will update you once executed');
    }
    else if(type == 'Invest Now' && data.portfolio_state == 'rebalance_in_progress') {
      this.openRebalancingInProgressModal('rebalancingInProgress');
      // this.toast_message.warning("We're rebalancing your portfolio. Please wait a moment, and you'll be able to continue shortly.");
    }
  }

  openRebalancingInProgressModal(type: any) {
    const dialogRef = this.dialog.open(OrderInProgressComponent, {
      height: 'auto',
      disableClose: false,
      autoFocus: true,
      width: '50%',
      data: {
        modalType: type
      },
    });
  }

  renewNow(data: any) {
    if(data && data == 'Basket Details') {
      let obj = {
        sub_product_id: this.sub_product_id
      }
      this.renewBasket(obj);
    }
    else {
      this.renewBasket(data);
    }
  }

  renewBasket(data: any) {
    //call create onboarding API
    this.spinner = true;
    //call moengage subscribeNow event
    // let eventDetails = {
    //   'basketName': data?.sub_product_name,
    //   'categoryName': 'Dashboard',
    //   'basketId': data?.category_id,
    //   'minInvestmentAmount': data?.pb_details?.min_investment_amount,
    //   'returns': data?.pb_details?.cagr || 0,
    //   'returnsDuration': data?.investment_horizon,
    //   'basketrisk': data?.pb_details?.risk_level,
    //   'basketprice': data?.fees_details[0]?.details?.advisory_fees
    // }
    // this.moengageService.subscribeNowEvent(eventDetails);
    //
    let payload = {
      "sub_product_id": data?.sub_product_id,
      "contact_id": this.tokenData?.contact_id,
    }
    let authToken = localStorage.getItem('authToken');
    this.onboardingService.renewOnboarding(payload, authToken).subscribe((res: any) => {
      this.spinner = false;
      console.log('lead creation api res..', res);
      if(res && res.success && Object.keys(res.data).length != 0) {
          const parts = res.data.url.split('/');
          const id = parts[parts.length - 1];
          this.user_service.pushHashKey(id);
    
          // call redis api to save data
          let payload = {
            id: this.user_service.getHashKey(),
            data: {
              categoryId: data.category_id,
              subProductId: data.sub_product_id,
              onboardingId: res.data.onboarding_id,
              productCode: data.sub_product_code,
            }
          }
          this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
            console.log('got redis es..',res);
          })
          //
    
          this.route.navigate(['pan', id]);
      }
      else {
        this.toast_message.error('Unable to fetch onboarding details, please try again!');
      }
    },
    (err) => {
      this.spinner = false;
    }
    );
    //
    }

  goBackToDashboard() {
    this.currentUser = false;
    this.existinguser = true;
  }

  getPortfolioData(basketType: any) {
    let payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.spinner = true;
    this.apiService.getPortfolioData(payload).subscribe((res: any) => {
     if(res && res.success) {
      if(this.dashboardEventData) {
        this.dashboardEventData.BasketName = this.basketNameMoe;
        this.dashboardEventData['NumberOfStocks'] = res.data?.data?.portfolio_composition?.length;
      }
      //calling api to get jar details for header details
      this.isAfterMarketOrder = res.data?.data?.is_after_market_order;
      this.isBasketRebalanced = res.data?.data?.isRebalanced;
      this.isRenewNow = res.data?.data?.is_getting_renewed;
      this.apiService.getProductData(payload).subscribe((res: any) => {
        if(res && res.success) {
          this.dashboardEventData.InvestedAmount = res.data?.data?.investment_details?.invested_amount;
          this.dashboardEventData.CurrentValue = res.data?.data?.investment_details?.current_value;
          this.dashboardEventData.TotalReturns = res.data?.data?.investment_details?.total_returns;
          this.dashboardEventData.DaysPL = res.data?.data?.investment_details?.daily_profit_loss;
          this.dashboardEventData['InvestmentStatus'] = res.data?.data?.investment_details?.invested_amount === 0 ? 'No' : 'Yes';
          this.dashboardEventData.eventType = 'BasketDashboardViewed';
          this.moengageService.dashboardEvents(this.dashboardEventData);
          this.jarDetails = res.data.data.jar_details;
          this.minimum_Investement_Amount = Math.ceil(res.data.data.jar_details.min_investment_amount);
          this.investementDetails = res.data.data.investment_details;
        }
        else {
          this.toast_message.error(res.data.data.message);
        }
      })
      //
      if(res.data.data?.portfolio_type == 'model_portfolio') {
        this.invest_now_text = 'Invest Now';
      // if(res.data.data?.is_getting_renewed) {
      //   this.invest_now_text = 'Renew Now';
      // }
      this.stocksList = res.data.data?.portfolio_composition;
      // this.currentUser = true;
      // this.existinguser = false;
      }
      if(res.data.data?.portfolio_type == 'model_portfolio' && res.data.data?.portfolio_state == 'rebalance_in_progress') {
        this.invest_now_text = this.investNowText;
      }
      if(res.data.data?.portfolio_type == 'user_portfolio') {
        if(basketType == 'multipleBasket') {
          this.invest_now_text = this.investNowText;
          this.currentUser = true;
          this.existinguser = false;
          this.goBackRoute = true;
          this.isUserPortfolio = true;
          this.stocksList = res.data.data?.portfolio_composition;
        }
        else {
          this.invest_now_text = this.investNowText;
          // this.currentUser = false;
          // this.existinguser = true;
          this.goBackRoute = true;
          this.isUserPortfolio = true;
          this.stocksList = res.data.data.portfolio_composition;
        }
        
      }
      this.spinner = false;
     }
     else {
      this.toast_message.error(res.data.data.message);
     }
    },
  
  (err: any) => {
    this.spinner = false;
  })
  }

  getFixInvestmentData() {
    const payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.apiService.correctPortfolio(payload).subscribe((res: any) => {
      console.log('assigned portfolio successfully');
      this.fixInvestmentData = res.data.transactions;
    });
  }

  openInvestementModal() {
    if(this.investNowText == 'Order Pending') {
      this.openRebalancingInProgressModal('orderPending');
    // this.toast_message.warning('Your order is in progress, we will update you once executed');
    }
    else if(this.rebalancingInProgress) {
      this.openRebalancingInProgressModal('rebalancingInProgress');
    // this.toast_message.warning("We're rebalancing your portfolio. Please wait a moment, and you'll be able to continue shortly.");
    }
    else {
      console.log('investNowText:::', this.investNowText);
      if(this.investNowText == 'Fix Investment' || this.investNowText == 'Rebalance Now') {
        this.getFixInvestmentData();
      }
      let payload = {
        customer_id: this.customer_Id,
        code_id: this.product_code
      }
      this.apiService.getProductData(payload).subscribe((res: any) => {
        if(res && res.success) {
          this.jarDetails = res.data.data.jar_details;
          this.minimum_Investement_Amount = Math.ceil(res.data.data.jar_details.min_investment_amount);
          this.investementDetails = res.data.data.investment_details;
          const dialogRef = this.dialog.open(InvestementModalDashboardComponent, {
            height: '90%',
            disableClose: true,
            autoFocus: true,
            backdropClass: 'backdropBackground',
            width: '100%',
            data: {
              minimumInvestmentAmount: this.minimum_Investement_Amount,
              onboardingId: this.onboardingId,
              investementType: this.invest_now_text,
              productName: this.jarDetails,
              product_code: this.product_code,
              sub_product_id: this.sub_product_id,
              actionType: this.investNowText,
              fixInvestmentData: this.fixInvestmentData,
              productRisk: res.data?.data?.jar_details?.risk_level,
              isAfterMarketOrder: this.isAfterMarketOrder
            },
          });
      
          //get smallcase instance
          const reqData = {
            user_medium_src: `multiplyrr_${res?.data?.data?.jar_details?.code_id?.toLowerCase() || ''}`,
            user_medium_id: this.customer_Id,
          }
          this.smallCase_service.getAuthToken(reqData).subscribe((res: any) => {
            this.gatewayInstance = new (window as any).scDK({
              gateway: "researchandranking",
              smallcaseAuthToken: `${res.data.jwt}`,
              config: {
                amo: true
              }
            });
          })
          //
      
          // handle success event from smallcase
        dialogRef.componentInstance.transactionResponse.subscribe((txnResponse: any) => {
          console.log('.......', txnResponse)
          let userMessage = txnResponse.orderBatches[0].orders[0].statusMessage;
          let status = txnResponse.orderBatches[0].orders[0].status;
          this.smallCaseAuthToken = txnResponse.smallcaseAuthToken;
          if(status == 'PLACED' || status == 'COMPLETE') {
          this.toast_message.success('Transaction successful');
          //calling logout event
          this.gatewayInstance.brokerLogout();
          //
          window.location.reload();
          this.getDataFromRedis();
          }
          else {
          this.toast_message.error(userMessage);
          window.location.reload();
          }
        });
      
        dialogRef.componentInstance.isGuest.subscribe((isGuest: any) => {
          console.log('is guest....', isGuest);
          if(isGuest) {
            this.registerAuthToken(this.smallCaseAuthToken, `multiplyrr_${res?.data?.data?.jar_details?.code_id?.toLowerCase() || ''}`);
          }
        });
      
        // handle failure event from smallcase
        dialogRef.componentInstance.transactionError.subscribe((error: any) => {
          console.log('received error......', error);
          this.toast_message.error('Transaction failed: ' + error.message);
          //calling logout event
          this.gatewayInstance.brokerLogout();
          //
          let reqData = {
            txnID: this.transaction_id,
            token: this.smallCaseInitToken,
            }
            this.smallCase_service.userCancelService(reqData).subscribe((res: any) => {
              console.log('user cancel success',res);
              window.location.reload();
              this.getDataFromRedis();
            },
            (err) => {
              window.location.reload();
              this.getDataFromRedis();
            }
          );
        });
      
        //get transaction id
        dialogRef.componentInstance.transactionID.subscribe((id: any) => {
          console.log('received error......', id);
          this.transaction_id = id;
        });
      
        //get init auth token
        dialogRef.componentInstance.authTokenInit.subscribe((token: any) => {
          console.log('token received...', token);
          this.smallCaseInitToken = token;
        })
        }
        else {
          this.toast_message.error(res.data.data.message);
        }
      })
    }
  }

  registerAuthToken(token: any, code: any) {
    let reqData = {
      'user_medium_src': code,
      'user_medium_id': this.customer_Id,
      'auth_id': token
    }
  this.smallCase_service.registerToken(reqData).subscribe((res: any) => {
    console.log('token registered successfully.....', res)
  })
  }

  openPDF(pdfLink: any, data: any) {
    window.open(pdfLink);
    let eventData = {
      'stockName' : data.company_short_name,
      'basketName' : this.jarDetails?.jar_name
    }
    this.moengageService.reportDownload(eventData);
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'dashboard',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: false,
        autoFocus: true,
        backdropClass: 'backdropBackground',
        width: isMobileView ? '100%' : '100%',
        data: {
          closeLogin: true
        }
      });
    }
  }

  goToInsights(jarData: any) {
    this.route.navigate(['insights'], { queryParams: { code_id: jarData.code_id } });
  }
}
