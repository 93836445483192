import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-curve',
  templateUrl: './footer-curve.component.html',
  styleUrls: ['./footer-curve.component.scss'],
})
export class FooterCurveComponent {
  scrollTop() {
    scroll(0,0);
  }
}
