import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WebSocketService } from '../app/core/services/web-socket.service';
import { SocketType } from './core/models/types';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from './core/services/api-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'multiplyRR_FE';
  onboarding_ID: any;
  customer_Id: any;
  matBadgeCount: number = 0;
  @Output() updateBadgeCount: EventEmitter<number> = new EventEmitter<number>();

  constructor (
    private route: Router,
    private websocket: WebSocketService,
    private toast_message: ToastrService,
    private apiService: ApiServiceService,
  ) {}

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';

    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if(token && data?.is_other_product_active) {
      console.log('user already exists');
    }
    else if (token && data.is_registered) {
      localStorage.setItem('categoryNameMoe', 'dashboard');
      console.log('ngOnInit token data', data);
      this.customer_Id = data.customer_id;
    }

    //notification socket connection
    this.websocket.initNotificationService();
    //

    // onboarding socket connection
    this.route.events.subscribe((data) => {
      console.log(data);
      if (data instanceof NavigationEnd) {
        if (data.url.charAt(0) == '/') {
          data.url = data.url.replace('/', '');
        }
        const onboarindId = data.url.split('/');
        this.onboarding_ID = onboarindId[1];
        this.websocket.init(this.onboarding_ID, onboarindId);
      }
    });
    //

    // connecting socket for getting live notifications
    this.websocket
      .listenNotification(SocketType.notification)
      .subscribe((res: any) => {
        let response_data = JSON.parse(res);
        response_data = response_data.body;
        response_data = JSON.parse(response_data);
        console.log('got notification response..', response_data.title);
        this.getNotificationList(this.customer_Id);
        // this.toast_message.success(response_data.title);
        const toast = this.toast_message.success(response_data.title);

        toast.onTap.subscribe(() => {
          this.route.navigate(['notification']);
        });
      });
      //
  }

  getNotificationList(id: any) {
    this.apiService.getNotificationList(id, 1).subscribe((res: any) => {
      if (res && res.success) {
        this.matBadgeCount = res.data.undeliveredMsgCount;
        this.updateBadgeCount.emit(this.matBadgeCount);
      }
    })
  }
}
