<!-- laptop view  -->
<div
  class="container-fluid main-wrapper"
  *ngIf="this.screenType !== 'mob'"
>
<div id="toolbar" class="toolbar-main">
  <div class="main-logo">
    <img
      class="logo-img"
      (click)="goToHome()"
      src="../../../../assets/multiplyRR_logo.svg"
      style="cursor: pointer;"
    />
  </div>
  <div class="toolbar-list">

    <button mat-button *ngIf="screenType !== 'mob'" (click)="navigateTo('discover')">
      <span class="toolbar-name" [class.selected]="selectedButton === 'discover'">Discover</span>
    </button>
    <button mat-button *ngIf="screenType !== 'mob'" (click)="navigateTo('dashboard')">
      <span class="toolbar-name" [class.selected]="selectedButton === 'dashboard'">My Portfolio</span>
    </button>
    <!-- <button mat-button *ngIf="screenType !== 'mob'" (click)="navigateTo('infoHub')">
      <span class="toolbar-name" [class.selected]="selectedButton === 'infoHub'">Info Hub</span>
    </button> -->
    <button mat-button *ngIf="screenType !== 'mob'" (click)="navigateTo('watchlist')">
      <span class="toolbar-name" [class.selected]="selectedButton === 'watchlist'">Watchlist</span>
    </button>
    
    <div *ngIf="showUserName" style="display: flex; align-items: center;">
      <button
        mat-button
        #notificationMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="notificationMenu"
      >
        <span
          class="custom-notification-icon"
          matBadge="{{ matBadgeCount }}"
          matBadgeSize="medium"
          matBadgeOverlap="false"
        >
          <img src="../../../../assets/notification_icon_updated.svg" alt="notifications" style="width: 38px; height: 38px;">
        </span>
      </button>
      <mat-menu class="mat-menu-notification" #notificationMenu="matMenu">
        <app-notifications
          (notificationClick)="notificationMenuTrigger.closeMenu()"
          (updateBadgeCount)="matBadgeCount = $event"
        ></app-notifications>
      </mat-menu>
    </div>
    
    <div *ngIf="screenType !== 'mob'" class="verticalLine-desktop"></div>
  </div>
  <div class="toolbar-cta">
    <div style="display: flex; align-items: center; cursor: pointer;" *ngIf="showUserName">
      <div [matMenuTriggerFor]="menu">
        <p [attr.data-letters]="dataLetter" style="margin: 0; display: flex; align-items: center;">
          <span *ngIf="showUserName == true && this.screenType !== 'mob'">{{ username }}</span>
          <img src="../../../../assets/dropdownIcon.svg" alt="" style="margin-left: 5px;" />
        </p>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goToProfile()"><span style="font-family: 'Source Sans Pro';">Profile</span></button>
        <button mat-menu-item (click)="logout()"><span style="font-family: 'Source Sans Pro';">Logout</span></button>
      </mat-menu>
    </div>
    <div style="display: flex; align-items: center; cursor: pointer;" *ngIf="!showUserName">
      <!-- <app-button
        [savingsText]="loginText"
        (click)="openLoginModal('openLogin')"
        *ngIf="screenType !== 'mob'"
      ></app-button> -->
      <button mat-button color="primary" style="border-radius: 8px;"
        *ngIf="screenType !== 'mob'"
        [style.background-color]="'#C3966E'" 
        [style.color]="'white'" (click)="openLoginModal('openLogin')">
        {{loginText}}
      </button>
      <img
        src="../../../../assets/userMobIcon.svg"
        (click)="openLoginModal('openLogin')"
        alt=""
        *ngIf="screenType == 'mob'"
      />
    </div>
  </div>
</div>

</div>
<!-- -->

<!-- mobile view  -->
<div
  class="container-fluid"
  *ngIf="this.screenType == 'mob'"
  style="
  background-color: #fff;
  width: 100%; padding: 0;"
>
<div id="toolbar" class="toolbar-container">
  <div class="logo-container">
    <img
      class="logo-img"
      style="height: 65px;"
      (click)="goToHome()"
      src="../../../../assets/multiplyRR_logo.svg"
    />
  </div>
  <div *ngIf="showUserName" class="notification-container">
    <button
      mat-button
      #notificationMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="notificationMenu"
    >
      <span
        class="custom-notification-icon"
        matBadge="{{ matBadgeCount }}"
        matBadgeSize="medium"
        matBadgeOverlap="false"
      >
        <img src="../../../../assets/notification_icon_updated.svg" alt="notifications" style="width: 38px; height: 38px;">
      </span>
    </button>
    <mat-menu class="mat-menu-notification" #notificationMenu="matMenu">
      <app-notifications
        (notificationClick)="notificationMenuTrigger.closeMenu()"
        (updateBadgeCount)="matBadgeCount = $event"
      ></app-notifications>
    </mat-menu>
    <div *ngIf="screenType !== 'mob'" class="verticalLine-desktop"></div>
  </div>
  <div class="spacer" *ngIf="!showUserName"></div>
  <div
    class="user-container"
    style="cursor: pointer;"
    *ngIf="showUserName"
  >
    <div [matMenuTriggerFor]="menu" style="height: 45px;">
      <p [attr.data-letters]="dataLetter">
        <span *ngIf="showUserName == true && this.screenType !== 'mob'">
          Manish Singh
        </span>
        <img src="../../../../assets/dropdownIcon.svg" alt="" />
      </p>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="goToProfile()">Profile</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
  <div
    class="login-container"
    style="cursor: pointer"
    *ngIf="!showUserName"
  >
    <app-button
      [savingsText]="loginText"
      (click)="openLoginModal('openLogin')"
      *ngIf="screenType !== 'mob'"
    ></app-button>
    <img
      src="../../../../assets/userMobIcon.svg"
      (click)="openLoginModal('openLogin')"
      alt=""
      *ngIf="screenType == 'mob'"
    />
  </div>
</div>

</div>
<!--  -->
