import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent {
  @Input() label: string = '';
  @Input() width: string = '250px';
  @Input() options: any[] = [];
  @Input() required: boolean = false;
  @Input() errorMessage: string = '';

  @Output() selectedValueChange = new EventEmitter<any>();
  selectedValue: any;

  constructor() { }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  emitSelectedValue() {
    this.selectedValueChange.emit(this.selectedValue);
    this.onChange(this.selectedValue);
    this.onTouch();
  }
}
