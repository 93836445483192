<ng-select
  [class.mat-blur]="ngBlur && showError"
  [items]="list"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [appearance]="appearance"
  [placeholder]="label"
  [searchable]="searchable"
  [multiple]="isMultiple"
  [disabled]="disabled"
  [clearable]="clearable"
  [virtualScroll]="virtualScroll"
  [readonly]="readonly"
  [required]="required"
  [class.required]="required"
  [groupBy]="groupBy"
  [loading]="loading"
  (blur)="onBlur()"
  [virtualScroll]="virtualScroll"
  [appendTo]="appendTo"
  [addTagText]="addNewItemsText"
  [addTag]="addNewItems"
  [closeOnSelect]="isMultiple ? false : true"
  [disabled]="disabled || loading"
  [(ngModel)]="selectedValue"
  (change)="onSelectionChange($event)"
  [id]="id"
  (open)="selectBoxOpen($event)"
  className="myPanelClass">
  
  <ng-template ng-option-tmp let-item="item" *ngIf="dynamicOption">
    <ng-container
      [ngTemplateOutlet]="dynamicOption"
      [ngTemplateOutletContext]="{ item: item }"></ng-container>
  </ng-template>

  <ng-template ng-optgroup-tmp let-item="item">
    <ng-container
      [ngTemplateOutlet]="dynamicGroupOption || defaultGroupTemplate"
      [ngTemplateOutletContext]="{ item: item }"></ng-container>
  </ng-template>

  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <ng-container
      [ngTemplateOutlet]="dynamicLabelOption || defaultLabelTemplate"
      [ngTemplateOutletContext]="{ item: item, clear: clear }">
    </ng-container>
  </ng-template>

  <ng-template #defaultLabelTemplate let-item="item" let-clear="clear">
    <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
    <span class="ng-value-label">{{ item[bindLabel] }}</span>
  </ng-template>

  <ng-template #defaultGroupTemplate let-item="item">
    {{ item[groupBy] }}
  </ng-template>
</ng-select>
<p class="select-description-cls" *ngIf="description">{{ description }}</p>
