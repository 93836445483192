import { Component, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { ScreenTypeService } from 'src/app/core/services/screen-type.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login-mobile-view',
  templateUrl: './login-mobile-view.component.html',
  styleUrls: ['./login-mobile-view.component.scss'],
})
export class LoginMobileViewComponent implements OnInit {
  isMobileSuffix: boolean = false;
  isNameSuffix: boolean = false;
  isEmailSuffix: boolean = false;
  termsCondition: boolean = false;
  termsData: boolean = false;
  mobileNumber: string = '';
  fullName: string = '';
  emailID: string = '';
  mobileDiv: boolean = true;
  otpDiv: boolean = false;
  loading: boolean = false;
  SignUpDiv: boolean = false;
  countdown: Subscription | undefined;
  counter: number = 60;
  tick = 1000;
  timerCountdown: string = '';
  countdownCompleted: boolean = false;
  error_message: string = '';
  error_Message_MobileNo: string = '';
  error_Message_Name: string = '';
  error_Message_Email: string = '';
  mobileHeader: string = 'Welcome to MultiplyRR';
  screenType: string = '';
  emailRegex = /^\S+@\S+\.\S+$/;
  registerToken: any;
  OTPError: string = '';
  isEdit: boolean = true;
  previousRouteName: string = '';
  websiteURL: string = 'https://www.equentis.com/researchandranking/';

  constructor(
    private apiService: ApiServiceService,
    private _toastSerivce: ToastrService,
    private route: Router,
    private router: ActivatedRoute,
    private screenTypeService: ScreenTypeService,
    private authService: AuthService
  ) {}

  goToTermsOfService() {
    window.open(`${this.websiteURL+'term-of-service'}`);
  }

  goToPrivacyPolicy() {
    window.open(`${this.websiteURL + 'privacy-policy-and-legal-disclaimer'}`);
  }

  ngOnInit() {
    if (this.screenTypeService.breakpointState !== 'mob') {
      this.route.navigate(['']);
    }
    this.router.paramMap.subscribe((params: any) => {
      let customData = history.state.customData;
      this.previousRouteName = customData.customData;
      console.log('got prevoius route', customData.customData);
    });
  }

  onTermsChange(data: any) {
    if (data.checked) {
      this.termsCondition = false;
      this.termsData = true;
    } else {
      this.termsCondition = true;
      this.termsData = false;
    }
  }

  goToPreviousPage() {
    // if (this.previousRouteName) {
    //   this.route.navigate([this.previousRouteName]);
    // } else {
    //   this.route.navigate(['']);
    // }
    this.route.navigate(['']);
  }

  onMobileChange() {
    if (this.mobileNumber.length === 10 && !['6', '7', '8', '9'].includes(this.mobileNumber.charAt(0))) {
      this.isMobileSuffix = true;
    } else {
      this.isMobileSuffix = false;
    }
  }

  onNameChange() {
    if (this.fullName) {
      this.isNameSuffix = true;
    } else {
      this.isNameSuffix = false;
    }
  }

  onEmailChange() {
    if (this.emailID && this.emailRegex.test(this.emailID) === true) {
      this.isEmailSuffix = true;
    } else {
      this.isEmailSuffix = false;
    }
  }

  onGetOTP() {
    if (
      this.mobileNumber.length < 10 ||
      /[0-5]/.test(this.mobileNumber.charAt(0))
    ) {
      this.error_Message_MobileNo = 'Please enter valid mobile number';
      this.isMobileSuffix = false;
    } else if (this.termsData == false) {
      this.isMobileSuffix = true;
      this.error_Message_MobileNo = '';
      this.termsCondition = true;
    } else {
      this.isMobileSuffix = true;
      this.termsCondition = false;
      let reqData = {
        mobile_number: this.mobileNumber,
      };
      this.authService.getOTP(reqData).subscribe((res: any) => {
        if (res.success) {
          if (res.data.error) {
            this.SignUpDiv = true;
            this.mobileDiv = false;
            this.otpDiv = false;
          } else {
            this.error_Message_MobileNo = '';
            this.mobileDiv = false;
            this.otpDiv = true;
            this.mobileHeader = 'Enter the OTP';
            this.initCountdownTimer(1);
          }
        }
      });
    }
  }

  resendOTP() {
    let reqData = {
      mobile_number: this.mobileNumber,
    };
    this.authService.getOTP(reqData).subscribe((res: any) => {
      if (res.success) {
        if (res.data.error) {
          this.SignUpDiv = false;
          this.mobileDiv = false;
          this.otpDiv = true;
          this.initCountdownTimer(1);
        } else {
          this.error_Message_MobileNo = '';
          this.mobileDiv = false;
          this.otpDiv = true;
          this.mobileHeader = 'Enter the OTP';
          this.initCountdownTimer(1);
        }
      }
    });
  }

  onSignup() {
    if (this.fullName) {
      this.isNameSuffix = true;
    }
    if (this.emailID) {
      this.isEmailSuffix = true;
    }
    if (
      this.fullName &&
      this.emailID &&
      this.emailRegex.test(this.emailID) === true
    ) {
      this.error_Message_Email = '';
      this.error_Message_Name = '';
      let reqData = {
        mobile_number: this.mobileNumber,
        name: this.fullName,
        email: this.emailID,
      };
      this.authService.signUp(reqData, this.registerToken).subscribe(
        (res: any) => {
          if (res.success && !res.data.error) {
            localStorage.setItem('authToken', res.data.token);
            // this._toastSerivce.success('SignUp successfull');
            // window.location.reload();
            this.loading = false;
            this.isEdit = false;
            this.mobileNumber = this.emailID;
            this.otpDiv = true;
            this.SignUpDiv = false;
            this.mobileDiv = false;
            this.initCountdownTimer(1);
          } else {
            this._toastSerivce.error(res.data.error);
          }
        },
        (err: any) => {
          this._toastSerivce.error('something went wrong!');
        }
      );
    } else {
      if (!this.fullName) {
        this.error_Message_Name = 'Please enter full name';
        this.error_Message_Email = '';
        this.isNameSuffix = false;
      } else if (
        !this.emailID ||
        this.emailRegex.test(this.emailID) === false
      ) {
        this.error_Message_Email = 'Please enter valid email Id';
        this.error_Message_Name = '';
        this.isEmailSuffix = false;
      }
    }
  }

  editMobile() {
    this.mobileDiv = true;
    this.otpDiv = false;
    this.mobileHeader = 'Welcome to MultiplyRR';
  }

  initCountdownTimer(counter: number) {
    if (this.countdown) {
      this.countdown.unsubscribe();
    }

    this.countdownCompleted = false;
    this.counter = counter * 60;

    // Start the timer
    this.countdown = timer(0, this.tick).subscribe(() => {
      --this.counter;

      if (this.counter <= 0) {
        this.countdownCompleted = true;
        this.countdown?.unsubscribe();
      }
    });
  }

  onMobileOtpChanges(otp: string) {
    console.log('On Otp Changes : ', otp);
    if (otp.trim().length == 4) {
      this.loading = true;
      let reqData: any;
      reqData = {
        mobile_number: this.mobileNumber,
        otp: otp,
      };
      if (this.emailID) {
        reqData = {
          email: this.mobileNumber,
          otp: otp,
        };
      }
      this.authService.verifyOTP(reqData).subscribe((res: any) => {
        console.log(res);
        if (res.success && !res.data.error) {
          this.OTPError = '';
          if (res.data.is_registered) {
            localStorage.setItem('authToken', res.data.token);
            this.SignUpDiv = false;
            // window.location.reload();
            if (this.previousRouteName) {
              this.route.navigate([this.previousRouteName]);
            } else {
              this.route.navigate(['']);
            }
          } else {
            if (this.emailID) {
              // window.location.reload();
              if (this.previousRouteName) {
                this.route.navigate([this.previousRouteName]);
              } else {
                this.route.navigate(['']);
              }
            } else {
              this.registerToken = res.data.token;
              this.mobileHeader = 'Please register yourself';
              this.SignUpDiv = true;
              this.mobileDiv = false;
              this.otpDiv = false;
            }
          }
        } else if (res.data.error) {
          this.loading = false;
          this.OTPError = res.data.error;
        }
      });
    }
  }

  formatCountDownPipe(): string {
    const minutes: number = Math.floor(this.counter / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(this.counter - minutes * 60)).slice(-2)
    );
  }
}
