<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>

<div>
            <div class="bank-details" [ngStyle]="{ 'padding': (screenType.breakpointState === 'mob' ? '40px 20px' : '40px 70px') }">
                <div class="overlay" *ngIf="spinner">
                    <div class="spinner">
                      <img src="../../../../assets/loader.svg" alt="" class="loader">
                    </div>
                </div>
                <mat-card
                [ngStyle]="{ 'margin-top': (screenType.breakpointState === 'mob' ? '0' : '80px') }">
                <mat-card-content>
                <div class="bank-wrapper"
                [ngStyle]="{ 'padding': (screenType.breakpointState === 'mob' ? '0' : '40px') }">
                    <div class="plan" style="width: 100%;">
                        <div class="plan-title">
                            <div>Notifications</div>
                            <div class="markAllRead" (click)="markAllAsRead()"><a>Mark all as read</a></div>
                        </div>
                        <img src="../../../../assets/hr_notification.svg" alt="" *ngIf="screenType.breakpointState!='mob'">
                        <hr *ngIf="screenType.breakpointState=='mob'">
                        <!-- <img src="../../../../assets/hrDarkMobIcon.svg" alt="" *ngIf="screenType.breakpointState=='mob'"> -->
                    </div>
        
                    <div class="scroll-container" *ngIf="isDataLoaded">
                        <div *ngFor="let group of groupedNotifications | keyvalue : customSort">
                            <span class="date-header">{{ group.key }}</span>
                          
                            <div class="notification" *ngFor="let data of group.value"
                                 (click)="markAsRead(data._id, data.isDelivered)"
                                 [ngStyle]="{ 'background-color': data.isDelivered ? 'transparent' : '#EBF3FA' }">
                              <span class="notification-data">
                                <div class="wrapper">
                                  <div>
                                    <img src="../../../../assets/notification_Icon.svg" alt="">
                                  </div>
                                  <div>
                                    <div>
                                      <span class="title">{{ data.data.title }}</span>
                                    </div>
                                    <div style="padding-top: 10px;">
                                      <span class="sub-title">{{ data.data.subtitle }}</span>
                                    </div>
                                  </div>
                                </div>
                                <span class="updated-at">{{ data.createdAt | timeAgo }}</span>
                                <img src="../../../../assets/hr_notification.svg" alt="" *ngIf="screenType.breakpointState != 'mob'">
                                <hr *ngIf="screenType.breakpointState == 'mob'">
                              </span>
                            </div>
                          </div>                          
                          
                   <div class="emptyState" *ngIf="notificationsList.length === 0">
                    <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
                   </div>
                    </div>
                </div> 
            </mat-card-content>
        </mat-card>
            </div>
    <app-footer-curve *ngIf="this.screenType.breakpointState !== 'mob'" ></app-footer-curve>
    <app-footer></app-footer>
</div>