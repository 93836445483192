<button mat-button color="primary" style="border-radius: 8px;" 
        *ngIf="!isTransparentBTN" 
        [style.background-color]="backgroundColor" 
        [style.color]="color"
        >
        <img src="../../../../../assets/retry_icon.svg" alt="" *ngIf="isRetry">&nbsp;
  {{savingsText}}
</button>

<button mat-button color="primary" *ngIf="isTransparentBTN" 
        [ngStyle]="{
          'background-color': 'transparent', 
          'border': '1px solid ' + borderColor, 
          'border-radius': '8px', 
          'color': textColor
        }"
        >
  <img src="../../../../../assets/watchlistIcon.svg" alt="" *ngIf="showWatchListIcon">
  <img src="../../../../assets/backArrow.svg" alt="" *ngIf="goBackIcon">
  &nbsp;{{savingsText}}
</button>
