<!-- laptop view -->
<div class="investement-modal-container" *ngIf="this.screenType.breakpointState !== 'mob'">
  <div class="overlay" *ngIf="spinner">
    <div class="spinner">
      <img src="../../../../assets/loader.svg" alt="" class="loader">
    </div>
</div>
<div class="header">
<div class="headerText">
    Investing in {{data?.productName?.jar_name}}
</div>
<div (click)="closeDialog()">
    <img src="../../../../assets/crossIconDark.svg" style="cursor: pointer;" alt="">
</div>
</div>
<img src="../../../../assets/hrDarkTagIcon.svg" alt="">
<div *ngIf="actionType !== 'Fix Investment' && actionType !== 'Rebalance Now'"
>
<div class="input-field-wrapper">
    <div class="subText">Enter the amount you want to invest</div>
    <div style="width: 40%;">
      <div style="display: flex; align-items: baseline;">
        <div style="flex: 1;">
          <app-input
            class="pan-input"
            [placeholder]="'Enter your Invest Amount'"
            [autofocus]="false"
            [type]="'number'"
            [name]="'pan'"
            [maxLength]="10"
            [required]="true"
            [(ngModel)]="amountToInvest"
            (mouseleave)="onSubmitAmount()"
            style="width: 100%;"
          ></app-input>
        </div>
        <!-- <div style="width: 40px; display: flex; justify-content: center; align-items: center;">
          <mat-icon style="color: #6C6F9D; cursor: pointer;" (click)="onSubmitAmount()"
          matTooltip="Refresh to update portfolio">autorenew</mat-icon>
        </div> -->
      </div>
      
        <span class="hintText">
          <div>
            <span class="defaultMinInvestement" (click)="changeColor($event)"><i class="fa fa-rupee"></i> {{minInvestementAmount * 2 | readableNumber}}</span>
            <span class="defaultMinInvestement" (click)="changeColor($event)"><i class="fa fa-rupee"></i> {{minInvestementAmount * 3 | readableNumber}}</span>
          </div>
        <div>
          <span style="color: #0F0F0F">Min Investment Amount</span>&nbsp; <span style="color: black; font-weight: 600;">&#8377; {{minInvestementAmount | readableNumber}}</span>
        </div>
      </span>
    </div>
    <!-- <div>
        <app-button [savingsText]="'Save'" [backgroundColor]="'rgb(195, 150, 110)'" (click)="onSubmitAmount()"></app-button>
    </div> -->
</div>

<!-- <div class="suggestion-section">
    <span class="innerText">We suggest you to invest atlest 2x or 3x of the minimum investement amount to get benefit of lower transaction cost</span>
</div> -->
</div>
<br>
<mat-card>
  <mat-card-content>
    <div class="stocks-list">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="headerTable">Stocks</th>
            <th scope="col" class="headerTable">Current Price ( <i class="fa fa-rupee"></i> )</th>
            <th scope="col" class="headerTable">Weightage</th>
            <th scope="col" class="headerTable">Quantity</th>
            <th scope="col" class="headerTable">Order Type</th>
          </tr>
        </thead>
        <tbody class="tbody-style" style="max-height: 200px; overflow-y: auto;">
          <tr *ngFor="let stock of stocksList; let i = index">
            <td [ngStyle]="{'background-color': (i % 2 === 1)  ? '#F4F4F4' : ''}">{{ stock.company_name }}</td>
            <td [ngStyle]="{'background-color': (i % 2 === 1)  ? '#F4F4F4' : ''}">{{ stock.last_cmp | readableNumber }}</td>
            <td [ngStyle]="{'background-color': (i % 2 === 1)  ? '#F4F4F4' : ''}">{{ stock.allocation }}%</td>
            <td [ngStyle]="{'background-color': (i % 2 === 1)  ? '#F4F4F4' : ''}">{{ stock.quantity }}</td>
            <td [ngStyle]="{
              'background-color': (i % 2 === 1) ? '#F4F4F4' : '',
              'color': stock.transaction_type == 'BUY' ? '#0D7816' : '#FF4347',
              'font-weight': '500'
            }">
            <span [ngStyle]="{ 
              'background-color': stock.transaction_type === 'BUY' ? '#E4F3E3' : '#FFECED',
              'padding': '6px',
              'border-radius': '12px',
              'padding-left': '15px',
              'padding-right': '15px'
            }">
        {{ stock.transaction_type }}
      </span>
      
          </td>
          </tr>
        </tbody>
      </table>
      
    </div>
  </mat-card-content>
</mat-card>
<div class="bottom-info" *ngIf="actionType == 'Invest Now' || actionType == 'Invest More'">
<div><img src="../../../../assets/info_dashboard.svg" alt=""></div>
<div class="bottomText">*Final invested amount will be based on the market price when the order is placed.<br>
  *Please invest in all the stocks in the basket. Not doing so could give different returns from the original basket.
</div>
</div>
<div class="disclaimer">
  <span class="text">
    Disclaimer: If you do not have a Trading Account, kindly open the same with your choice of Broker, to start your investment journey.
  </span>
</div>
    <div class="investNow-wrapper">
        <app-button [savingsText]="actionType" (click)="initiateSmallCase()" [backgroundColor]="buttonColor"></app-button>
    </div>
</div>
<!-- laptop view end -->

<!-- mobile view start -->
<div class="investement-modal-container" *ngIf="this.screenType.breakpointState == 'mob'" style="max-height: 80vh; overflow-y: auto; padding-top: 25px; padding-bottom: 25px; padding: 0px;">
  <div class="header" style="padding: 25px;">
  <div class="headerText">
      Investing in {{data?.productName?.jar_name}}
  </div>
  <div (click)="closeDialog()">
      <img src="../../../../assets/crossIconDark.svg" style="cursor: pointer;" alt="">
  </div>
  </div>
  <img src="../../../../assets/hrDarkMobIcon.svg" alt="">
  <div *ngIf="actionType !== 'Fix Investment' && actionType !== 'Rebalance Now'" style="padding: 25px;">
  <div class="subText" style="font-size: 18px; font-weight: 500;">Enter the amount you want to invest</div>
  <div class="input-field-wrapper">
      <div style="display: flex;">
        <div>
          <app-input
          class="pan-input"
          [placeholder]="'Enter your Invest Amount'"
          [autofocus]="false"
          [type]="'number'"
          [name]="'pan'"
          [maxLength]="10"
          [required]="true"
          [(ngModel)]="amountToInvest"
          ></app-input>
        </div>
          <div style="width: 40px; display: flex; justify-content: center; margin-top: 15px;">
            <mat-icon style="color: #6C6F9D; cursor: pointer;" (click)="onSubmitAmount()"
            matTooltip="Refresh to update portfolio">autorenew</mat-icon>
        </div>
      </div>
      <span class="hintText">Min Investment Amount <span style="color: black; font-weight: 600;">&#8377; {{minInvestementAmount | readableNumber}}</span></span>
  </div>
  <br>
  <!-- <div style="display: flex; justify-content: center;">
    <app-button [savingsText]="'Save'" [backgroundColor]="'rgb(195, 150, 110)'" (click)="onSubmitAmount()"></app-button>
  </div> -->
  <br>
  
  <div>
    <span class="defaultMinInvestement" (click)="changeColor($event)"><i class="fa fa-rupee"></i> {{minInvestementAmount * 2}}</span>
    <span class="defaultMinInvestement" (click)="changeColor($event)"><i class="fa fa-rupee"></i> {{minInvestementAmount * 3}}</span>
    </div>

  <!-- <div class="suggestion-section" style="width: 100%;">
      <span class="innerText">We suggest you to invest atlest 2x or 3x of the minimum investement amount to get benefit of lower transaction cost</span>
  </div> -->
</div>
  <br>
  <div class="mob-table">
    <!-- <table class="table">
      <thead>
        <tr>
          <th scope="col" class="headerTable">Stocks</th>
          <th scope="col" class="headerTable">Current Price ( <i class="fa fa-rupee"></i> )</th>
          <th scope="col" class="headerTable">Weightage</th>
          <th scope="col" class="headerTable">Quantity</th>
          <th scope="col" class="headerTable">Order Type</th>
        </tr>
      </thead>
      <tbody class="tbody-style">
        <tr *ngFor="let stock of stocksList">
          <td>{{ stock.company_name }}</td>
          <td>{{ stock.last_cmp }}</td>
          <td>{{ stock.allocation }}%</td>
          <td>{{ stock.quantity }}</td>
          <td style="font-weight: 500; color: #0D7816;">{{ stock.transaction_type }}</td>
        </tr>
      </tbody>
    </table> -->
    <div class="table-heading">
      <span class="text">Stocks</span>
      <span class="text">Current Price(<i class="fa fa-inr" aria-hidden="true"></i>)</span>
    </div>

    <hr>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let stock of stocksList" style="padding-top: 20px; padding-bottom: 20px;">
        <mat-expansion-panel-header class="custom-expansion-panel-header">
          <mat-panel-title>
            {{ stock.company_name }}
          </mat-panel-title>
          <mat-panel-description>
            {{ stock.last_cmp | readableNumber }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="d-flex align-items-center">
          <div>Weightage</div>
          <div class="ms-auto mt-2">Quantity</div>
        </div>
        <div class="d-flex align-items-center">
          <div>{{ stock.allocation }}%</div>
          <div class="ms-auto mt-2">{{ stock.quantity }}</div>
        </div>
        <div class="mt-3">
          <div>Order Type</div>
          <div class="mt-2" [ngStyle]="{ 'color': stock.transaction_type == 'BUY' ? '#0D7816' : '#FF4347'}">{{ stock.transaction_type }}</div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    
    
    </div>
      <div class="investNow-wrapper">
          <app-button [savingsText]="actionType" (click)="initiateSmallCase()" [backgroundColor]="buttonColor"></app-button>
      </div>
</div>
<!-- mobile view end -->
