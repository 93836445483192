import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { BasketsComponent } from '../components/baskets/baskets.component';
import { RiskProfileQuestionsComponent } from '../components/risk-profile-questions/risk-profile-questions.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { PanComponent } from '../components/pan/pan.component';
import { InsightsTabComponent } from '../components/insights-tab/insights-tab.component';
import { ReBalanceHistoryComponent } from '../components/rebalance-history/rebalance-history.component';
import { LoginMobileViewComponent } from '../components/auth/login-mobile-view/login-mobile-view.component';
import { PurchaseHistoryComponent } from '../components/purchase-history/purchase-history.component';
import { BankDetailsComponent } from '../components/bank-details/bank-details.component';
import { FatcaComponent } from '../components/fatca/fatca.component';
import { AgreementComponent } from '../components/agreement/agreement.component';
import { DiscoverComponent } from '../components/discover/discover.component';
import { WatchlistComponent } from '../components/watchlist/watchlist.component';
import { NotificationListComponent } from '../components/notification-list/notification-list.component';
import { ProfileComponent } from '../components/profile/profile.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginMobileViewComponent },
  { path: 'baskets', component: BasketsComponent },
  { path: 'riskProfile/:id', component: RiskProfileQuestionsComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'pan/:id', component: PanComponent },
  { path: 'bank_account/:id', component: BankDetailsComponent },
  { path: 'fatca_detail/:id', component: FatcaComponent },
  { path: 'agreement/:id', component: AgreementComponent },
  { path: 'insights', component: InsightsTabComponent },
  { path: 'rebalance-history', component: ReBalanceHistoryComponent },
  { path: 'purchase-history', component: PurchaseHistoryComponent },
  { path: 'discover', component: DiscoverComponent },
  { path: 'watchlist', component: WatchlistComponent },
  { path: 'notification', component: NotificationListComponent },
  { path: 'profile', component: ProfileComponent },
  {
    path: 'EQNTIS',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
