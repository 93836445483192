<div class="main-wrapper" *ngIf="modalType == 'rebalancingInProgress'">
    <div class="half-circle-wrapper">
        <div class="half-circle">
            <img src="../../../../../assets/Alert GIF.gif" alt="Icon" height="60px" width="60px">
        </div>
    </div>
    <div class="cross-icon-wrapper" (click)="closeDialog()">
        <img src="../../../../assets/crossIconDark.svg" alt="">
    </div>
    <div class="header">Upcoming Portfolio Update: Stay Tuned!</div>
    <div class="hrTag">
        <img src="../../../../../assets/hrDarkTagIcon.svg" alt="">
    </div>
    <div class="sub-text">
        Rebalancing is currently underway! We recommend holding off for now
        and staying tuned for our latest update.
    </div>
    <div class="sub-text">
        Soon, you'll have access to the newest portfolio designed for optimal growth
        —an excellent opportunity to invest with confidence!
    </div>
</div>



<div class="main-wrapper" *ngIf="modalType == 'orderPending'">
    <div class="half-circle-wrapper">
        <img src="../../../../../assets/Alert GIF.gif" alt="Icon" class="half-circle-icon">
    </div>
    <div class="cross-icon-wrapper" (click)="closeDialog()">
        <img src="../../../../assets/crossIconDark.svg" style="cursor: pointer;" alt="">
    </div>
    <div class="header">Order in Progress</div>
    <div class="hrTag">
        <img src="../../../../../assets/hrDarkTagIcon.svg" alt="">
        </div>
    <div class="sub-text">
        It looks like you’ve already tried placing an order.
        We’re reviewing its status—please hold on!
    </div>
    <div class="sub-text">
        If the order wasn’t successfully placed with the broker,
        you can try again in 5 minutes.
    </div>
</div>