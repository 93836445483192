import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTypeService } from '../../services/screen-type.service';
import { NavigationExtras, Router } from '@angular/router';
import { ApiServiceService } from '../../services/api-service.service';
import { MoengageService } from '../../services/moengage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  screenType: string = 'https://www.equentis.com/';
  mainWebsiteURL: string = 'https://www.equentis.com/';
  websiteURL: string = 'https://www.equentis.com/researchandranking/';
  financialCalculatorURL: string = 'https://www.equentis.com/researchandranking/financial-calculators/';
  ipoURL: string = 'https://www.equentis.com/researchandranking/ipo-watch/';
  stocksURL: string = 'https://www.equentis.com/stocks-screener/';
  isActiveSubscription: boolean = false;
  customer_Id: any;
  pageURL: any;
  categoryList: any[] = [];

  constructor(
    private screenTypeService: ScreenTypeService,
    private route: Router,
    private apiService: ApiServiceService,
    private moengageService: MoengageService
  ) {
    this.screenType = this.screenTypeService.breakpointState;
  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if (token) {
      const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if (token && data.customer_meta_info.is_registered) {
      this.customer_Id = data.customer_id;
    }
    this.checkActiveSubscription();
    this.pageURL = this.route.url;
    this.getCategoryList();
  }

  getCategoryList() {
    this.apiService.getCategoriesList().subscribe((res: any) => {
     if(res && res.success) {
      this.categoryList = res.data;
     }
    })
  }

  callMoengageEvent(data: any) {
    if(this.pageURL == '/') {
      this.pageURL = '/home';
    }
    let eventDetails = {
      'CTA_Name': data,
      'PageURL': this.pageURL,
      'Active_Subscription': this.isActiveSubscription
    }
    this.moengageService.footerEvent(eventDetails);
  }

  checkActiveSubscription() {
    this.apiService.getPurchasedBasketData(this.customer_Id).subscribe((res: any) => {
      if(res && res.success && res.data.data.length>0) {
       this.isActiveSubscription = true;
      }
      else {
       this.isActiveSubscription = false;
      }
    });
}

  openPlayStore() {
    let eventDetails = {
      Active_Subscription: this.isActiveSubscription,
      Page_URL: 'clicked from footer',
      downloadAppButton: 'Android'
    }
    this.moengageService.appDownload(eventDetails);
    window.open('https://play.google.com/store/apps/details?id=com.equentis.multiplyrr');
  }

  openAppStore() {
    let eventDetails = {
      Active_Subscription: this.isActiveSubscription,
      Page_URL: 'clicked from footer',
      downloadAppButton: 'iOS'
    }
    this.moengageService.appDownload(eventDetails);
    window.open('https://apps.apple.com/app/id6503437792');
  }

  onFAQClick() {
    window.open(`${this.websiteURL+'faq'}`);
    // let data = {
    //   customData: 'faqRoute',
    // };
    // let navigationExtras: NavigationExtras = {
    //   state: {
    //     customData: data,
    //   },
    // };
    // this.route.navigate([''], navigationExtras);
  }

  goToBasket(type: any) {
    this.callMoengageEvent(type);
    this.route.navigate(['baskets'], { queryParams: { category_id: type } });
  }

  goToAboutUs(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'about-us'}`);
  }

  goToNews(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'press-news'}`);
  }

  goToPerformance(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'performance-of-stock-recommendations'}`);
  }

  goToEliteClub(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'club-of-elite-share-market-investors'}`);
  }

  goToStockScreener(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://www.equentis.com/stocks-screener/`);
  }

  goToNewsletters(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'newsletter'}`);
  }

  goToFinancialCalculators(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'market/financial-calculators'}`);
  }

  goToIPO(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'market/ipo-watch'}`);
  }

  goToMagzine(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'magazine'}`);
  }

  goToVideo(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'videos-on-stock-investing'}`);
  }

  goToMedia(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'media'}`);
  }

  goToBlogs(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://www.equentis.com/blog/`);
  }

  goToRegulatoryDisclaimer(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'regulatory-disclosures'}`);
  }

  goToPrivacyPolicy(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'privacy-policy-and-legal-disclaimer'}`);
  }

  goToWebsiteDisclaimer(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'website-disclaimer'}`);
  }

  goToTermsOfService(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'term-of-service'}`);
  }

  goToContactUs(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.websiteURL+'contact-us-for-stock-advice'}`);
  }

  onPrivacyPolicy(type: any, route: any) {
    this.callMoengageEvent(type);
    window.open(`${this.mainWebsiteURL+route}`);
  }

  goToFacebook(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://www.facebook.com/researchandranking/`);
  }

  goToTwitter(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://x.com/researchandrank/`);
  }

  goToLinkedIn(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://www.linkedin.com/company/research-and-ranking/`);
  }

  goToInstagram(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://www.instagram.com/equentis/`);
  }

  goToYouTube(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://www.youtube.com/channel/UC-U06u6qrxWfCTL3CT0_39w`);
  }

  goToTeleGram(type: any) {
    this.callMoengageEvent(type);
    window.open(`https://t.me/researchandranking`);
  }

  callPhoneNumber(phoneNumber: string): void {
    window.location.href = 'tel:' + phoneNumber;
  }

  sendEmail(emailAddress: string): void {
    window.location.href = 'mailto:' + emailAddress;
  }

  routeFinancialCalculators(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.financialCalculatorURL}${type}`);
  }

  routeIPO(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.ipoURL}${type}`);
  }
  routeStocks(type: any) {
    this.callMoengageEvent(type);
    window.open(`${this.stocksURL}${type}`);
  }
}
