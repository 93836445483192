<div class="popup-background">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>
<div class="planSelection-wrapper">
    <span class="headerText" style="margin: 0 auto;">Best Pricing for <span style="color: #E49944; font-weight: 700;">Wealth Creators</span>
    </span>
    <!-- <span style="padding: 10px;"><img mat-dialog-close src="../../../../assets/crossIconWhite.svg" alt=""></span> -->
</div>
<span class="subtext">Subscription Plans for &nbsp;<span style="font-size: 22px; color: #8EAFCE;">{{data.productName}}</span></span>
<br>
<span class="subtext"><img src="../../../../../assets/hrMobIcon.svg" alt=""></span>
<div class="plan-cards">
    <mat-card style="border-radius: 20px;" [ngClass]="{ 'default-border': showBorder === 1 }" (click)="toggleBorder(1)">
        <mat-card-content class="cards-item">
            <span>{{feeDetails[0].investment_amount}}</span>
            <span class="pricingText">&#8377; {{feeDetails[0].details.advisory_fees}}</span>
            <!-- <span style="color: #7E8B99;">(&#8377; 10/day)</span> -->
            <span><app-button [savingsText]="'Get Started Now'" (click)="planSelection('plan-6')"></app-button></span>
        </mat-card-content>
    </mat-card>
    <!-- <mat-card style="border-radius: 20px;" [ngClass]="{ 'default-border': showBorder === 2 }" (click)="toggleBorder(2)">
        <mat-card-content class="cards-item">
            <div class="popular">
                Popular
            </div>
            <span>12 Months</span>
            <span>&#8377; 4299</span>
            <span class="pricingText" style="color: #007D4F;">&#8377; 2999</span>
            <span style="color: #7E8B99;">(&#8377; 8/day)</span>
            <span><app-button [savingsText]="'Get Started Now'" (click)="planSelection('plan-12')"></app-button></span>
        </mat-card-content>
    </mat-card> -->
</div>
</div>
