import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { ScreenTypeService } from '../../services/screen-type.service';
import { MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'rxjs';
import { ApiServiceService } from '../../services/api-service.service';
import { DownloadPDFFromLinkService } from '../../services/download-pdffrom-link.service';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from '../../services/web-socket.service';
import { UserDeleteComponent } from '../user-delete/user-delete.component';
declare let Moengage: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  usernameToolBar: boolean = false;
  userName: string = '';
  selectedProfileItem: string = 'My Profile';
  mobileNo: any;
  emailId: any;
  firstLetterName: any;
  myProfile: boolean = true;
  purchaseHistory: boolean = false;
  riskProfile: boolean = false;
  helpFAQ: boolean = false;
  regulatoryDisclosure: boolean = false;
  purchaseHistoryList: any[] = [];
  regulatoryData: any;
  homePageJSONResponse: any;
  visibleItemCount = 5;
  userName_Toolbar: any;
  userDetails: any;
  riskScore: any;
  riskType: any;

  constructor(
    private route: Router,
    public dialog: MatDialog,
    public screenType: ScreenTypeService,
    private apiService: ApiServiceService,
    private downloadService: DownloadPDFFromLinkService,
    private sanitizer: DomSanitizer,
    private _toastSerivce: ToastrService,
    private websocket: WebSocketService,
  ) {

  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  handleLinkClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      const href = target.getAttribute('href');
      if (href && href.startsWith('app://')) {
        event.preventDefault();
        this.handleCustomLink(href);
      }
    }
  }

  handleCustomLink(href: string): void {
    if (href === 'app://delete-account') {
      const dialogRef = this.dialog.open(UserDeleteComponent, {
        height: 'auto',
        disableClose: false,
        autoFocus: false,
        width: 'auto'
      });
      // this.deleteAccount();
    }
  }

  ngOnInit() {
    scroll(0, 0);
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if (token) {
      const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      const data = JSON.parse(jsonString);
      console.log('ngOnInit token data', data);
      this.mobileNo = data.mobile_number;
      this.emailId = data.email_address;
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName_Toolbar = name;
      this.userName = name;
      this.firstLetterName = this.userName.substring(0, 1);
    }
    else {
      this.openLogin('');
    }
    this.getProfileData();
    this.getPurchaseHistoryData();
    this.getFaqData();
    this.getRegulatoryData();
  }

  getRegulatoryData() {
    this.apiService.getDisclosureData().subscribe((res: any) => {
      if(res && res.message == 'success') {
        this.regulatoryData = res.result?.data[0]?.div;
      }
    })
  }

  getProfileData() {
    this.apiService.getProfileData().subscribe((res: any) => {
    if(res && res.message == 'success') {
     this.userDetails = res?.result;
     this.riskScore = res?.result?.risk_score;
     this.riskType = this.riskScore >= 15 && this.riskScore <= 24 ? 'Low' :
               this.riskScore >= 25 && this.riskScore <= 34 ? 'Medium' : 
               this.riskScore >= 35 ? 'High' : '';
     console.log('this.userDetails', this.userDetails);
    }
    })
  }

  get visibleData() {
    return this.homePageJSONResponse.slice(0, this.visibleItemCount);
  }
  get hasMoreData() {
    return this.homePageJSONResponse.length > this.visibleItemCount;
  }
  get buttonLabel() {
    return this.isExpanded ? 'View Less' : 'View More';
  }

  isExpanded = false;

  toggleVisibility() {
    if (this.isExpanded) {
      this.visibleItemCount = 5;
    } else {
      this.visibleItemCount = this.homePageJSONResponse.length;
    }
    this.isExpanded = !this.isExpanded;
    }

  getFaqData() {
    this.apiService.faqData().subscribe((res: any) => {
      if (res.message == 'success') {
        this.homePageJSONResponse = res?.result;
      }
    })
  }

  downloadInvoice(data: any, fileName: any) {
  console.log(data, fileName);
  this.downloadService.downloadPdf(data, fileName);
  }

  getDateFormat(date: any) {
    return moment(date, 'DD MMMM YYYY, HH:mm:ss Z').format('DD-MM-YYYY');
  }

  getPurchaseHistoryData() {
    this.apiService.getPurchaseHistory().subscribe((res: any) => {
      if(res && res.message == 'success') {
        this.purchaseHistoryList = res?.result?.list;
      }
    })
  }

  selectProfileItem(item: string) {
    this.selectedProfileItem = item;
    if(item == 'My Profile') {
      this.myProfile = true;
      this.purchaseHistory = false;
      this.riskProfile = false;
      this.helpFAQ = false;
      this.regulatoryDisclosure = false;
    }
    else if(item == 'Purchase History') {
      this.purchaseHistory = true;
      this.myProfile = false;
      this.riskProfile = false;
      this.helpFAQ = false;
      this.regulatoryDisclosure = false;
    }
    else if(item == 'Risk Profile') {
      this.purchaseHistory = false;
      this.myProfile = false;
      this.riskProfile = true;
      this.helpFAQ = false;
      this.regulatoryDisclosure = false;
    }
    else if(item == 'Regulatory Disclosure') {
      this.purchaseHistory = false;
      this.myProfile = false;
      this.riskProfile = false;
      this.helpFAQ = false;
      this.regulatoryDisclosure = true;
    }
    else if(item == 'Help & FAQ') {
      this.purchaseHistory = false;
      this.myProfile = false;
      this.riskProfile = false;
      this.helpFAQ = true;
      this.regulatoryDisclosure = false;
    }
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: `profile`,
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: true,
        backdropClass: 'backdropBackground',
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

}
