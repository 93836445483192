import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenBreakpointService } from 'src/app/core/services/screen-breakpoint.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() savingsText: any;
  @Input() isTransparentBTN: boolean = false;
  @Input() showWatchListIcon: boolean = true;
  @Input() backgroundColor: string = '#C3966E';
  @Input() color: string = 'white';
  @Input() borderColor = '#C3966E';
  @Input() textColor = '#C3966E';
  @Input() isRetry: boolean = false;
  @Input() goBackIcon: boolean = false

  constructor(
  public screenType: ScreenBreakpointService,
  ) {

  }
}
