import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/core/core.module').then((x) => x.CoreModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./core/components/payment/payment.module').then(
        (x) => x.PaymentModule
      ),
    data: { title: 'payment', page: 'payment' },
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
