<div class="transaction-details">
    <div class="header-wrapper">
    <div class="stock-name">
     {{data.moreDetails.company_short_name}}
     &nbsp;&nbsp;
     <div class="stockType active" *ngIf="data.stockType == 'active'">
        Active Stocks
    </div>
    <div class="stockType exited" *ngIf="data.stockType == 'exited'">
        Exited Stocks
    </div>
    </div>
    <div (click)="closeDialog()">
        <img src="../../../../assets/crossIconDark.svg" style="cursor: pointer;" alt="">
    </div>
    </div>
    <img src="../../../../assets/hrDarkMobIcon.svg" alt="">
    <div class="transaction-history">
       <span class="history-label">Transaction History</span>
       <div class="history-data">
       <table class="table">
        <thead>
          <tr>
            <th scope="col" class="headerTable">Transaction Date &nbsp;</th>
            <th scope="col" class="headerTable">
            <span *ngIf="data.stockType == 'active'">Buying Price(₹) &nbsp;</span>
            <span *ngIf="data.stockType == 'exited'">Selling Price(₹) &nbsp;</span>
            </th>
            <th scope="col" class="headerTable">Quantity &nbsp;</th>
            <th scope="col" class="headerTable">Amount (₹) &nbsp;</th>
          </tr>
        </thead>
        <tbody *ngIf="!transactionsData || transactionsData.length == 0">
            <tr>
              <td colspan="8" style="text-align: center; padding: 20px 0; color: #9AA6AC; font-weight: 700; font-size: 16px;">
                No Data Found
              </td>
            </tr>
          </tbody>
        <tbody class="tbody-style">
          <tr *ngFor="let data of transactionsData">
            <td class="tableData">{{data.transaction_date}}</td>
            <td class="tableData">{{data.transaction_price | decimalFormat}}</td>
            <td class="tableData">{{data.quantity}}</td>
            <td class="tableData">{{data.quantity * data.transaction_price | decimalFormat}}</td>
          </tr>
        </tbody>
    </table>
    </div>
    </div>
</div>