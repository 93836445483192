import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  getOTP(reqData: any) {
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/user/send-otp`,
      reqData
    );
  }

  signUp(reqData: any, registerToken: any) {
    let headers = new HttpHeaders();
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/user/register`,
      reqData,
      {
        headers: {
          Authorization: `Bearer ${registerToken}`,
        },
      }
    );
  }

  verifyOTP(reqData: any) {
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/user/verify-otp`,
      reqData
    );
  }

  logout(token: any) {
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/user/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
