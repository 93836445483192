import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableNumber',
})
export class ReadableNumberPipe implements PipeTransform {
  transform(number: string | number, args: any[] = []): string | number {
    if (number == 0) return number.toLocaleString('en-IN');
    if (!number) return '';
    return number.toLocaleString('en-IN');
  }
}
