import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../services/api-service.service';
import { MatDialog } from '@angular/material/dialog';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';
import { ScreenTypeService } from '../../services/screen-type.service';
import { NavigationExtras } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  usernameToolBar: boolean = false;
  userName: any;
  constructor(private apiService: ApiServiceService,
    public dialog: MatDialog,
    private route: Router,
    public screenType: ScreenTypeService,
  ) {}

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if(token && data.is_registered) {
      console.log('ngOnInit token data', data);
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
    }
  }

  backToHome() {
    this.route.navigate(['']);
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'baskets',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

}
