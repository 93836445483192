import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenTypeService {
  breakpointState: 'mob' | 'tab' | 'lap' | 'desk' | '' = '';
  observeBreakpointState: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private _breakPointObserver: BreakpointObserver) {
    this.observerBreakpoints();
  }

  private observerBreakpoints() {
    this._breakPointObserver
      .observe([Breakpoints.Handset])
      .subscribe((data: any) => {
        if (data.matches) {
          this.breakpointState = 'mob';
          console.log(' Mobile : ', data);

          this.observeBreakpointState.next(this.breakpointState);
        }
      });
    this._breakPointObserver
      .observe([
        Breakpoints.Tablet,
        Breakpoints.HandsetLandscape,
        Breakpoints.WebPortrait,
      ])
      .subscribe((data: any) => {
        if (data.matches) {
          console.log(' Tablet : ', data);
          this.breakpointState = 'tab';
          this.observeBreakpointState.next(this.breakpointState);
        }
      });
    this._breakPointObserver
      .observe([Breakpoints.WebLandscape, Breakpoints.Large])
      .subscribe((data: any) => {
        if (data.matches) {
          console.log(' Laptop : ', data);

          this.breakpointState = 'lap';
          this.observeBreakpointState.next(this.breakpointState);
        }
      });
    this._breakPointObserver
      .observe([Breakpoints.XLarge])
      .subscribe((data: any) => {
        if (data.matches) {
          console.log(' Desktop : ', data);
          this.breakpointState = 'desk';
          this.observeBreakpointState.next(this.breakpointState);
        }
      });
  }
}
