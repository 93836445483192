<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName_Toolbar" (loginClicked)="openLogin($event)"></app-toolbar>
<div *ngIf="this.screenType.breakpointState !== 'mob'">
<div class="profile-wrapper">
    <div class="row">
       <div class="col-3">
        <mat-card class="card-wrapper">
            <mat-card-content class="custom-card-content">
                <div class="user-profile">
                    <div class="name-background">{{firstLetterName}}</div>
                    <div class="user-name">{{userDetails?.full_name}}</div>
                    <div class="user-email">{{userDetails?.email_address}}</div>
                </div>
                <div class="profile-list">
                    <span 
                      [class.selected]="selectedProfileItem === 'My Profile'" 
                      (click)="selectProfileItem('My Profile')">
                      <img src="../../../../assets/myProfile/myprofile.svg" alt="">&nbsp;&nbsp;My Profile
                      <ng-container *ngIf="selectedProfileItem === 'My Profile'">
                        <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                      </ng-container>
                    </span>
                    <span 
                      [class.selected]="selectedProfileItem === 'Purchase History'" 
                      (click)="selectProfileItem('Purchase History')">
                      <img src="../../../../assets/myProfile/purchaseHistory.svg" alt="">&nbsp;&nbsp;Purchase History
                      <ng-container *ngIf="selectedProfileItem === 'Purchase History'">
                        <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                      </ng-container>
                    </span>
                    <span 
                      [class.selected]="selectedProfileItem === 'Risk Profile'" 
                      (click)="selectProfileItem('Risk Profile')">
                      <img src="../../../../assets/myProfile/riskProfile.svg" alt="">&nbsp;&nbsp;Risk Profile
                      <ng-container *ngIf="selectedProfileItem === 'Risk Profile'">
                        <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                      </ng-container>
                    </span>
                    <span 
                    [class.selected]="selectedProfileItem === 'Regulatory Disclosure'" 
                    (click)="selectProfileItem('Regulatory Disclosure')">
                    <img src="../../../../assets/myProfile/regulatory_icon.svg" alt="">&nbsp;&nbsp;Regulatory Disclosure
                    <ng-container *ngIf="selectedProfileItem === 'Regulatory Disclosure'">
                      <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                    </ng-container>
                    </span>
                    <span 
                      [class.selected]="selectedProfileItem === 'Help & FAQ'" 
                      (click)="selectProfileItem('Help & FAQ')">
                      <img src="../../../../assets/myProfile/helpandFAQ.svg" alt="">&nbsp;&nbsp;Help & FAQ
                      <ng-container *ngIf="selectedProfileItem === 'Help & FAQ'">
                        <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                      </ng-container>
                    </span>
                </div>
                
                
                  
            </mat-card-content>
        </mat-card>
       </div>
       <div class="col-9">
        <mat-card class="card-wrapper">
            <mat-card-content class="custom-card-content">
                <div class="user-content" *ngIf="myProfile">
                    <div class="header">
                        <span class="name">My Profile</span>
                    </div>
                    <div class="content">
                        <div>
                         <span class="text-header">Name as per PAN</span>
                         <div class="text-wrapper">
                            <span class="text">{{userDetails?.full_name}}</span>
                         </div>
                        </div>
                        <br>
                        <div>
                         <span class="text-header">Mobile Number</span>
                         <div class="text-wrapper">
                            <span class="text">{{userDetails?.mobile_number}}</span>
                         </div>
                        </div>
                        <br>
                        <div>
                         <span class="text-header">Email ID</span>
                         <div class="text-wrapper">
                            <span class="text">{{userDetails?.email_address}}</span>
                         </div>
                        </div>
                    </div>
                    
                </div>
                <div class="user-content" *ngIf="purchaseHistory">
                    <div class="header">
                        <span class="name">Purchase History
</span>
                    </div>
                    <div class="content" style="width: 100%;">
                        <div>
                            <table class="table">
                                <thead>
                                    <tr>
                                      <th scope="col" class="headerTable">Basket Name</th>
                                      <th scope="col" class="headerTable">Subscription Date</th>
                                      <th scope="col" class="headerTable">Paid Amount</th>
                                      <th scope="col" class="headerTable">Total Amount</th>
                                      <th scope="col" class="headerTable"></th>
                                     
                                    </tr>
                                  </thead>
                                <tbody class="tbody-style">
                                    <span *ngIf="purchaseHistoryList.length === 0"><br>No data to display</span>
                                  <tr *ngFor="let data of purchaseHistoryList">
                                    <td class="tdContent">{{data.product_name}}</td>
                                    <td class="tdContentValue">
                                        <p>{{getDateFormat(data.paymentInfo.payment_date)}}</p>
                                    </td>
                                    <td class="tdContentValue">
                                        <p>&#8377; {{data.paymentInfo.net_amount | readableNumber}}</p>
                                    </td>
                                    <td class="tdContentValue">
                                        <p>&#8377; {{data.paymentInfo.total_amount | readableNumber}}</p>
                                    </td>
                                    <td><img style="cursor: pointer;" src="../../../../assets/btn_Download Invoice.svg" alt="" (click)="downloadInvoice(data.invoice_link, 'Invoice_' +  data.product_name)"></td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
                <div class="user-content" *ngIf="riskProfile">
                    <div class="header">
                        <span class="name">Risk Profile</span>
                    </div>
                    <div class="content risk-profile" *ngIf="riskScore">
                    <div *ngIf="riskType == 'Low'">
                        <img src="../../../../assets/riskoMeter/img_Risklevel_Low.svg" alt="">
                    </div>
                    <div *ngIf="riskType == 'Medium'">
                      <img src="../../../../assets/riskoMeter/img_Risklevel_Medium.svg" alt="">
                  </div>
                  <div *ngIf="riskType == 'High'">
                    <img src="../../../../assets/riskoMeter/img_Risklevel_High.svg" alt="">
                </div>
                    </div>
                    <div class="content risk-profile" *ngIf="!riskScore">
                        <div class="bottom-info">
                          <div><img src="../../../../assets/info_dashboard.svg" alt=""></div>
                          <div class="bottomText">*Your Risk Profiling has not been done yet. Please onboard to view Risk Profile.
                          </div>
                        </div>
                    </div>
                    
                </div>

                <div class="user-content" *ngIf="regulatoryDisclosure">
                  <div class="header">
                      <span class="name">Regulatory Disclosure</span>
                  </div>
                  <div class="content">
                    <span [innerHTML]="sanitizeHtml(regulatoryData)"></span>
                  </div>
                  
              </div>

                <div class="user-content" *ngIf="helpFAQ">
                  <div class="header">
                      <span class="name">Help & FAQ</span>
                  </div>
                  <div class="content" style="width: 100%;">
                      <div class="row faqSection" *ngFor="let data of visibleData let i = index" [id]="'faqSectionID' + i">
                          <div class="col-1"></div>
                          <div class="col-10" style="padding: 5px;">
                              <mat-accordion>
                                  <mat-expansion-panel #panel>
                                    <mat-expansion-panel-header style="padding: 10px; display: flex; justify-content: space-between; align-items: center;">
                                      <span class="faqHeader">
                                        {{data.name}}
                                      </span>
                                      <!-- Hide the default expansion indicator -->
                                      <!-- <span style="flex-grow: 1; text-align: right;">
                                        <mat-icon *ngIf="!panel.expanded" style="font-size: 24px;">add</mat-icon>
                                        <mat-icon *ngIf="panel.expanded" style="font-size: 24px;">remove</mat-icon>
                                      </span> -->
                                    </mat-expansion-panel-header>
                                    <p class="faqSubHeader" [innerHTML]="sanitizeHtml(data.detail)" (click)="handleLinkClick($event)"></p>
                                  </mat-expansion-panel>
                                </mat-accordion>
                          </div>
                        </div>
                        <div class="row" *ngIf="hasMoreData || isExpanded" style="padding-top: 15px;">
                          <div class="col-4"></div>
                          <div class="col-8">
                          <app-button [savingsText]="buttonLabel" [isTransparentBTN]="true" [showWatchListIcon]="false" [borderColor] = "'#001E5A'" [textColor] = "'#001E5A'" [backgroundColor]="'#F4F4F4'" (click)="toggleVisibility()"></app-button>
                          </div>
                        </div>
                  </div>
                  
              </div>
            </mat-card-content>
        </mat-card>
       </div>
    </div>
</div>
<app-footer-curve></app-footer-curve>
<app-footer></app-footer>
</div>

<div *ngIf="this.screenType.breakpointState == 'mob'" style="overflow-x: hidden;">
    <div class="profile-wrapper" style="padding: 10px; overflow-x: hidden;">
        <div class="row">
            <mat-card class="card-wrapper">
                <mat-card-content class="custom-card-content">
                    <div class="user-profile">
                        <span class="name-background">{{firstLetterName}}</span>
                        <span class="user-name">{{userName}}</span>
                        <span class="user-email">{{emailId}}</span>
                    </div>
                    <div class="profile-list">
                        <span 
                          [class.selected]="selectedProfileItem === 'My Profile'" 
                          (click)="selectProfileItem('My Profile')">
                          <img src="../../../../assets/myProfile/myprofile.svg" alt="">&nbsp;&nbsp;My Profile
                          <ng-container *ngIf="selectedProfileItem === 'My Profile'">
                            <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                          </ng-container>
                        </span>
                        <span 
                          [class.selected]="selectedProfileItem === 'Purchase History'" 
                          (click)="selectProfileItem('Purchase History')">
                          <img src="../../../../assets/myProfile/purchaseHistory.svg" alt="">&nbsp;&nbsp;Purchase History
                          <ng-container *ngIf="selectedProfileItem === 'Purchase History'">
                            <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                          </ng-container>
                        </span>
                        <span 
                          [class.selected]="selectedProfileItem === 'Risk Profile'" 
                          (click)="selectProfileItem('Risk Profile')">
                          <img src="../../../../assets/myProfile/riskProfile.svg" alt="">&nbsp;&nbsp;Risk Profile
                          <ng-container *ngIf="selectedProfileItem === 'Risk Profile'">
                            <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                          </ng-container>
                        </span>
                        <span 
                          [class.selected]="selectedProfileItem === 'Regulatory Disclosure'" 
                          (click)="selectProfileItem('Regulatory Disclosure')">
                          <img src="../../../../assets/myProfile/regulatory_icon.svg" alt="">&nbsp;&nbsp;Regulatory Disclosure
                          <ng-container *ngIf="selectedProfileItem === 'Regulatory Disclosure'">
                            <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                          </ng-container>
                        </span>
                        <span 
                          [class.selected]="selectedProfileItem === 'Help & FAQ'" 
                          (click)="selectProfileItem('Help & FAQ')">
                          <img src="../../../../assets/myProfile/helpandFAQ.svg" alt="">&nbsp;&nbsp;Help & FAQ
                          <ng-container *ngIf="selectedProfileItem === 'Help & FAQ'">
                            <img src="../../../../assets/myProfile/selectedItem.svg" alt="" style="margin-left: auto;">
                          </ng-container>
                        </span>
                    </div>
                    
                    
                      
                </mat-card-content>
            </mat-card>
            </div>
            <br>
            <br>
           <div class="row">
            <mat-card class="card-wrapper">
                <mat-card-content class="custom-card-content">
                    <div class="user-content" *ngIf="myProfile">
                        <div class="header">
                            <span class="name">My Profile</span>
                        </div>
                        <div class="content" style="width: 100%;">
                            <div>
                             <span class="text-header">Name as per PAN</span>
                             <div class="text-wrapper">
                                <span class="text">{{userName}}</span>
                             </div>
                            </div>
                            <br>
                            <div>
                             <span class="text-header">Mobile Number</span>
                             <div class="text-wrapper">
                                <span class="text">{{mobileNo}}</span>
                             </div>
                            </div>
                            <br>
                            <div>
                             <span class="text-header">Email ID</span>
                             <div class="text-wrapper">
                                <span class="text">{{emailId}}</span>
                             </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="user-content" *ngIf="purchaseHistory">
                        <div class="header">
                            <span class="name">Purchase History
</span>
                        </div>
                        <div class="content" style="width: 100%;">
                            <div style="overflow-y: auto;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                          <th scope="col" class="headerTable">Basket Name</th>
                                          <th scope="col" class="headerTable">Subscription Date</th>
                                          <th scope="col" class="headerTable">Paid Amount</th>
                                          <th scope="col" class="headerTable">Total Amount</th>
                                          <th scope="col" class="headerTable"></th>
                                         
                                        </tr>
                                      </thead>
                                      <tbody class="tbody-style">
                                        <span *ngIf="purchaseHistoryList.length === 0"><br>No data to display</span>
                                      <tr *ngFor="let data of purchaseHistoryList">
                                        <td class="tdContent">{{data.product_name}}</td>
                                        <td class="tdContentValue">
                                            <p>{{getDateFormat(data.paymentInfo.payment_date)}}</p>
                                        </td>
                                        <td class="tdContentValue">
                                            <p>&#8377; {{data.paymentInfo.net_amount | readableNumber}}</p>
                                        </td>
                                        <td class="tdContentValue">
                                            <p>&#8377; {{data.paymentInfo.total_amount | readableNumber}}</p>
                                        </td>
                                        <td><img style="cursor: pointer;" src="../../../../assets/btn_Download Invoice.svg" alt="" (click)="downloadInvoice(data.invoice_link, 'Invoice_' +  data.product_name)"></td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                    <div class="user-content" *ngIf="riskProfile">
                        <div class="header">
                            <span class="name">Risk Profile</span>
                        </div>
                        <div class="content risk-profile" *ngIf="riskScore">
                          <div *ngIf="riskType == 'Low'">
                            <img src="../../../../assets/riskoMeter/img_Risklevel_Low.svg" alt="">
                        </div>
                        <div *ngIf="riskType == 'Medium'">
                          <img src="../../../../assets/riskoMeter/img_Risklevel_Medium.svg" alt="">
                      </div>
                      <div *ngIf="riskType == 'High'">
                        <img src="../../../../assets/riskoMeter/img_Risklevel_High.svg" alt="">
                    </div>
                        </div>
                        <div class="content risk-profile" *ngIf="!riskScore">
                            <div class="bottom-info">
                              <div><img src="../../../../assets/info_dashboard.svg" alt=""></div>
                              <div class="bottomText">*Your Risk Profiling has not been done yet. Please onboard to view Risk Profile.
                              </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="user-content" *ngIf="regulatoryDisclosure">
                      <div class="header">
                          <span class="name">Regulatory Disclosure</span>
                      </div>
                      <div class="content">
                        <span [innerHTML]="sanitizeHtml(regulatoryData)"></span>
                      </div>
                      
                  </div>
                    <div class="user-content" *ngIf="helpFAQ">
                      <div class="header">
                          <span class="name">Help & FAQ</span>
                      </div>
                      <div class="content" style="width: 100%; padding: 5px;">
                          <div class="row faqSection" *ngFor="let data of visibleData let i = index" [id]="'faqSectionID' + i">
                              <div class="col-1"></div>
                              <div class="col-10" style="padding: 5px;">
                                  <mat-accordion>
                                      <mat-expansion-panel #panel>
                                        <mat-expansion-panel-header style="padding: 10px; display: flex; justify-content: space-between; align-items: center;">
                                          <span class="faqHeader">
                                            {{data.name}}
                                          </span>
                                          <!-- Hide the default expansion indicator -->
                                          <!-- <span style="flex-grow: 1; text-align: right;">
                                            <mat-icon *ngIf="!panel.expanded" style="font-size: 24px;">add</mat-icon>
                                            <mat-icon *ngIf="panel.expanded" style="font-size: 24px;">remove</mat-icon>
                                          </span> -->
                                        </mat-expansion-panel-header>
                                        <p class="faqSubHeader" [innerHTML]="sanitizeHtml(data.detail)" (click)="handleLinkClick($event)"></p>
                                      </mat-expansion-panel>
                                    </mat-accordion>
                              </div>
                            </div>
                            <div class="row" *ngIf="hasMoreData || isExpanded" style="padding-top: 15px;">
                              <div class="col-4"></div>
                              <div class="col-8">
                              <app-button [savingsText]="buttonLabel" [isTransparentBTN]="true" [showWatchListIcon]="false" [borderColor] = "'#001E5A'" [textColor] = "'#001E5A'" [backgroundColor]="'#F4F4F4'" (click)="toggleVisibility()"></app-button>
                              </div>
                            </div>
                      </div>
                      
                  </div>
                </mat-card-content>
            </mat-card>
           </div>
        </div>
        <app-footer></app-footer>
    </div> 


