export enum ColorType {
  default = '',
  primary = 'primary',
  accent = 'accent',
  warn = 'warn',
}

export enum InputType {
  textarea = 'textarea',
  email = 'email',
  number = 'number',
  tel = 'tel',
  password = 'password',
  text = 'text',
}

export class InputResultData {
  inputName: string;
  value: string;

  constructor(inputName: string, value: string) {
    this.inputName = inputName;
    this.value = value;
  }
}

export enum AppearanceType {
  fill = 'fill',
  outline = 'outline',
}

export enum ButtonType {
  flat = 'mat-flat-button',
  raised = 'mat-raised-button',
  stroked = 'mat-stroked-button',
  text = 'mat-button',
  icon = 'mat-icon-button',
}

export class SelectBoxData {
  key!: string;
  value!: string;
  group?: string;
  description?: string;
  disabled: boolean = false;
  selected: boolean = false;
  item: any;
}

export class ButtonProp {
  id: string = '';
  text: string = '';
  processingText: string = '';
  state: 'enable' | 'disabled' | 'loading' = 'enable';
}
