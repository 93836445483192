import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string): string {
    const now = moment();
    const createdAt = moment(value);
    const duration = moment.duration(now.diff(createdAt));

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      return 'just now';
    }
  }
}
