<div class="container-fluid login-page">
    <!-- laptop view div -->
    <div class="row" *ngIf="screenType !== 'mob'">
        <div class="col-6 login-background" style="padding: 0;">
            <div class="image-container">
              <img [src]="selectedImage" class="background-image" />
              <div class="dots">
                <span *ngFor="let image of images; let i = index" 
                      [class.active]="i === selectedIndex"
                      (click)="changeImage(i)">
                </span>
              </div>
            </div>
          </div>
          
    <div class="col-6 login-wrapper">
        <div style="position: absolute; top: 10px; right: 10px;">
            <span style="cursor: pointer;" (click)="closeDialog()" *ngIf="!SignUpDiv"><img src="../../../../../assets/crossIcon.svg" alt=""></span>
        </div>
        <div>
            <span class="texHeader center">
                <span style="position: absolute;">{{mobileHeader}}</span>
                <!-- <span style="margin-left: auto; cursor: pointer;" (click)="closeDialog()"><img src="../../../../../assets/crossIcon.svg" alt=""></span> -->
            </span>
            <br>
            <hr class="header-hr">
            <div class="fields-wrapper" *ngIf="mobileDiv">
                <p class="font-color center">Please Enter your 10 digits mobile number</p>
                <app-input [placeholder]="'Mobile No.'"
                [hint]="'You will receive an OTP on this number'"
                [hintRequired]="true" [errorMessage]="error_Message_MobileNo"
                (mouseleave)="onMobileChange()" [(ngModel)]="mobileNumber" [maxLength]="10" [type]="'text'" [isSuffix]="isMobileSuffix"></app-input>
                <mat-checkbox class="example-margin" [checked]="false" [(ngModel)]="termsData" (change)="onTermsChange($event)"></mat-checkbox>
                <span style="color: white; font-size: 13px;">I have read and agreed to the &nbsp;<span style="cursor: pointer; color: #5589FF; font-size: 13px;"><a (click)="goToPrivacyPolicy($event)">privacy policy,</a><a (click)="goToTermsOfService($event)">terms & conditions.</a></span></span>
                <br>
                <span style="color: #FF8884; font-size: 14px;" *ngIf="termsCondition">Please accept terms of service</span>
                <div class="center" style="margin-top: 30px;">
                    <app-button [savingsText]="'GET OTP'" (click)="onGetOTP()"></app-button>
                </div>
            </div>
    
            <div class="fields-wrapper" *ngIf="SignUpDiv">
                <app-input [placeholder]="'Full Name'" [hintRequired]="false" [errorMessage]="error_Message_Name" [(ngModel)]="fullName" (mouseleave)="onNameChange()" [type]="'text'" [isSuffix]="isNameSuffix"></app-input>
                <app-input [placeholder]="'Email Id'" [hintRequired]="false" [errorMessage]="error_Message_Email" [(ngModel)]="emailID" [maxlength]="30" (mouseleave)="onEmailChange()" [type]="'text'" [isSuffix]="isEmailSuffix"></app-input>
                <div class="center" style="margin-top: 30px;">
                    <app-button [savingsText]="'Next'" (click)="onSignup()"></app-button>
                </div>
            </div>
    
            <div class="fields-wrapper" *ngIf="otpDiv">
                <span class="font-color" style="display: flex; flex-direction: column; align-items: center; text-align: center;">We’ve sent you an {{ mobileNumber.includes('.com') ? 'Email' : 'SMS' }} with a 4-digit verification code on</span>
                <span class="center">
                    <span class="mobileNumberStyle"><span *ngIf="!mobileNumber.includes('.com')">+91</span> {{ mobileNumber }}</span>
                    &nbsp;&nbsp;
                    <div style="cursor: pointer; font-size: 12px;" *ngIf="isEdit" (click)="editMobile()"><fa-icon style="color: #5589FF;" [icon]="['fas', 'edit']"></fa-icon>
                    <span style="color: #5589FF; text-decoration: underline; font-size: 15px;">   Edit</span>
                    </div>
                </span>
                <br>
                <app-pin-box (otpChange)="onMobileOtpChanges($event)" [loading]="loading" [errorMessage]="error_message"></app-pin-box>
                <span class="center" style="color: #FF8884;">{{OTPError}}</span>
                <span class="font-color center" *ngIf="!countdownCompleted">Resend OTP in &nbsp;<span style="color: #C3966E;">{{formatCountDownPipe()}}</span></span>
                <span class="font-color center" *ngIf="countdownCompleted">Didn't get the code? &nbsp;<span class="mobileNumberStyle" style="color: #5589FF;" (click)="resendOTP()">Resend OTP</span></span>
            </div>
        </div>
    </div>
    
</div>
<!--  -->
<!-- Mobile view div -->
<div class="row login-wrapper" style="height: 400px" *ngIf="screenType == 'mob'">
        <span class="texHeader center">{{mobileHeader}}</span>
        <div class="fields-wrapper" *ngIf="mobileDiv">
            <p class="font-color center">Please Enter your 10 digits mobile number</p>
            <app-input [placeholder]="'Mobile No.'" [hint]="'You will receive an OTP on this number'" [hintRequired]="true" [errorMessage]="error_Message_MobileNo" [(ngModel)]="mobileNumber" [maxLength]="10" [type]="'text'"></app-input>
            <mat-checkbox class="example-margin" [checked]="true"><span style="color: white; font-size: 12px;">By login, you’re agree to our &nbsp;<span><a href="">Terms & Conditions.</a></span></span></mat-checkbox>
            <div class="center">
            <app-button [savingsText]="'GET OTP'" (click)="onGetOTP()"></app-button>
            </div>
        </div>

        <div class="fields-wrapper" *ngIf="SignUpDiv">
          <app-input [placeholder]="'Full Name'" [hint]="''" [hintRequired]="false" [(ngModel)]="fullName" [type]="'text'"></app-input>
          <app-input [placeholder]="'Email Id'" [hint]="''" [hintRequired]="false" [(ngModel)]="emailID" [type]="'text'"></app-input>
          <div class="center">
          <app-button class="center" [savingsText]="'Next'" (click)="onSignup()"></app-button>
          </div>
      </div>

        <div class="fields-wrapper" *ngIf="otpDiv">
            <span class="font-color">We’ve sent you an SMS with a 4-digit verification code on</span>
            <span class="center">
                <span class="mobileNumberStyle">{{ mobileNumber }}</span>
                &nbsp;&nbsp;
                <div style="cursor: pointer; font-size: 12px;" (click)="editMobile()"><fa-icon style="color: white;" [icon]="['fas', 'edit']"></fa-icon>
                    <span style="color: white; text-decoration: underline; font-size: 12px;">   Edit</span>
                </div>
              </span>
            <br>
            <app-pin-box (otpChange)="onMobileOtpChanges($event)" [loading]="loading" [errorMessage]="error_message"></app-pin-box>
            <span class="font-color center" *ngIf="!countdownCompleted">Resend OTP in &nbsp;<span style="color: #C3966E;">{{formatCountDownPipe()}}</span></span>
            <span class="font-color center" *ngIf="countdownCompleted">Didn't get the code? &nbsp;<span class="mobileNumberStyle">Resend OTP</span></span>
        </div>
</div>
<!--  -->
</div>