import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit {
  transactionsData: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TransactionDetailsComponent>,
  ) {}

  ngOnInit() {
    this.transactionsData = this.data.moreDetails.transactions;
    console.log(this.data.moreDetails);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
