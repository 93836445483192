import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyCharacter]'
})
export class OnlyCharacterDirective {
  @Input() validationFieldsType: string | undefined;
  @Input() placeholder: string | undefined; // Accept placeholder as input

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    let e = event;
    /* 
      8 -  for backspace
      9 -  for tab
      13 - for enter
      27 - for escape
      46 - for delete
  */
    if (
      [8, 9, 13, 27, 46].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+A
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+C
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+V
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+X
      (e.keyCode >= 35 && e.keyCode <= 39) // Allow: home, end, left, right
    ) {
      return;
    }

    // If placeholder is "Full Name", block numbers
    if (this.placeholder === 'Full Name' && e.keyCode >= 48 && e.keyCode <= 57) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event']) onKeyup(event: KeyboardEvent) {
    this.validateFields(event);
  }

  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    this.validateFields(event);
  }

  validateFields(event: any) {
    setTimeout(() => {
      if (this.placeholder === 'Full Name') {
        // Remove any numbers from pasted input
        this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '');
      } else {
        // Allow numbers if placeholder is not "Full Name"
        this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z0-9 ]/g, '');
      }
      event.preventDefault();
    }, 100);
  }
}
