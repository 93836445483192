import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ScreenTypeService } from '../../services/screen-type.service';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from '../../services/web-socket.service';
import { ApiServiceService } from '../../services/api-service.service';
import { MoengageService } from '../../services/moengage.service';
declare let Moengage: any;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() showUserName: boolean = false;
  loginText: string = 'Login/Register';
  @Output() loginClicked = new EventEmitter<string>();
  screenType: string = '';
  @Input() username: any;
  dataLetter: string = '';
  @Input() matBadgeCount: number = 0;
  isMenu: boolean = false;
  selectedButton: string = '';
  token: any;
  moeEventData: any;

  constructor(
    private route: Router,
    private screenTypeService: ScreenTypeService,
    private authService: AuthService,
    private _toastrService: ToastrService,
    private websocket: WebSocketService,
    private apiService: ApiServiceService,
    private moengageService: MoengageService,
  ) {}

  goToHome() {
    this.route.navigate(['']);
  }

  ngOnInit(): void {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
      this.apiService.getTokenData().subscribe((res: any) => {
        if(res && res.message == 'success') {
            if (res.result?.customer_meta_info?.is_registered) {
              console.log('ngOnInit token data', data);
              const name = res.result?.first_name || res.result?.full_name || '';
              this.username = name?.trim();
              let activeSubscription = localStorage.getItem('ActiveSubscription') || '';
              this.moeEventData = {
                'Phone': res?.result?.mobile_number,
                'Name': res?.result?.full_name,
                'Email': res?.result?.email_address,
                'createdOn': new Date(),
                'Active_Subscription': activeSubscription ? JSON.parse(activeSubscription) : '' // Check before parsing
              };
  
              this.getSelectedRoute();
              this.screenType = this.screenTypeService.breakpointState;
              if (this.username) {
                if(this.username.includes('Mr') ||
                   this.username.includes('mr') ||
                   this.username.includes('MR') ||
                   this.username.includes('MISTER') ||
                   this.username.includes('MS') ||
                   this.username.includes('Ms') ||
                   this.username.includes('ms') ||
                   this.username.includes('MISS')
                   ) {
                  let userData = this.username.split(' ');
                  this.username = userData[1];
                }
                else {
                  let userData = this.username.split(' ');
                  this.username = userData[0];
                }
                this.dataLetter = this.username?.substring(0, 1);
              }
            }
        }
        })
    }
    // if (token && data?.is_registered) {
    //   this.token = data;
    // }
  }

  logout() {
    let token = localStorage.getItem('authToken');
    this.authService.logout(token).subscribe((res: any) => {
      console.log('got logout response', res);
      if (res) {
        Moengage.destroy_session();
        this.route.navigate(['']);
        localStorage.removeItem('authToken');
        this.websocket.disconnect();
        this.showUserName = false;
      }
    });
  }

  getSelectedRoute() {
    let urlSegment = this.route.url.split('/')[1];
    switch (urlSegment) {
      case '':
        this.selectedButton = 'home';
        break;
      case 'discover':
        this.selectedButton = 'discover';
        break;
      case 'dashboard':
        this.selectedButton = 'dashboard';
        break;
      case 'watchlist':
        this.selectedButton = 'watchlist';
        break;
      case 'infoHub':
        this.selectedButton = 'infoHub';
        break;
    }
  }

  navigateTo(item: string) {
    switch (item) {
      case 'home':
        this.route.navigate(['']);
        break;
      case 'discover':
        if(this.moeEventData) {
          this.moeEventData['eventType'] = 'DiscoverClicked';
          this.moengageService.tabsClicked(this.moeEventData);
        }
        this.route.navigate(['discover']);
        break;
      case 'infoHub':
        this.route.navigate(['']);
        break;
      case 'dashboard':
        if(this.moeEventData) {
        this.moeEventData['eventType'] = 'MyPortfolioClicked';
        this.moengageService.tabsClicked(this.moeEventData);
        }
        this.route.navigate(['dashboard']);
        break;
      case 'watchlist':
        this.route.navigate(['watchlist']);
        break;
      default:
        break;
    }
  }

  goToProfile() {
    this.moeEventData['eventType'] = 'ProfileClicked';
    this.moengageService.tabsClicked(this.moeEventData);
    this.route.navigate(['profile']);
  }

  openLoginModal(value: string) {
    this.loginClicked.emit(value);
  }
}
