<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>

<div>
    <div class="pan" *ngIf="this.screenType.breakpointState !== 'mob'" style="padding: 125px 70px;">
        <mat-card>
            <mat-card-content>
                <div class="overlay" *ngIf="spinner">
                    <div class="spinner">
                      <img src="../../../../assets/loader.svg" alt="" class="loader">
                    </div>
                </div>
                <div class="pan-wrapper">
                    <div class="row">
                        <div class="col-4">
                            <div class="plan">
                                <div class="plan-title">Selected Basket</div>
                                <div class="plan-desc mt-2">The basket is valid for 6 months.</div>
                                <div class="d-flex align-item-center mt-5">
                                    <!-- <div class="plan-6" [class.highlighted]="selectedPlan === 'plan-6'" (click)="planSelection('plan-6')">
                                        <div class="duration">6 Months</div>
                                        <div class="value mt-2">&#8377; {{feeDetailsData[1]?.details.advisory_fees}}</div>
                                    </div> -->
                                    
                                    <div class="plan-12" [class.highlighted]="selectedPlan === 'plan-12'">
                                        <span class="highlight">Best value</span>
                                        <div class="duration">6 Months</div>
                                        <div class="value mt-2">&#8377; {{feeDetailsData[0]?.details.advisory_fees | readableNumber}}</div>
                                        <!-- <div class="text-center desc mt-1">
                                            <div>Payable &#8377; 2,449</div>
                                            <div>(every six month)</div>
                                        </div> -->
                                    </div>           
                                </div>
                                <div class="gst-wrapper">
                                    <img src="../../../../assets/hrDarkMobIcon.svg" alt="">  
                                    <div class="gst-content">
                                       <div style="display: flex;">
                                        <div style="display: flex; align-items: center;">
                                       <img src="../../../../assets/gstIcon.svg" alt="">&nbsp;&nbsp;
                                       </div>
                                       <div style="display: flex; flex-direction: column;">
                                       <span class="amount">Amount to Pay</span>
                                       <span class="subText">(Inc. GST)</span>
                                       </div>
                                       </div>
                                       <div class="amount-value">
                                        &#8377; {{gstAmount | decimalFormat}}
                                       </div>
                                    </div>
                                </div>
            
                                <!-- <div class="mt-4 subscription">
                                    <div>
                                        <div class="sub-title">Auto renew subscription</div>
                                        <div class="sub-desc">Cancel anytime &nbsp; <img src="../../../../assets/info_pan.svg" alt="" 
                                            matTooltip="
                                            Auto renew cancellation to be done via your bank anytime after orders are placed.
                                            To cancel subscription please connect with our support team on (XXXX9090) or email support.multiplyrr@equentis.com
                                            ">
                                        </div>
                                    </div>
            
                                    <div class="ms-auto">
                                        <mat-checkbox class="example-margin" [checked]="isAutoRenew" (change)='showOptions($event.checked)'></mat-checkbox>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-1">
                            <img src="../../../../assets/verticalLine.svg" alt="">
                        </div>
                        <div class="col-7">
                             <div>
                                <div class="plan">
                                    <div class="plan-title">Let's Verify Your Identity
                                        <!-- <span class="h-line"></span> <span class="page-order">1/8</span> -->
                                    </div>
                                    <div class="plan-desc">This is required to complete the KYC (Know Your Customer) process to meet regulatory compliance</div>
                                </div>
                                
                
                                <div class="mt-5 pan-details">
                                    <div class="label">
                                        PAN Number
                                    </div>
            
                                    <div class="d-flex align-item-center">
                                        <div class="input-value mt-2">
                                            <app-input
                                            class="pan-input"
                                            [placeholder]="'eg.ABCDE5506D'"
                                            [autofocus]="true"
                                            [type]="'text'"
                                            [name]="'pan'"
                                            [maxLength]="10"
                                            [required]="true"
                                            [(ngModel)]="pan"
                                            (mouseenter)="onMouseEnterPan()"
                                            (mouseleave)="onMouseLeavePan()"
                                            [errorMessage]="error_Message_pan"
                                            [isSuffix]="isPanSuffix"
                                            [isDisabled]="pan_name !== ''"
                                            ></app-input>
                                        </div>
                                        <div *ngIf="showPanInfo" style="margin: 15px 0 0 30%; position: absolute;">
                                            <app-info [title]="'Your PAN is required for:'" [infoType]="'pan'" [showPanImage]="isPanImage" [messageArray]="panInfoData"></app-info>
                                        </div>
                                    </div>
                                    
                                </div>
                
                                <div class="mt-3 pan-details">
                                    <div class="label">
                                        Date of Birth (As per PAN)
                                    </div>
                                    <div class="d-flex align-item-center">
                                        <div class="input-value mt-2">
                                            
                                            <app-datepicker
                                            class="date"
                                            [name]="'date_of_birth'"
                                            [maxDate]="today"
                                            [required]="true"
                                            [id]="'date_of_birth'"
                                            [(ngModel)]="date_of_birth"
                                            [clearable]="true"
                                            [placeholder]="'DD/MM/YYYY'"
                                            (mouseenter)="onMouseEnterDOB()"
                                            (mouseleave)="onMouseLeaveDOB()"
                                            [isSuffix]="isDateSuffix"
                                            [errorMessage]="error_Message_dob"
                                            [isDisabled]="pan_name !== ''"
                                            ></app-datepicker>
                                        </div>
                
                                        <div *ngIf="showDOBInfo" style="margin: 15px 0 0 30%; position: absolute;">
                                            <app-info [title]="'📅 &nbsp; Age Calculation for Risk Assessment: '" [infoType]="'dob'" [messageArray]="DoBInfoData"></app-info>
                                        </div>
                                    </div>
                                    
                                </div>
                
                                <div class="mt-3 pan-details" *ngIf="pan_name !== '' ">
                                    <div class="label">
                                        Your Name as per PAN
                                    </div>
                                    <div class="input-value mt-2">
                                        <!-- <app-input
                                        class="pan-name"
                                        [placeholder]="'Name'"
                                        [autofocus]="true"
                                        [type]="'text'"
                                        [name]="'pan-name'"
                                        [required]="true"
                                        [(ngModel)]="pan_name"
                                        [disabled]="true"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                                        ></app-input> -->
                                        <div class='name-wrapper'>{{pan_name}}</div>
            
                                    </div>
                                    
                                </div>
                
                                <div class="mt-3 submit-btn">
                                    <app-button [savingsText]="pan_btn_text" (click)="onSubmit()"></app-button>
                                </div>
                             </div>
                            </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="pan" *ngIf="this.screenType.breakpointState == 'mob'" style="padding: 10px 0;">
        <div class="overlay" *ngIf="spinner">
            <div class="spinner">
              <img src="../../../../assets/loader.svg" alt="" class="loader">
            </div>
        </div>
        <app-toolbar-mobile></app-toolbar-mobile>
        <div class="pan-wrapper" style="border-radius: 0; height: 100%; padding-bottom: 70px;">
            <div class="row">
                <div class="col-12">
                    <div class="plan">
                        <div class="plan-title">Selected Basket</div>
                        <div class="plan-desc mt-2" style="font-size: 12px;">The basket is valid for 6 months.</div>
                        <div class="d-flex align-item-center mt-3">
                            <!-- <div class="plan-6" [class.highlighted]="selectedPlan === 'plan-6'" (click)="planSelection('plan-6')">
                                <div class="duration">6 Months</div>
                                <div class="value mt-2">&#8377; {{feeDetailsData[0]?.details.advisory_fees}}</div>
                            </div> -->
    
                            <div class="plan-12" [class.highlighted]="selectedPlan === 'plan-12'" (click)="planSelection('plan-12')">
                                <span class="highlight">Best value</span>
                                <div class="duration">6 Months</div>
                                <div class="value mt-2">&#8377; {{feeDetailsData[0]?.details.advisory_fees | readableNumber}}</div>                         
                            </div>
                        </div>
                        <div class="gst-wrapper">
                            <img src="../../../../assets/hrDarkMobIcon.svg" alt="">  
                            <div class="gst-content">
                               <div style="display: flex;">
                                <div style="display: flex; align-items: center;">
                               <img src="../../../../assets/gstIcon.svg" alt="">&nbsp;&nbsp;
                               </div>
                               <div style="display: flex; flex-direction: column;">
                               <span class="amount">Amount to Pay</span>
                               <span class="subText">(Inc. GST)</span>
                               </div>
                               </div>
                               <div class="amount-value">
                                &#8377; {{gstAmount | decimalFormat}}
                               </div>
                            </div>
                        </div>
    
                        <!-- <div class="mt-4 subscription" style="width: 100%;" [style.display]="isAutoRenew ? 'block' : 'none'">
                            <div>
                                <div class="sub-title" style="font-size: 16px;">Auto renew subscription</div>
                                <div class="sub-desc">Cancel anytime</div>
                            </div>
    
                            <div class="ms-auto">
                                <mat-checkbox class="example-margin" [checked]="isAutoRenew" (change)='showOptions($event.checked)'></mat-checkbox>
                            </div>
                        </div> -->
                    </div>
    
                    <!-- <div class="vertical-line"></div> -->
                    <div style="margin: 10px 0;">
                        <img src="../../../../assets/horizontalDevider.svg" alt="">
                    </div>
                </div>
                <div class="col-12">
                    <div class="plan">
                        <div class="plan-title" style="width: 100%; font-size: 18px;">Let's Verify Your Identity</div>
                        <div class="plan-desc" style="font-size: 12px;">This is required to complete the KYC (Know Your Customer) process to meet regulatory compliance</div>
                    </div>
    
                    <div class="mt-3 pan-details">
                        <div class="label">PAN Number
                            <span class="ms-2" (mouseenter)="onMouseEnterPan()"
                            (mouseleave)="onMouseLeavePan()">
                                <img src="../../../../assets/information.svg" alt="">
                            </span>
                        </div>
                        <div *ngIf="showPanInfo" style="position: absolute;z-index: 1;margin: -28px 0 0 33%;">
                            <app-info [title]="'Your PAN is required to,'" [infoType]="'pan'" [messageArray]="panInfoData"></app-info>
                        </div>
                        
                        <div class="input-value mt-2" style="width: 100%;">
                            <app-input
                            class="pan-input"
                            [placeholder]="'eg.ABCDE5506D'"
                            [autofocus]="true"
                            [type]="'text'"
                            [name]="'pan'"
                            [maxLength]="10"
                            [required]="true"
                            [(ngModel)]="pan"
                            [isSuffix]="isPanSuffix"
                            [errorMessage]="error_Message_pan"
                            ></app-input>
                        </div>
                        
                    </div>

                    
    
                    <div class="mt-2 pan-details">
                        <div class="label">
                            Date of Birth (As per PAN)
                            <span class="ms-2">
                                <img src="../../../../assets/information.svg" alt="" (mouseenter)="onMouseEnterDOB()"
                                (mouseleave)="onMouseLeaveDOB()">
                            </span>
                        </div>
                        <div *ngIf="showDOBInfo" style="position: absolute;z-index: 1;margin: -28px 0 0 33%;">
                            <app-info [title]="'Your DOB is required to,'" [infoType]="'dob'" [messageArray]="DoBInfoData"></app-info>
                        </div>
                        <div class="input-value mt-2" style="width: 100%;">
                            <app-datepicker
                            class="date"
                            [name]="'date_of_birth'"
                            [maxDate]="today"
                            [required]="true"
                            [id]="'date_of_birth'"
                            [(ngModel)]="date_of_birth"
                            [clearable]="true"
                            [placeholder]="'DD/MM/YYYY'"
                            [isSuffix]="isDateSuffix"
                            [errorMessage]="error_Message_dob"
                            ></app-datepicker>
                        </div>
                        
                    </div>
    
                    <div class="mt-3 pan-details" *ngIf="pan_name !== '' ">
                        <div class="label">
                            Your Name as per PAN
                        </div>
                        <div class="input-value mt-2" style="width: 100%;">
                            <!-- <app-input
                            class="pan-name"
                            [placeholder]="'Name'"
                            [autofocus]="true"
                            [type]="'text'"
                            [name]="'pan-name'"
                            [required]="true"
                            [(ngModel)]="pan_name"
                            [disabled]="true"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                            ></app-input> -->
                            <div class='name-wrapper'>{{pan_name}}</div>
                        </div>
                        
                    </div>
    
                    <div class="mt-3 submit-btn" style="display: flex; justify-content: center;">
                        <!-- <button class="btn btn-primary" [disabled]="isPanValid" (click)="onSubmit()">Continue</button> -->
                        <app-button [savingsText]="pan_btn_text" (click)="onSubmit()"></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>