<div class="info-start"></div>
<div class="info" *ngIf="screenType !== 'mob'">
    <div class="text-content">{{title}}</div>

    <div class="mt-3">
        <ul *ngFor="let item of messageArray;">
            <li class="list-item">{{item}}</li>
        </ul>
        <div class="image" *ngIf="infoType == 'pan' && showPanImage">
        <img src="../../../../../assets/pan_info.png" height="100px" width="158px" alt="">
        </div> 
        <div class="image" *ngIf="infoType == 'dob'">
            <img src="../../../../../assets/dob_info.png" height="100px" width="158px" alt="">
        </div> 
    </div>
</div>

<div class="info-mob" *ngIf="screenType === 'mob'">
    <div  class="text-content">{{title}}</div>

    <div class="mt-3">
        <ul *ngFor="let item of messageArray;">
            <li class="list-item">{{item}}</li>
        </ul>
        <div class="image" *ngIf="infoType == 'pan'">
            <img src="../../../../../assets/pan_info.png" height="100px" width="158px" alt="">
        </div> 
        <div class="image" *ngIf="infoType == 'dob'">
            <img src="../../../../../assets/dob_info.png" height="100px" width="158px" alt="">
        </div> 
    </div>
</div>
