
<!-- laptop view -->
<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>
<div class="savings-wrapper" *ngIf="this.screenType.breakpointState !== 'mob' && homePageJSONResponse">
    <div class="row" style="overflow-x: hidden;">
        <div class="carousel-wrapper">
        <div style="padding-top: 100px; padding-bottom: 70px;">
        <div class="center header-text" style="color: white;">
            <span [innerHTML]="sanitizeHtml(homePageJSONResponse[0].h1 ? homePageJSONResponse[0].h1 : '')"></span>
        </div>
        <div class="center subheader-text">
            <span style="color: #FFF;">{{homePageJSONResponse[0].h2 ? homePageJSONResponse[0].h2 : ''}}</span>
        </div>
        <div class="center buttonPosition">
        <app-button [savingsText]="homePageJSONResponse[0].b1" (click)="goToDiscover(homePageJSONResponse[0].h1, 'beginNow')"></app-button>
        </div>
        <!-- carousel to be added here -->
        <div class="carousel-container">
            <!-- Backward Navigation Icon -->
            <img [src]="isAtStart ? '../../../../assets/backwardIcon_disabled.svg' : '../../../../assets/backwardIcon.svg'"
                 width="50px" class="navigation-icon left" alt=""
                 (click)="scroll(-1)" 
                 [class.disabled]="isAtStart"
                 [style.pointer-events]="isAtStart ? 'disabled' : 'auto'">
          
            <!-- Carousel Cards -->
            <div class="carousel-track">
              <div *ngFor="let item of visibleItems; let i = index"
                   class="carousel-card" 
                   [ngClass]="{'hovered': i === hoveredIndex}" 
                   (click)="goToBaskets(item)" 
                   (mouseenter)="hoverCard(i)" 
                   (mouseleave)="unhoverCard(i)"
                   [ngStyle]="{'width': screenType.breakpointState === 'desk' ? '350px' : '275px'}"
                   style="position: relative;">
                
                   <div [ngStyle]="{
                    'position': 'relative',
                    'height': '100%',
                    'width': '100%',
                    'borderRadius': '8px',
                    'overflow': 'hidden'
                    }">
                    <img [src]="item.image_url"
                         (load)="onImageLoad(i)"
                         [class.loaded]="loadedIndexes[i]" 
                         class="carousel-image">
                    <img *ngIf="!loadedIndexes[i]" 
                     src="../../../../assets/placeholderImg.svg" 
                     alt="Loading..." 
                     class="carousel-image">
                    <div [ngStyle]="{
                      'position': 'absolute',
                      'top': '0',
                      'left': '0',
                      'right': '0',
                      'bottom': '0',
                      'background': 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(66, 66, 66, 0.3) 73.81%, #050506 100%)',
                      'pointerEvents': 'none'
                    }"></div>
                </div>
                
                <div class="carousel-title">
                  {{item.title}}
                </div>
              </div>
            </div>
          
            <!-- Forward Navigation Icon -->
            <img [src]="isAtEnd ? '../../../../assets/forwardIcon_disabled.svg' : '../../../../assets/forwardIcon.svg'"
                 width="50px" class="navigation-icon right" alt=""
                 (click)="scroll(1)" 
                 [class.disabled]="isAtEnd"
                 [style.pointer-events]="isAtEnd ? 'disabled' : 'auto'">
          </div>
          
        </div>
        </div>
        <div class="wealthCreation-wrapper" style="background-image: url('../../../../assets/home_background_2.svg');background-repeat: no-repeat; background-size: cover; background-color: white; margin-top: 0px;">
            <div class="users-details">
            <div class="content">
                <div class="icon"><img src="../../../../assets/registered_user.svg" alt=""> &nbsp;&nbsp;</div>
                <div class="highlighted-text numberHeader">{{homePageJSONResponse[2].content[0].name ? homePageJSONResponse[2].content[0].name : ''}}
                <br>
                <span class="subText" style="display: flex;">{{homePageJSONResponse[2].content[0].detail ? homePageJSONResponse[2].content[0].detail : ''}}</span>
                </div>
            </div>
            <div class="content">
                <div class="icon"><img src="../../../../assets/years_experience.svg" alt=""> &nbsp;&nbsp;</div>
                <div class="highlighted-text numberHeader">{{homePageJSONResponse[2].content[1].name ? homePageJSONResponse[2].content[1].name : ''}}
                <br>
                <span class="subText" style="display: flex;">{{homePageJSONResponse[2].content[1].detail ? homePageJSONResponse[2].content[1].detail : ''}}</span>
                </div>
            </div>
            <div class="content">
                <div class="icon"><img src="../../../../assets/research_type.svg" alt=""> &nbsp;&nbsp;</div>
                <div class="highlighted-text numberHeader">{{homePageJSONResponse[2].content[2].name ? homePageJSONResponse[2].content[2].name : ''}}
                <br>
                <span class="subText" style="display: flex;">{{homePageJSONResponse[2].content[2].detail ? homePageJSONResponse[2].content[2].detail : ''}}</span>
                </div>
            </div>
            </div>
            <!-- <div class="center">
                <img src="../../../../assets/hrLapIcon.svg" alt="">
            </div>

            <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 30px;">
                <div style="flex-shrink: 0;">
                    <img src="../../../../assets/stampIcon.svg" alt="" height="100px">
                </div>
                <div style="flex-grow: 1; display: flex; justify-content: center;">
                    <app-button [savingsText]="homePageJSONResponse[2].b1" style="margin-top: 40px;" (click)="goToDiscover('')"></app-button>
                </div>
                <div style="flex-shrink: 0; visibility: hidden;">
                    <img src="../../../../assets/stampIcon.svg" alt="" height="100px">
                </div>
            </div> -->
            
            
            
            
            <!-- <div class="center">
                <div>
                    <img src="../../../../assets/stampIcon.svg" alt="">
                </div>
                <div>
                    <app-button [savingsText]="homePageJSONResponse[2].b1" style="margin-top: 40px;" (click)="goToDiscover('')"></app-button>
                </div>
            </div> -->
        </div>
        <div class="center header-text" style="margin-top: 50px;">
            <!-- <span>A perfect fit for every <span class="highlighted-text">&nbsp;investor</span></span> -->
            <span [innerHTML]="sanitizeHtml(homePageJSONResponse[1].h1 ? homePageJSONResponse[1].h1 : '')"></span>
            
        </div>
        <div class="center subheader-text">
            <!-- We believe everyone has right to create wealth no matter how big or small the Investment Amount is. -->
            <span>{{homePageJSONResponse[1].h2 ? homePageJSONResponse[1].h2 : ''}}</span>
        </div>
        <div class="newToMarketWrapper">
            <div class="row">
                <div class="center newsHeader col-4" *ngFor="let item of homePageJSONResponse[1].content; let i = index">
                    <img src="../../../../assets/new_to_market.svg" alt="" *ngIf="i == 0">
                    <img src="../../../../assets/start_small.svg" alt="" *ngIf="i == 1">
                    <img src="../../../../assets/quick_profit_potential.svg" alt="" *ngIf="i == 2">
                    <br>
                    {{ item.name }}
                    <br>
                    <br>
                    <div style="display: flex;">
                    <span class="center newsText" [innerHTML]="sanitizeHtml(item.detail)"></span>
                    <span style="margin-top: 23px;"><img src="../../../../assets/info_pan.svg" 
                         alt="Info" 
                         *ngIf="item.tooltip"
                         [matTooltip]="item.tooltip" 
                         matTooltipPosition="above" 
                         style="cursor: pointer; margin-right: 5px;">
                    </span>
                    </div>
                    
                    <app-button [savingsText]="item.b1" style="margin-top: 20px;" (click)="goToDiscover(homePageJSONResponse[1]?.h1, 'beginNow')"></app-button>
                  </div>
                  
            </div>
        </div>
        <div class="wealthCreation-wrapper" style="padding-bottom: 40px; background-image: url('../../../../assets/stock_investing_bg.svg');background-repeat: no-repeat; background-size: cover;">
            <div class="center header-text" style="padding-top: 30px;">
                <span style="color: white;" [innerHTML]="sanitizeHtml(homePageJSONResponse[4].h1 ? homePageJSONResponse[4].h1 : '')"></span>
            </div>
            <div class="center subheader-text" style="color: white;">
                {{homePageJSONResponse[4].h2 ? homePageJSONResponse[4].h2 : ''}}
            </div>
            
            <div class="row section-spacing" style="margin-top: 50px;">
                <div class="col-1"></div>
                <div class="col-4 textColor">
                    <div style="display: flex; gap: 10px;">
                        <div><img src="../../../../assets/tickIcon.svg" alt=""></div>
                        <div>{{homePageJSONResponse[4].content[0].name}}
                            <br>
                            <span class="subText">{{homePageJSONResponse[4].content[0].detail}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
                <div class="col-4 textColor">
                    <div style="display: flex; gap: 10px;">
                        <div><img src="../../../../assets/tickIcon.svg" alt=""></div>
                        <div>{{homePageJSONResponse[4].content[1].name}}
                            <br>
                            <span class="subText">{{homePageJSONResponse[4].content[1].detail}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row section-spacing">
                <div class="col-1"></div>
                <div class="col-4 textColor">
                    <div style="display: flex; gap: 10px;">
                        <div><img src="../../../../assets/tickIcon.svg" alt=""></div>
                        <div>{{homePageJSONResponse[4].content[2].name}}
                            <br>
                            <span class="subText">{{homePageJSONResponse[4].content[2].detail}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
                <div class="col-4 textColor">
                    <div style="display: flex; gap: 10px;">
                        <div><img src="../../../../assets/tickIcon.svg" alt=""></div>
                        <div>{{homePageJSONResponse[4].content[3].name}}
                            <br>
                            <span class="subText">{{homePageJSONResponse[4].content[3].detail}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row section-spacing">
                <div class="col-1"></div>
                <div class="col-4 textColor">
                    <div style="display: flex; gap: 10px;">
                        <div><img src="../../../../assets/tickIcon.svg" alt=""></div>
                        <div>{{homePageJSONResponse[4].content[4].name}}
                            <br>
                            <span class="subText">{{homePageJSONResponse[4].content[4].detail}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-3"></div>
                <div class="col-4 textColor">
                    <div style="display: flex; gap: 10px;">
                        <div><img src="../../../../assets/tickIcon.svg" alt=""></div>
                        <div>{{homePageJSONResponse[4].content[5].name}}
                            <br>
                            <span class="subText">{{homePageJSONResponse[4].content[5].detail}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row section-spacing" style="margin-top: 30px;">
                <img src="../../../../assets/hrLapIcon.svg" alt="">
            </div>
            
            <div class="center buttonPosition" style="padding-bottom: 20px;">
                <app-button [savingsText]="homePageJSONResponse[4].b1" (click)="goToDiscover(homePageJSONResponse[4].h1, 'beginNow')"></app-button>
            </div>
        </div>

        <div class="wealthCreation-wrapper" style="padding-bottom: 40px; background-image: url('../../../../assets/ready_to_multiplySavings.svg');
        background-repeat: no-repeat; 
        background-size: cover; 
        margin-top: 0px; 
        padding: 40px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-around;">
        <div style="display: flex;">
            <div>
                <img src="../../../../assets/mobile_phone.svg" alt="">
            </div>
            <div class="newSection-wrapper">
                <img src="../../../../assets/updatedStamp.svg" alt="" height="150px" width="112px">
                <br>
                <span class="subHeaderText">Download App From</span>
                <div style="display: flex; gap: 20px; padding-top: 20px;">
                <img src="../../../../assets/download_button_playStore.svg" alt="" style="cursor: pointer;" (click)="openPlayStore()">
                <img src="../../../../assets/download_button_appStore.svg" alt="" style="cursor: pointer;" (click)="openAppStore()">
                </div> 
            </div>
        </div>
        <div class="newSection-wrapper" style="gap: 15px;">
               <span class="headerText">Ready to multiply <br>
                your savings?</span>
                <span class="subHeaderText">Our Wealth Advising team can help you</span>
                <div style="display: flex; flex-direction: column; gap: 25px;">
                    <span class="mobileNoText" *ngIf="!isLoggedIn">Enter Mobile No.
                      <br>
                      <div style="position: relative; display: inline-block; width: 100%;">
                        <span style="
                          position: absolute;
                          left: 10px;
                          top: 50%;
                          transform: translateY(-50%);
                          color: #0F0F0F;
                          pointer-events: none;
                        ">+91 |</span>
                        <input type="number"
                          [(ngModel)]="mobile_number_newScreen"
                          maxlength="10"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          class="inputField" style="
                          padding-left: 50px;
                          height: 40px;
                          width: 100%;
                          border: 1px solid #C1C1C1;
                          border-radius: 4px;">
                      </div>
                    </span>
                    <div>
                      <app-button [savingsText]="'Begin Now'" (click)="openLoginNewScreen('')"></app-button>
                    </div>
                  </div>
                  
        </div>
        </div>
        
        <div class="row section-wrapper">
            <div class="center header-text" style="position: relative;">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[3].h1 ? homePageJSONResponse[3].h1 : '')"></span>
                <!-- <div style="position: absolute; top: 0; right: 0;">
                    <img src="../../../../assets/stampIcon.svg" alt="" style="height: 100px;">
                </div> -->
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[3].h2 ? homePageJSONResponse[3].h2 : ''}}
            </div>
            <br>
            <br>
            <div class="center" style="padding: 30px;">
                <!-- <img src="../../../../assets/portfolioIcon.svg" alt="" style="max-height: 100%;"> -->
                <video [src]="portfolio_url" controls width="60%" [poster]="thumbnailUrl"
                (play)="videoClick($event)"
                (pause)="videoPaused($event)">
                    Your browser does not support the video tag.
                </video>   
            </div>
        </div>
        <div class="row section-wrapper">
            <div class="center header-text" style="padding-top: 30px;">
                <!-- <span>Simple <span class="highlighted-text">4 easy steps</span> to start your wealth creation</span> -->
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[5].h1 ? homePageJSONResponse[5].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[5].h2 ? homePageJSONResponse[5].h2 : ''}}
            </div>
            <div class="hoverSection">
                <!-- <div class="col-1"></div> -->
                <div>
                    <mat-card (mouseenter)="mouseHover('signup')" class="matCard-wrapper"
                    [style.transform]="isHoveredSignUp ? 'scale(1.05)' : 'scale(1)'"
                      style="transition: transform 0.3s ease; transform-origin: left;">
                        <mat-card-content>
                        <span class="stepsHeader" [ngClass]="{'hovered': isHoveredSignUp}">
                        <div style="display: flex; justify-content: flex-start;">
                            <div>
                                <span class="stepCount" [ngClass]="{'hovered-bg': isHoveredSignUp}">1</span>
                            </div>
                            <div>
                                <span class="headerText">{{homePageJSONResponse[5].content[0].name ? homePageJSONResponse[5].content[0].name : ''}}:</span><br>
                                <span class="stepsText" style="padding-left: 15px;" [innerHTML]="homePageJSONResponse[5].content[0].detail ? homePageJSONResponse[5].content[0].detail : ''"></span>
                            </div>
                        </div>
                        </span>
                        </mat-card-content>
                    </mat-card>
                    <br>
                    <mat-card (mouseenter)="mouseHover('Subscribe')" class="matCard-wrapper"
                    [style.transform]="isHoveredSubscribe ? 'scale(1.05)' : 'scale(1)'"
                      style="transition: transform 0.3s ease; transform-origin: left;">
                        <mat-card-content>
                        <span class="stepsHeader" [ngClass]="{'hovered': isHoveredSubscribe}">
                            <div style="display: flex; justify-content: flex-start;">
                                <div>
                                    <span class="stepCount" [ngClass]="{'hovered-bg': isHoveredSubscribe}">2</span>
                                </div>
                                <div>
                                    <span class="headerText">{{homePageJSONResponse[5].content[2].name ? homePageJSONResponse[5].content[2].name : ''}}:</span><br>
                                    <span class="stepsText" style="padding-left: 15px;" [innerHTML]="homePageJSONResponse[5].content[2].detail ? homePageJSONResponse[5].content[2].detail : ''"></span>
                                </div>
                            </div>
                        </span>
                    </mat-card-content>
                    </mat-card>
                    <br>
                    <mat-card (mouseenter)="mouseHover('Invest')" class="matCard-wrapper"
                    [style.transform]="isHoveredInvest ? 'scale(1.05)' : 'scale(1)'"
                      style="transition: transform 0.3s ease; transform-origin: left;">
                        <mat-card-content>
                        <span class="stepsHeader" [ngClass]="{'hovered': isHoveredInvest}">

                            <div style="display: flex; justify-content: flex-start;">
                                <div>
                                    <span class="stepCount" [ngClass]="{'hovered-bg': isHoveredInvest}">3</span>
                                </div>
                                <div>
                                    <span class="headerText">{{homePageJSONResponse[5].content[3].name ? homePageJSONResponse[5].content[3].name : ''}}:</span><br>
                                    <span class="stepsText" style="padding-left: 15px;" [innerHTML]="homePageJSONResponse[5].content[3].detail ? homePageJSONResponse[5].content[3].detail : ''"></span>
                                </div>
                            </div>
                        </span>
                    </mat-card-content>
                    </mat-card>
                </div>
                <div>
                    <!-- add steps GIF here -->
                    <img src="../../../../assets/Step_1.svg" style="max-height: none;" alt="" *ngIf="isSignup">
                    <img src="../../../../assets/subscribe.svg" style="max-height: none;" alt="" *ngIf="isKYC">
                    <img src="../../../../assets/3_Invest.svg" style="max-height: none;" alt="" *ngIf="isInvest">
                </div>
                <!-- <div class="col-1"></div> -->
            </div>
            <div class="center" style="margin-top: 20px;">
            <app-button [savingsText]="'Begin Now'" (click)="goToDiscover(homePageJSONResponse[5].h1, 'beginNow')"></app-button>
            </div>
        </div>
        <!-- <div class="row section-wrapper" style="background-color: white;">
            <div class="center header-text" style="padding-top: 20px;">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[12].h1 ? homePageJSONResponse[12].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[12].h2 ? homePageJSONResponse[12].h2 : ''}}
            </div>
            <br>
            <br>
            <div class="mediaSection">
                <div>
                    <img src="../../../../assets/brokerImages/zerodha.svg" alt="">
                </div>
                <div>
                    <img src="../../../../assets/brokerImages/angelOne.svg" alt="">
                </div>
                <div>
                    <img src="../../../../assets/brokerImages/5Paisa.svg" alt="">
                </div>
                <div>
                    <img src="../../../../assets/brokerImages/groww.svg" alt="">
                </div>
                <div>
                    <img src="../../../../assets/brokerImages/upstox.svg" alt="">
                </div>
                <div>
                    <img src="../../../../assets/brokerImages/hdfcSecurities.svg" alt="">
                </div>
            </div>
        </div> -->

        <!-- commenting chart part now -->
        <!-- <div class="row section-wrapper" style="background-color: white; margin-top: 0px;">
            <div style="margin-top: 40px;">
            <div class="center header-text" style="padding-top: 30px;">
                <span>A single platform to achieve all your <span class="highlighted-text">financial goals</span></span>
            </div>
            <div class="center subheader-text">
                Multiplying your savings is our goal
            </div>
            <div style="padding: 30px;">
                    <div class="chart-container">
                        <div class="chart-wrapper" [class.blurred]="!isLoggedIn">
                            <span style="font-size: 20px; font-weight: 600; color: black;font-family: 'manrope';">Benchmark Comparision :</span>
                    <br>
                    <div style="display: flex; justify-content: space-between;">
                        <div>
                            <span style="font-size: 12px; color: #858585;"><span class="basketCompareMark"></span><img src="../../../../assets/basketCompareMark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData?.benchmark_comparison?.jar_name}}</span>
                            &nbsp;&nbsp;
                            <span style="font-size: 12px; color: #858585;"><img src="../../../../assets/nifty50Mark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData?.benchmark_comparison?.benchmark_name}}</span>
                        </div>
                        <div class="chartFilter" style="border: 1px; width: 30%;">
                            <div [class.selected]="selectedFilter === '1 Year'" (click)="selectFilter('1 Year')">1 Year</div>
                                <div [class.selected]="selectedFilter === '3 Year'" (click)="selectFilter('3 Year')">3 Year</div>
                                <div [class.selected]="selectedFilter === '5 Year'" (click)="selectFilter('5 Year')">5 Year</div>
                        </div>
                    </div>
                    <br>
                          <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="chartOptions"
                            style="width: 100%; height: 350px; display: block;">
                          </highcharts-chart>
                        </div>
                      
                        <div *ngIf="!isLoggedIn" class="popup-container">
                          <div class="popup-content">
                            <img src="../../../../assets/lockedIcon.svg" alt="">
                            <br><br>
                            <app-button (click)="openLogin('')" [savingsText]="'Login/Register'"></app-button>
                          </div>
                        </div>
                      </div>
            </div>
        </div>
        </div> -->
        <!--  -->
        <div class="row section-wrapper" style="background-color: white;">
            <div class="center header-text" style="padding-top: 60px;">
                <!-- What our<p class="highlighted-text">&nbsp;Clients say</p> -->
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[6].h1 ? homePageJSONResponse[6].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                <!-- Definitely We are leading the conversations on all social media. Here are some of them. -->
                {{homePageJSONResponse[6].h2 ? homePageJSONResponse[6].h2 : ''}}
            </div>
            <!-- commented older design -->
            <!-- <div class="testimonial-container">
                <div class="testimonial-item" *ngFor="let testimonial of testimonials; let i = index" [class.active]="activeIndex === i">
                <span class="testimonial-header">{{ testimonial.header }}</span>
                <br>
                <br>
                <div style="text-align: center;">
                  <img [src]="testimonial.image_url" alt="Testimonial Image">
                  <br>
                  <br>
                  <span class="testimonial-content">{{ testimonial.content }}</span>
                  <br>
                  <span class="testimonial-subContent">{{ testimonial.sub_content }}</span>
                </div>
                </div>
            </div>
              
              <div class="dots">
                <span class="dot" *ngFor="let _ of testimonials; let i = index" [class.active]="activeIndex === i" (click)="setActiveIndex(i)"></span>
              </div> -->
              <!-- -->
              <div class="testimonial-container">
                <img [src]="isAtStartTestimonial ? '../../../../assets/backwardIcon_disabled.svg' : '../../../../assets/backwardIcon.svg'"
                 width="50px" class="navigation-icon left" alt=""
                 (click)="scrollTestimonial(-1)" 
                 [class.disabled]="isAtStartTestimonial"
                 [style.pointer-events]="isAtStartTestimonial ? 'disabled' : 'auto'">
                <!-- <img src="../../../../assets/backwardIcon.svg" class="navigation-icon left" alt="" (click)="scrollTestimonial(-1)"> -->
                <div class="testimonial-track">
                  <mat-card *ngFor="let testimonial of testimonials">
                    <mat-card-content>
                      <div class="testimonial-header">{{ testimonial.header }}</div>
                      <br>
                      <br>
                      <div style="display: flex; gap: 20px;">
                        <div>
                          <img [src]="testimonial.image_url" alt="Testimonial Image">
                        </div>
                        <div>
                          <span class="testimonial-content">{{ testimonial.content }}</span>
                          <br>
                          <span class="testimonial-subContent">{{ testimonial.sub_content }}</span>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <img 
                [src]="isAtEndTestimonial ? '../../../../assets/forwardIcon_disabled.svg' : '../../../../assets/forwardIcon.svg'"
                [class.disabled]="isAtEndTestimonial"
                [style.pointer-events]="isAtEndTestimonial ? 'disabled' : 'auto'"
                class="navigation-icon right" alt="" (click)="scrollTestimonial(1)">
              </div>            
    </div>
    <div class="row section-wrapper" style="background-image: url(../../../../assets/leaders_bg.svg); background-repeat: no-repeat; background-size: cover; padding-top: 20px;">
        <div class="center header-text">
            <span [innerHTML]="sanitizeHtml(homePageJSONResponse[8].h1 ? homePageJSONResponse[8].h1 : '')"></span>
        </div>
        <div class="center subheader-text">
            {{homePageJSONResponse[8].h2 ? homePageJSONResponse[8].h2 : ''}}
        </div>
        <div style="display: flex; position: relative;">
            <img [src]="isAtStartLeaders ? '../../../../assets/backwardIcon_disabled.svg' : '../../../../assets/backwardIcon.svg'"
            class="navigation-icon"
            style="left: 15;"
            alt="Backward"
            (click)="scrollLeadership(-1)"
                 [class.disabled]="isAtStartLeaders"
                 [style.pointer-events]="isAtStartLeaders ? 'disabled' : 'auto'">
            
            <div class="leadership-container" style="width: 100%; padding-top: 60px;">
              <div *ngFor="let leader of leaders; let i = index" class="leadership-item">
                <img [src]="'../../../../assets/leaderShipIcons/' + leader.icon"
                     alt=""
                     (click)="redirectToLinkedIn(leader.redirectionURL)"
                     [class.hovered]="hoveredIndex === i"
                     (mouseenter)="onMouseEnter(i)"
                     (mouseleave)="onMouseLeave()">
                <span class="leaderShipHeader">{{ leader.name }}</span>
                <span class="leaderShipSubHeader">{{ leader.role }}</span>
              </div>
            </div>
            <img [src]="isAtEndLeaders ? '../../../../assets/forwardIcon_disabled.svg' : '../../../../assets/forwardIcon.svg'"
            class="navigation-icon right"
            alt="Forward"
            (click)="scrollLeadership(1)"
                 [class.disabled]="isAtEndLeaders"
                 [style.pointer-events]="isAtEndLeaders ? 'disabled' : 'auto'">
          </div>
          
    </div>
    
        <div class="row section-wrapper" style="background-color: white;">
            <div class="center header-text" style="padding-top: 20px;">
                <!-- <span>Covered by the <span class="highlighted-text">Leading Media</span></span> -->
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[7].h1 ? homePageJSONResponse[7].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                <!-- We are everywhere. -->
                {{homePageJSONResponse[7].h2 ? homePageJSONResponse[7].h2 : ''}}
            </div>
            <br>
            <br>
            <div class="mediaSection">
                <div *ngFor="let data of mediaSectionData">
                <mat-card (click)="mediaRoute(data.feature_link)" class="media-card">
                    <mat-card-content class="card-content">
                        <span class="logo-header">{{data.logo}}</span>
                        <br>
                        <span class="desc" style="display: block; white-space: normal; word-wrap: break-word;">
                            {{data.headline}}
                        </span>
                    </mat-card-content>
                </mat-card>
                </div>
            </div>
        </div>
        <div style="padding: 0 60px 60px; padding-top: 50px;">
        <mat-card>
            <mat-card-content>
        <div class="row" style="padding-top: 20px;">
            <div class="center header-text">
                <!-- Read our<p class="highlighted-text">&nbsp;Blogs</p> -->
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[10].h1 ? homePageJSONResponse[10].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                <!-- Learn new technology -->
                {{homePageJSONResponse[10].h2 ? homePageJSONResponse[10].h2 : ''}}
            </div>

            <!-- new blogs code -->
                    <div class="row faqSection">
                        <div class="col-1"></div>
                        <div class="col-10">
                            <span class="text">Recent blog posts</span>
                            <br>
                            <br>
                            <div class="row blogsSection">
                                <div class="col-6" style="cursor: pointer;" (click)="goToBlogs(blogsData[0]?.link, blogsData[0])">
                                    <div class="blogs-content">
                                     <span><img src="{{blogsData[0]?.image?.src}}" width="100%" alt=""></span>
                                     <br>
                                     <span class="user-text">By {{blogsData[0]?.author}} • Published on {{getFormattedDate(blogsData[0]?.blog_time)}} • Reading Time 4 mins</span>
                                     <span class="Header-text">{{blogsData[0]?.title}}</span>
                                     <br>
                                     <span class="categoriesText" style="width: 35%;">{{blogsData[0]?.categories[0]}}</span>
                                    <!-- <span class="subheader-text">Remember when you decided to make your first stock market investment? Going
                                        through several fundamental and technical indicators, researching</span> -->
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row" style="cursor: pointer;" (click)="goToBlogs(blogsData[1]?.link, blogsData[1])">
                                        <div class="col-6">
                                            <span><img src="{{blogsData[1]?.image?.src}}" width="100%" height="200px" alt=""></span>
                                        </div>
                                        <div class="col-6">
                                            <div class="blogs-content">
                                                <span class="user-text">By {{blogsData[1]?.author}} • Published on {{getFormattedDate(blogsData[1]?.blog_time)}} • Reading Time 4 mins</span>
                                                <span class="Header-text">{{blogsData[1]?.title}}</span>
                                                <br>
                                                <span class="categoriesText" style="width: 70%;">{{blogsData[1]?.categories[0]}}</span>
                                               <!-- <span class="subheader-text">Remember when you decided to make your first stock market investment? Going</span> -->
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row" style="cursor: pointer;" (click)="goToBlogs(blogsData[2]?.link, blogsData[2])">
                                        <div class="col-6">
                                            <span><img src="{{blogsData[2]?.image?.src}}" width="100%" height="200px" alt=""></span>
                                        </div>
                                        <div class="col-6">
                                            <div class="blogs-content">
                                                <span class="user-text">By {{blogsData[2]?.author}} • Published on {{getFormattedDate(blogsData[2]?.blog_time)}} • Reading Time 4 mins</span>
                                                <span class="Header-text">{{blogsData[2]?.title}}</span>
                                                <br>
                                                <span class="categoriesText" style="width: 70%;">{{blogsData[2]?.categories[0]}}</span>
                                               <!-- <span class="subheader-text">Remember when you decided to make your first stock market investment? Going</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            <!--  -->
        </div>
    </mat-card-content>
        </mat-card>
        </div>
        <div class="section-wrapper" style="background-color: white; padding-bottom: 30px;">
            <div class="center header-text">
                <!-- <span>Frequently <span class="highlighted-text">asked questions</span></span> -->
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[9].h1 ? homePageJSONResponse[9].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                <!-- Everything you need to know about the product and billing. -->
                {{homePageJSONResponse[9].h2 ? homePageJSONResponse[9].h2 : ''}}
            </div>
            <br>
            <br>
            <div style="padding: 0 60px 60px;">
            <mat-card style="padding-bottom: 30px;">
            <div class="row faqSection" *ngFor="let data of visibleData let i = index" [id]="'faqSectionID' + i">
                <div class="col-1"></div>
                <div class="col-10">
                    <mat-accordion>
                        <mat-expansion-panel #panel (opened)="onPanelToggle(data, true)" (closed)="onPanelToggle(data, false)">
                          <mat-expansion-panel-header style="padding: 10px; display: flex; justify-content: space-between; align-items: center;">
                            <span class="faqHeader">
                              {{data.name}}
                            </span>
                            <!-- Hide the default expansion indicator -->
                            <span style="flex-grow: 1; text-align: right;">
                              <mat-icon *ngIf="!panel.expanded" style="font-size: 24px; color: #001E5A;">add</mat-icon>
                              <mat-icon *ngIf="panel.expanded" style="font-size: 24px; color: #C3966E;">remove</mat-icon>
                            </span>
                          </mat-expansion-panel-header>
                          <p class="faqSubHeader" [innerHTML]="sanitizeHtml(data.detail)" (click)="handleLinkClick($event)"></p>
                        </mat-expansion-panel>
                      </mat-accordion>
                                         
                      
                </div>
              </div>
              <div class="row center" *ngIf="hasMoreData || isExpanded" style="padding-top: 15px;">
                <app-button [savingsText]="buttonLabel" [isTransparentBTN]="true" [showWatchListIcon]="false" [borderColor] = "'#001E5A'" [textColor] = "'#001E5A'" [backgroundColor]="'#F4F4F4'" (click)="toggleVisibility()"></app-button>
              </div>
            </mat-card>
        </div>
              
              
            <!-- <div class="row faqSection">
                <div class="col-1"></div>
                <div class="col-10 background">
                    <span style="font-weight: 600; font-size: 22px; font-family: 'manrope';" class="center">{{homePageJSONResponse[9].h3 ? homePageJSONResponse[9].h3 : ''}}</span>
                    <br>
                    <span style="font-weight: 500; font-size: 18px; color: #667085;" class="center">{{homePageJSONResponse[9].h4 ? homePageJSONResponse[9].h4 : ''}}</span>
                    <br>
                    <app-button [savingsText]="homePageJSONResponse[9].b1" class="center"
                    (click)="requestCallBack()"
                    [backgroundColor]="'#001E5A'"></app-button>
                </div>
            </div> -->
        </div>
        <!-- <div class="row" style="background-color: white; padding-top: 20px;">
            <div class="center header-text">
                <span><span style="font-weight:700;color: #CE8F47;">Customer</span> Complaints in</span>        
            </div>
    
            <div class="row" style="padding-top: 20px;">
                <div class="col-1"></div>
                <div class="col-10 cc-table">
                    <table class="table table-bordered">
                        <thead>
                          <tr class="tableHeading">
                            <th scope="col w-80">Sr. No.</th>
                            <th scope="col w-200">Received from</th>
                            <th scope="col">Pending at the end of the month</th>
                            <th scope="col">Received</th>
                            <th scope="col">Resolved*</th>
                            <th scope="col">Total Pending</th>
                            <th scope="col">Pending complaints > 3 months</th>
                            <th scope="col">Average Resolution time (in days)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="w-80">1</td>
                            <td class="w-200">Directly from Investors</td>
                            <td>1</td>
                            <td>0</td>
                            <td>2</td>
                            <td>3</td>
                            <td>5</td>
                            <td>8</td>
                          </tr>
                          <tr>
                            <td class="w-80">2</td>
                            <td class="w-200">Sebi Scores</td>
                            <td>0</td>
                            <td>1</td>
                            <td>1</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                          </tr>
                          <tr>
                            <td class="w-80">3</td>
                            <td class="w-200">Other Sources (if Any)</td>
                            <td>0</td>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                          </tr>
                          <tr>
                            <th colspan="2">Total</th>
                            <th>10</th>
                            <th>20</th>
                            <th>12</th>
                            <th>14</th>
                            <th>11</th>
                            <th>19</th>
                          </tr>
                        </tbody>
                    </table>
                    <a href="" class="end" style="font-size: 12px;">Monthly/Annual complaints</a>
                    <span style="font-size: 12px;">*Average Resolution time is the sum total of time taken to resolve each complaint in days, in the current month divided by total Number of complaints resolved in the current month.</span>
                </div>
                <div class="col-1"></div>
            </div>
        </div> -->
        <!-- <div class="row" style="background: var(--blue-gray-100, #F1F5F9); padding-top: 50px; padding-bottom: 40px;">
            <div class="center header-text">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[11].h1 ? homePageJSONResponse[11].h1 : '')"></span>
            </div>
            <app-button [savingsText]="homePageJSONResponse[11].b1" (click)="goToDiscover('')" class="center" style="margin-top: 30px;"></app-button>
        </div> -->
        <div class="faqSection" style="display: flex; padding-bottom: 40px;">
            <div class="col-1"></div>
            <div class="col-10 background">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[11].h1 ? homePageJSONResponse[11].h1 : '')" style="font-weight: 600; font-size: 22px; font-family: 'manrope'; display: flex; justify-content: center;"></span>
                <app-button [savingsText]="homePageJSONResponse[11].b1" [backgroundColor]="'#001E5A'" (click)="goToDiscover(homePageJSONResponse[11].h1, 'beginNow')" class="center" style="margin-top: 30px;"></app-button>
            </div>
        </div>
    </div>
    <app-footer-curve></app-footer-curve>
    <app-footer style="margin-top: 60px;"></app-footer>
</div>
<!-- -->

<!-- mobile view -->
<div class="savings-wrapper" style="overflow-x: hidden;">
    <div class="row" *ngIf="this.screenType.breakpointState == 'mob' && homePageJSONResponse" style="margin-bottom: 70px;">
        <!-- <app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar> -->
        <div style="background-color: #001E5A;">
            <div class="center header-text" style="padding-top: 40px; color: #fff;">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[0].h1 ? homePageJSONResponse[0].h1 : '')"></span>
            </div>
            <div class="center subheader-text" style="color: #FFFFFF;">
                {{homePageJSONResponse[0].h2 ? homePageJSONResponse[0].h2 : ''}}
            </div>
            <div class="center buttonPosition">
            <app-button [savingsText]="homePageJSONResponse[0].b1" (click)="goToDiscover(homePageJSONResponse[0].h1, 'beginNow')"></app-button>
            </div>
            <!-- carousel to be added here -->
            <div class="carousel-container" style="justify-content: space-evenly; height: 400px;">
                <!-- Backward Icon -->
                <img [src]="startIndex === 0 ? '../../../../assets/backwardIcon_disabled.svg' : '../../../../assets/backwardIcon.svg'"
                     width="50px" style="cursor: pointer; margin-top: 50px"
                     alt="" (click)="scrollMobile(-1)" 
                     [style.pointer-events]="startIndex === 0 ? 'none' : 'auto'">
                
                <!-- Carousel Cards -->
                <div *ngFor="let item of visibleItemsMobile; let i = index" 
                     style="position: relative; height: 250px; width: 200px;" 
                     (click)="goToBaskets(item)">
                  
                     <div [ngStyle]="{
                        'position': 'relative',
                        'height': '100%',
                        'width': '100%',
                        'borderRadius': '8px',
                        'overflow': 'hidden'
                      }">
                      <img [src]="item.image_url" 
                      (load)="onMobileImageLoad(i)" 
                      (error)="onMobileImageError(i)" 
                      [class.loaded]="mobileLoadedIndexes[i]" 
                      style="width: 100%; height: 100%; object-fit: cover; max-height: 100%; border-radius: 8px; position: absolute; top: 0; left: 0;">
                      <img *ngIf="!mobileLoadedIndexes[i]" 
                         src="../../../../assets/placeholderImg.svg" 
                         alt="Loading..." 
                         style="width: 100%; height: 100%; object-fit: cover; max-height: 100%; border-radius: 8px; position: absolute; top: 0; left: 0;">
                        <div [ngStyle]="{
                          'position': 'absolute',
                          'top': '0',
                          'left': '0',
                          'right': '0',
                          'bottom': '0',
                          'background': 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(66, 66, 66, 0.3) 73.81%, #050506 100%)',
                          'pointerEvents': 'none'
                        }"></div>
                      </div>
                    
                    <div style="position: absolute; bottom: 0; left: 0; width: 100%; color: white; font-family: 'Source Sans Pro'; font-size: 15px; font-weight: 800; text-align: center; padding-bottom: 10px;">
                        {{item.title}}
                    </div>
                </div>
            
                <!-- Forward Icon -->
                <img [src]="startIndex + 1 >= categoriesList.length ? '../../../../assets/forwardIcon_disabled.svg' : '../../../../assets/forwardIcon.svg'"
                     width="50px" style="cursor: pointer; margin-top: 50px;"
                     alt="" (click)="scrollMobile(1)"
                     [style.pointer-events]="startIndex + 1 >= categoriesList.length ? 'none' : 'auto'">
            </div>
            
        </div>
        <div class="center header-text" style="margin-top: 40px;">
            <span [innerHTML]="sanitizeHtml(homePageJSONResponse[1].h1 ? homePageJSONResponse[1].h1 : '')"></span>
        </div>
        <div class="center subheader-text">
            {{homePageJSONResponse[1].h2 ? homePageJSONResponse[1].h2 : ''}}
        </div>
        <app-toolbar-mobile></app-toolbar-mobile>
        <div class="center" style="margin-top: 20px; gap: 25px;">
            <div class="col-12 center newsHeader" *ngFor="let item of homePageJSONResponse[1].content let i = index">
                <img src="../../../../assets/new_to_market.svg" alt="" *ngIf="i ==0">
                <img src="../../../../assets/start_small.svg" alt="" *ngIf="i ==1">
                <img src="../../../../assets/quick_profit_potential.svg" alt="" *ngIf="i ==2">
                <br>
              {{ item.name }}
              <br>
              <br>
              <div class="col-12 center newsText">
                <span class="center newsText" [innerHTML]="sanitizeHtml(item.detail)"></span>
              </div>
              <app-button [savingsText]="item.b1" style="margin-top: 20px;" (click)="goToDiscover(homePageJSONResponse[1]?.h1, 'beginNow')"></app-button>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 center newsText" style="justify-content: end;" *ngFor="let item of homePageJSONResponse[1].content">
              {{ item.detail }}
              <app-button [savingsText]="item.b1" style="margin-top: 20px;" (click)="goToDiscover('')"></app-button>
            </div>
        </div> -->
        <!-- <div class="row set-margin" style="margin-top: 50px;">
            <div class="col-12 center">
                <img src="../../../../assets/newsIcon.svg" alt="">
            </div>
        </div>
        <div class="row set-margin" style="margin-top: 20px;">
        <div class="col-12 center newsHeader">{{homePageJSONResponse[1].content[0].name ? homePageJSONResponse[1].content[0].name : ''}}</div>
        </div>
        <div class="row set-margin" style="margin-top: 10px;">
            <div class="col-12 center newsText" >{{homePageJSONResponse[1].content[0].detail ? homePageJSONResponse[1].content[0].detail : ''}}</div>
        </div>
        <div class="row set-margin" style="margin-top: 50px;">
            <div class="col-12 center">
                <img src="../../../../assets/newsIcon.svg" alt="">
            </div>
        </div>
        <div class="row set-margin" style="margin-top: 20px;">
          <div class="col-12 center newsHeader">{{homePageJSONResponse[1].content[1].name ? homePageJSONResponse[1].content[0].name : ''}}</div>
        </div>
        <div class="row set-margin" style="margin-top: 10px;">
            <div class="col-12 center newsText" >{{homePageJSONResponse[1].content[1].detail ? homePageJSONResponse[1].content[1].detail : ''}}</div>
        </div>
    
        <div class="row set-margin" style="margin-top: 50px;">
            <div class="col-12 center">
                <img src="../../../../assets/newsIcon.svg" alt="">
            </div>
        </div>
        <div class="row set-margin" style="margin-top: 20px;">
          <div class="col-12 center newsHeader">{{homePageJSONResponse[1].content[2].name ? homePageJSONResponse[1].content[2].name : ''}}</div>
        </div>
        <div class="row set-margin" style="margin-top: 10px;">
            <div class="col-12 center newsText" >{{homePageJSONResponse[1].content[2].detail ? homePageJSONResponse[1].content[2].detail : ''}}</div>
        </div> -->
        <div class="row wealthCreation-wrapper set-margin" style="padding: 10px;">
            <div class="col-12">
                <div class="row">
                    <div class="col-9"></div>
                    <div class="col-3"><img src="../../../../assets/stampIconMob.svg" style="max-height: 100%;" height="100px" alt=""></div>
                </div>
            </div>
            <div style="margin-bottom: 30px;">
            <div class="col-12">
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-5"><div class="highlighted-text center numberHeader">{{homePageJSONResponse[2].content[0].name ? homePageJSONResponse[2].content[0].name : ''}}</div></div>
                </div>
                <div class="center" style="color: white; padding: 10px; margin-left: 15px;">{{homePageJSONResponse[2].content[0].detail ? homePageJSONResponse[2].content[0].detail : ''}}</div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-5"><div class="highlighted-text center numberHeader">{{homePageJSONResponse[2].content[1].name ? homePageJSONResponse[2].content[1].name : ''}}</div></div>
                </div>
                <div class="center" style="color: white; padding: 10px; margin-left: 15px;">{{homePageJSONResponse[2].content[1].detail ? homePageJSONResponse[2].content[1].detail : ''}}</div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-5"><div class="highlighted-text center numberHeader">{{homePageJSONResponse[2].content[2].name ? homePageJSONResponse[2].content[2].name : ''}}</div></div>
                </div>
                <div class="center" style="color: white; padding: 10px; margin-left: 15px;">{{homePageJSONResponse[2].content[2].detail ? homePageJSONResponse[2].content[2].detail : ''}}</div>
            </div>
            <img src="../../../../assets/hrMobIcon.svg" alt="">
            <br>
            <br>
            <div class="center" style="padding-bottom: 20px;">
                <app-button [savingsText]="homePageJSONResponse[2].b1" (click)="goToDiscover(homePageJSONResponse[2].h1, 'beginNow')"></app-button>
            </div>
        </div>
        </div>
        <div class="row section-wrapper set-margin" >
            <div class="center header-text">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[3].h1 ? homePageJSONResponse[3].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[3].h2 ? homePageJSONResponse[3].h2 : ''}}
            </div>
            <br>
            <br>
            <div class="center" style="padding-top: 30px;">
                <!-- <img src="../../../../assets/portfolioIcon.svg" alt="" width="375px" height="350px"> -->
                <video [src]="portfolio_url" controls width="100%" [poster]="thumbnailUrl"
                (play)="videoClick($event)"
                (pause)="videoPaused($event)">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
        <div class="row wealthCreation-wrapper set-margin" >
            <div class="center header-text" style="padding-top: 30px;">
                <span style="color: white;" [innerHTML]="sanitizeHtml(homePageJSONResponse[4].h1 ? homePageJSONResponse[4].h1 : '')"></span>
            </div>
            <div class="center subheader-text" style="color: white;">
                {{homePageJSONResponse[4].h2 ? homePageJSONResponse[4].h2 : ''}}
            </div>
            <br>
            <br>
            <br>
            <div style="padding: 0px;">
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11 textColor"><img src="../../../../assets/tickIcon.svg" alt="">&nbsp;{{homePageJSONResponse[4].content[0].name}}
                <br>
                <span class="subText">{{homePageJSONResponse[4].content[0].detail}}</span>
                </div>
                
            </div>
            <br>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11 textColor"><img src="../../../../assets/tickIcon.svg" alt="">&nbsp;{{homePageJSONResponse[4].content[1].name}}
                <br>
                <span class="subText">{{homePageJSONResponse[4].content[1].detail}}</span>
            </div>
            </div>
            <br>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11 textColor"><img src="../../../../assets/tickIcon.svg" alt="">&nbsp;{{homePageJSONResponse[4].content[2].name}}
                    <br>
                    <span class="subText">{{homePageJSONResponse[4].content[2].detail}}</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11 textColor"><img src="../../../../assets/tickIcon.svg" alt="">&nbsp;{{homePageJSONResponse[4].content[3].name}}
                    <br>
                    <span class="subText">{{homePageJSONResponse[4].content[3].detail}}</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11 textColor"><img src="../../../../assets/tickIcon.svg" alt="">&nbsp;{{homePageJSONResponse[4].content[4].name}}
                    <br>
                    <span class="subText">{{homePageJSONResponse[4].content[4].detail}}</span>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-11 textColor"><img src="../../../../assets/tickIcon.svg" alt="">&nbsp;{{homePageJSONResponse[4].content[5].name}}
                    <br>
                    <span class="subText">{{homePageJSONResponse[4].content[5].detail}}</span>
                </div>
            </div>
        </div>
            <div class="center buttonPosition" style="padding-bottom: 20px;">
            <app-button [savingsText]="homePageJSONResponse[4].b1" (click)="goToDiscover(homePageJSONResponse[4].h1, 'beginNow')"></app-button>
            </div>
        </div>
        <!-- <div class="row section-wrapper set-margin" style="background-color: white; margin-top: 0;" >
            <div class="center header-text" style="padding-top: 30px; margin-top: 40px;">
                <span>A single platform to achieve all your <span class="highlighted-text">financial goals</span></span>
            </div>
            <div class="center subheader-text">
                Your Wealth, Our Mission.
            </div>
            <br>
            <br>
            <br>
        <div class="row" style="margin-bottom: 10px;">
                <div><span style="font-size: 14px; font-weight: 600; font-family: 'manrope';">Benchmark Comparision :</span></div>
        <div style="display: flex; justify-content: flex-start;padding-top: 14px;">
            <span style="font-size: 12px;"><img src="../../../../assets/basketCompareMark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData?.benchmark_comparison?.jar_name}}</span>
            &nbsp;
            <span style="font-size: 12px;"><img src="../../../../assets/nifty50Mark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData?.benchmark_comparison?.benchmark_name}}</span>
        </div>
        <div class="chartFilter" style="border: 1px; margin-top: 10px;">
            <div [class.selected]="selectedFilter === '1 Year'" (click)="selectFilter('1 Year')">1 Year</div>
            <div [class.selected]="selectedFilter === '3 Year'" (click)="selectFilter('3 Year')">3 Year</div>
            <div [class.selected]="selectedFilter === '5 Year'" (click)="selectFilter('5 Year')">5 Year</div>
        </div>
        </div>
        <div style="margin-top: 20px;">
            <div class="chart-container">
                <div class="chart-wrapper" [class.blurred]="!isLoggedIn">
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    style="width: 100%; height: 350px; display: block;">
                  </highcharts-chart>
                </div>
              
                <div *ngIf="!isLoggedIn" class="popup-container">
                  <div class="popup-content">
                    <img src="../../../../assets/lockedIcon.svg" alt="">
                    <p>Login to see the live performance and return</p>
                    <app-button (click)="openLogin('')" [savingsText]="'Login'"></app-button>
                  </div>
                </div>
              </div>
        </div>
        </div> -->
        <div class="row section-wrapper set-margin" >
            <div class="center header-text" style="padding-top: 30px;">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[5].h1 ? homePageJSONResponse[5].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[5].h2 ? homePageJSONResponse[5].h2 : ''}}
            </div>
            <br>
            <br>
            <br>
            <div class="row set-margin">
                <div class="col-1"></div>
                <div class="col-12">
                    <mat-card (mouseenter)="mouseHover('signup')" class="matCard-wrapper">
                        <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredSignUp}"><span class="stepCount" [ngClass]="{'hovered-bg': isHoveredSignUp}">1</span>&nbsp;&nbsp;&nbsp;&nbsp;{{homePageJSONResponse[5].content[0].name ? homePageJSONResponse[5].content[0].name : ''}}:&nbsp;</span>
                        <span class="stepsText">{{replaceBreakLine(homePageJSONResponse[5].content[0].detail ? homePageJSONResponse[5].content[0].detail : '')}}</span></mat-card-content>
                    </mat-card>
                    <br>
                    <!-- <mat-card (mouseenter)="mouseHover('KYC')" class="matCard-wrapper">
                        <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredKYC}"><span class="stepCount" [ngClass]="{'hovered-bg': isHoveredKYC}">2</span>&nbsp;&nbsp;&nbsp;&nbsp;{{homePageJSONResponse[5].content[1].name ? homePageJSONResponse[5].content[1].name : ''}}:&nbsp;</span>
                        <span class="stepsText">{{homePageJSONResponse[5].content[1].detail ? homePageJSONResponse[5].content[1].detail : ''}}</span></mat-card-content>
                    </mat-card>
                    <br> -->
                    <mat-card (mouseenter)="mouseHover('Subscribe')" class="matCard-wrapper">
                        <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredSubscribe}"><span class="stepCount" [ngClass]="{'hovered-bg': isHoveredSubscribe}">2</span>&nbsp;&nbsp;&nbsp;&nbsp;{{homePageJSONResponse[5].content[2].name ? homePageJSONResponse[5].content[2].name : ''}}:&nbsp;</span>
                        <span class="stepsText">{{replaceBreakLine(homePageJSONResponse[5].content[2].detail ? homePageJSONResponse[5].content[2].detail : '')}}</span></mat-card-content>
                    </mat-card>
                    <br>
                    <mat-card (mouseenter)="mouseHover('Invest')" class="matCard-wrapper">
                        <mat-card-content><span class="stepsHeader" [ngClass]="{'hovered': isHoveredInvest}"><span class="stepCount" [ngClass]="{'hovered-bg': isHoveredInvest}">3</span>&nbsp;&nbsp;&nbsp;&nbsp;{{homePageJSONResponse[5].content[3].name ? homePageJSONResponse[5].content[3].name : ''}}:&nbsp;</span>
                        <span class="stepsText">{{replaceBreakLine(homePageJSONResponse[5].content[3].detail ? homePageJSONResponse[5].content[3].detail : '')}}</span></mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="row section-wrapper set-margin" >
            <div class="center header-text">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[6].h1 ? homePageJSONResponse[6].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[6].h2 ? homePageJSONResponse[6].h2 : ''}}
            </div>
            <div class="testimonial-container-mob">
                <div *ngFor="let testimonial of testimonials">
                  <mat-card>
                      <mat-card-content>
                          <div class="testimonial-header">{{ testimonial.header }}</div>
                          <br>
                          <br>
                          <div style="display: flex; gap: 20px;">
                          <div>
                              <img [src]="testimonial.image_url" alt="Testimonial Image">
                          </div>
                          <div>
                              <span class="testimonial-content">{{ testimonial.content }}
                              <br>
                              <span class="testimonial-subContent">{{ testimonial.sub_content }}</span>
                              </span>
                          </div>
                          </div>
                      </mat-card-content>
                  </mat-card>
              </div>
              </div>
        </div>
        <div class="row section-wrapper set-margin" style="background-color: white;">
            <div class="center header-text" style="padding-top: 20px;">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[7].h1 ? homePageJSONResponse[7].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[7].h2 ? homePageJSONResponse[7].h2 : ''}}
            </div>
            <br>
            <br>

            <div class="mediaSection">
                <div *ngFor="let data of mediaSectionData">
                <mat-card (click)="mediaRoute(data.feature_link)">
                    <mat-card-content class="card-content">
                        <span class="logo-header">{{data.logo}}</span>
                        <br>
                        <span class="desc">
                            {{data.headline}}
                        </span>
                    </mat-card-content>
                </mat-card>
                </div>
            </div>
        </div>
        <div class="row section-wrapper set-margin">
            <div class="center header-text">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[8].h1 ? homePageJSONResponse[8].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[8].h2 ? homePageJSONResponse[8].h2 : ''}}
            </div>
            <br>
            <br>
            <div class="leadership-container">
                <div *ngFor="let leader of leaders" class="leadership-item">
                  <img [src]="'../../../../assets/leaderShipIcons/' + leader.icon" alt=""
                  (click)="redirectToLinkedIn(leader.redirectionURL)">
                  <p class="leaderShipHeader">{{ leader.name }}</p>
                  <p class="leaderShipSubHeader">{{ leader.role }}</p>
                </div>
            </div>
        </div>
        <div class="row section-wrapper set-margin" style="background-color: white; padding-bottom: 30px;">
            <div class="center header-text" style="padding-top: 20px;">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[9].h1 ? homePageJSONResponse[9].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[9].h2 ? homePageJSONResponse[9].h2 : ''}}
            </div>
            <br>
            <br>
            <!-- <div class="row faqSection set-margin" *ngFor="let data of homePageJSONResponse[9].content">
                <mat-accordion>
                    <mat-expansion-panel style="margin-bottom: 10px;">
                      <mat-expansion-panel-header style="margin-top: 10px;">
                        <mat-panel-title class="faqHeader">
                            {{data.name}}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <p class="faqSubHeader">{{data.detail}}</p>
                    </mat-expansion-panel>
                  </mat-accordion>
            </div> -->


            <div
            class="faqSection set-margin"
            style="padding-top: 20px; display: flex; flex-direction: column; width: 100%;"
            id="faqSectionID"
            *ngFor="let data of visibleData; let i = index"
            [id]="'faqSectionID' + i"
          >
            <mat-accordion>
              <mat-expansion-panel #panel (opened)="onPanelToggle(data, true)" (closed)="onPanelToggle(data, false)" style="width: 100%;">
                <mat-expansion-panel-header style="display: flex; align-items: center; width: 100%;">
                  <span class="faqHeader" style="white-space: normal; font-size: 15px;">
                    {{data.name}}
                  </span>
                  <span style="flex-grow: 1; text-align: right;">
                    <mat-icon *ngIf="!panel.expanded" style="font-size: 24px; color: #001E5A;">add</mat-icon>
                    <mat-icon *ngIf="panel.expanded" style="font-size: 24px; color: #C3966E;">remove</mat-icon>
                  </span>
                </mat-expansion-panel-header>
                <p class="faqSubHeader" style="white-space: normal; overflow-wrap: break-word; font-size: 15px;" [innerHTML]="sanitizeHtml(data.detail)"
                (click)="handleLinkClick($event)"></p>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          


            <!-- <div class="row faqSection set-margin" *ngFor="let data of visibleData let i = index" [id]="'faqSectionID' + i">
                <div class="col-1"></div>
                <div class="col-10">
                    <mat-accordion>
                        <mat-expansion-panel #panel>
                          <mat-expansion-panel-header style="padding: 10px; display: flex; justify-content: space-between; align-items: center;">
                            <span class="faqHeader">
                              {{data.name}}
                            </span>
                            <span style="flex-grow: 1; text-align: right;">
                              <mat-icon *ngIf="!panel.expanded" style="font-size: 24px;">add</mat-icon>
                              <mat-icon *ngIf="panel.expanded" style="font-size: 24px;">remove</mat-icon>
                            </span>
                          </mat-expansion-panel-header>
                          <p class="faqSubHeader" [innerHTML]="sanitizeHtml(data.detail)"></p>
                        </mat-expansion-panel>
                      </mat-accordion>
                </div>
              </div> -->

              <div class="center" *ngIf="hasMoreData || isExpanded" style="padding-top: 15px;">
                <app-button [savingsText]="buttonLabel" (click)="toggleVisibility()"></app-button>
              </div>





            <!-- <div class="row faqSection set-margin">
                <div class="col-1"></div>
                <div class="col-10 background" style="background-color: #E7E7E7; border-radius: 16px; padding: 20px; width: 100%;">
                    <span style="font-weight: 500; font-size: 18px;" class="center">{{homePageJSONResponse[9].h3 ? homePageJSONResponse[9].h3 : ''}}</span>
                    <br>
                    <span style="font-weight: 400; font-size: 15px; color: #667085;" class="center">{{homePageJSONResponse[9].h4 ? homePageJSONResponse[9].h4 : ''}}</span>
                    <br>
                    <app-button [savingsText]="homePageJSONResponse[9].b1" 
                    (click)="requestCallBack()"
                    class="center" [backgroundColor]="'#001E5A'"></app-button>
                </div>
            </div> -->
        </div>
        <div class="row set-margin faqSection" style="background-color: #F8FAFC; padding-top: 20px; gap: 30px;">
            <div class="center header-text">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[10].h1 ? homePageJSONResponse[10].h1 : '')"></span>
            </div>
            <div class="center subheader-text">
                {{homePageJSONResponse[10].h2 ? homePageJSONResponse[10].h2 : ''}}
            </div>
            <div class="row blogsSection">
                <div style="cursor: pointer;" (click)="goToBlogs(blogsData[0]?.link, blogsData[0])">
                    <div class="blogs-content">
                     <span><img src="{{blogsData[0]?.image?.src}}" width="100%" alt=""></span>
                     <br>
                     <span class="user-text">By {{blogsData[0]?.author}} • Published on {{getFormattedDate(blogsData[0]?.blog_time)}} • Reading Time 4 mins</span>
                     <span class="Header-text">{{blogsData[0]?.title}}</span>
                    <!-- <span class="subheader-text">Remember when you decided to make your first stock market investment? Going
                        through several fundamental and technical indicators, researching</span> -->
                    </div>
                </div>
            </div>
            <div class="row blogsSection">
                <div style="cursor: pointer;" (click)="goToBlogs(blogsData[1]?.link, blogsData[1])">
                    <div class="blogs-content">
                     <span><img src="{{blogsData[1]?.image?.src}}" width="100%" alt=""></span>
                     <br>
                     <span class="user-text">By {{blogsData[1]?.author}} • Published on {{getFormattedDate(blogsData[1]?.blog_time)}} • Reading Time 4 mins</span>
                     <span class="Header-text">{{blogsData[1]?.title}}</span>
                    <!-- <span class="subheader-text">Remember when you decided to make your first stock market investment? Going
                        through several fundamental and technical indicators, researching</span> -->
                    </div>
                </div>
            </div>
            <div class="row blogsSection">
                <div style="cursor: pointer;" (click)="goToBlogs(blogsData[2]?.link, blogsData[2])">
                    <div class="blogs-content">
                     <span><img src="{{blogsData[2]?.image?.src}}" width="100%" alt=""></span>
                     <br>
                     <span class="user-text">By {{blogsData[2]?.author}} • Published on {{getFormattedDate(blogsData[2]?.blog_time)}} • Reading Time 4 mins</span>
                     <span class="Header-text">{{blogsData[2]?.title}}</span>
                    <!-- <span class="subheader-text">Remember when you decided to make your first stock market investment? Going
                        through several fundamental and technical indicators, researching</span> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row set-margin" style="background-color: white; padding-top: 20px;">
            <span class="center" style="font-size: 20px; font-weight: 500;">Customer Complaints in</span>
            <div class="row set-margin" style="padding-top: 20px;">
                <div class="col-1"></div>
                <div class="col-10">
                    <div class="table-container">
                        <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">Received from</th>
                                <th scope="col">Pending at the end of the month</th>
                                <th scope="col">Received</th>
                                <th scope="col">Resolved*</th>
                                <th scope="col">Total Pending</th>
                                <th scope="col">Pending complaints > 3 months</th>
                                <th scope="col">Average Resolution time (in days)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Directly from Investors</td>
                                <td>1</td>
                                <td>0</td>
                                <td>2</td>
                                <td>3</td>
                                <td>5</td>
                                <td>8</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Sebi Scores</td>
                                <td>0</td>
                                <td>1</td>
                                <td>1</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>Other Sources (if Any)</td>
                                <td>0</td>
                                <td>1</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                              </tr>
                              <tr>
                                <th>Total</th>
                                <th></th>
                                <th>10</th>
                                <th>20</th>
                                <th>12</th>
                                <th>14</th>
                                <th>11</th>
                                <th>19</th>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                    <a href="" class="end" style="font-size: 12px;">Monthly/Annual complaints</a>
                    <span style="font-size: 12px;">*Average Resolution time is the sum total of time taken to resolve each complaint in days, in the current month divided by total Number of complaints resolved in the current month.</span>
                </div>
                <div class="col-1"></div>
            </div>
        </div> -->
        <div class="row set-margin" style="background: var(--blue-gray-100, #F1F5F9); padding-top: 50px; padding-bottom: 40px;">
            <div class="center header-text">
                <span [innerHTML]="sanitizeHtml(homePageJSONResponse[11].h1 ? homePageJSONResponse[11].h1 : '')"></span>
            </div>
            <app-button [savingsText]="homePageJSONResponse[11].b1" style="margin-top: 30px;" (click)="goToDiscover(homePageJSONResponse[11].h1, 'beginNow')" class="center"></app-button>
        </div>
        <app-footer></app-footer>
    </div>
</div>
<!-- -->