import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-pin-box',
  templateUrl: './pin-box.component.html',
  styleUrls: ['./pin-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PinBoxComponent {
  @Input() pinLength: number = 4;
  inputAppearanceType = 'fill';
  inputType = 'number';
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  dashLineArray: number[] = [];
  otpViewArray: (HTMLElement | null)[] = [];
  otpArray: string[] = [];
  @Output() otpChange: EventEmitter<string> = new EventEmitter();
  @Input() errorMessage: string = '';

  numberArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  ngOnInit(): void {
    for (let index = 0; index < this.pinLength; index++) {
      this.dashLineArray.push(index);
      this.otpArray.push('');
    }
  }

  clearFields(): void {
    this.otpArray.fill('');
    // Emit the cleared OTP value
    this.otpChange.emit('');
  }

  ngAfterViewInit(): void {
    for (let index = 0; index < this.pinLength; index++) {
      const htmlTempData = document.getElementById(`pin-box-${index}`);
      this.otpViewArray.push(htmlTempData);
    }
  }

  onKeyPressed(event: KeyboardEvent, currentIndex: number) {
    console.log('Evet : ', event);
    if (event.code == 'Backspace') {
      if (currentIndex != 0) {
        if (this.otpArray[currentIndex] == '') {
          this.otpViewArray[currentIndex - 1]?.focus();
        }
      }
    } else {
      if (this.numberArray.includes(event.key)) {
        if (currentIndex != this.dashLineArray.length - 1) {
          this.otpViewArray[currentIndex + 1]?.focus();
        }
      }
    }
  }

  onOtpChange(event: any, index: number) {
    const otpValue = event;
    if (otpValue.length === this.pinLength) {
      for (let i = 0; i < this.pinLength; i++) {
        this.otpArray[i] = otpValue[i] || '';
      }
      this.otpChange.emit(this.otpArray.join(''));
    } else {
      this.otpArray[index] = event;
      this.otpChange.emit(this.otpArray.join(''));
    }
  }
}
