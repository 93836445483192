<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>

<div class="agreement-wrapper" *ngIf="isKYCCompleted">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>
    <div class="agreement-wrapper-content">
    <div *ngIf="!pdfLoading">
    <div  *ngIf="screenType.breakpointState!='mob'" class="thumbnail_desk" (click)="openPdfModal()" >
        <pdf-viewer
          [src]="pdfSrc"
          [page]="1" 
          [zoom]="1" 
          [original-size]="false"
          [render-text]="true"
          (load-progress)="onLoadProgress($event)"
          (after-load-complete)="onLoadComplete()"
          style="width: 450px; height: 580px"
    
        ></pdf-viewer>
      </div>
    
      <div  *ngIf="screenType.breakpointState=='mob'" class="thumbnail_mob"  >
        <pdf-viewer
          [src]="pdfSrc"
          [page]="1" 
          [zoom]="1" 
          [original-size]="false"
          [render-text]="true"
          style="width: 350px; height: 500px"
        ></pdf-viewer>
      </div>
      <div class="agreement-action" *ngIf="!esignStatus">
        <app-button style="display: flex; justify-content: center;" *ngIf="signed === 'aadhaar'" class="e-sign-aadhar" [savingsText]="confirm_button_text" (click)="esignWithAadhar()"></app-button>
        <app-button style="display: flex; justify-content: center;" *ngIf="signed === 'otp'" class="e-sign-otp" [savingsText]="confirm_button_text" (click)="openOtpDialog()"></app-button>
    </div>

    <div class="agreement-action" *ngIf="esignStatus">
        <app-button style="display: flex; justify-content: center;" *ngIf="signed === 'aadhaar'" class="e-sign-aadhar" [savingsText]="confirm_button_text" (click)="downloadAndProceed()"></app-button>
        <app-button style="display: flex; justify-content: center;" *ngIf="signed === 'payment'" class="e-sign-aadhar" [savingsText]="'Continue to Payment'" (click)="continueToPayment()"></app-button>
    </div>
    </div>
    
    
    <div class="center-container" *ngIf="!isKYCCompleted">
        <div class="centered-content">
          <mat-card>
            <mat-card-content><span style="font-size: 18px;">{{kycStatusText}}</span></mat-card-content>
          </mat-card>
        </div>
    </div>
</div>
</div>