import { Component } from '@angular/core';
import { ScreenTypeService } from '../../services/screen-type.service';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss'],
})
export class PurchaseHistoryComponent {
  constructor(public screenType: ScreenTypeService) {}
  openLogin(data: string) {}
  downloadInvoice() {}
}
