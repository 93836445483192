export enum SocketType {
  'ping' = 'ping',
  'serverResponse' = 'Socket-Response',
  'notification' = 'notification',
  'portfolio' = 'portfolio-nudge',
  'self_kyc_response' = 'Self-KYC',
}
export class SelectData {
  key!: string;
  value!: string;
  group?: string;
  displayValue!: string;
  description?: string;
  detailsInfo?: any;
  allowed?: any;
  disabled: boolean = false;
  selected: boolean = false;
}

export enum AppearanceType {
  fill = 'fill',
  outline = 'outline',
}
