import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { LoginComponent } from '../auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../../services/api-service.service';
import { ScreenTypeService } from '../../services/screen-type.service';
import { UserService } from '../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { OnboardingService } from '../../services/onboarding.service';
import { environment } from 'src/environments/environment';
import { MoengageService } from '../../services/moengage.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.scss'],
})
export class WatchlistComponent {

  selectedFilter: string = '3 Year';
  usernameToolBar: boolean = false;
  userName: any;
  watchList: any[] = [];
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  isLoggedIn: boolean = false;
  tokenData: any;
  spinner: boolean = false;
  emptyState: boolean = false;
  closeLogin: boolean = true;
  isDataLoaded: boolean = false;
  currentTime: number = 0;
  private eventTriggered: boolean = false;
  isInitialLoad: boolean = true;
  currentVideo: HTMLVideoElement | null = null;

  constructor(
    public screenType: ScreenTypeService,
    private apiService: ApiServiceService,
    private _toastSerivce: ToastrService,
    public dialog: MatDialog,
    private activated_route: ActivatedRoute,
    private route: Router,
    private user_service: UserService,
    private sanitizer: DomSanitizer,
    private onboardingService: OnboardingService,
    private moengageService: MoengageService,
  ) {

  }

  scrollTop() {
    scroll(0,0);
  }

  setCurrentTime(event: any, data: any) {
    this.currentTime = event.target.currentTime;
    if(!this.eventTriggered && this.currentTime >= 10) {
      let eventData = {
        'BasketName' : data.sub_product_name,
        'VideoUrl' : data.videoBlobUrl,
        'WatchedDuration': Math.round(this.currentTime),
        'VideoThumbnailUrl' : data.image_url,
        'ActiveSubscription' : data.is_subscribed
        }
        this.moengageService.videoWatched(eventData);
        this.eventTriggered = true;
    }
  }

  videoClick(event: any, data: any) {
    const videoElement = event.target as HTMLVideoElement;
    if (this.currentVideo && this.currentVideo !== videoElement) {
      this.currentVideo.pause();
    }
    this.currentVideo = videoElement;
    const time = Math.round(event.target.currentTime);
    if(time == 0) {
    let eventData = {
      'CategoryName': localStorage.getItem('categoryNameMoe') || '',
      'BasketName' : data.sub_product_name,
      'VideoUrl' : data.videoBlobUrl,
      'VideoThumbnailUrl' : data.image_url,
      'ActiveSubscription' : data.is_subscribed,
      'BasketRisk': data?.pb_details?.risk_level,
      'MinInvestmentAmount': data?.pb_details?.min_investment_amount,
      'ReturnsDuration': data?.investment_horizon,
      'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
      'Returns': data?.pb_details?.cagr,
      }
        this.moengageService.basketVideoPlay(eventData);
    }
  }

  videoPaused(event: any, data: any) {
    const videoElement = event.target as HTMLVideoElement;
    if (this.currentVideo === videoElement) {
      this.currentVideo = null;
    }
    const watchedDuration = Math.round(event.target.currentTime);
    if(watchedDuration > 10) {
      let eventData = {
        'CategoryName': localStorage.getItem('categoryNameMoe') || '',
        'BasketName' : data.sub_product_name,
        'VideoUrl' : data.videoBlobUrl,
        'VideoThumbnailUrl' : data.image_url,
        'ActiveSubscription' : data.is_subscribed,
        'WatchedDuration': Math.round(event.target.currentTime),
        'BasketRisk': data?.pb_details?.risk_level,
        'MinInvestmentAmount': data?.pb_details?.min_investment_amount,
        'ReturnsDuration': data?.investment_horizon,
        'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
        'Returns': data?.pb_details?.cagr,
        }
      this.moengageService.videoWatched(eventData);
    }
  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if (token && data.customer_meta_info.is_registered) {
      localStorage.setItem('categoryNameMoe', 'watchlist');
      this.tokenData = data;
      console.log('ngOnInit token data', data);
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
      this.openLogin('');
    }
    this.getWatchlistData();
  }

  callMoeEvent(count: any) {
    let activeSubscription = localStorage.getItem('ActiveSubscription') || '';
    let moeEventData = {
      'eventType': 'WatchlistClicked',
      'Phone': this.tokenData.mobile_number,
      'Name': this.tokenData.full_name,
      'Email': this.tokenData.email_address,
      'createdOn': new Date(),
      'count': count,
      'Active_Subscription': JSON.parse(activeSubscription)
    }
    this.moengageService.tabsClicked(moeEventData);
  }

  getWatchlistData() {
    this.apiService.getWatchListData(localStorage.getItem('authToken')).subscribe((res: any) => {
      console.log(res);
      if(res.success) {
        if(this.tokenData) {
          this.callMoeEvent(res.data.length);
        }
        // const isWatchlistClicked = localStorage.getItem('watchlistClick') || '';
        // if (!JSON.parse(isWatchlistClicked)) {
        //   this.callMoeEvent(res.data.length);
        //   localStorage.setItem('watchlistClick', 'true');
        // }
        this.isDataLoaded = true;
        if(res.data.length == 0) {
          // this._toastSerivce.error('No watchlist found!');
          this.emptyState = true;
        }
        else {
          this.emptyState = false;
          this.watchList = res.data;

          for(let data of this.watchList) {
            data.videoBlobUrl = `${environment.apiUrlFiles}/file/${data.video_url}`;
            data.image_url = `${environment.apiUrlFiles}/file/${data.image_url}`;
          }

          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].pb_details) {
            } else {
                this.chartOptions = {};
            }
        }
        }
        
      }
      else {
        if(this.isLoggedIn) {
          this._toastSerivce.error('Failed to load watchlist details!');
        }
      }
    })
  }

  removeWatchList(data: any) {
    this.apiService.removeWatchList(data.watch_id).subscribe((res: any) => {
      if(res && res.success == true) {
      this._toastSerivce.success('Removed from Watchlist');
      let eventData = {
        'BasketName' : data?.sub_product_name,
        'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
        'VideoThumbnailUrl': data?.image_url,
        'VideoUrl': data?.videoBlobUrl,
        'CategoryName' : localStorage.getItem('categoryNameMoe') || '',
        'BasketId' : data?.sub_product_id,
        'MinInvestmentAmount' : data?.pb_details?.min_investment_amount,
        'BasketRisk' : data?.pb_details?.risk_level,
        'Returns' : data?.pb_details?.cagr ?? '0',
        'ReturnsDuration' : data?.investment_horizon
      }
      this.moengageService.watchlistRemoved(eventData);
      this.getWatchlistData();
      }
      else {
        this._toastSerivce.error('Failed to remove from Wachlist');
      }
    })
  }

  onPanelToggle(data: any, isExpanded: boolean) {
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
      return;
    }

    if (isExpanded) {
      let eventData = {
        'BasketName': data?.sub_product_name,
        'CategoryName': localStorage.getItem('categoryNameMoe') || '',
        'BasketOverview': data?.product_description,
        'BasketRisk': data?.pb_details?.risk_level,
        'MinInvestmentAmount': data?.pb_details?.min_investment_amount,
        'ReturnsDuration': data?.pb_details?.investment_horizon,
        'BasketPrice': data?.fees_details[0]?.details?.advisory_fees,
        'Returns': data?.pb_details?.cagr
      };
      this.moengageService.buttonClickedEvents(eventData);
    }
  }

  subscribeBasket(data: any, index: any) {
    console.log('get basket details .......',index, data);
    console.log(this.tokenData);
    if(this.tokenData) {
      //call create onboarding API
    this.spinner = true;
    //call moengage subscribeNow event
    let eventDetails = {
      'basketName': data?.sub_product_name,
      'categoryName': 'Watchlist',
      'basketId': data?.category_id,
      'minInvestmentAmount': data?.pb_details?.min_investment_amount,
      'returns': data?.pb_details?.cagr || 0,
      'returnsDuration': data?.investment_horizon,
      'basketrisk': data?.pb_details?.risk_level,
      'basketprice': data?.fees_details[0]?.details?.advisory_fees
    }
    this.moengageService.subscribeNowEvent(eventDetails);
    //
    let payload = {
      "lead_id": this.tokenData.lead_id,
      "sub_product_id": data.sub_product_id,
      "country_id": this.tokenData.country_id === null ? 0 : this.tokenData.country_id,
      "state_id": this.tokenData.state_id === null ? 0 : this.tokenData.state_id,
      "complimentary_period": 0,
      "fees_details": data.fees_details[0],
      "first_year_amount": data.fees_details[0].details.max_yearly_amount,
      "advisory_discount_amount": 0,
      "immediate_advisory_payment_amount": data.fees_details[0].details.advisory_fees,
      "advisory_installment_option": data.advisory_installment_option[0],
      "advisory_number_of_installments": data.advisory_installment_option.length,
      "advisory_is_emandate": false,
      "advisory_emandate_date": moment().format('YYYY-MM-DD'),
      "advisory_emandate_discount": 0,
      "email_contact_detail_id": this.tokenData.email_contact_detail_id,
      "whatsapp_contact_detail_id": this.tokenData.mobile_contact_detail_id,
      "sms_contact_detail_id": this.tokenData.mobile_contact_detail_id
    }
    let authToken = localStorage.getItem('authToken');
    this.onboardingService.createOnboarding(payload, authToken).subscribe((res: any) => {
      this.spinner = false;
      console.log('lead creation api res..', res);
      if(res && res.success && Object.keys(res.data).length != 0) {
          const parts = res.data.url.split('/');
          const id = parts[parts.length - 1];
          this.user_service.pushHashKey(id);
  
          // call redis api to save data
          let payload = {
            id: this.user_service.getHashKey(),
            data: {
              categoryId: data.category_id,
              subProductId: data.sub_product_id,
              onboardingId: res.data.onboarding_id,
              productCode: data.sub_product_code,
            }
          }
          this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
            console.log('got redis es..',res);
          })
          //
  
          this.route.navigate(['pan', id]);
      }
      else {
        this._toastSerivce.error('Unable to fetch onboarding details, please try again!');
      }
    },
    (err) => {
      this.spinner = false;
    }
    );
    //
    }
    else {
    this.openLogin('');
    }
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'watchlist',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: false,
        width: isMobileView ? '100%' : '100%',
        data: {
          closeLogin: true
        }
      });
    }
  }

}
