import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/core/services/api-service.service';
import { UserService } from 'src/app/core/services/user.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { OnboardingService } from 'src/app/core/services/onboarding.service';
import { ToastrService } from 'ngx-toastr';
import { StepsManagerService } from 'src/app/core/services/steps-manager.service';
@Component({
  selector: 'app-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
})

export class PlanSelectionComponent implements OnInit {
  showBorder: number = 1;
  getAuthToken: any;
  tokenData: any;
  categoryId: any;
  subProductId: any;
  onboardingId: any;
  feeDetails: any;
  spinner: boolean = false;

toggleBorder(cardNumber: number) {
    this.showBorder = cardNumber;
}
  constructor(
    private onboardingService: OnboardingService,
    public dialogRef: MatDialogRef<PlanSelectionComponent>,
    private route: Router,
    private user_service: UserService,
    private apiService: ApiServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // @Inject(MAT_DIALOG_DATA) public productName: any,
    // @Inject(MAT_DIALOG_DATA) public productCode: any,
    private _toastSerivce: ToastrService,
    private steps_service: StepsManagerService,
  ) {
  }
  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if (token) {
      // const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      // const data = JSON.parse(jsonString);
      // this.tokenData = data;


      this.apiService.getTokenData().subscribe((res: any) => {
          console.log('skjshjshs', res);
          if(res && res.message == 'success') {
            if (res?.result?.customer_meta_info?.is_registered) {
              // this.customer_Id = res.data?.customer_id;
              this.tokenData = res?.result;
            }
          }
      })
      this.feeDetails = this.data.fees_details;
    }
    this.getRedisData();
  }

  getRedisData() {
    this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
      console.log('got redi data', res);
     if(res && res.message == 'success') {
     this.categoryId = res?.result?.categoryId;
     this.subProductId = res?.result?.subProductId;
     this.onboardingId = res?.result?.onboardingId;
     }
    })
   }

   saveDataInRedis(plan: any) {
    //calling all basket list api to get sub_product_code
    this.apiService.getAllBasketData().subscribe((res: any) => {
      console.log(res);
      if(res.message == 'success') {
        for(let data of res?.result) {
        if(data.sub_product_name == this.data.productName) {
        this.subProductId = data.sub_product_id;
        }
        }
      // call redis api to save data
    let payload = {
      id: this.user_service.getHashKey(),
      data: {
        categoryId: this.categoryId,
        subProductId: this.subProductId,
        onboardingId: this.onboardingId,
        productCode: this.data.productCode,
      }
    }
    this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
      console.log('got redis es..',res);
      this.updateOnboarding(plan);

    })
    //
      }
    })
    //
   }

   updateOnboarding(plan: any) {
    this.spinner = true;
    this.apiService.getAllBasketData().subscribe((res: any) => {
      console.log('get basket details......', res.result);
      if(res.message == 'success') {
        for(let data of res?.result) {
          if(this.subProductId == data.sub_product_id) {
            const feeDetails = plan === 'plan-6' ? data.fees_details[0] : data.fees_details[0];
            const first_year_amount = plan === 'plan-6' ? data.fees_details[0].details.max_yearly_amount : data.fees_details[0].details.max_yearly_amount;
            const immediate_advisory_payment_amount = plan === 'plan-6' ? data.fees_details[0].details.advisory_fees : data.fees_details[0].details.advisory_fees;
            const advisory_installment_option = plan === 'plan-6' ? data.advisory_installment_option[0] : data.advisory_installment_option[0];
            console.log(feeDetails);
            let payload = {
              "lead_id": this.tokenData.lead_id,
              "sub_product_id": this.subProductId,
              "country_id": this.tokenData.country_id === null ? 0 : this.tokenData.country_id,
              "state_id": this.tokenData.state_id === null ? 0 : this.tokenData.state_id,
              "complimentary_period": 0,
              "fees_details": feeDetails,
              "first_year_amount": first_year_amount,
              "advisory_discount_amount": 0,
              "immediate_advisory_payment_amount": immediate_advisory_payment_amount,
              "advisory_installment_option": advisory_installment_option,
              "advisory_number_of_installments": res?.result[0]?.advisory_installment_option.length,
              "advisory_is_emandate": false,
              "advisory_emandate_date": moment().format('YYYY-MM-DD'),
              "advisory_emandate_discount": 0,
              "email_contact_detail_id": this.tokenData.email_contact_detail_id,
              "whatsapp_contact_detail_id": this.tokenData.mobile_contact_detail_id,
              "sms_contact_detail_id": this.tokenData.mobile_contact_detail_id
            }
              this.onboardingService.updateOnboarding(payload, this.onboardingId, localStorage.getItem('authToken')).subscribe((res: any) => {
                console.log('product updated', res);
                if(res.success) {
                  this.onboardingService.updateSuitability(localStorage.getItem('hashKey')).subscribe((resRiskProfile: any) => {
                    if(resRiskProfile && resRiskProfile.message == 'success') {
                      // this.route.navigate(['bank_account', this.user_service.getHashKey()]);
                      this.spinner = false;
                      this.dialogRef.close();
                      this.steps_service.initializeAndNavigate();
                    }
                  });
                }
                else {
                  this._toastSerivce.error(res.data.error.message);
                  this.spinner = false;
                }
              })
          }
        }
      }
    })
   }

  planSelection(plan: any) {
    this.saveDataInRedis(plan);
  }
}
