<div class="pin-box-field-wrapper">
    <div class="pin-box-wrapper">
      <mat-form-field [appearance]="'outline'" class="pin-box" *ngFor="let item of dashLineArray; let i = index">
          <input matInput [type]="'number'" step="0.01" style="text-align: center; border: none; font-size: 18px; font-weight: 700;" [id]="'pin-box-' + i" [readonly]="readonly" [disabled]="disabled || loading"
          (keyup)="onKeyPressed($event, i)" [(ngModel)]="otpArray[i]" (keypress)="otpArray[i].length <  1" 
          (ngModelChange)="onOtpChange($event, i)">
      </mat-form-field>
    <fa-icon class="pin-box-loading" *ngIf="loading" [icon]="['fas', 'spinner']" animation="spin-pulse"></fa-icon>
  </div>
  <span *ngIf="" class="error-message">{{errorMessage}}</span>
</div>