import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
  @Input() labelType: 'title' | 'subtitle' | 'message' | 'normal' = 'normal';
  @Input() text: string = '';
  @Input() class: string = '';
  @Input() required: boolean = false;
  @Input() id: string = '';
  @Input() isSpaceRequired: boolean = false;
}
