import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmallCaseIntegrationService {

  constructor(private http: HttpClient) {}

  getAuthToken(reqData: any) {
    return this.http.get(
      `${environment.smallcase_api_url}v1/auth-token?user_medium_src=${reqData.user_medium_src}&user_medium_id=${reqData.user_medium_id}`
    );
  }

  getTransactionDetails(reqData: any, token: any) {
    const headers = new HttpHeaders({
      'x-sc-gateway-authtoken': token
    });
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/transaction/securities`,
      reqData,
      { headers: headers }
    );
  }

  registerToken(reqData: any) {
    return this.http.post(
      `${environment.smallcase_api_url}v1/register-auth-id`,
      reqData,
    );
  }

  userCancelService(reqData: any) {
    const headers = new HttpHeaders({
      'x-sc-gateway-authtoken': reqData.token
    });
  return this.http.delete(
    `${environment.smallcase_api_url}v1/transaction/securities/${reqData.txnID}`,
    { headers: headers }
  )
  }
}
