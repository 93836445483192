import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OnboardingService } from '../../services/onboarding.service';
import { ScreenTypeService } from '../../services/screen-type.service';
import { UserService } from '../../services/user.service';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../auth/login/login.component';
import { WebSocketService } from '../../services/web-socket.service';
import { SocketType } from '../../models/types';
import { retryWhen, delay, take, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StepsManagerService } from '../../services/steps-manager.service';
import { ApiServiceService } from '../../services/api-service.service';
import { MoengageService } from '../../services/moengage.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent implements OnInit {
  isKYCCompleted: boolean = true;
  kycStatusText: string =
    'Your KYC process is currently underway. Please wait...';
  signed: string = 'aadhaar';
  pdfSrc: any;
  onboarding_detail_id: any;
  isLoading: boolean = true;
  spinner: boolean = false;
  confirm_button_text: string = 'eSign with Aadhaar';
  esignStatus: boolean = false;
  usernameToolBar: any;
  getAuthToken: string | undefined;
  userName: any;
  agreementRequestId: any;
  paymentUrl: any;
  spinnerText: string = '';
  pdfLoading: boolean = true;
  riskType: any;

  constructor(
    private socket_service: WebSocketService,
    public screenType: ScreenTypeService,
    private route: Router,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    private router: ActivatedRoute,
    private onboardingService: OnboardingService,
    private user_service: UserService,
    private http: HttpClient,
    private steps_service: StepsManagerService,
    private apiService: ApiServiceService,
    private moengageService: MoengageService,
  ) {

  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);

      this.apiService.getTokenData().subscribe((res: any) => {
        if(res && res.message == 'success') {
            if(res.result?.is_other_product_active) {
              const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
                height: 'auto',
                disableClose: true,
                autoFocus: false,
                backdropClass: 'backdropBackground',
                width: 'auto',
              });
            }
            else if (res.result?.customer_meta_info?.is_registered) {
              console.log('ngOnInit token data', data);
              const name = data?.name || data?.full_name || '';
              this.usernameToolBar = true;
              this.userName = name;
            }
            else {
              this.openLogin('');
            }
        }
      })
    }
    this.getJSONData();
    this.getKYCState();
    //check status for digio
    this.checkStatus();
    this.checkContinueToPayment();
    //calling socket service

    this.socket_service
      .listen(SocketType.serverResponse)
      .subscribe((res: any) => {
        console.log('receieved response from agreement socket', res);
        this.isKYCCompleted = true;
      });

    //
  }

  checkContinueToPayment() {
    let payload = {
      "parameters": {
          "signed": "aadhaar"
      },
      "step_id": 30,
      "onboarding_detail_id": this.user_service.getOboardingDetailID(),
      "onboarding_id": this.user_service.getHashKey()
  }
    this.onboardingService.getPaymentStatus(payload).subscribe((res: any) => {
      console.log('get payment....', res);
      if(res && res.message == 'success') {
       if(res.data.agreement[0].completed) {
        this.signed = 'payment';
       }
      }
    })
  }

  continueToPayment() {
  this.steps_service.initializeAndNavigate();
  // this.route.navigate(['payment', paymentId]);

  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'agreement',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

  checkStatus() {
    this.onboardingService
      .checkAgreementStatus(this.user_service.getHashKey())
      .subscribe((responseData: any) => {
        if (
          responseData.message == 'success' &&
          responseData.data.status == 'completed'
        ) {
          this.confirm_button_text = 'Download and Proceed';
          this.esignStatus = true;
        } else if (
          responseData.message == 'success' &&
          responseData.data.status == 'requested'
        ) {
          this.confirm_button_text = 'Check Status';
        }
      });
  }

  getPDF(id: any) {
    this.spinner = true;
    this.onboardingService
      .getPDFService(id)
      .subscribe((res: any) => {
        if (res && res.message == 'success') {
          this.isLoading = false;
          this.pdfSrc = res.data.s3SignedPdfLink;
          this.checkPdfAvailability();
        }
      },
    (err: any) => {
      this._toastrService.error('something went wrong');
      this.spinner = false;
    }
    );
  }

  checkPdfAvailability(): void {
    this.http
      .head(this.pdfSrc, { observe: 'response' })
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            // Retry with a delay
            delay(400),
            // Retry only a specified number of times
            take(80),
            // If still failing, return an observable with an error
            catchError((err) => {
              this._toastrService.error(
                'Agreement generation failed. Kindly contact.'
              );
              this.pdfLoading = false;
              return of(null);
            })
          )
        )
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.pdfLoading = false;
            this.pdfSrc = this.pdfSrc; // Assign the URL to the PDF viewer
            this.spinner = false;
          }
        },
        (err: any) => {
          this.pdfLoading = false;
          this._toastrService.error('Agreement generation failed. Kindly contact.');
        }
      );
  }

    getJSONData() {
      this.onboardingService
        .getOnboardingData(this.user_service.getHashKey())
        .subscribe((res: any) => {
          if(res && res.message == 'success') {
          let riskScore = res?.data?.suitability_assesment[0]?.selected_product?.riskScore;
          this.riskType = riskScore >= 15 && riskScore <= 24 ? 'Low' :
               riskScore >= 25 && riskScore <= 34 ? 'Medium' : 
               riskScore >= 35 ? 'High' : '';
          this.paymentUrl = res.data.payment_url;
          // this.user_service.pushPaymentURL(this.paymentUrl.split('payment/')[1]);
          this.onboarding_detail_id = res.data.onboarding_detail_id;
          this.getPDF(this.onboarding_detail_id);
          if(res.data.agreement[0].parameters[0].signed == 'aadhaar' || res.data.agreement[0].parameters[0].signed == 'aadhar') {
            this.confirm_button_text = 'eSign with Aadhaar';
            this.signed = 'aadhaar';
          }
          else if(res.data.agreement[0].parameters[0].signed == 'otp') {
            this.confirm_button_text = 'eSign with OTP';
            this.signed = 'otp';
          }
          }
        })
    }


  getKYCState() {
    this.onboardingService
        .getKYCStatus(this.user_service.getHashKey())
        .subscribe({
          next: (res: any) => {
            if (res && res.message == 'success') {
              if (res.data && res.data.kyc == 'done') {
                console.log('KYC is done');
                this.isKYCCompleted = true;
              } else {
                this._toastrService.warning(
                  'Your KYC process is currently underway. Please try again later by clicking below link'
                );
                this.isKYCCompleted = false;
              }
            }
          },
          error: (err) => {
            console.error('error while getting KYC status : ', err);
          },
        });
  }

  onLoadProgress(progressData: any) {
    this.isLoading = progressData.loaded < progressData.total;
  }
  onLoadComplete() {
    this.isLoading = false; // PDF has finished loading
  }

  esignWithAadhar() {
  if(this.isLoading) {
  this._toastrService.error('PDF not generated yet');
  }
  else {
    if(environment.digioEnabled) {
      let reqData = {
        callbackUrl: `${environment.website_url}agreement/${localStorage.getItem('hashKey')}?type=agreement`,
        onboarding_id: this.user_service.getHashKey(),
        productService: 'MULTIPLYRR'
      }
     this.spinner = true;
     this.spinnerText = '';
     this.onboardingService.uploadAgreementDigio(reqData, this.user_service.getOboardingDetailID()).subscribe((res: any) => {
      this.spinner = false;
     if(res && res.message == 'success') {
      const encodeCustomUrl = (url: any) => {
        return url.replace(/[/:?#=&]/g, function (match: any) {
          return '%' + match.charCodeAt(0).toString(16).toUpperCase();
        });
      };
      let callbackURL = `${environment.onboarding_url}onboarding/${this.user_service.getHashKey()}?type=agreement`;
      callbackURL = encodeCustomUrl(callbackURL);
      console.log(res.data.redirectionUrl);
      let url = res.data.redirectionUrl
      const urlParams = new URLSearchParams(url);
      const redirectUrl = urlParams.get('redirect_url');
      const updatedURL = url.replace(redirectUrl, callbackURL);
      window.location.href = updatedURL;       
      
     }
     })
    }
  }
  }

  openOtpDialog() {

  }

  downloadAndProceed() {
   this.spinner = true;
   // call moe event
   let eventData = this.onboardingService.getMoeEventDetails();
   let eventMoeData = {
   'onboardingId': eventData?.onboardingId,
   'plan': eventData?.plan,
   'basketPrice': eventData?.basketPrice,
   'basketRisk': eventData?.basketRisk,
   'status': eventData?.status,
   'userRisk': this.riskType,
   'subStatus': 'Agreement Signed',
   'basketName': eventData?.basketName,
   }
  this.moengageService.onboardingEvent(eventMoeData);
  //
   this.onboardingService.downloadAgreementDigio(this.user_service.getHashKey()).subscribe((res: any) => {
    console.log(res);
    this.spinner = false;
    if(res && res.message == 'success') {
      window.open(res.data.signedUrl);
      this.checkContinueToPayment();
      this.confirm_button_text = 'Continue to Payment';
    }
   },
  (err) => {
    this.spinner = false;
  })
  }

  openPdfModal() {
      this.dialog.open(PdfViewerComponent, {
        data: {
          pdfSrc: this.pdfSrc,
          page: 1,
          zoom: 1,
        },
        width: '90%',
        height: '90%',
      });
  }

}
