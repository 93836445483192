import { Injectable, OnInit } from '@angular/core';
declare let Moengage: any;
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class MoengageService {
  customer_Id: any;
  activeSubscription = localStorage.getItem('ActiveSubscription') || '';

  constructor(
    private apiService: ApiServiceService
  ) {
    this.getCustomerId();
    if(this.activeSubscription) {
      this.activeSubscription = JSON.parse(this.activeSubscription);
    }
  }

  getCustomerId() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);

      this.apiService.getTokenData().subscribe((res: any) => {
        if(res && res.success) {
          console.log('skjshjshs', res);
          if(res && res.success) {
            if (res.data?.customer_meta_info?.is_registered) {
              this.customer_Id = res?.data?.customer_id;
            }
          }
        }
      })
    }
  }

  createLoginEvent(customerObject: any) {
    Moengage.add_unique_user_id(customerObject?.contact_id).then(() => {
      Moengage.add_first_name(customerObject.fullName);
      Moengage.add_email(customerObject.emailID);
      Moengage.add_mobile(customerObject.mobileNumber);
  })
  }

  categoryClick(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event("CategoryClick", {
        "CategoryName": eventDetails.categoryName,
        "CategoryImage": eventDetails.categoryImage,
        "CategoryOverview": eventDetails.categoryOverview,
        "ActiveSubscription":eventDetails.activeSubscription,
        "BasketNames":eventDetails.basketNames
    });
    });
  }

  appDownload(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event("AppDownloadButtonClicked", {
        "ActiveSubscription": eventDetails.Active_Subscription,
        "PageName": eventDetails.Page_URL,
        "DownloadAppButton": eventDetails.downloadAppButton
      });
    });
  }

  beiginNowClick(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event("BeginNowClicked", {
        "ActiveSubscription": eventDetails.Active_Subscription,
        "PageName": eventDetails.Page_URL
      });
    });
  }

  blogClick(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event("BlogClick", {
        "BlogTitle": eventDetails.blogTitle,
        "BlogImage": eventDetails.blogImage,
        "ActiveSubscription": eventDetails.isSubscribed,
        "BlogURL": eventDetails.blogURL
      });
    });
  }

  basketVideoPlay(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('BasketVideoPlay',
      eventDetails
      )
    });
  }

  videoWatched(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('BasketVideoWatched',
        eventDetails
      )
    });
  }

  videoWatchedHome(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('WhoAreWeVideoWatched', {
        'BasketName' : eventDetails.basketName,
        'VideoUrl' : eventDetails.videoUrl,
        'WatchedDuration': eventDetails.watchedDuration,
        'VideoThumbnailUrl' : eventDetails.videoThumbnailUrl,
        'ActiveSubscription' : eventDetails.isSubscribed
       })
    });
  }

  basketVideoPlayHomePage(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('WhoAreWeVideoPlay', {
        'VideoUrl' : eventDetails.videoUrl,
        'VideoThumbnailUrl' : eventDetails.videoThumbnailUrl,
        'ActiveSubscription' : eventDetails.isSubscribed
       })
    });
   }
 
   videoWatchedHomePage(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('WhoAreWeVideoWatched', {
        'VideoUrl' : eventDetails.videoUrl,
        'WatchedDuration': eventDetails.watchedDuration,
        'VideoThumbnailUrl' : eventDetails.videoThumbnailUrl,
        'ActiveSubscription' : eventDetails.isSubscribed
       })
    });
   }

  watchlistAdded(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('WatchlistAdded',
      eventDetails
      )
    });
  }

  watchlistRemoved(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('WatchlistRemoved',
      eventDetails
      )
    });
  }

  viewedFAQs(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('ViewedFAQs', {
        'FaqQuestion' : eventDetails.faqQuestion,
        'IsExpanded' : eventDetails.isExpanded,
        'ActiveSubscription': this.activeSubscription
       })
    });
  }

  reportDownload(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('ReportDownload', {
        'StockName' : eventDetails.stockName,
        'BasketName' : eventDetails.basketName
       })
    });
  }

  investmentAmountChange(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('InvestmentAmountChange', {
        'BasketName' : eventDetails.basketName,
        'MinInvestmentAmount' : eventDetails.minInvestmentAmount,
        'NewInvestmentAmount' : eventDetails.newInvestmentAmount,
        'NumberOfStocks' : eventDetails.numberOfStocks
       })
    });
  }

  subscribeNowEvent(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('SubscribeNow', {
        'BasketName': eventDetails.basketName,
        'CategoryName': eventDetails.categoryName,
        'MinInvestmentAmount': eventDetails.minInvestmentAmount,
        'Returns': eventDetails.returns + ' CAGR',
        'ReturnsDuration': eventDetails.returnsDuration,
        'Basketrisk': eventDetails.basketrisk,
        'Basketprice': eventDetails.basketprice,
        'ActiveSubscription': this.activeSubscription
       })
    });
  }

  footerEvent(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('FooterClicked', {
        'CtaName': eventDetails.CTA_Name,
        'PageURL': eventDetails.PageURL,
        'ActiveSubscription': eventDetails.Active_Subscription
       })
    });
  }

  onboardingEvent(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      const eventData: { [key: string]: any } = {
        'OnboardingId': eventDetails.onboardingId,
        'Plan': eventDetails.plan,
        'BasketPrice': eventDetails.basketPrice,
        'BasketRisk': eventDetails.basketRisk,
        'Status': eventDetails.status,
        'SubStatus': eventDetails.subStatus,
        'BasketName': eventDetails.basketName,
        'CategoryName': localStorage.getItem('categoryNameMoe')
      };
    
      if (eventDetails.subStatus == 'Agreement Signed') {
        eventData['UserRisk'] = eventDetails.userRisk;
      }
    
      Moengage.track_event('Onboarding', eventData);
    });
  }

  tabsClicked(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      const eventData: { [key: string]: any } = {
        'Phone': eventDetails.Phone,
        'Name': eventDetails.Name,
        'Email': eventDetails.Email,
        // 'createdOn': eventDetails.createdOn,
        'ActiveSubscription': eventDetails.Active_Subscription
      };
    
      if (eventDetails.eventType == 'WatchlistClicked') {
        eventData['NoOfVideosWatchlisted'] = eventDetails.count;
      }
    
      Moengage.track_event(eventDetails.eventType, eventData);
    });
  }

  dashboardEvents(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event(eventDetails.eventType, eventDetails)
    });
  }

  buttonClickedEvents(eventDetails: any) {
    Moengage.add_unique_user_id(this.customer_Id).then(() => {
      Moengage.track_event('BasketClicked', eventDetails)
    });
  }
}
