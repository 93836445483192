<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>

<div>
    <div class="bank-details" [ngStyle]="{ 'padding': (screenType.breakpointState === 'mob' ? '40px 20px' : '40px 70px') }">
        <div class="overlay" *ngIf="spinner">
            <div class="spinner">
              <img src="../../../../assets/loader.svg" alt="" class="loader">
            </div>
        </div>
        <div class="bank-wrapper" [ngStyle]="{ 'margin-top': (screenType.breakpointState === 'mob' ? '0' : '60px') }">
            <mat-card style="padding: 60px;">
                <mat-card-content>
                    <div class="plan">
                        <div class="bankIcon">
                            <img src="../../../../assets/bankDetails.svg" alt="">
                        </div>
                        <div class="plan-title">Bank Details</div>
                        <!-- <span class="h-line"></span> <span class="page-order">1/13</span> -->
                        <div class="plan-desc">These details will be used for Penny Drop Mechanism</div>
                        <img src="../../../../assets/hrDarkMobIcon.svg" alt="">
                    </div>
                    <div style="margin-top: 30px; display: flex; flex-direction: column; align-items: center;">
                        <div class="label">
                            Bank Account Number
                        </div>
                        <div class="d-flex align-item-center">
                            <div class="input-value mt-2 increase-length">
                                <app-input
                                    [placeholder]="'eg.4967850001200'"
                                    [width]="'300px'"
                                    [autofocus]="true"
                                    [type]="'number'"
                                    [name]="'bankNumber'"
                                    [required]="true"
                                    [(ngModel)]="bankAccNo"
                                    (mouseleave)="onMouseLeaveBank()"
                                    [errorMessage]="error_Message_bank"
                                ></app-input>
                            </div>
                        </div>
                        <div class="label">
                            IFSC Code
                        </div>
                        <div class="d-flex align-item-center">
                            <div class="input-value mt-2">
                                <app-input
                                    [placeholder]="'eg.IDIB000K023'"
                                    [width]="'300px'"
                                    [autofocus]="true"
                                    [type]="'text'"
                                    [name]="'ifscCode'"
                                    [required]="true"
                                    [(ngModel)]="ifscCode"
                                    (mouseleave)="onMouseLeaveIFSC()"
                                    [errorMessage]="error_Message_ifsc"
                                    (keyup)="verifyIFSC($event)"
                                ></app-input>
                            </div>
                        </div>
        
                          <div class="d-flex align-item-center" *ngIf="showIfscData">
                            <div class="input-value mt-2">
                                <app-input
                                    [placeholder]="'eg.IDIB000K023'"
                                    [width]="'300px'"
                                    [type]="'text'"
                                    [name]="'ifscCode'"
                                    [readonly]="true"
                                    [(ngModel)]="ifscbankData.BANK"
                                ></app-input>
                            </div>
                        </div>
        
                          <div class="d-flex align-item-center" *ngIf="showIfscData">
                            <div class="input-value mt-2">
                                <app-input
                                    [placeholder]="'eg.IDIB000K023'"
                                    [width]="'300px'"
                                    [type]="'text'"
                                    [name]="'ifscCode'"
                                    [readonly]="true"
                                    [(ngModel)]="ifscbankData.BRANCH"
                                ></app-input>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end align-item-center" style="width: 300px;">
                        <div class="ifsc-wrapper" style="align-self: flex-end; margin-top: 10px;">
                            <a (click)="onClickSearchIFSC()" class="searchIFSC">Search IFSC</a>
                        </div>
                        </div>
                    </div>
                
                    <div class="submit-btn">
                        <app-button [savingsText]="'Continue'" (click)="onSubmit()"></app-button>
                    </div>
                </mat-card-content>
            </mat-card>
            <img src="../../../../assets/bull_logo.svg" class="bull-logo" alt="">
        </div>
        
    </div>
</div>