import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

  transform(value: number | string): string {
    if (value == null || value === '') return '';
    let formattedNumber = parseFloat(value.toString()).toFixed(2);
    formattedNumber = parseFloat(formattedNumber).toLocaleString('en-IN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return formattedNumber;
  }

}
