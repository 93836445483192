<div class="redirection-wrapper">
    <span class="headerText center">Redirecting...</span>
    <span class="center">
      <img src="../../../../../assets/icons/header/payment_success.svg" alt="" />
    </span>
    <span class="subText center">
      We're now redirecting you to onboarding.
    </span>
    <span class="center">
      <img src="../../../../../assets/icons/header/payment_line.svg" alt="" />
    </span>
    <span class="center">
        <!-- <img src="../../../../assets/loader.min.gif" alt="Redirecting..." /> -->
      <img src="../../../../../assets/loader.min.gif" alt="Redirecting..." />
    </span>
  </div>
  