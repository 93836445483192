import { Component } from '@angular/core';
import { ScreenTypeService } from 'src/app/core/services/screen-type.service';
import { Input } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
})
export class InfoComponent {
  screenType: string = '';
  constructor(private screenTypeService: ScreenTypeService) {}

  @Input() title: string = '';
  @Input() messageArray: string[] = [];
  @Input() infoType: string = '';
  @Input() showPanImage: boolean = true;

  ngOnInit() {
    console.log('on login');
    this.screenType = this.screenTypeService.breakpointState;
  }
}
