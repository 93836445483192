<div class="display-flex">
    <div class="popup-title">Search IFSC</div>
    <!-- <div>
      <img
        src="assets/icons/close-popup.svg"
        class="close-pop-up"
        mat-dialog-close />
    </div> -->
  </div>
  
  <mat-dialog-content class="search-ifsc">

  <div *ngIf="bankList.length>0">
    <app-select
    class="language child-container"
    style="width: 49%"
    [label]="'Bank Name'"
    [list]="bankList"
    [name]="'Bank Name'"
    [required]="true"
    [isMultiple]="false"
    [selectedValue]="selectedBank | formatSelectData : false"
    [(selection)]="selectedBank"
    (selectionChange)="onSelectionChange($event, 'bank')"
    [virtualScroll]="false" ></app-select>
  
  </div>

  <div *ngIf="cityList.length>0">
   
      <app-select
      class="city child-container"
      style="width: 49%"
      [label]="'City'"
      [list]="cityList"
      [name]="'city'"
      [required]="true"
      [isMultiple]="false"
      [selectedValue]="selectedCity | formatSelectData : false"
      [(selection)]="selectedCity"
      (selectionChange)="onSelectionChange($event, 'city')"
      [virtualScroll]="false"></app-select>
   
  </div>

  <div  *ngIf="branchList.length>0">
    <app-select
      class="city child-container"
      style="width: 49%"
      [label]="'Branch'"
      [list]="branchList"
      [name]="'branch'"
      [required]="true"
      [isMultiple]="false"
      [selectedValue]="selectedBranch | formatSelectData : false"
      [(selection)]="selectedBranch"
      (selectionChange)="onSelectionChange($event, 'branch')"
      [virtualScroll]="false"></app-select>
  </div>

  <div class="btn-div margin-top-10" *ngIf="bankList.length>0">
    <button mat-flat-button class="payment-btn" (click)="onSubmitIFSC()" [disabled]="!(selectedBank && selectedCity && selectedBranch)">Select IFSC</button>
  </div>
    
  </mat-dialog-content>
  