export const environment = {
  production: false,
  environment: 'UAT',
  apiUrl: 'https://13nd1tvrsg.execute-api.ap-south-2.amazonaws.com/uat',
  apiUrlFiles: 'https://multiplyrr-api-uat.equentis.com/files/api',
  socket_url: 'http://localhost:3001',
  socket_url_notification: 'https://multiplyrr-api-uat.equentis.com',
  socket_url_onboarding: 'https://onboarding-api-uat.equentis.com',
  paymentApiUrl: 'https://astrra-api-uat.equentis.com/payment',
  socketUrl: 'http://localhost:2403',
  onboarding_api_url: 'https://onboarding-api-uat.equentis.com/onboarding/',
  onboarding_api_url_cans: 'https://onboarding-api-uat.equentis.com/',
  smallcase_api_url: 'https://pfexecutor-uat.equentis.com/',
  digioEnabled: true,
  onboarding_url: 'http://localhost:4200/#/',
  digio_url: 'https://app.digio.in',
  website_url: 'https://multiplyrr-uat.equentis.com/'
  // apiUrl: 'http://localhost:2400',
};
