<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>

<div class="rebalance" *ngIf="this.screenType.breakpointState !== 'mob'">
    <div class="name-header" style="margin-top: 20px;">
        <div class="heading-wrapper" style="display: flex; flex-wrap: wrap;">
            <span class="headerText">{{jarDetails?.jar_name}}
                <br>
                <span class="subText">Invest in fundamentally strong stocks where the intrinsic value is more than the current share price</span>
            </span>
        </div>
        <div style="display: flex; gap: 30px;">
            <div>
                <div>Min Investment </div> 
                <div><i class="fa fa-rupee"></i>&nbsp;<span style="font-weight: 600; color:#001E5A; font-size: 18px; line-height: 27px;">{{minimum_Investement_Amount | readableNumber}}</span></div>
            </div>

            <div>
                <img src="../../../../assets/hr_vertical.svg" alt="">
            </div>
    
            <div>
                <div>5Y CAGR</div>
                <div style="color: #0AC01C; font-weight: 600; font-size: 18px; line-height: 27px;"><img [src]="jarDetails?.cagr < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'" alt=""> {{jarDetails?.cagr}}%</div>
            </div>
        </div>
    </div>

    <div class="cards-wrapper">
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
                <div><img src="../../../../assets/ic_Invested Amount.svg" alt=""> &nbsp;</div>
                <div>
                    <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.invested_amount) | readableNumber}}<br>
                    <span class="headSubText">Invested Amount</span></span>
                </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
            <div><img src="../../../../assets/ic_Current Value.svg" alt=""> &nbsp;</div>
            <div>
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.current_value) | readableNumber}}<br>
            <span class="headSubText">Current Value</span></span>
            </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
            <div><img src="../../../../assets/ic_Total Returns.svg" alt=""> &nbsp;</div>
            <div>
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.total_returns) | readableNumber}}&nbsp;
                <span style="position: absolute; top: 7px; right: 10px;" 
                [class.incrementText]="(investementDetails?.total_returns_percentage) >= 0" 
                [class.decrementText]="(investementDetails?.total_returns_percentage) < 0"
                ><img src="../../../../assets/upwardArrowIcon.svg" alt="" *ngIf="(investementDetails?.total_returns_percentage) >= 0">
                <img src="../../../../assets/downwardArrow.svg" alt="" *ngIf="(investementDetails?.total_returns_percentage) < 0">
                {{(investementDetails?.total_returns_percentage)}}%</span><br>
            <span class="headSubText">Total Returns</span></span>
            </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="card-content">
          <mat-card-content>
            <div class="card-inside">
            <div><img src="../../../../assets/ic_Day's P&L.svg" alt=""> &nbsp;</div>
            <div>
                <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.daily_profit_loss) | readableNumber}}&nbsp;
                    <span style="position: absolute; top: 7px; right: 10px;" 
                    [class.incrementText]="(investementDetails?.daily_profit_loss_percentage) >= 0" 
                    [class.decrementText]="(investementDetails?.daily_profit_loss_percentage) < 0"
                    >
                    <img src="../../../../assets/upwardArrowIcon.svg" alt="" *ngIf="(investementDetails?.daily_profit_loss_percentage) >= 0">
                    <img src="../../../../assets/downwardArrow.svg" alt="" *ngIf="(investementDetails?.daily_profit_loss_percentage) < 0">
                    {{(investementDetails?.daily_profit_loss_percentage)}}%</span><br>
                <span class="headSubText">Day’s P&L</span></span>
            </div>
            </div>
          </mat-card-content>
        </mat-card>
    </div>

    <div class="h-line">
        <hr>
    </div>

    <!-- <div class="stock-btn">
        <button mat-stroked-button color="primary" (click)="goToDashboard()"><img src="../../../../assets/backArrow.svg" alt="">&nbsp; Stock List</button>
    </div> -->
    <br>
    <mat-card>
        <mat-card-content>
            <div class="title mt-3">
            <div>Rebalancing History</div>
            <div>
            <app-button [isTransparentBTN]="true" [savingsText]="'Dashboard'"
            [showWatchListIcon]="false" [borderColor]="'#324066'"
            [textColor]="'#324066'" [goBackIcon]="true"
            (click)="goToDashboard()"></app-button>
            </div>
            </div>
            <hr>
            <div *ngIf="!noData">
                <div *ngFor="let rebalance of rebalancingData" class="rebalance-history mt-4">
                    <div class="date-time">{{ rebalance.rebalance_date | date: 'dd MMMM yyyy' }}</div>
                    <div class="content">
                        <div class="content-header">
                            <img src="../../../../assets/document.svg" alt="">&nbsp; Rebalancing Analysis
                        </div>
                        <hr>
                        <div class="row gx-5">
                            <!-- Left side for SELL transactions -->
                            <div class="col-6">
                                <ng-container *ngFor="let transaction of rebalance.transactions">
                                    <div *ngIf="transaction.transaction_type === 'SELL'" class="d-flex align-items-center">
                                        <div class="content-stock">{{ transaction.symbol }}</div>
                                        <div class="ms-auto content-cat-sell">
                                            {{ transaction.transaction_type }}
                                        </div>
                                    </div>
                                    <hr *ngIf="transaction.transaction_type === 'SELL'">
                                </ng-container>
                            </div>
                            <!-- Right side for BUY transactions -->
                            <div class="col-6">
                                <ng-container *ngFor="let transaction of rebalance.transactions">
                                    <div *ngIf="transaction.transaction_type === 'BUY'" class="d-flex align-items-center">
                                        <div class="content-stock">{{ transaction.symbol }}</div>
                                        <div class="ms-auto content-cat-buy">
                                            {{ transaction.transaction_type }}
                                        </div>
                                    </div>
                                    <hr *ngIf="transaction.transaction_type === 'BUY'">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                               
            </div>
            <hr>
        </mat-card-content>
    </mat-card>

    <div class="footer-items">
        <div>
                   <div  class="reach-out">
                     <img src="../../../../assets/reachOut.svg" alt="" style="cursor: pointer;" href = "mailto: support.multiplyrr@equentis.com">
                   </div>
        </div>
        <div>
                   <div  class="learn-more">
                       <a href="https://www.equentis.com/researchandranking" target="_blank">
                           <img src="../../../../assets/longTerm.svg" alt="Long Term" style="cursor: pointer;">
                         </a>                      
                   </div>
        </div>
       </div>

    <div *ngIf="noData" class="emptyState">
        <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
    </div>
    

</div>

<div class="rebalance" style="padding: 20px; margin-bottom: 70px;" *ngIf="this.screenType.breakpointState === 'mob'">
    <div >
        <app-toolbar-mobile></app-toolbar-mobile>
        <div class="r-heading w-100">
            <div class="title" style="color: #272E35;">{{jarDetails?.jar_name}}</div>
            <div class="desc">This diversified portfolio comprises a selection of renowned large-cap stocks, representing industry leaders known for their stability and resilience.</div>
        </div>
        <div class="overview mt-3 w-100" style="background-color: #E9E9E9;">
            <div>
                <div class="overview-title">5Y CAGR <span><img src="../../../../assets/information.svg" alt=""></span></div>
                <div class="overview-value-cagr"><span><img src="../../../../assets/cagrGraph.svg" alt=""></span>&nbsp; {{jarDetails?.cagr}}%</div>
            </div>
            <div class="ms-auto">
                <div class="overview-title">Min Investment <span><img src="../../../../assets/information.svg" alt=""></span></div>
                <div class="overview-value">&#8377; {{minimum_Investement_Amount}}</div>
            </div>
        </div>
    
        <!-- <div class="btns mt-3 ms-0  d-flex align-items-center">
            <div class="btn-wrapper">
                <button mat-stroked-button color="primary" style="width: 180px;"><img src="../../../../assets/bookmark.svg" alt="">&nbsp;Add To Watchlist</button>
            </div>
            <div class="btn-wrapper-flat ms-auto">
                <button mat-flat-button color="primary" style="width: auto;">Invest More</button>
            </div>
        </div> -->
    </div>

    <div class="mt-3 card-wrapper container-fluid">
        <div class="row">
            <div class="col-6 mob-card">
                <div>
                    <img src="../../../../assets/investedAmount.svg" alt="">
                </div>
                <div class="card-value text-center">
                    &#8377; {{investementDetails?.invested_amount}}
                    <br>
                    <span>Invested Amount</span>
                </div>
            </div>
            <div class="col-6 mob-card ms-auto">
                <div>
                    <img src="../../../../assets/currentValue.svg" alt="">
                </div>
                <div class="card-value text-center">
                    &#8377; {{investementDetails?.current_value}}
                    <br>
                    <span>Current Amount</span>
                </div>
            </div>
            
        </div>
        <div class="row mt-3">
            <div class="col-6 mob-card">
                <div>
                    <img src="../../../../assets/totalReturn.svg" alt="">
                </div>
                <div class="card-value text-center">
                    &#8377; {{investementDetails?.total_returns}} <span class="profit">{{investementDetails?.total_returns_percentage}}%</span>
                    <br>
                    <span>Total Returns</span>
                </div>
            </div>
            <div class="col-6 mob-card ms-auto">
                <div>
                    <img src="../../../../assets/dayPNL.svg" alt="">
                </div>
                <div class="card-value text-center">
                    &#8377; {{investementDetails?.daily_profit_loss}} <span class="loss">{{investementDetails?.daily_profit_loss_percentage}}%</span>
                    <br>
                    <span>Day's P&L</span>
                </div>
            </div>
        </div>
    </div>

    <div class="h-line">
        <hr>
    </div>

    <!-- <div class="stock-btn">
        <button mat-stroked-button color="primary" (click)="goToDashboard()"><img src="../../../../assets/backArrow.svg" alt="">&nbsp; Stock List</button>
    </div> -->

    <div class="title mt-3">Rebalancing History</div>
    <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
    <app-button [isTransparentBTN]="true" [savingsText]="'Dashboard'"
            [showWatchListIcon]="false" [borderColor]="'#324066'"
            [textColor]="'#324066'" [goBackIcon]="true"
            (click)="goToDashboard()"></app-button>
    </div>        
    <hr>
    <div *ngIf="!noData">
        <div *ngFor="let rebalance of rebalancingData" class="rebalance-history" style="gap: 1em; margin-top: 1em;">
            <div class="date-time-mob">{{ rebalance.rebalance_date | date: 'dd MMMM yyyy' }}</div>
            <div class="content">
                <div class="content-header" style="font-size: 14px;">
                    <img src="../../../../assets/document.svg" alt=""> &nbsp; Rebalancing Analysis
                </div>
                <hr>
                <div *ngFor="let transaction of rebalance.transactions">
                    <div class="d-flex align-items-center">
                        <div class="content-stock fs-14">{{ transaction.symbol }}</div>
                        <div class="ms-auto" [ngClass]="{'content-cat-buy': transaction.transaction_type === 'BUY', 'content-cat-sell': transaction.transaction_type === 'SELL'}">
                            {{ transaction.transaction_type | titlecase }}
                        </div>
                    </div>
                    <hr *ngIf="!isLastTransaction(transaction, rebalance.transactions)">
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="noData" class="emptyState">
        <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
    </div>
    
</div>