import { Component, ViewEncapsulation } from '@angular/core';
import { ScreenTypeService } from '../../services/screen-type.service';
import { Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../auth/login/login.component';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OnboardingService } from '../../services/onboarding.service';
import * as moment from 'moment';
import { UserService } from '../../services/user.service';
import { ApiServiceService } from '../../services/api-service.service';
import { environment } from 'src/environments/environment';
import { SocketType } from '../../models/types';
import { WebSocketService } from '../../services/web-socket.service';
import { StepsManagerService } from '../../services/steps-manager.service';
import { MoengageService } from '../../services/moengage.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-pan',
  templateUrl: './pan.component.html',
  styleUrl: './pan.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PanComponent {
[x: string]: any;
  DoBInfoData = [
    'Your Date of Birth is crucial for accurately assessing your risk profile It also helps us tailor our stock baskets to match your financial goals and risk tolerance.',
  ];
  panInfoData = [
    '🔒 Security: Your PAN number lets us verify your identity, ensuring your account is secure.',
    '📝 KYC Compliance: As a SEBI-registered investment advisory, we collect your PAN details to comply with SEBI regulations and complete your KYC process.',
  ];
  pan: string = '';
  date_of_birth: any = '';
  today: Date = new Date();
  isPanValid: boolean = false;
  pan_name: string = '';
  showPanInfo: boolean = false;
  showDOBInfo: boolean = false;
  error_Message_pan: string = '';
  isPanSuffix: boolean = false;
  isDateSuffix: boolean = false;
  error_Message_dob: string = '';
  usernameToolBar: boolean = false;
  userName: string = '';
  getAuthToken: string = '';
  selectedPlan: string = 'plan-6';
  isAutoRenew: boolean = true;
  pan_btn_text: string = 'Continue';
  panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  private routeSub: Subscription | undefined;
  onboardingDetail: any;
  spinner: boolean = false;
  categoryId: any;
  subProductId: any;
  onboardingId: any;
  tokenData: any;
  feeDetailsData: any[] = [];
  stepsData: any;
  onboardingStepsOrder: any[] = [];
  gstAmount: any;
  moeProductName: any;
  isPanImage: boolean = true;


  constructor(
    public screenType: ScreenTypeService,
    private route: Router,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    private router: ActivatedRoute,
    private onboardingService: OnboardingService,
    private user_service: UserService,
    private apiService: ApiServiceService,
    private socket_service: WebSocketService,
    private steps_service: StepsManagerService,
    private moengageService: MoengageService,
  ) {}

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
      this.apiService.getTokenData().subscribe((res: any) => {
        if(res && res.message == 'success') {
            if(res?.result?.is_other_product_active) {
              const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
                height: 'auto',
                disableClose: true,
                autoFocus: false,
                backdropClass: 'backdropBackground',
                width: 'auto',
              });
            }
            else if (res?.result?.customer_meta_info?.is_registered) {
              this.tokenData = res?.result;
              console.log('ngOnInit token data', data);
              const name = data?.name || data?.full_name || '';
              this.usernameToolBar = true;
              this.userName = name;
            }
            else {
              this.openLogin('');
            }
        }
        });
    }
    console.log(this.screenType.breakpointState);
    this.routeSub = this.router.params.subscribe((params) => {
      console.log('got params', params);
      console.log('got id', params['id']);
      if(params['id']) {
      this.user_service.pushHashKey(params['id']);
      }
      //this.user_service.pushHashKey(params['id']);
    });
    this.getOnboardingStepsData();
    this.getOnboardingDetails(this.user_service.getHashKey());

    //calling socket service to check if video kyc is completed
    this.socket_service.listen(SocketType.self_kyc_response)
    .subscribe((res: any) => {
      console.log('received response for video kyc completion', res);
      window.location.reload();
    });
    //
  }

  getOnboardingStepsData() {
    this.onboardingService.getStepsData(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.message == 'success') {
        this.spinner = true;
        this.stepsData = res.data;
        this.onboardingStepsOrder = res.data.stepOrder;
        console.log('this.onboardingStepsOrder',this.onboardingStepsOrder);
        this.getDataOnLoad();
      }
      else {
        this.spinner = false;
        this._toastrService.error('unable to fetch onboarding details');
      }
    })
  }

  getCategoryId() {
    this.apiService.getAllBasketData().subscribe((res: any) => {
      if(res?.message == 'success') {
        this.spinner = true;
        for(let data of res?.result) {
          if(this.stepsData.productCode == data.sub_product_code) {
            this.moeProductName = data.sub_product_name;
           this.categoryId = data.category_id;
          }
        }
        //call redis api to save data
        this.saveDataInRedis();
        //
      }
      else {
        this.spinner = false;
        this._toastrService.error('Failed to load basket details!');
      }
    });
  }

  saveDataInRedis() {
   let payload = {
      id: this.user_service.getHashKey(),
      data: {
        categoryId: this.categoryId,
        subProductId: this.stepsData.subProductId,
        onboardingId: this.stepsData.onboardingId,
        productCode: this.stepsData.productCode,
      }
    }
    this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
      console.log('got redis es..',res);
      this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
          if(res && res?.message == 'success' && res?.result) {
            this.spinner = true;
            this.categoryId = res?.result?.categoryId;
            this.subProductId = res?.result?.subProductId;
            this.onboardingId = res?.result?.onboardingId;
            // this.spinner = true;
            this.apiService.getBasketData(this.categoryId).subscribe((res: any) => {
              // this.spinner = false;
              // calling insights api to get correct fee details
              this.spinner = true;
              this.apiService.getInsightsData(this.subProductId).subscribe((res: any) => {
                if(res && res?.message == 'success') {
                  this.feeDetailsData = res?.result?.fees_details;
                  //set details for onboarding Moe event
                  let eventData = {
                    onboardingId: res.result?.onboarding_detail_id,
                    plan: 6,
                    basketPrice: this.feeDetailsData[0]?.details.advisory_fees,
                    basketRisk: res.result?.pb_details?.risk_level,
                    status: 'onboarding',
                    basketName: this.moeProductName
                  }
          
                  this.onboardingService.setMoeEventDetails(eventData);
                  //
                  this.gstAmount = (res.result?.fees_details[0]?.details.advisory_fees) +
                  (res.result?.fees_details[0]?.details.advisory_fees * res.result?.igst_percentage/100);
                  this.spinner = false;
                }
                else {
                  this.spinner = false;
                }
              })
              //
            },
          (err) => {
            this.spinner = false;
            this._toastrService.error('something went wrong while fetching details');
          }
          );
          }
          else {
            this.spinner = false
          }
        });
    });
  }

  getDataOnLoad() {
    //get category id based on prduct_code
    this.getCategoryId();
    //
  }

  getRedisData(plan: any) {
    this.apiService.getBasketData(this.categoryId).subscribe((res: any) => {
      console.log('get basket details......', res.data);
      for(let data of res?.result) {
        if(this.subProductId == data.sub_product_id) {
          const feeDetails = plan === 'plan-6' ? data.fees_details[0] : data.fees_details[0];
          const first_year_amount = plan === 'plan-6' ? data.fees_details[0].details.max_yearly_amount : data.fees_details[0].details.max_yearly_amount;
          const immediate_advisory_payment_amount = plan === 'plan-6' ? data.fees_details[0].details.advisory_fees : data.fees_details[0].details.advisory_fees;
          const advisory_installment_option = plan === 'plan-6' ? data.advisory_installment_option[0] : data.advisory_installment_option[0];
          console.log(feeDetails);
          let payload = {
            "lead_id": this.tokenData.lead_id,
            "sub_product_id": this.subProductId,
            "country_id": this.tokenData.country_id === null ? 0 : this.tokenData.country_id,
            "state_id": this.tokenData.state_id === null ? 0 : this.tokenData.state_id,
            "complimentary_period": 0,
            "fees_details": feeDetails,
            "first_year_amount": first_year_amount,
            "advisory_discount_amount": 0,
            "immediate_advisory_payment_amount": immediate_advisory_payment_amount,
            "advisory_installment_option": advisory_installment_option,
            "advisory_number_of_installments": res?.result[0].advisory_installment_option.length,
            "advisory_is_emandate": false,
            "advisory_emandate_date": moment().format('YYYY-MM-DD'),
            "advisory_emandate_discount": 0,
            "email_contact_detail_id": this.tokenData.email_contact_detail_id,
            "whatsapp_contact_detail_id": this.tokenData.mobile_contact_detail_id,
            "sms_contact_detail_id": this.tokenData.mobile_contact_detail_id
          }
            this.onboardingService.updateOnboarding(payload, this.onboardingId, localStorage.getItem('authToken')).subscribe((res: any) => {
              console.log('product updated', res);
              if(res && res.message == 'success') {
              this._toastrService.success('Details updated successfully');
              }
              else {
              this._toastrService.error(res.data.error.message);
              }
            })
        }
      }
    });
  }

  getOnboardingDetails(id: any) {
    this.onboardingService.getOnboardingData(id).subscribe({
      next: (res: any) => {
      if(res && res.message == 'success') {
        console.log(res);
        this.user_service.pushPaymentURL(res.data.payment_url.split('payment/')[1]);
        this.user_service.pushOboardingDetailID(res.data.onboarding_detail_id);
        this.onboardingDetail = res.data.onboarding_detail_id;
              
        if(res.data?.incomeTax) {
          if(res.data?.incomeTax[0]?.meta_info['pan']) {
            this.pan_name = res.data.incomeTax[0].meta_info['full_name'];
            this.pan_btn_text = 'Confirm';
            this.pan = res.data.incomeTax[0].meta_info['pan'];
            let formattedDate = res.data.incomeTax[0].meta_info['dob'];
            let parts = formattedDate.split('/');
            let year = parseInt(parts[2], 10);
            let month = parseInt(parts[1], 10) - 1;
            let day = parseInt(parts[0], 10);
            let date = new Date(year, month, day);
            console.log(date);
            this.date_of_birth = date;
            if (this.panRegex.test(this.pan)) {
              this.error_Message_pan = '';
              this.isPanSuffix = true;
            }
            if (this.date_of_birth.length != 0) {
              this.error_Message_dob = '';
              this.isDateSuffix = true;
            }
            }
        }
      }
      },
      error: (err) => {
        console.error(err);
      },
    })
  }

  planSelection(plan: string) {
    this.getRedisData(plan);
    this.selectedPlan = plan;
    this.isAutoRenew = plan === 'plan-6' ? false : true;
  }

  showOptions(event: any) {
    this.isAutoRenew = event;
    this.getRedisData(this.selectedPlan);
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'pan',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

  downloadDocuments() {
    this.spinner = true;
    this.onboardingService.downloadDocument(this.user_service.getHashKey()).subscribe({
      next: (res: any) => {
        this.spinner = false;
      if(res && res.message == 'success') {
        console.log(res);
        this.route.navigate(['riskProfile', this.user_service.getHashKey()]);
      }
      },
      error: (err) => {
        this.spinner = false;
      },
    })
  }

  onSubmit() {
    if(this.pan_btn_text == 'Confirm') {
    let moeEventData = this.onboardingService.getMoeEventDetails();
    moeEventData.basketPrice = this.feeDetailsData[0]?.details.advisory_fees;
    moeEventData.basketName = this.moeProductName;
    this.onboardingService.setMoeEventDetails(moeEventData);
    this.steps_service.initializeAndNavigate();
    //this.downloadDocuments();
    // this.route.navigate(['risk-profile', this.user_service.getHashKey()]);
    }
    else {
      if (this.panRegex.test(this.pan) === false) {
        this.error_Message_pan = 'Please enter a valid PAN Number';
        this.isPanSuffix = false;
        this.panInfoData = [
          'Verify who you are and secure your account.',
          'Comply with SEBI-regulations'
        ]
        this.isPanImage = false;
      } else {
        this.error_Message_pan = '';
        this.isPanSuffix = true;
        this.panInfoData = [
          '🔒 Security: Your PAN (Permanent Account Number) helps us verify your identity and ensure the security of your account.',
          '📝 KYC Compliance: As a SEBI registered investment advisory, we are mandated to collect your PAN details to complete your KYC process. This is essential to adhere to SEBI compliance regulations.',
        ];
        this.isPanImage = true;
      }

      if (this.date_of_birth.length === 0) {
        this.error_Message_dob = 'Please enter DOB';
        this.isDateSuffix = false;
      } else {
        this.error_Message_dob = '';
        this.isDateSuffix = true;
      }

      const currentDate = new Date();
      const minDate = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      var currentAge: boolean = false;
      if (new Date(this.date_of_birth) > minDate) {
        currentAge = true;
      } else {
        currentAge = false;
      }

      if (
        this.panRegex.test(this.pan) === true &&
        this.date_of_birth.length !== 0 &&
        !currentAge
      ) {
          let reqPayload = {
            "parameters":
            {
              "pan_number": this.pan.toUpperCase(),
              "date_of_birth": moment(new Date(this.date_of_birth)).format('DD/MM/YYYY')
            },
            "onboarding_detail_id":this.onboardingDetail,
            "step_id":"3"
          }
          this.spinner = true;
          this.onboardingService.panVerify(reqPayload).
          subscribe({
            next: (res: any) => {
              if(res && res.message == 'success') {
                this.spinner = false;
               if(
                res.data.self_kyc
                && res.data.self_kyc.length > 0
                && res.data.self_kyc[0].completed === false
                && res.data.self_kyc[0].kyc_action == 'initiated'
               ) {
                const randomID = Date.now();
                const equentisLogo = 'https://rnr-cdn.s3.ap-south-1.amazonaws.com/rr_logo_new.svg';
                const encodeCustomUrl = (url: any) => {
                  return url.replace(/[/:?#=&]/g, function (match: any) {
                    return '%' + match.charCodeAt(0).toString(16).toUpperCase();
                  });
                };
                const callbackUrl = encodeCustomUrl(
                  `https://multiplyrr-uat.equentis.com/payment/${this.user_service.getPaymentURL()}`,
                );

                let redirectURL = `${environment.digio_url}/#/gateway/login/${res.data.self_kyc[0].reqId}/${randomID}
                /${res.data.self_kyc[0].customer_identifier}?link_approach=true&token_id=${
                  res.data.self_kyc[0].access_token
                }&redirect_url=${callbackUrl}&logo=${equentisLogo}
                &theme={”PRIMARY_COLOR”:“#643CB5”,“SECONDARY_COLOR”:“#000000”,“FONT_FAMILY”:“Montserrat”,“FONT_URL”:“https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet”}`
                // localStorage.setItem('digio_URL', redirectURL_for_agreement_check);

                window.location.href = redirectURL;
               }
               else if(res.data &&
                  res.data.incomeTax &&
                  res.data.incomeTax[0].completed) 
                {
                  // call pan verfied moe event
                 let eventData = this.onboardingService.getMoeEventDetails();
                 let eventMoeData = {
                  'onboardingId': eventData?.onboardingId,
                  'plan': eventData?.plan,
                  'basketPrice': eventData?.basketPrice,
                  'basketRisk': eventData?.basketRisk,
                  'status': eventData?.status,
                  'subStatus': 'PAN Verified',
                  'basketName': this.moeProductName
                 }
                 this.moengageService.onboardingEvent(eventMoeData);
                 //
                 this.pan_name = res.data.incomeTax[0].meta_info['full_name'];
                 this.pan_btn_text = 'Confirm';
                //  this.downloadDocuments();
                }
               //this.route.navigate(['risk-profile']);
              }
              else {
                this.pan_name = '';
                this.spinner = false;
              }
            },
            error: (err) => {
              this.pan_name = '';
              this.spinner = false;
            },
          });
      } else if (currentAge) {
        this._toastrService.error(
          'Whoa! Hold on, future investor! This product is for the 18+ club. See you when you hit that milestone!'
        );
      }
    }
  }

  onMouseEnterPan() {
    console.log('Mouse entered pan');
    this.showPanInfo = true;
  }
  onMouseLeavePan() {
    console.log('Mouse leave pan');
    this.showPanInfo = false;
    if (this.panRegex.test(this.pan) === true) {
      this.error_Message_pan = '';
      this.isPanSuffix = true;
    }
  }

  onMouseEnterDOB() {
    console.log('Mouse entered DOB');
    this.showDOBInfo = true;
  }
  onMouseLeaveDOB() {
    console.log('Mouse leave DOB');
    if (this.date_of_birth.length !== 0) {
      this.error_Message_dob = '';
      this.isDateSuffix = true;
    }
    // if (
    //   this.panRegex.test(this.pan) === true &&
    //   this.date_of_birth.length !== 0
    // ) {
    //   this.error_Message_dob = '';
    //   this.error_Message_pan = '';
    //   this.pan_name = 'Manish Singh';
    // } else {
    //   this.pan_name = '';
    // }
    this.showDOBInfo = false;
  }
}
