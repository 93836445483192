<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>
<!-- laptop view -->
<div class="row" *ngIf="this.screenType.breakpointState !== 'mob'">
    <div class="col-1"></div>
    <div class="col-10">
        <mat-card class="laptop-main-wrapper">
            <div class="congratulation-msg" *ngIf="pageNumber == 2">🎉 <span style="color: #2997FF; font-size: 14px; font-weight: 550;">Congratulations! Your identity is verified</span></div>
            <br>
            <span class="header-text">Let’s Understand Your Financial Comfort Zone
                <!-- <img src="../../../../assets/dividerRiskProfileIcon.svg" alt=""><span class="page-order">{{pageNumber}}/8</span> -->
            </span>
            <br>
            <span class="subHeader-text">We’d like to know more about your investment goals and risk tolerance to recommend a suitable stock basket.</span>

            <div *ngFor="let item of allRiskProfileList">
                <div [ngClass]="{showRiskProfile: item.show==true,hideRiskProfile: item.show==false}">
                    <div class="list-wrapper">
                        <span class="header">{{item.parameters[0].label}}</span>
                        <div style="padding-top: 10px;">
                        <app-list [list]="item.parameters[0].options" [selectedQue]="item.parameters[0].selected"
                        (listSelectionChange)="onListSelectionChange($event)" [mainQuestion]="item"></app-list>
                    </div>
                    </div>
                    <div class="button-wrapper">
                        <div>
                            <app-button [savingsText]="'Back'" [isTransparentBTN]="true" (click)="goToPreviousStep(item.completed,item.step_id, pageNumber)" [showWatchListIcon]="false"></app-button>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div>
                            <app-button [savingsText]="'Continue'" (click)="onContinueRiskProfile(item.completed,item.step_id, item.parameters[0].label)"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-1"></div>
    
</div>
<!--  -->

<!-- mobile view -->
<div class="row" *ngIf="this.screenType.breakpointState == 'mob'">
        <mat-card style="padding: 30px; height: 1000px;">
            <div class="congratulation-msg" style="width: 100%;">🎉 <span style="color: #2997FF; font-size: 14px; font-weight: 550;">Congratulations! Your identity is verified</span></div>
            <br>
            <span class="header-text">Let’s Understand Your Financial Comfort Zone Zone
                <!-- <img src="../../../../assets/dividerRiskProfileIconMob.svg" width="250px" alt=""><span class="page-order">{{pageNumber}}/8</span> -->
            </span>
            <span class="subHeader-text">We’d like to know more about your investment goals and risk tolerance to recommend a suitable stock basket.</span>
            <div *ngFor="let item of allRiskProfileList">
            <div [ngClass]="{showRiskProfile: item.show==true,hideRiskProfile: item.show==false}">
        <div class="list-wrapper">
            <span class="header">{{item.parameters[0].label}}</span>
            <div style="padding-top: 10px;">
            <app-list [list]="item.parameters[0].options" [selectedQue]="item.parameters[0].selected" [isMobile]="true"
            (listSelectionChange)="onListSelectionChange($event)" [mainQuestion]="item"></app-list>
            </div>
        </div>
        <div class="button-wrapper" style="display: flex; justify-content: center;">
        <div>
            <app-button [savingsText]="'Back'" [isTransparentBTN]="true" (click)="goToPreviousStep(item.completed,item.step_id, pageNumber)" [showWatchListIcon]="false"></app-button>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div>
            <app-button [savingsText]="'Continue'" (click)="onContinueRiskProfile(item.completed,item.step_id, item.parameters[0].label)"></app-button>
        </div>
        </div>
        </div>
        </div>
        
        </mat-card>
        <app-toolbar-mobile></app-toolbar-mobile>
    
</div>
<!--  -->