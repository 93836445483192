import {
  AfterViewInit,
  Component,
  Input,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
  ngAfterViewInit() {}
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() isSuffix: boolean = false;
  @Input() hint: string = '';
  @Input() maxLength: any;
  @Input() type: any;
  autofocus: boolean = false;
  @Input() inputValue!: string;
  @Input() errorMessage: string = '';
  @Input() hintRequired: boolean = false;
  @Input() width: string = 'auto';
  @Input() readonly: boolean = false;
  @Input() isDisabled: boolean = false;
  writeValue(value: any) {
    this.inputValue = value;
  }
  get paddingLeft(): string {
    return this.placeholder === 'Mobile No.' ? '50px' : '10px';
  }
}
