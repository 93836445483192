import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../../services/api-service.service';
import { MoengageService } from '../../services/moengage.service';
import { OnboardingService } from '../../services/onboarding.service';
import { ScreenTypeService } from '../../services/screen-type.service';
import { SmallCaseIntegrationService } from '../../services/small-case-integration.service';
import { UserService } from '../../services/user.service';
import { WebSocketService } from '../../services/web-socket.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';
import { LoginComponent } from '../auth/login/login.component';
import { TransactionDetailsComponent } from '../transaction-details/transaction-details.component';
import { InvestementModalDashboardComponent } from '../investement-modal-dashboard/investement-modal-dashboard.component';
import { OrderInProgressComponent } from '../../shared/dialogs/order-in-progress/order-in-progress.component';
import * as moment from 'moment';

@Component({
  selector: 'app-basket-details',
  templateUrl: './basket-details.component.html',
  styleUrls: ['./basket-details.component.scss'],
})
export class BasketDetailsComponent implements OnInit {
  getAuthToken: any;
  tokenData: any;
  customer_Id: any;
  usernameToolBar: any;
  userName: any;
  product_code: any;
  onboardingId: any;
  matBadgeCount: number = 0;
  spinner: boolean = false;
  jarDetails: any;
  minimum_Investement_Amount: any;
  isAfterMarketOrder: boolean = false;
  isBasketRebalanced: boolean = false;
  isRenewNow: boolean = false;
  exitedStocksList: any[] = [];
  rebalancingInProgress: boolean = false;
  selectedStockType: string = 'active';
  activeStocks: boolean = true;
  exitedStocks: boolean = false;
  sub_product_id: any;
  invest_now_text: string = 'Invest Now';
  stocksList: any[] = [];
  btnColorExistingUser: string = '#C3966E';
  isUserPortfolio: boolean = false;
  fixInvestmentData: any[] = [];
  investementDetails: any;
  gatewayInstance: any;
  smallCaseAuthToken: any;
  transaction_id: any;
  smallCaseInitToken: any;

  constructor(
    public screenType: ScreenTypeService,
    private route: Router,
    private router: ActivatedRoute,
    public dialog: MatDialog,
    private toast_message: ToastrService,
    private smallCase_service: SmallCaseIntegrationService,
    private apiService: ApiServiceService,
    private user_service: UserService,
    private socket_service: WebSocketService,
    private onboardingService: OnboardingService,
    private moengageService: MoengageService
  ) {}

  getBackgroundColor(text: string): string {
    if(text == 'Fix Investment' || text == 'Rebalance Now') {
      return '#E17466';
    }
    else {
      return '#C3966E';
    }
  }

  formatDate(date: any) {
    return moment(date).format('Do MMMM YYYY');
   }

  openRebalancingInProgressModal(type: any) {
    const dialogRef = this.dialog.open(OrderInProgressComponent, {
      height: 'auto',
      disableClose: false,
      autoFocus: true,
      width: '50%',
      data: {
        modalType: type
      },
    });
  }

  openPDF(pdfLink: any, data: any) {
    window.open(pdfLink);
    let eventData = {
      'stockName' : data.company_short_name,
      'basketName' : this.jarDetails?.jar_name
    }
    this.moengageService.reportDownload(eventData);
  }

  getFixInvestmentData() {
    const payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.apiService.correctPortfolio(payload).subscribe((res: any) => {
      console.log('assigned portfolio successfully');
      this.fixInvestmentData = res.data.transactions;
    });
  }

  registerAuthToken(token: any, code: any) {
    let reqData = {
      'user_medium_src': code,
      'user_medium_id': this.customer_Id,
      'auth_id': token
    }
  this.smallCase_service.registerToken(reqData).subscribe((res: any) => {
    console.log('token registered successfully.....', res)
  })
  }


  openInvestementModal() {
    if(this.invest_now_text == 'Order Pending') {
      this.openRebalancingInProgressModal('orderPending');
    // this.toast_message.warning('Your order is in progress, we will update you once executed');
    }
    else if(this.rebalancingInProgress) {
      this.openRebalancingInProgressModal('rebalancingInProgress');
    // this.toast_message.warning("We're rebalancing your portfolio. Please wait a moment, and you'll be able to continue shortly.");
    }
    else {
      console.log('investNowText:::', this.invest_now_text);
      if(this.invest_now_text == 'Fix Investment' || this.invest_now_text == 'Rebalance Now') {
        this.getFixInvestmentData();
      }
      let payload = {
        customer_id: this.customer_Id,
        code_id: this.product_code
      }
      this.apiService.getProductData(payload).subscribe((res: any) => {
        if(res && res.success) {
          this.jarDetails = res.data.data.jar_details;
          this.minimum_Investement_Amount = Math.ceil(res.data.data.jar_details.min_investment_amount);
          this.investementDetails = res.data.data.investment_details;
          const dialogRef = this.dialog.open(InvestementModalDashboardComponent, {
            height: '90%',
            disableClose: true,
            autoFocus: true,
            backdropClass: 'backdropBackground',
            width: '100%',
            data: {
              minimumInvestmentAmount: this.minimum_Investement_Amount,
              onboardingId: this.onboardingId,
              investementType: this.invest_now_text,
              productName: this.jarDetails,
              product_code: this.product_code,
              sub_product_id: this.sub_product_id,
              actionType: this.invest_now_text,
              fixInvestmentData: this.fixInvestmentData,
              productRisk: res.data?.data?.jar_details?.risk_level,
              isAfterMarketOrder: this.isAfterMarketOrder
            },
          });
      
          //get smallcase instance
          const reqData = {
            user_medium_src: `multiplyrr_${res?.data?.data?.jar_details?.code_id?.toLowerCase() || ''}`,
            user_medium_id: this.customer_Id,
          }
          this.smallCase_service.getAuthToken(reqData).subscribe((res: any) => {
            this.gatewayInstance = new (window as any).scDK({
              gateway: "researchandranking",
              smallcaseAuthToken: `${res.data.jwt}`,
              config: {
                amo: true
              }
            });
          })
          //
      
          // handle success event from smallcase
        dialogRef.componentInstance.transactionResponse.subscribe((txnResponse: any) => {
          console.log('.......', txnResponse)
          let userMessage = txnResponse.orderBatches[0].orders[0].statusMessage;
          let status = txnResponse.orderBatches[0].orders[0].status;
          this.smallCaseAuthToken = txnResponse.smallcaseAuthToken;
          if(status == 'PLACED' || status == 'COMPLETE') {
          this.toast_message.success('Transaction successful');
          //calling logout event
          this.gatewayInstance.brokerLogout();
          //
          window.location.reload();
          this.getDataFromRedis();
          }
          else {
          this.toast_message.error(userMessage);
          window.location.reload();
          }
        });
      
        dialogRef.componentInstance.isGuest.subscribe((isGuest: any) => {
          console.log('is guest....', isGuest);
          if(isGuest) {
            this.registerAuthToken(this.smallCaseAuthToken, `multiplyrr_${res?.data?.data?.jar_details?.code_id?.toLowerCase() || ''}`);
          }
        });
      
        // handle failure event from smallcase
        dialogRef.componentInstance.transactionError.subscribe((error: any) => {
          console.log('received error......', error);
          this.toast_message.error('Transaction failed: ' + error.message);
          //calling logout event
          this.gatewayInstance.brokerLogout();
          //
          let reqData = {
            txnID: this.transaction_id,
            token: this.smallCaseInitToken,
            }
            this.smallCase_service.userCancelService(reqData).subscribe((res: any) => {
              console.log('user cancel success',res);
              window.location.reload();
              this.getDataFromRedis();
            },
            (err) => {
              window.location.reload();
              this.getDataFromRedis();
            }
          );
        });
      
        //get transaction id
        dialogRef.componentInstance.transactionID.subscribe((id: any) => {
          console.log('received error......', id);
          this.transaction_id = id;
        });
      
        //get init auth token
        dialogRef.componentInstance.authTokenInit.subscribe((token: any) => {
          console.log('token received...', token);
          this.smallCaseInitToken = token;
        })
        }
        else {
          this.toast_message.error(res.data.data.message);
        }
      })
    }
  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
      this.tokenData = data;
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if (token && data.customer_meta_info.is_registered) {
      this.router.queryParams.subscribe(params => {
        console.log('Param 1:', params['code']);
        console.log('Param 2:', params['product_id']);
        this.product_code = params['code'];
        this.sub_product_id = params['product_id'];
      });
      localStorage.setItem('categoryNameMoe', 'dashboard');
      console.log('ngOnInit token data', data);
      this.customer_Id = data.customer_id;
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
    } else {
      this.openLogin('');
    }
    // this.checkPaymentStatus();
    this.getDataFromRedis();
  }

  selectStockType(type: string): void {
    this.selectedStockType = type;
    if(type == 'active') {
      this.exitedStocks = false;
      this.activeStocks = true;
    }
    else if(type == 'exited') {
      this.activeStocks = false;
      this.exitedStocks = true;
    }
  }

  openTransactionDetails(data: any) {
    const dialogRef = this.dialog.open(TransactionDetailsComponent, {
      height: '100%',
      width: 'auto',
      disableClose: false,
      position: { right: '0' },
      data: {
        moreDetails: data,
        stockType: this.selectedStockType
      },
    });
  }

  goToRebalancingPage() {
    this.route.navigate(['rebalance-history'], { queryParams: { product_code: this.product_code } });
  }

  renewBasket(data: any) {
    //call create onboarding API
    this.spinner = true;
    let payload = {
      "sub_product_id": data?.sub_product_id,
      "contact_id": this.tokenData?.contact_id,
    }
    let authToken = localStorage.getItem('authToken');
    this.onboardingService.renewOnboarding(payload, authToken).subscribe((res: any) => {
      this.spinner = false;
      console.log('lead creation api res..', res);
      if(res && res.success && Object.keys(res.data).length != 0) {
          const parts = res.data.url.split('/');
          const id = parts[parts.length - 1];
          this.user_service.pushHashKey(id);
    
          // call redis api to save data
          let payload = {
            id: this.user_service.getHashKey(),
            data: {
              categoryId: data.category_id,
              subProductId: data.sub_product_id,
              onboardingId: res.data.onboarding_id,
              productCode: data.sub_product_code,
            }
          }
          this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
            console.log('got redis es..',res);
          })
          //
    
          this.route.navigate(['pan', id]);
      }
      else {
        this.toast_message.error('Unable to fetch onboarding details, please try again!');
      }
    },
    (err) => {
      this.spinner = false;
    }
    );
    //
    }

  renewNow(data: any) {
    if(data && data == 'Basket Details') {
      let obj = {
        sub_product_id: this.sub_product_id
      }
      this.renewBasket(obj);
    }
    else {
      this.renewBasket(data);
    }
  }

  getDataFromRedis() {
    this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.success) {
        console.log('got dashboard redis',res);
        // this.product_code = res.data.productCode;
        this.onboardingId = res.data.onboardingId;
        // this.getPurchasedBasketData();
        // this.getAllDashboardData();
        this.getPortfolioData();
        this.getExitedStocks();
      }
     })
  }

  getExitedStocks() {
    let payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.apiService.getPortfolioExitedStocks(payload).subscribe((res: any) => {
     if(res && res.success && res.data.status) {
        this.exitedStocksList = res.data.data.portfolio_composition;
     }
     else {
      this.toast_message.error(res.data.data.message);
     }
    })
  }

  getPortfolioData() {
    let payload = {
      customer_id: this.customer_Id,
      code_id: this.product_code
    }
    this.spinner = true;
    this.apiService.getPortfolioData(payload).subscribe((res: any) => {
     if(res && res.success) {
      //calling api to get jar details for header details
      if(res?.data?.data?.portfolio_state == 'rebalance_in_progress') {
        this.rebalancingInProgress = true;
      }
      this.isAfterMarketOrder = res.data?.data?.is_after_market_order;
      this.isBasketRebalanced = res.data?.data?.isRebalanced;
      this.isRenewNow = res.data?.data?.is_getting_renewed;
      this.apiService.getProductData(payload).subscribe((res: any) => {
        if(res && res.success) {
          this.jarDetails = res?.data?.data?.jar_details;
          this.investementDetails = res?.data?.data?.investment_details;
          this.minimum_Investement_Amount = Math.ceil(res.data.data.jar_details.min_investment_amount);
        }
        else {
          this.toast_message.error(res.data.data.message);
        }
      });
      if(res.data.data?.portfolio_type == 'model_portfolio') {
        this.invest_now_text = 'Invest Now';
      // if(res.data.data?.is_getting_renewed) {
      //   this.invest_now_text = 'Renew Now';
      // }
      this.stocksList = res.data.data?.portfolio_composition;
      // this.currentUser = true;
      // this.existinguser = false;
      }
      this.invest_now_text = this.getBtnText(res?.data?.data);
      if(res.data.data?.portfolio_type == 'user_portfolio') {
          this.isUserPortfolio = true;
          this.stocksList = res.data.data.portfolio_composition;
      }
      this.spinner = false;
     }
     else {
      this.toast_message.error(res.data.data.message);
     }
    },
  
  (err: any) => {
    this.spinner = false;
  })
  }

  getBtnText(firstTrueKey: any | null): any {
    if (firstTrueKey.is_getting_renewed) {
      this.btnColorExistingUser = '#E17466';
      return 'Renew Now';
    } else {
      switch (firstTrueKey.portfolio_state) {
        case 'rebalance_now':
          this.btnColorExistingUser = '#E17466';
          return 'Rebalance Now';
        case 'invest_more':
          this.btnColorExistingUser = '#C3966E';
          return 'Invest More';
        case 'invest_now':
          this.btnColorExistingUser = '#C3966E';
          return 'Invest Now';
        case 'fix_investment':
          this.btnColorExistingUser = '#E17466';
          return 'Fix Investment';
        case 'order_in_progress':
          this.btnColorExistingUser = '#C3966E';
          return 'Order Pending';
        case 'is_renewal':
          return 'Renew Portfolio';
        case 'rebalance_in_progress':
          this.btnColorExistingUser = '#C3966E';
          return 'Invest Now';
        default:
          return 'No Action Available';
      }
    }
    
  }


  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'dashboard',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: false,
        autoFocus: true,
        backdropClass: 'backdropBackground',
        width: isMobileView ? '100%' : '100%',
        data: {
          closeLogin: true
        }
      });
    }
  }

}
