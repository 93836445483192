import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerComponent implements ControlValueAccessor {
  @ViewChild('datepickerFooter', { static: false })
  datepickerFooter!: ElementRef;
  @ViewChild('picker', { static: false }) picker!: MatDatepicker<any>;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() clearable: boolean = false;
  @Input() minDate!: null | Date;
  @Input() maxDate!: null | Date;
  @Input() name: string = '';
  @Output() dateChange = new EventEmitter();
  @Input() showError: boolean = false;
  @Input() ngBlur: boolean = false;
  @Input() isSuffix: boolean = false;
  @Input() errorMessage: string = '';
  @Input()
  _dateValue: any = new Date();
  @Input() placeholder: string = '';
  @Input() isDisabled: boolean = false;
  startDate: Date | undefined;

  constructor() {
    const today = new Date();
    this.startDate = new Date(today.setFullYear(today.getFullYear() - 25));
  }

  today() {
    this.dateValue = new Date();
    this.picker.close();
  }

  clear() {
    this.picker.close();
  }

  get dateValue() {
    return this._dateValue;
  }

  onDateChange() {
    this.dateChange.emit();
  }

  set dateValue(val) {
    this._dateValue = val;
    this.propagateChange(this._dateValue);
  }

  addEvent(type: string, event: any) {
    this.dateValue = event.value;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.dateValue = value;
    }
  }
  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  clearDate(event: any) {
    event.stopPropagation();
    this.dateValue = null;
    this.dateChange.emit();
  }
}
