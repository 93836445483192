<div class="more-details-wrapper">
    <div class="main-header">
        <div class="close-icon" (click)="closeDialog()">
            <img src="../../../../assets/crossIconDark.svg" style="cursor: pointer;" alt="">
        </div>
    <div class="row">
        <div class="col-4">
            <div class="content">
                <div class="product_name">{{moreDetails.sub_product_name}}</div>
                <div class="product_description">{{moreDetails.product_description}}</div>
            </div>
        </div>
        <div class="col-8 investment-details">
            <div class="content">
                <div class="product_description">Minimum Investment</div>
                <div class="bold-text"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{moreDetails?.pb_details?.min_investment_amount | readableNumber}}</div>
            </div>
            <div class="content">
                <img src="../../../../../assets/hr_vertical.svg" alt="">
            </div>
            <div class="content">
                <div class="product_description">Investment period</div>
                <div class="bold-text">{{moreDetails?.investment_horizon}}</div>
            </div>
            <!-- <div class="content">
                <div class="product_description">3Y  CAGR</div>
                <div class="cagr-text">
                    <img [src]="moreDetails?.pb_details.cagr < 0 ? '../../../../assets/downwardArrow.svg' : '../../../../assets/upwardArrowIcon.svg'"
                             alt="">&nbsp;{{ moreDetails?.pb_details.cagr ?? 0 }} %
                </div>
            </div> -->
            <div class="content" style="display: flex; flex-direction: column; gap: 10px;">
                <div class="product_description">
                    <span class="basket-price">Basket Price: &nbsp;</span> <span class="price"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{moreDetails?.fees_details[0]?.details.advisory_fees | readableNumber}}</span>
                    <br>
                    <span class="gst-text">Exclusive of all taxes</span>
                </div>
                <div>
                    <app-button [savingsText]="moreDetails?.is_getting_renewed 
                    ? 'Renew Now' 
                    : !moreDetails?.is_subscribed && !moreDetails?.is_getting_onboard
                    ? 'Subscribe Now' 
                    : !moreDetails?.is_subscribed && moreDetails?.is_getting_onboard
                    ? 'Continue'
                    : 'No Action Available'" ></app-button>
                </div>
            </div>
        </div>
    </div>
    </div>
    <br>
    <div>
        <img src="../../../../assets/hrDarkTagIcon.svg" alt="">
    </div>
    <br>
    <div class="row">
      <div class="col-7">
        <div class="data-details-wrapper">
            <div *ngFor="let detail of moreDetails.product_more_details" class="detail-item">
              <h3 class="detail-title">{{ detail.title }}</h3>
              <div class="detail-desc" [innerHTML]="detail.desc"></div>
            </div>
        </div>
      </div>
      <div class="col-5">
          <video [src]="videoURL" controls height="40%" width="100%" [poster]="thumbnailUrl">
            Your browser does not support the video tag.
        </video> 
      </div>
    </div>
</div>