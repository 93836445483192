import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, retry } from 'rxjs';

@Injectable({
  providedIn: `root`,
})
export class ApiServiceService {
  constructor(private http: HttpClient) {}

  homePageService() {
    return this.http.get(`${environment.apiUrl}/v1/desktop/config/default`);
  }

  faqData() {
    return this.http.get(`${environment.apiUrl}/v1/desktop/component/faq`);
  }

  basketPageService() {
    return this.http.get(`${environment.apiUrl}/v1/desktop/config/baskets`);
  }

  continueRiskProfile(reqData: any) {
    return this.http.post(
      `${environment.onboarding_api_url}api/riskProfile/${reqData.step_id}`,
      reqData
    );
  }

  getCategoriesList() {
    let authToken = localStorage.getItem('authToken');
    let url: any;
    if(authToken) {
      url = `${environment.apiUrl}/v1/desktop/categories`;
    }
    else {
      url = `${environment.apiUrl}/v1/desktop/categories?byPassToken=true`;
    }
    return this.http.get(url,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
    );
  }

  getBasketData(category_id: any) {
    let authToken = localStorage.getItem('authToken');
    let url: any;
    if(authToken) {
      url = `${environment.apiUrl}/v1/desktop/categories/${category_id}`;
    }
    else {
      url = `${environment.apiUrl}/v1/desktop/categories/${category_id}?byPassToken=true`;
    }
    return this.http.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getImagefromId(id: any) {
    return this.http.get(`${environment.apiUrlFiles}/file/${id}`, {
      responseType: 'blob',
    });
  }

  saveDataInRedis(reqData: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/onboarding-status`,
      reqData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getDataFromRedis(reqData: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/onboarding-status/${reqData}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getPortfolioDetails(reqData: any) {
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/draft-portfolio/${reqData.investment_amount}/${reqData.code_id}/${reqData.customerId}`
    );
  }

  getPortfolioData(reqData: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/portfolio/${reqData.customer_id}/${reqData.code_id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getPortfolioExitedStocks(reqData: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/portfolio/${reqData.customer_id}/${reqData.code_id}&mode=exit`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getProductData(reqData: any) {
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/portfolio/product/${reqData.customer_id}/${reqData.code_id}`
    );
  }

  createPortfolioService(reqData: any, investType: any) {
    let url: any;
    if(investType == `Invest Now`) {
    url = `${environment.apiUrl}/v1/desktop/user-portfolio`;
    }
    else if(investType == `Invest More`) {
    url = `${environment.apiUrl}/v1/desktop/user-portfolio/invest-more`;
    }
    return this.http.post(
      `${url}`, reqData
    );
  }

  getRebalancingData(reqData: any) {
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/user-rebalance-history/${reqData.customer_id}/${reqData.code_id}`
    );
  }

  getPurchasedBasketData(reqData: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/user-portfolio-list/${reqData}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getRecommendedBasketData() {
    let authToken = localStorage.getItem('authToken')
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/recommend-basket`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getAllBasketData() {
    let authToken = localStorage.getItem('authToken');
    let url: any;
    if(authToken) {
      url = `${environment.apiUrl}/v1/desktop/categories/all`;
    }
    else {
      url = `${environment.apiUrl}/v1/desktop/categories/all?byPassToken=true`;
    }
    return this.http.get(url,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
    );
  }

  getWatchListData(token: any) {
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/watch-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  addToWatchList(reqData: any) {
    let authToken = localStorage.getItem('authToken')
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/watch-list`, reqData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  removeWatchList(reqData: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.delete(
      `${environment.apiUrl}/v1/desktop/watch-list/${reqData}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getNotificationList(customer_id: any, pageNumber: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/notification/list?page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }

  verifyIFSCCode(
    reqObj: any,
    onboardingID: any
  ): Observable<any> {
    const headers = new HttpHeaders().set('authorization', onboardingID);
    return this.http.post(
      `${environment.onboarding_api_url_cans}verify-ifsc`, reqObj,
      { headers: headers }
    );
  }

  getBranchDetails(bankObj: any, onboardingID: any): Observable<any> {
    const headers = new HttpHeaders().set('authorization', onboardingID);
    let send_data = '';
    Object.keys(bankObj).map((key)=> {
      send_data = send_data + (send_data != '' ? '&' : '') + key + '=' + bankObj[key];
    });
    return this.http.get(
      `${environment.onboarding_api_url_cans}branch-detail?${send_data}`,
      { headers: headers }
    );
  }

  markPaymentCompletion(payload: any) {
    return this.http.post(
      `${environment.onboarding_api_url}api/payment/31`,
        payload
    );
  }

  markNotificationAsRead(notificationId: any, deviceId: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.put(
      `${environment.apiUrl}/v1/desktop/notification/mark-delivered/${notificationId}/${deviceId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }

  markAllNotificationAsRead(cust_id: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.put(
      `${environment.apiUrl}/v1/desktop/notification/mark-all-delivered`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
  }

  assignPortfolio(req: any) {
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/user-portfolio/assign`,
      req
    )
  }

  correctPortfolio(req: any) {
    return this.http.post(
      `${environment.apiUrl}/v1/desktop/user-portfolio/correct-investment`,
      req
    )
  }

  getLatestOnboardingDetails(customer_id: any, sub_product_id: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/onboarding-details/${sub_product_id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  benchmarkData() {
    return this.http.get(`${environment.apiUrl}/v1/desktop/top-benchmark`)
  }

  getAllDashboardData(custId: any) {
    return this.http.get(`${environment.apiUrl}/v1/desktop/portfolio/products/${custId}`)
  }

  getInsightsData(id: any) {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/product/${id}/insights?byPassToken=false`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getPurchaseHistory() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/purchase-history?page=1&limit=100`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getBlogsData() {
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/blogs`);
  }

  requestCallBack() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/request-callback`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getMediaService() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/media-features`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getLeadersService() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/leaders`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getTestimonialService() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/testimonials`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getProfileData() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/user/profile`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  getDisclosureData() {
    let authToken = localStorage.getItem('authToken');
    return this.http.get(
      `${environment.apiUrl}/v1/desktop/disclosure-disclaimer?refetch=true`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  decodeToken(str: any) {
    // Replace URL-safe characters ('-' and '_') with base64 standard characters ('+' and '/')
    str = str.replace(/-/g, '+').replace(/_/g, '/');

    // Add padding if necessary (Base64 strings need to be a multiple of 4)
    while (str.length % 4 !== 0) {
      str += '=';
    }

    // Decode the base64 string
    return atob(str);
  }

  deleteUserAccount() {
    let authToken = localStorage.getItem('authToken');
    return this.http.delete(
      `${environment.apiUrl}/v1/desktop/user/delete`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }
 }
