import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { Router } from '@angular/router';
import { ScreenTypeService } from '../../services/screen-type.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OnboardingService } from '../../services/onboarding.service';
import { UserService } from '../../services/user.service';
import { StepsManagerService } from '../../services/steps-manager.service';
import { ApiServiceService } from '../../services/api-service.service';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-fatca',
  templateUrl: './fatca.component.html',
  styleUrls: ['./fatca.component.scss'],
})
export class FatcaComponent implements OnInit {

  getAuthToken: string | undefined;
  usernameToolBar: any;
  userName: any;
  spinner: boolean = false;
  selectedOption: any;
  //all fields error message
  error_message_place: string = '';
  error_message_country_birth: string = '';
  error_message_country_citizenship: string = '';
  error_message_country_nationality: string = '';
  error_message_taxIdentificationNumber: string = '';
  error_message_country_taxResidency: string = '';
  error_message_taxIdentificationType: string = '';
  //
  //all fields
  placeOfBirth: any;
  countryOfBirth: any;
  countryOfCitizenship: any;
  countryOfNationality: any;
  countryOfTaxResidency: any;
  taxIdentificationNumber: any;
  taxIdentificationType: any;
  //

  isOptionSelected: boolean = false;

  // dropdownOptions = [
  //   { label: 'Option 1', value: 1 },
  //   { label: 'Option 2', value: 2 },
  //   { label: 'Option 3', value: 3 }
  // ];

  dropdownOptions: any[] = [];

  constructor(
    public screenType: ScreenTypeService,
    private route: Router,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    private router: ActivatedRoute,
    private onboardingService: OnboardingService,
    private user_service: UserService,
    private steps_service: StepsManagerService,
    private apiService: ApiServiceService,
  ) {

  }

  ngOnInit() {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if(token && data.customer_meta_info.is_registered) {
      console.log('ngOnInit token data', data);
      const name = data?.name || data?.full_name || '';
      this.usernameToolBar = true;
      this.userName = name;
    } else {
      this.openLogin('');
    }
    this.getCountriesList();
    this.fatcaDetails();
  }

  getCountriesList() {
    this.onboardingService.getMasterCountries().subscribe({
      next: (res: any) => {
        console.log('Country Code : ', res);
        if (res && res.message == 'success') {
          this.dropdownOptions = res.data;
          const indiaIndex = this.dropdownOptions.findIndex(country => country.name === 'India');
          if (indiaIndex !== -1) {
            this.countryOfBirth = this.dropdownOptions[indiaIndex].country_id;
            this.countryOfCitizenship = this.dropdownOptions[indiaIndex].country_id;
            this.countryOfNationality = this.dropdownOptions[indiaIndex].country_id;
            this.countryOfTaxResidency = this.dropdownOptions[indiaIndex].country_id;
          }
        }
      },
      error: (err) => {
        this._toastrService.error('unable to fetch country list');
      },
    });
  }

  onSelectedValueChange(value: any, fieldType: any) {
    console.log('Selected value:', value);
    switch(fieldType) {
      case 'CountryofBirth':
        this.countryOfBirth = value;
        break;
      case 'CountryofCitizenship':
        this.countryOfCitizenship = value;
        break;
        case 'CountryOfNationality':
        this.countryOfNationality = value;
        break;
        case 'CountryOfTaxResidency':
        this.countryOfTaxResidency = value;
        break;
  
      default:
        break;
    }
  }

  fatcaDetails() {
    this.onboardingService.getOnboardingData(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.message == 'success') {
       if(res.data.fatca[0].fatca_detail_id) {
       this.onboardingService.getFatcaData(res.data.fatca[0].fatca_detail_id, this.user_service.getHashKey()).
       subscribe((res: any) => {
       console.log('got fatca data......', res);
       this.selectedOption = res.data.is_tax_residency_india ? 'Yes' : 'No';
       this.placeOfBirth = res.data.place_of_birth;
       this.countryOfBirth = res.data.country_of_birth;
       this.countryOfCitizenship =
         res.data.country_of_citizenship;
       this.countryOfNationality =
         res.data.country_of_nationality;
       this.countryOfTaxResidency = res.data.country_of_tax_residency;
       this.taxIdentificationNumber = res.data.tax_identification_no;
       this.taxIdentificationType =
         res.data.tax_identification_type;
       })
       }
      }
    })
  }

  onSelectionChange(event: any) {
    this.selectedOption = event.value;
    console.log(event.value);
  }

  onSubmit() {
    console.log(this.selectedOption);
    if(this.selectedOption) {
    this.isOptionSelected = false;
      //apply all fields validations
      let reqData: any;
      if(this.selectedOption == 'Yes') {
         reqData = {
          place_of_birth: this.placeOfBirth,
          country_of_birth: this.countryOfBirth,
          country_of_citizenship: this.countryOfCitizenship,
          country_of_nationality: this.countryOfNationality,
          is_tax_residency_india: true,
          country_of_tax_residency: this.countryOfTaxResidency,
          tax_identification_no: this.taxIdentificationNumber,
          tax_identification_type: this.taxIdentificationType,
          }
      }
      else {
        reqData = {
          place_of_birth: undefined,
          country_of_birth: undefined,
          country_of_citizenship: undefined,
          country_of_nationality: undefined,
          is_tax_residency_india: false,
          country_of_tax_residency: undefined,
          tax_identification_no: undefined,
          tax_identification_type: undefined,
          }
      }
      this.onboardingService.saveFatcaDetails(reqData, this.user_service.getHashKey()).subscribe((res: any) => {
        if(res && res.message == 'success') {
          let id = this.user_service.getHashKey();
          // this.route.navigate(['agreement', id]);
          this.steps_service.initializeAndNavigate();
        }
      })
    }
    else {
    this.isOptionSelected = true;
    }
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: 'fatca_detail',
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: true,
        width: isMobileView ? '100%' : '100%',
      });
    }
  }

}
