import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenTypeService } from '../../services/screen-type.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ApiServiceService } from '../../services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import * as Highcharts from 'highcharts';
import { LoginComponent } from '../auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-insights-tab',
  templateUrl: './insights-tab.component.html',
  styleUrls: ['./insights-tab.component.scss'],
})
export class InsightsTabComponent implements OnInit {
  jarDetails: any;
  minimum_Investement_Amount: number = 0;
  investementDetails: any;
  customer_Id: any;
  videoURL: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  benchmarkChartData: any;
  selectedFilter: string = '3 Year';
  usernameToolBar: boolean = false;
  userName: string = '';
  codeId: any;

  constructor(public screenType: ScreenTypeService,
    private activated_route: ActivatedRoute,
    private apiService: ApiServiceService,
    private toast_message: ToastrService,
    private sanitizer: DomSanitizer,
    private route: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    if (token) {
      // const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      // const data = JSON.parse(jsonString);
      // console.log('ngOnInit token data', data);
      // this.customer_Id = data.customer_id;
      // const name = data?.name || data?.full_name || '';
      // this.usernameToolBar = true;
      // this.userName = name;
      this.apiService.getTokenData().subscribe((res: any) => {
        if(res && res.message == 'success') {
            if (res.data?.customer_meta_info?.is_registered) {
              const name = res.result?.first_name || res.result?.full_name || '';
              this.customer_Id = res.result?.customer_id;
              this.usernameToolBar = true;
              this.userName = name;
            }
        }
      })
    } else {
      this.openLogin('');
    }
    this.activated_route.queryParams.subscribe(params => {
      this.getPortfolioData(params['code_id']);
      this.getSubProductId(params['code_id']);
      this.codeId = params['code_id'];
    })
  }

  selectFilter(filter: string): void {
    this.selectedFilter = filter;
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getPortfolioData(product_code: any) {
    let payload = {
      customer_id: this.customer_Id,
      code_id: product_code
    }

    this.apiService.getProductData(payload).subscribe((res: any) => {
      if(res && res.message == 'success') {
        this.jarDetails = res?.result?.data?.jar_details;
        this.minimum_Investement_Amount = Math.round(res?.result?.data?.jar_details?.min_investment_amount);
        this.investementDetails = res?.result?.data?.investment_details;
      }
      else {
        this.toast_message.error(res.result?.data.message);
      }
    })
  }

  getSubProductId(code_id: any) {
    this.apiService.getAllBasketData().subscribe((res: any) => {
      if(res.message == 'success') {
        for(let data of res?.result) {
           if(data.sub_product_code == code_id) {
            this.getInsightsData(data.sub_product_id);
           }
        }
      }
      else {
        this.toast_message.error('Unable to fetch sub product id');
      }
    });
  }

  getInsightsData(sub_product_code: any) {
   this.apiService.getInsightsData(sub_product_code).subscribe((res: any) => {
    console.log('insights data received..', res);
    if(res.message == 'success') {
      this.benchmarkChartData = res?.result?.pb_details;
      this.initializeChartOptions(res?.result?.pb_details);
      this.apiService.getImagefromId(res?.result?.video_url).subscribe((res: Blob) => {
        const videoUrl = URL.createObjectURL(res);
        this.videoURL = this.sanitizer.bypassSecurityTrustUrl(videoUrl);
      })
    }
    else {
      this.toast_message.error('Failed to load insights details');
    }
   })
  }

  initializeChartOptions(data: any) {
    console.log('chart data', data);
    this.chartOptions = {
        chart: {
            type: 'areaspline'
        },
        title: {
            text: '',
            align: 'left'
        },
        xAxis: {
            categories: data?.benchmark_comparison.axis_date,
            title: {
                text: ''
            }
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            shared: true,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.5
            }
        },
        series: [
            {
                name: data?.benchmark_comparison.jar_name,
                data: data?.benchmark_comparison.axis_jar,
                type: 'areaspline',
                color: '#E49944'
            },
            {
                name: data?.benchmark_comparison.benchmark_name,
                data: data?.benchmark_comparison.axis_benchmark,
                type: 'areaspline',
                color: '#7E8B99'
            }
        ],
        legend: {
          enabled: false
      }
    };
    console.log('sjksjksjksjksjk',this.chartOptions)
  }

  openLogin(data: string) {
    const isMobileView = this.screenType.breakpointState === 'mob';
    if (isMobileView) {
      let data = {
        customData: `insights?code_id=${this.codeId}`,
      };
      let navigationExtras: NavigationExtras = {
        state: {
          customData: data,
        },
      };
      this.route.navigate(['login'], navigationExtras);
    } else {
      const dialogRef = this.dialog.open(LoginComponent, {
        height: 'auto',
        disableClose: false,
        autoFocus: true,
        backdropClass: 'backdropBackground',
        width: isMobileView ? '100%' : '100%',
      });
    }
  }
}
