import { Component } from '@angular/core';
import { ScreenTypeService } from '../../services/screen-type.service';
import { UserService } from '../../services/user.service';
import { ApiServiceService } from '../../services/api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-rebalance-history',
  templateUrl: './rebalance-history.component.html',
  styleUrl: './rebalance-history.component.scss'
})
export class ReBalanceHistoryComponent {
  product_code: any;
  customerId: any;
  rebalancingData: any[] = [];
  jarDetails: any;
  usernameToolBar: boolean = false;
  userName: string = '';
  investementDetails: any;
  isHidden = true;
  minimum_Investement_Amount: number = 0;
  noData: boolean = false;

  constructor(
    private route: Router,
    public screenType: ScreenTypeService,
    private user_service: UserService,
    private apiService: ApiServiceService,
    private toast_message: ToastrService,
    private activated_route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.activated_route.queryParams.subscribe(params => {
      this.product_code = params['product_code'];
      let token = '';
      token = localStorage.getItem('authToken') || '';
      if(token) {
        var jsonString = this.apiService.decodeToken(token.split('.')[1]);
        var data = JSON.parse(jsonString);
      }
      if(token && data?.is_other_product_active) {
        const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
          height: 'auto',
          disableClose: true,
          autoFocus: false,
          backdropClass: 'backdropBackground',
          width: 'auto',
        });
      }
      else if(token && data.customer_meta_info.is_registered) {
        console.log('ngOnInit token data', data);
        this.customerId = data.customer_id;
        const name = data?.name || data?.full_name || '';
        this.usernameToolBar = true;
        this.userName = name;
      }
      this.getPortfolioData();
      this.getRebalancingData();
    });
    // this.getDataFromRedis();
  }

  goToDashboard() {
    this.route.navigate(['dashboard']);
  }

  getDataFromRedis() {
    this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.success) {
        console.log('got dashboard redis',res);
        this.product_code = res.data.productCode;
        this.getPortfolioData();
        this.getRebalancingData();
      }
     })
  }

  isLastTransaction(transaction: any, transactions: any[]): boolean {
    return transactions.indexOf(transaction) === transactions.length - 1;
  }

  getPortfolioData() {
    let payload = {
      customer_id: this.customerId,
      code_id: this.product_code
    }
      //calling api to get jar details for header details
      this.apiService.getProductData(payload).subscribe((res: any) => {
        if(res && res.success) {
          this.jarDetails = res.data.data.jar_details;
          this.investementDetails = res.data.data.investment_details;
          this.minimum_Investement_Amount = Math.round(res.data.data.jar_details.min_investment_amount);
        }
        else {
          this.toast_message.error(res.data.data.message);
        }
      });
      
  }

  getRebalancingData() {
    // this.rebalancingData = [
    //   {
    //     "rebalance_date": "2024-05-10",
    //     "total_buy_value": 19867.4,
    //     "total_sell_value": 19882.5,
    //     "transactions": [
    //       {
    //         "symbol": "HDFCBANK",
    //         "transaction_type": "SELL",
    //         "transaction_quantity": 10,
    //         "transaction_price": 1100
    //       },
    //       {
    //         "symbol": "CIPLA",
    //         "transaction_type": "SELL",
    //         "transaction_quantity": 25,
    //         "transaction_price": 355.3
    //       },
    //       {
    //         "symbol": "ICICIBANK",
    //         "transaction_type": "BUY",
    //         "transaction_quantity": 6,
    //         "transaction_price": 1833.2
    //       },
    //       {
    //         "symbol": "UNOMINDA",
    //         "transaction_type": "BUY",
    //         "transaction_quantity": 11,
    //         "transaction_price": 806.2
    //       }
    //     ]
    //   },
    //   {
    //     "rebalance_date": "2024-04-22",
    //     "total_buy_value": 20942.5,
    //     "total_sell_value": 22075.2,
    //     "transactions": [
    //       {
    //         "symbol": "MARUTI",
    //         "transaction_type": "SELL",
    //         "transaction_quantity": 3,
    //         "transaction_price": 3098
    //       },
    //       {
    //         "symbol": "M&M",
    //         "transaction_type": "SELL",
    //         "transaction_quantity": 6,
    //         "transaction_price": 2130.2
    //       },
    //       {
    //         "symbol": "RELIANCE",
    //         "transaction_type": "BUY",
    //         "transaction_quantity": 2,
    //         "transaction_price": 4130.6
    //       },
    //       {
    //         "symbol": "VBL",
    //         "transaction_type": "BUY",
    //         "transaction_quantity": 41,
    //         "transaction_price": 309.3
    //       }
    //     ]
    //   }
    // ]
    let payload = {
      customer_id: this.customerId,
      code_id: this.product_code
    }
  this.apiService.getRebalancingData(payload).subscribe((res: any) => {
    console.log('got rebalancing data',res);
    if(res && res.success) {
     if(res.data.status) {
     this.noData = false;
     this.rebalancingData = res.data.data;
     }
     else {
     this.noData = true;
     }
    }
  })
  }

  openLogin(data: string) {}


}
