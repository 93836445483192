import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements AfterViewInit {
  selectedItem: string | null = null;

  @Input() list: any[] = [];
  @Input() mainQuestion:any;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() selectedQue: any | undefined;
  @Output() listSelectionChange: EventEmitter<''> = new EventEmitter();

  ngAfterViewInit() {
    // this.changeSelectedOption(this.selectedQue);
  }

  changeSelectedOption(listData: any) {
    this.list.forEach((element) => {
      element.selected = element.label == listData.label;
    });
  }

  onItemClick(item: any) {
    console.log('jkdjkdjk', item);
      this.selectedItem = item;
      if (item.error) {
        this.changeSelectedOption(item);
      } else {
        if (!this.disabled && !this.loading) {
          console.log('on Item click : ', item);
          this.selectedQue = null;
          this.listSelectionChange.emit(item);
          this.listSelectionChange.emit({'selectedItem':item,...this.mainQuestion});
          this.changeSelectedOption(item);
        }
      }
  }
}
