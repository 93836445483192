import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppearanceType } from '../../model/types';
import { SelectData } from 'src/app/core/models/types';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent
  implements OnInit, ControlValueAccessor, OnChanges {
  @Input() label: string = '';
  @Input() appearance = AppearanceType.outline;
  @Input() groupList: any[] = [];
  @Input() list: Array<SelectData> = [];
  @Input() appendTo: string = '';
  @Input() clearable: boolean = true;
  @Input() virtualScroll: boolean = true;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() searchable: boolean = true;
  @Input() isMultiple: boolean = false;
  @Input() name: string = '';
  @Input() groupBy: string = 'group';
  @Input() bindValue: string = 'key';
  @Input() bindLabel: string = 'value';
  @Input() selection!: any;
  @Input() addNewItems: boolean = false;
  @Input() addNewItemsText: string = '';
  @Input() id: string = 'my-select-box';
  @Input() dynamicOption!: TemplateRef<any>;
  @Input() dynamicGroupOption!: TemplateRef<any>;
  @Input() dynamicLabelOption!: TemplateRef<any>;
  @Output() selectionChange = new EventEmitter();
  @Output() selectBoxOpened = new EventEmitter();
  @Input() required: boolean = false;
  @Input() loading: boolean = false;
  @Input() selectedValue!: any;
  @Input() description: string | undefined = '';

  selected!: SelectData;
  nodeType: any;

  errorIconExists: boolean = false;

  @Input() ngBlur: boolean = false;
  @Input() showError: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (
        changes['ngBlur'] &&
        changes['ngBlur'].currentValue != changes['ngBlur'].previousValue
      ) {
        if (changes['ngBlur'].currentValue == true) {
          this.addErrorIcon();
        } else {
          this.removeErrorIcon();
        }
      }

      if (
        changes['showError'] &&
        changes['showError'].currentValue != changes['showError'].previousValue
      ) {
        if (changes['showError'].currentValue == true) {
          this.addErrorIcon();
        } else {
          this.removeErrorIcon();
        }
      }
    }
  }

  addErrorIcon() {
    if (!this.ngBlur || !this.showError || this.errorIconExists) {
      return;
    }

    let element = document.getElementById(this.id);

    if (element) {
      let selectContainer = element.querySelector('.ng-select-container');
      const matIcon = document.createElement('mat-icon');
      matIcon.innerHTML = 'info';
      matIcon.className = 'mat-icon material-icons error-icon';

      selectContainer?.appendChild(matIcon);
      console.log('Element : ', selectContainer);
      this.errorIconExists = true;
    }
  }

  removeErrorIcon() {
    let element = document.getElementById(this.id);

    if (element) {
      let selectContainer = element.querySelector('.ng-select-container');

      let errorIcon = element.querySelector('.error-icon');
      if (errorIcon) {
        selectContainer?.removeChild(errorIcon);
        this.errorIconExists = false;
      }
    }
  }

  onBlur() {
    if (this.ngBlur == false) {
      this.ngBlur = true;
      this.addErrorIcon();
    }
  }

  selectBoxOpen(e: any) {
    this.selectBoxOpened.emit(e);
  }

  ngOnInit() {
    this.nodeType = this.list[1];
  }
  onSelectionChange(e: any) {
    if (e) this.selectedValue = e.key;
    this.onChange(this.selected);
    this.selectionChange.emit(e);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  clear(item: any) {}

  propagateChange: (value: any) => void = () => {};

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  writeValue(obj: any): void {
    if (obj !== null && obj !== this.selected) {
      this.selected = obj;
      this.propagateChange(this.selected);
      this.onSelectionChange(obj);
    }
  }
}
