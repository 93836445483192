<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>


<!-- laptop view -->
<div class="insights-wrapper" *ngIf="this.screenType.breakpointState !== 'mob'" style="padding-top: 100px;">
    <div class="row">
        <div class="col-7 heading-wrapper" style="display: flex; flex-wrap: wrap;">
            <span class="headerText">{{jarDetails?.jar_name}}
                <br>
                <span class="subText">This diversified portfolio comprises a selection of renowned large-cap stocks,
                representing industry leaders known for their stability and resilience.</span>
            </span>
            <!-- <span class="riskType"><span class="riskTypeText">Medium Risk&nbsp;&nbsp;</span></span>
            <span class="headerText"><img src="../../../../assets/informationIcon.svg" alt=""></span> -->
        </div>
        
     <div class="col-2" style="display: flex; align-items: center;">
        <!-- <span><a (click)="goToInsights(jarDetails)" class="anchorTag"><img src="../../../../assets/insightsIcon.svg" alt=""><span style="font-size: 14px;">More Insights</span></a></span> -->
     </div>
     <div class="col-3">
        <div class="industryContainer">
            <div>
            <div>5Y CAGR <img src="../../../../assets/tooltipTagIcon.svg"
            matTooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." alt=""></div>
            <div style="color: #0D7816; font-weight: 600; font-size: 18px; line-height: 27px;"><img src="../../../../assets/upwardArrowIcon.svg" alt=""> {{jarDetails?.cagr}}%</div>
            </div>

            <div>
                <div>Min Investment <img src="../../../../assets/tooltipTagIcon.svg"
                matTooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." alt=""></div> 
                <div><i class="fa fa-rupee"></i>&nbsp;<span style="font-weight: 600; color:#001E5A; font-size: 18px; line-height: 27px;">{{minimum_Investement_Amount | readableNumber}}</span></div>
            </div>
             
        </div>
     </div>
    </div>
    <div class="cards-wrapper">
        <mat-card style="width: 20%; border-radius: 10px;">
          <mat-card-content>
            <img src="../../../../assets/ic_Invested Amount.svg" alt=""> &nbsp;
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.invested_amount) | readableNumber}}<br>
            <span class="headSubText">Invested Amount</span></span>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 20%; border-radius: 10px;">
          <mat-card-content>
            <img src="../../../../assets/ic_Current Value.svg" alt=""> &nbsp;
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.current_value) | readableNumber}}<br>
            <span class="headSubText">Current Value</span></span>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 20%; border-radius: 10px;">
          <mat-card-content>
            <img src="../../../../assets/ic_Total Returns.svg" alt=""> &nbsp;
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.total_returns) | readableNumber}}&nbsp;<span class="incrementText">+{{(investementDetails?.total_returns_percentage)}}%</span><br>
            <span class="headSubText">Total Returns</span></span>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 20%; border-radius: 10px;">
          <mat-card-content>
            <img src="../../../../assets/ic_Day's P&L.svg" alt=""> &nbsp;
            <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.daily_profit_loss) | readableNumber}}&nbsp;<span class="decrementText">+{{(investementDetails?.daily_profit_loss_percentage)}}%</span><br>
            <span class="headSubText">Day’s P&L</span></span>
          </mat-card-content>
        </mat-card>
    </div>
    <div class="row" style="margin-top: 20px;">
    <img src="../../../../assets/hrDarkTagIcon.svg" alt="">
    </div>
    <div style="margin-top: 30px;">
        <div style="font-size: 20px; font-weight: 900;">Insights</div>
        <br>
        <div class="center">
            <video [src]="videoURL" controls width="90%">
                Your browser does not support the video tag.
            </video> 
        </div>
        <br>

        <div style="padding: 30px;">
            <span style="font-size: 20px; font-weight: 600; color: black;font-family: 'manrope';">Benchmark Comparision :</span>
            <br>
            <div style="display: flex; justify-content: space-between;">
                <div>
                    <span style="font-size: 12px; color: #858585;"><span class="basketCompareMark"></span><img src="../../../../assets/basketCompareMark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData.benchmark_comparison.jar_name}}</span>
                    &nbsp;&nbsp;
                    <span style="font-size: 12px; color: #858585;"><img src="../../../../assets/nifty50Mark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData.benchmark_comparison.benchmark_name}}</span>
                </div>
                <div class="chartFilter" style="border: 1px; width: 30%;">
                    <div [class.selected]="selectedFilter === '1 Year'" (click)="selectFilter('1 Year')">1 Year</div>
                        <div [class.selected]="selectedFilter === '3 Year'" (click)="selectFilter('3 Year')">3 Year</div>
                        <div [class.selected]="selectedFilter === '5 Year'" (click)="selectFilter('5 Year')">5 Year</div>
                </div>
            </div>
            <br>
            <div class="chart-container">
                <div class="chart-wrapper">
                    <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    style="width: 100%; height: 350px; display: block;">
                  </highcharts-chart>
                </div>
              </div>
        </div>
    </div>
</div>
<!--  -->
<!-- mobile view -->
<div class="insights-wrapper" *ngIf="this.screenType.breakpointState == 'mob'">
    <div class="row">
        <div class="col-12 heading-wrapper" style="display: flex; flex-wrap: wrap;">
            <span class="headerText">{{jarDetails?.jar_name}}&nbsp;&nbsp;</span>
        </div>
    </div>
    <br>
    <div class="row">
        <span style="font-size: 12px; font-weight: 400; color: #7E8B99;">This diversified portfolio comprises a selection of renowned large-cap stocks, representing industry leaders known for their stability and resilience.</span>
    </div>
    <div class="cards-wrapper">
        <mat-card style="width: 47%;">
            <mat-card-content>
                <img src="../../../../assets/ic_Invested Amount.svg" alt=""> &nbsp;
                <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.invested_amount) | readableNumber}}<br>
                <span class="headSubText">Invested Amount</span></span>
            </mat-card-content>
        </mat-card>
        <mat-card style="width: 47%;">
            <mat-card-content>
                <img src="../../../../assets/ic_Current Value.svg" alt=""> &nbsp;
                <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.current_value) | readableNumber}}<br>
                <span class="headSubText">Current Value</span></span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="cards-wrapper">
        <mat-card style="width: 47%;">
            <mat-card-content>
                <img src="../../../../assets/ic_Total Returns.svg" alt=""> &nbsp;
                <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.total_returns) | readableNumber}} &nbsp;<span class="incrementText">+{{(investementDetails?.total_returns_percentage)}}%</span><br>
                <span class="headSubText">Total Returns</span></span>
            </mat-card-content>
        </mat-card>
        <mat-card style="width: 47%;">
            <mat-card-content>
                <img src="../../../../assets/ic_Day's P&L.svg" alt=""> &nbsp;
                <span class="headText"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{(investementDetails?.daily_profit_loss) | readableNumber}}&nbsp;<span class="decrementText">+{{(investementDetails?.daily_profit_loss_percentage)}}%</span><br>
                <span class="headSubText">Day’s P&L</span></span>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="row" style="margin-top: 20px;">
    <img src="../../../../assets/hrTagsmallRiskProfile.svg" alt="">
    </div>
    <div style="margin-top: 30px;">
        <div style="font-size: 20px; font-weight: 900;">Insights</div>
        <br>
        <div class="center">
            <video [src]="videoURL" controls width="90%">
                Your browser does not support the video tag.
            </video> 
        </div>
        <br>

        <div style="padding: 30px;">
            <span style="font-size: 20px; font-weight: 600; color: black;font-family: 'manrope';">Benchmark Comparision :</span>
            <br>
            <div style="display: flex; justify-content: space-between;">
                <div>
                    <span style="font-size: 12px; color: #858585;"><span class="basketCompareMark"></span><img src="../../../../assets/basketCompareMark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData.benchmark_comparison.jar_name}}</span>
                    &nbsp;&nbsp;
                    <span style="font-size: 12px; color: #858585;"><img src="../../../../assets/nifty50Mark.svg" alt="">&nbsp;&nbsp;{{benchmarkChartData.benchmark_comparison.benchmark_name}}</span>
                </div>
            </div>
            <br>
            <div class="chartFilter" style="border: 1px; width: 100%;">
                <div [class.selected]="selectedFilter === '1 Year'" (click)="selectFilter('1 Year')">1 Year</div>
                    <div [class.selected]="selectedFilter === '3 Year'" (click)="selectFilter('3 Year')">3 Year</div>
                    <div [class.selected]="selectedFilter === '5 Year'" (click)="selectFilter('5 Year')">5 Year</div>
            </div>
            <br>
            <div class="chart-container">
                <div class="chart-wrapper">
                    <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    style="width: 100%; height: 350px; display: block;">
                  </highcharts-chart>
                </div>
              </div>
        </div>
    </div>
</div>
<!--  -->


