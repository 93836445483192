import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiServiceService } from '../../services/api-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UserAlreadyExistsComponent } from '../../shared/dialogs/user-already-exists/user-already-exists.component';
import { Router } from '@angular/router';
import { MoengageService } from '../../services/moengage.service';
import * as moment from 'moment';
import { OnboardingService } from '../../services/onboarding.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-basket-more-details',
  templateUrl: './basket-more-details.component.html',
  styleUrls: ['./basket-more-details.component.scss'],
})
export class BasketMoreDetailsComponent implements OnInit {
moreDetails: any;
videoURL: any;
thumbnailUrl: any;
tokenData: any;
customer_Id: any;
spinner: boolean = false;
  constructor(
    private route: Router,
    public dialog: MatDialog,
    private apiService: ApiServiceService,
    private sanitizer: DomSanitizer,
    private moengageService: MoengageService,
    private onboardingService: OnboardingService,
    private toast_message: ToastrService,
    private user_service: UserService,
    public dialogRef: MatDialogRef<BasketMoreDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  closeDialog(): void {
    this.dialogRef.close();
  }

  getVideo(videoUrl: any, thumbnailUrl: any) {
    this.videoURL = `${environment.apiUrlFiles}/file/${videoUrl}`;
    this.thumbnailUrl = `${environment.apiUrlFiles}/file/${thumbnailUrl}`;
  }

  ngOnInit() {
    this.moreDetails = this.data.moreDetails;
    console.log('ksjhskshjshj', this.moreDetails);
    this.getVideo(this.moreDetails.video_url, this.moreDetails.image_url);

    let token = '';
    token = localStorage.getItem('authToken') || '';
    if(token) {
      var jsonString = this.apiService.decodeToken(token.split('.')[1]);
      var data = JSON.parse(jsonString);
      this.tokenData = data;
    }
    if(token && data?.is_other_product_active) {
      const dialogRef = this.dialog.open(UserAlreadyExistsComponent, {
        height: 'auto',
        disableClose: true,
        autoFocus: false,
        backdropClass: 'backdropBackground',
        width: 'auto',
      });
    }
    else if (token && data.customer_meta_info.is_registered) {
      localStorage.setItem('categoryNameMoe', 'dashboard');
      console.log('ngOnInit token data', data);
      this.customer_Id = data.customer_id;
    }
  }

  buyRecommededBasket(data: any) {
    //call create onboarding API
    this.spinner = true;
    //call moengage subscribeNow event
    let eventDetails = {
      'basketName': data?.sub_product_name,
      'categoryName': 'Dashboard',
      'basketId': data?.category_id,
      'minInvestmentAmount': data?.pb_details?.min_investment_amount,
      'returns': data?.pb_details?.cagr || 0,
      'returnsDuration': data?.investment_horizon,
      'basketrisk': data?.pb_details?.risk_level,
      'basketprice': data?.fees_details[0]?.details?.advisory_fees
    }
    this.moengageService.subscribeNowEvent(eventDetails);
    //
    let payload = {
      "lead_id": this.tokenData.lead_id,
      "sub_product_id": data.sub_product_id,
      "country_id": this.tokenData.country_id === null ? 0 : this.tokenData.country_id,
      "state_id": this.tokenData.state_id === null ? 0 : this.tokenData.state_id,
      "complimentary_period": 0,
      "fees_details": data.fees_details[0],
      "first_year_amount": data.fees_details[0].details.max_yearly_amount,
      "advisory_discount_amount": 0,
      "immediate_advisory_payment_amount": data.fees_details[0].details.advisory_fees,
      "advisory_installment_option": data.advisory_installment_option[0],
      "advisory_number_of_installments": data.advisory_installment_option.length,
      "advisory_is_emandate": false,
      "advisory_emandate_date": moment().format('YYYY-MM-DD'),
      "advisory_emandate_discount": 0,
      "email_contact_detail_id": this.tokenData.email_contact_detail_id,
      "whatsapp_contact_detail_id": this.tokenData.mobile_contact_detail_id,
      "sms_contact_detail_id": this.tokenData.mobile_contact_detail_id
    }
    let authToken = localStorage.getItem('authToken');
    this.onboardingService.createOnboarding(payload, authToken).subscribe((res: any) => {
      this.spinner = false;
      console.log('lead creation api res..', res);
      if(res && res.success && Object.keys(res.data).length != 0) {
          const parts = res.data.url.split('/');
          const id = parts[parts.length - 1];
          this.user_service.pushHashKey(id);
    
          // call redis api to save data
          let payload = {
            id: this.user_service.getHashKey(),
            data: {
              categoryId: data.category_id,
              subProductId: data.sub_product_id,
              onboardingId: res.data.onboarding_id,
              productCode: data.sub_product_code,
            }
          }
          this.apiService.saveDataInRedis(payload).subscribe((res: any) => {
            console.log('got redis es..',res);
          })
          //
          this.dialogRef.close();
          this.route.navigate(['pan', id]);
      }
      else {
        this.toast_message.error('Unable to fetch onboarding details, please try again!');
      }
    },
    (err) => {
      this.spinner = false;
    }
    );
    //
    }

}
