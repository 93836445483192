<app-toolbar [showUserName] = "usernameToolBar" [username] = "userName" (loginClicked)="openLogin($event)"></app-toolbar>
<div class="main_wrapper_laptop" *ngIf="this.screenType.breakpointState !== 'mob'">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>
    <div style="padding: 40px;" *ngIf="!emptyState">
        <div class="container-text">
            <span class="headerText">Your Watchlist &nbsp;</span>
            <hr>
        </div>  
        <br>
    <mat-accordion>
        <div *ngFor="let data of watchList; let i = index" class="expansion-panel-wrapper">
            <mat-expansion-panel #panel hideToggle [expanded]="i === 0"
            (opened)="onPanelToggle(data, true)" (closed)="onPanelToggle(data, false)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="content-wrapper">
                            <span class="risk-data">
                            <span>{{ data?.sub_product_name }} &nbsp;
                                <span class="risk-wrapper" [ngStyle]="{
                                    'background-color': data.pb_details?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                                                        data.pb_details?.risk_level === 'LOW' ? '#E4F3E3' : 
                                                        data.pb_details?.risk_level === 'HIGH' ? '#FFECED' : '',
                                                        'display': 'inline-block',
                                                        'padding': '0.2rem 0.5rem',
                                                        'white-space': 'nowrap'
                                  }">
                                <span class="risk-text" [ngStyle]="{
                                    'color': data.pb_details?.risk_level === 'MEDIUM' ? '#9B6F49' : 
                                             data.pb_details?.risk_level === 'LOW' ? '#0AC01C' : 
                                             data.pb_details?.risk_level === 'HIGH' ? '#FF4347' : ''
                                  }">
                                    {{data.pb_details?.risk_level | camelCase}} Risk
                                </span>
                                </span>
                            </span>
                            </span>
                            <span class="sub-header">{{ data?.product_description }}</span>
                        </div>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div style="display: flex; justify-content: space-around; align-items: center; width: 100%;">
                            <div class="content-wrapper">
                                <span>Min Investment</span>
                                <span style="font-weight: 800; color: #001E5A;">
                                    <i class="fa fa-rupee"></i>&nbsp;{{ data?.pb_details?.min_investment_amount ?? 0 | decimalFormat }}
                                </span>
                            </div>
                            <img src="../../../../assets/hrVertical.svg" alt="" *ngIf="!panel.expanded">
                            <img src="../../../../assets/hrVertical.svg" alt="" style="margin-top: 25px;" *ngIf="panel.expanded">
                            <div class="content-wrapper">
                                <span>Investment period</span>
                                <span style="font-weight: 800; color: #001E5A;">
                                    {{ data?.investment_horizon }}
                                </span>
                            </div>
                            <!-- <div class="content-wrapper">
                                <span>3Y CAGR</span>
                                <span [ngClass]="{ 'blurred': !isLoggedIn }" style="color: #5BCD65; font-weight: 600;">
                                    <img src="../../../../assets/upwardArrowIcon.svg" alt="">
                                    {{ data?.pb_details?.cagr ?? 0 }} %
                                </span>
                            </div> -->
                            <div>
                                <div class="pricing-content">
                                    Basket Price: 
                                    <span class="price-value">
                                    <i class="fa fa-rupee"></i>&nbsp;{{ data?.fees_details[0].details.advisory_fees | readableNumber }}
                                    </span>
                                    <br>
                                    <span class="gst-text">Exclusive of all taxes</span>
                                </div>
                                <div class="button-position">
                                <app-button 
                                [savingsText]="data?.is_getting_renewed 
                                ? 'Renew Now' 
                                : !data?.is_subscribed && !data?.is_getting_onboard
                                ? 'Subscribe Now' 
                                : !data?.is_subscribed && data?.is_getting_onboard
                                ? 'Continue'
                                : data?.portfolio_state === 'fix_investment' 
                                ? 'Fix Investment' 
                                : data?.portfolio_state === 'rebalance_now' 
                                ? 'Rebalance Now' 
                                : data?.portfolio_state === 'invest_now' 
                                ? 'Invest Now'
                                : data?.portfolio_state === 'invest_more' 
                                ? 'Invest More' 
                                : data?.portfolio_state === 'rebalance_in_progress' 
                                ? 'Rebalancing...' 
                                : data?.portfolio_state === 'order_in_progress' 
                                ? 'Order Pending'
                                : 'No Action Available'"
                                    class="center"
                                    [backgroundColor]="data?.portfolio_state === 'fix_investment' || data?.portfolio_state === 'rebalance_now' ? '#E17466' : '#C3966E'"
                                    (click)="subscribeBasket(data, i); $event.stopPropagation()">
                                </app-button>
                                &nbsp;
                                <img
                                style="cursor: pointer;"
                                [matMenuTriggerFor]="menu"
                                src="../../../../assets/remove_watchlist.svg" alt=""
                                (click)="$event.stopPropagation()">
                           
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="removeWatchList(data); $event.stopPropagation()">
                                        <span style="font-size: 14px; color: #FF4347;">Remove From Watchlist</span>
                                    </button>
                                </mat-menu>
                               </div>
                            </div>
                        </div>
                        <span class="icons">
                            <img *ngIf="!panel.expanded" src=".././../../../assets/collapse_down.svg" alt="">
                            <img *ngIf="panel.expanded" src=".././../../../assets/collapse_up.svg" alt="">
                        </span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row mat-expansion-panel-body">
                    <img src="../../../../assets/hrDarkTagIcon.svg" alt="">
                    <br>
                    <br>
                    <br>
                    <div class="col-7">
                        <div class="more-details-wrapper">
                            <div *ngFor="let detail of data.product_more_details" class="detail-item">
                              <h3 class="detail-title">{{ detail.title }}</h3>
                              <div class="detail-desc" [innerHTML]="detail.desc"></div>
                            </div>
                        </div> 
                    </div>
                    <div class="col-5" style="display: flex; justify-content: flex-end;">
                        <video [src]="data?.videoBlobUrl" controls height="50%" width="100%" [poster]="data?.image_url"
                        (play)="videoClick($event, data)"
                        (pause)="videoPaused($event, data)">
                            Your browser does not support the video tag.
                        </video> 
                    </div>
                </div>
            </mat-expansion-panel>
            <!-- <div class="svg-wrapper">
                <img src="../../../../assets/basket_icon.svg" alt="Icon" class="top-right-svg">
            </div> -->
        </div>
    </mat-accordion>  
    </div>
    <div class="emptyState" *ngIf="emptyState">
        <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
    </div>
    <app-footer-curve *ngIf="isDataLoaded"></app-footer-curve>
    <app-footer *ngIf="isDataLoaded"></app-footer>
</div>

<div class="main_wrapper_mobile" *ngIf="this.screenType.breakpointState == 'mob'" style="margin-bottom: 70px; overflow-x: hidden;">
    <div class="overlay" *ngIf="spinner">
        <div class="spinner">
          <img src="../../../../assets/loader.svg" alt="" class="loader">
        </div>
    </div>
    <app-toolbar-mobile></app-toolbar-mobile>
    <div *ngIf="!emptyState">
    <div *ngFor="let data of watchList; let i = index">
        <div class="future-industry-wrapper" [style.background-color]="i % 2 !== 0 ? 'transparent' : ''" *ngIf="!emptyState">
            <div class="row">
                <div class="col-12">
                    <div class="header-text" style="color: white;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">{{data?.sub_product_name}}
                        <!-- <span class="riskTypeText" [style.background-color]="i % 2 !== 0 ? '#F5D16099' : '#BBA14F99'"
                        [style.color]="i % 2 !== 0 ? '#BC920B' : '#FFC200'">{{data?.pb_details?.risk_level + ' ' + 'RISK'}}</span> -->
                    </div>

                    <div style="display: flex; justify-content: flex-start;">
                        <span class="risk-wrapper-mobile" [ngStyle]="{
                            'background-color': data.pb_details?.risk_level === 'MEDIUM' ? '#F9F5F1' : 
                                                data.pb_details?.risk_level === 'LOW' ? '#E4F3E3' : 
                                                data.pb_details?.risk_level === 'HIGH' ? '#FFECED' : ''
                          }">
                        <span class="risk-text" [ngStyle]="{
                            'color': data.pb_details?.risk_level === 'MEDIUM' ? '#9B6F49' : 
                                     data.pb_details?.risk_level === 'LOW' ? '#0AC01C' : 
                                     data.pb_details?.risk_level === 'HIGH' ? '#FF4347' : ''
                          }">
                            {{data.pb_details?.risk_level | camelCase}} Risk
                        </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row industryContainerMobile" [ngStyle]="{ 'color': i % 2 === 0 ? '#C6C6C6' : '', 'background-color': i % 2 !== 0 ? '#E9E9E9' : '' }" style="width: 100%; margin-left: 0px; margin-top: 10px;">
                <div style="display: flex; justify-content: space-between;">
                <!-- <div>                    
                <div>3Y CAGR</div>
                <div [ngClass]="{ 'blurred': !isLoggedIn }" style="color: #5BCD65; font-weight: 600;"><img src="../../../../assets/upwardArrowIcon.svg" alt=""> {{ data?.pb_details?.cagr ?? 0 }} %</div>
                </div> -->
                <div style="display: flex; justify-content: flex-start;">
                    <div>
                        <div>Investment period</div>
                        <div style="font-weight: 800;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">
                            {{ data?.investment_horizon }}
                        </div>
                    </div>
                </div>
                <div>                 
                    <div>Min Investment </div>
                    <div><i class="fa fa-rupee"></i> &nbsp;<span style="font-weight: 800;" [style.color]="i % 2 !== 0 ? 'black' : 'white'">{{data?.pb_details?.min_investment_amount ?? 0 | decimalFormat}}</span></div>
                </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div style="display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px">
                    <div>
                    <app-button 
                                [savingsText]="data?.is_getting_renewed 
                                ? 'Renew Now' 
                                : !data?.is_subscribed && !data?.is_getting_onboard
                                ? 'Subscribe Now' 
                                : !data?.is_subscribed && data?.is_getting_onboard
                                ? 'Continue'
                                : data?.portfolio_state === 'fix_investment' 
                                ? 'Fix Investment' 
                                : data?.portfolio_state === 'rebalance_now' 
                                ? 'Rebalance Now' 
                                : data?.portfolio_state === 'invest_now' 
                                ? 'Invest Now'
                                : data?.portfolio_state === 'invest_more' 
                                ? 'Invest More' 
                                : data?.portfolio_state === 'rebalance_in_progress' 
                                ? 'Rebalancing...' 
                                : data?.portfolio_state === 'order_in_progress' 
                                ? 'Order Pending'
                                : 'No Action Available'"
                                    class="center"
                                    [backgroundColor]="data?.portfolio_state === 'fix_investment' || data?.portfolio_state === 'rebalance_now' ? '#E17466' : '#C3966E'"
                                    (click)="subscribeBasket(data, i); $event.stopPropagation()">
                    </app-button>
                    </div>
                    <div>
                    <app-button [savingsText]="'Remove'" [isTransparentBTN]="true" (click)="removeWatchList(data)"></app-button>
                    </div>
                    <!-- <div><app-button [savingsText]="'Subscribe Now'" (click)="subscribeBasket(data, i)"></app-button></div> -->
                </div>
            </div>
            <div style="    display: flex;
            justify-content: center;
            padding-top: 20px;">
                <ng-container *ngIf="i % 2 === 0">
                <img src="../../../../assets/hrMobIcon.svg" alt="">
                </ng-container>
                <ng-container *ngIf="i % 2 !== 0">
                    <img src="../../../../assets/hrDarkMobIcon.svg" alt="">
                </ng-container>
            </div>
        </div>
    </div>
    </div>
    <div *ngIf="emptyState" style="display: flex; justify-content: center; padding: 40px;">
        <img src="../../../../assets/Empty_State_No Data_Found.svg" alt="">
    </div>
    <app-footer *ngIf="isDataLoaded"></app-footer>
</div>