import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  
  constructor(private router: ActivatedRoute) {}

  public hashKey: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public paymentURL: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public onbaording_detailID: BehaviorSubject<any> = new BehaviorSubject(
    undefined
  );

  pushHashKey(id: any) {
    this.hashKey.next(id);
    localStorage.setItem('hashKey', id);
  }

  pushPaymentURL(paymentURL: any) {
    this.paymentURL.next(paymentURL);
    localStorage.setItem('paymentId', paymentURL);
   }

  pushOboardingDetailID(id: any) {
    this.onbaording_detailID.next(id);
    localStorage.setItem('onboarding_detail_id', id);
  }

  getOboardingDetailID() {
    let id: any;
    id = this.onbaording_detailID;
    return localStorage.getItem('onboarding_detail_id');
  }

  getHashKey() {
    let id: any;
    id = this.hashKey;
    return localStorage.getItem('hashKey');
  }

  getPaymentURL() {
    return localStorage.getItem('paymentId');
  }
}
