<div class="notification-wrapper">
    <div class="notification" *ngIf="notificationsList.length === 0">
        <span>No data found</span>
    </div>
    <div style="display: flex; border-bottom: 1px solid #ccc;">
    <span style="font-size: 15px; padding: 10px; font-weight: 700;">Notifications</span>
    <span class="markAllRead" (click)="markAllAsRead()"
    ><a>Mark all as read</a></span>
    </div>
    <div class="notification" *ngFor="let data of notificationsList">
        <span>
            <img src="../../../../assets/notification_Icon.svg" alt="">
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span style="font-size: 16px; font-weight: 400;">{{ data }}</span>
    </div>    
    <span class="viewAll" (click)="viewAllNotification()"><a style="border-bottom: 1px solid #001E5A;">View All</a><img src="../../../../assets/viewAll.svg" alt=""></span>
</div>
