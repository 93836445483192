<div
  class="label-all {{ class }}"
  [id]="id"
  [class.label-title]="labelType == 'title'"
  [class.label-sub-title]="labelType == 'subtitle'"
  [class.label-message]="labelType == 'message'"
  [class.label-normal]="labelType == 'normal'"
>
  {{ text }}<span *ngIf="isSpaceRequired">&nbsp;</span
  ><span class="required" *ngIf="required">*</span>
</div>
