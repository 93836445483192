import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SmallCaseIntegrationService } from '../../services/small-case-integration.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { ApiServiceService } from '../../services/api-service.service';
import { ScreenTypeService } from '../../services/screen-type.service';
import { MoengageService } from '../../services/moengage.service';

@Component({
  selector: 'app-investement-modal-dashboard',
  templateUrl: './investement-modal-dashboard.component.html',
  styleUrl: './investement-modal-dashboard.component.scss'
})

export class InvestementModalDashboardComponent implements OnInit {
  amountToInvest: any;
  getAuthToken: any;
  customer_Id: any;
  smallCaseAuthToken: any;
  isGuestInit: any;
  product_code: any;
  stocksList: any[] = [];
  securitiesList: any[] = [];
  minInvestementAmount: any;
  subProductId: any;
  buttonColor: string = '#C3966E';
  productRisk: any;

  @Output() transactionResponse = new EventEmitter<any>();
  @Output() transactionError = new EventEmitter<any>();
  @Output() transactionID = new EventEmitter<any>();
  @Output() authTokenInit = new EventEmitter<any>();
  @Output() isGuest = new EventEmitter<any>();
  onboardingId: any;
  investementType: any;
  actionType: any;
  moeEventData: any;

  constructor(
    public screenType: ScreenTypeService,
    private user_service: UserService,
    private apiService: ApiServiceService,
    private toast_message: ToastrService,
    private api_service: SmallCaseIntegrationService,
    private moengageService: MoengageService,
    public dialogRef: MatDialogRef<InvestementModalDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    let token = '';
    token = localStorage.getItem('authToken') || '';
    this.getAuthToken = token;
    if (token) {
      const jsonString = this.apiService.decodeToken(token.split('.')[1]);
      const data = JSON.parse(jsonString);
      console.log('ngOnInit token data........', data.customer_id);
      this.customer_Id = data.customer_id;
    }
  }
  ngOnInit(): void {
    this.getDataFromRedis();
    this.minInvestementAmount = this.data.minimumInvestmentAmount;
    this.onboardingId = this.data.onboardingId;
    this.amountToInvest = this.minInvestementAmount;
    this.investementType = this.data.investementType;
    this.actionType = this.data.actionType;
    this.productRisk = this.data.productRisk;
  }

  changeColor(event: Event) {
    const element = event.currentTarget as HTMLElement;
    const allElements = document.querySelectorAll('.defaultMinInvestement');
    allElements.forEach(el => el.classList.remove('clicked'));
    element.classList.add('clicked');
    let value = element.textContent?.trim();
    console.log('Clicked value:', value);
    if(value) {
      value = value.replaceAll(',', '');
      this.amountToInvest = value;
      this.getPortfolioData(this.product_code);
    }
  }

  onSubmitAmount() {
    if(Number(this.amountToInvest)>500000) {
      this.toast_message.error('Amount above 5 Lakhs is not allowed');
      this.stocksList = [];
    }
    else {
      this.getPortfolioData(this.product_code);
    }
  }

  getDataFromRedis() {
    this.apiService.getDataFromRedis(this.user_service.getHashKey()).subscribe((res: any) => {
      if(res && res.success) {
        console.log('got dashboard redis',res);
        this.product_code = this.data.product_code;
        this.subProductId = this.data.sub_product_id;
        if(!this.subProductId) {
          this.subProductId = res.data.subProductId;
        }
        else {
          this.subProductId = this.data.sub_product_id;
        }
        this.getPortfolioData(this.product_code);
      }
     })
  }

  getActionType(actionType: any) {
    switch(actionType) {
      case 'Fix Investment':
        return 'fix_investment';
      case 'Rebalance Now':
        return 'rebalance';
      case 'Invest More':
        return 'invest_more';
      case 'Invest Now':
        return 'invest_now';
      default:
        return 'Unknown Action';
    }
  }

  getPortfolioData(product_code: any) {
  let payload = {
    customer_id: this.customer_Id,
    investment_amount: this.amountToInvest,
    code_id: product_code,
    customerId: this.customer_Id
  }
  if(this.actionType == 'Fix Investment' || this.actionType == 'Rebalance Now') {
    this.buttonColor = '#E17466';
    this.stocksList = this.data.fixInvestmentData;
    this.securitiesList = [];
      for(let data of this.stocksList) {
      let securitiesData = {
       fincode: data.fincode, 
       ticker: data.symbol,
       quantity: data.quantity,
       type: data.transaction_type
      }
      this.securitiesList.push(securitiesData);
      console.log('get security list', this.securitiesList);
      }
  }
  else {
    this.apiService.getPortfolioDetails(payload).subscribe((res: any) => {
      if(res && res.success && res.data.status) {
      // call moengage event
        if(this.minInvestementAmount !== Number(this.amountToInvest)) {
          let eventData = {
            'basketName' : this.data?.productName?.jar_name,
            'minInvestmentAmount' : Number(this.minInvestementAmount),
            'newInvestmentAmount' : Number(this.amountToInvest),
            'numberOfStocks' : this.stocksList.length
          }
          console.log(eventData);
          // this.moengageService.investmentAmountChange(eventData);
        }
      //
      this.stocksList = res.data.transactions;
      this.securitiesList = [];
      for(let data of this.stocksList) {
      let securitiesData = {
       fincode: data.fincode,
       ticker: data.symbol,
       quantity: data.quantity,
       type: data.transaction_type
      }
      this.securitiesList.push(securitiesData);
      console.log('get security list', this.securitiesList);
      }
      }
      else {
       this.stocksList = [];
       this.toast_message.error(`${res.data.message}`);
      }
     });
  }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  createPortfolio() {
    let payload = {
      customer_id: this.customer_Id,
      investment_amount: this.amountToInvest,
      code_id: this.product_code
    }
    console.log('payload...', payload);
    this.apiService.createPortfolioService(payload, this.investementType).subscribe((res: any) => {
      console.log('create portfolio called..', res);
    })
  }

callMoeEvent() {
  const str = this.actionType;
  const result = str?.replace(/\s+/g, '');
  this.moeEventData = {
    'eventType': result + 'Clicked',
    'CategoryName': localStorage.getItem('categoryNameMoe') || '',
    'BasketName': this.data?.productName?.jar_name,
    'BasketRisk': this.productRisk,
    'ReturnsDuration': this.data?.productName?.invest_horizon,
    'BasketMinimumInvestment': this.minInvestementAmount,
    'NewInvestmentAmount': Number(this.amountToInvest),
    'NumberOfStocks': this.stocksList?.length
  }
  
  this.moengageService.dashboardEvents(this.moeEventData);
}

initiateSmallCase() {
this.callMoeEvent();
this.getPortfolioData(this.product_code);
if(this.securitiesList.length>0) {
//commenting create portfolio api
// this.createPortfolio();
//
const reqData = {
  user_medium_src: 'multiplyrr',
  user_medium_id: this.customer_Id,
}
this.api_service.getAuthToken(reqData).subscribe((authTokenRes: any) => {
console.log('get token res...',authTokenRes);
console.log('got window object....', window);
this.smallCaseAuthToken = authTokenRes.data.jwt;
this.isGuestInit = authTokenRes.data.isGuest;
// Initiate SmallCase
const gatewayInstance = new (window as any).scDK({
  gateway: "researchandranking",
  smallcaseAuthToken: `${authTokenRes.data.jwt}`,
  config: {
    amo: true
  }
})
//
//get transaction details
let reqData = {
  "user_medium_src": "multiplyrr",
  "user_medium_id": this.customer_Id,
  "securites": this.securitiesList,
  "sub_product_id": this.subProductId,
  "sub_product_code_id": this.product_code,
  "transaction_init_type": this.getActionType(this.actionType),
}
this.api_service.getTransactionDetails(reqData, this.smallCaseAuthToken).subscribe((transactionRes: any) => {
  console.log('get transaction details....', transactionRes);
  gatewayInstance.triggerTransaction({
    transactionId: `${transactionRes.data}`
  })
  .then((txnResponse:any) => {
    console.log('received response:', txnResponse);
    this.transactionResponse.emit(txnResponse);
    console.log('isGuest..', this.isGuestInit);
    this.isGuest.emit(this.isGuestInit);
  })
  .catch((err:any) => {
    // this.toast_message.error(err.message);
    console.log('received error:', err);
    this.authTokenInit.emit(this.smallCaseAuthToken);
    this.transactionID.emit(transactionRes.data);
    this.transactionError.emit(err);
  });
})
//
})
    }
    else {
    this.toast_message.error('Please enter amount to get list of stocks');
    }
  }

}
