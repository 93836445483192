<div style="display: flex;">
<div [ngStyle]="{ 'width': isMobile ? '100%' : '60%', 'display': 'flex', 'flex-direction': 'column' }">
<div *ngFor="let item of list" 
class="list-data"
[class.selected]="selectedItem === item || selectedQue.label === item.label"
(click)="onItemClick(item)">
<!-- *ngIf="item.label == '0-10 Lacs' || item.label == '10-25 Lacs' || item.label == '25-50 Lacs' || item.label == '50 Lacs+ '" -->
<i *ngIf="item.label.includes('Lacs') || item.label.includes('lacs')" class="fa fa-inr" aria-hidden="true"></i> {{item.label}}
<!-- <img src="../../../../../assets/forwardIconRiskProfile.svg" *ngIf="selectedItem !== item" style="float: right;" alt="">
<img src="../../../../../assets/forwardIconRiskProfileSelected.svg" *ngIf="selectedItem === item" style="float: right;" alt=""> -->
<div class="error-container-wrapper" *ngIf="item.error && item.error != '' && item.selected">
    <mat-icon class="error-icon">error_outline</mat-icon>
    <span class="label-error">{{item.error}}</span>
</div>
</div>
</div>
<div>
<!-- <img src="../../../../../assets/bull_logo.svg" alt=""> -->
</div>
</div>
