import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from '../../services/web-socket.service';
import { Router } from '@angular/router';
declare let Moengage: any;

@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss'],
})
export class UserDeleteComponent implements OnInit {
 isActiveSubscription: boolean = false;
 successDiv: boolean = false;
 mainDiv: boolean = true;

 constructor(
  private apiService: ApiServiceService,
  private _toastSerivce: ToastrService,
  private websocket: WebSocketService,
  private router: Router
 ) {}

  ngOnInit() {
    const activeSubscription = localStorage.getItem('ActiveSubscription');
    this.isActiveSubscription = activeSubscription === 'true';
  }


  deleteAccount() {
    if (!localStorage.getItem('authToken')) {
      this._toastSerivce.error('Please login to delete your account');
    }
    else {
      this.apiService.deleteUserAccount().subscribe((res: any) => {
        if(res && res.success) {
          this._toastSerivce.success('Account deleted successfully!');
          this.successDiv = true;
          this.mainDiv = false;
          Moengage.destroy_session();
          localStorage.removeItem('authToken');
          this.websocket.disconnect();
          this.router.navigate(['']);
        }
        else {
          this._toastSerivce.error('Something went wrong, please try again later!');
        }
       });
    }
  }

}
