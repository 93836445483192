import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar-mobile',
  templateUrl: './toolbar-mobile.component.html',
  styleUrls: ['./toolbar-mobile.component.scss'],
})
export class ToolbarMobileComponent implements OnInit {
  homeSelectedIcon: string = 'homeMobIcon.svg';
  dahboardSelectedIcon: string = 'dashboardMobIcon.svg';
  discoverSelectedIcon: string = 'discoverMobIcon.svg';
  watchListSelectedIcon: string = 'watchlistMobIcon.svg';
  selectedItem: string = '';
  constructor(private route: Router) {}

  ngOnInit() {
    // if (window.location.href.includes('dashboard')) {
    //   this.dahboardSelectedIcon = 'dashboardMobIconSelected.svg';
    // } else {
    //   this.homeSelectedIcon = 'homeMobIconSelected.svg';
    // }

    this.getSelectedRoute();
  }

  getSelectedRoute() {
    let urlSegment = this.route.url.split('/')[1];
    switch (urlSegment) {
      case '':
        this.selectedItem = 'home';
        this.homeSelectedIcon = 'homeMobIconSelected.svg';
        break;
      case 'discover':
        this.selectedItem = 'discover';
        this.discoverSelectedIcon = 'discoverMobIconSelected.svg';
        break;
      case 'dashboard':
        this.selectedItem = 'dashboard';
        this.dahboardSelectedIcon = 'dashboardMobIconSelected.svg';
        break;
      case 'watchlist':
        this.selectedItem = 'watchlist';
        this.watchListSelectedIcon = 'watchlistMobIconSelected.svg';
        break;
    }
  }

  navigateTo(item: string) {
    switch (item) {
      case 'home':
        this.route.navigate(['']);
        break;
      case 'discover':
        this.route.navigate(['discover']);
        break;
      case 'dashboard':
        this.route.navigate(['dashboard']);
        break;
      case 'watchlist':
        this.route.navigate(['watchlist']);
        break;
      default:
        break;
    }
  }


  goToHome() {
    this.route.navigate(['']);
  }
  goToDiscover() {
    this.route.navigate(['discover']);
  }
  goToDashboard() {
    this.route.navigate(['dashboard']);
  }
  goToWatchlist() {
    this.route.navigate(['watchlist']);
  }
}
