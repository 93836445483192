import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ScreenTypeService } from 'src/app/core/services/screen-type.service';
import { UserAlreadyExistsComponent } from '../user-already-exists/user-already-exists.component';

@Component({
  selector: 'app-order-in-progress',
  templateUrl: './order-in-progress.component.html',
  styleUrls: ['./order-in-progress.component.scss'],
})
export class OrderInProgressComponent implements OnInit {
  modalType: any;
  constructor(public screenType: ScreenTypeService,
    private route: Router,
    public dialogRef: MatDialogRef<UserAlreadyExistsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
  this.modalType = this.data.modalType;
  }
  
  closeDialog(): void {
    this.dialogRef.close();
  }
}
